import React, {Component} from 'react';
import {ids, strings} from "../Configuration/Config";
import {Col, Form} from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';

class Step1 extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        if(evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.value;

        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    render() {
        if (this.props.currentStep !== 1 && this.props.data.wizard) { // Prop: The current step
            return null
        }

        // The markup for the Step 1 UI
        return (
            <div>
                <Form.Row>
                    <Form.Group column="lg" lg={8} as={Col} controlId={ids.name}>
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            onChange={this.handleChange}
                            defaultValue={this.props.data[ids.name]}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId={ids.age}>
                        <Form.Label>{strings.age}</Form.Label>
                        <Form.Control onChange={this.handleChange} defaultValue={this.props.data[ids.age]}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId={ids.date}>
                        <Form.Label>{strings.date}</Form.Label>
                        <Form.Control onChange={this.handleChange} defaultValue={this.props.data[ids.date]}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId={ids.healthProblem}>
                        <Form.Label>{strings.healthProblem}</Form.Label>
                        <Form.Control
                            className="text-area-ctrl"
                            as="textarea"
                            onChange={this.handleChange}
                            defaultValue={this.props.data[ids.healthProblem]}
                            aria-describedby={ids.healthProblem}
                        />
                    </Form.Group>
                </Form.Row>
            </div>
        )
    }
}

export default Step1