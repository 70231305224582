import React, {Component} from 'react'
import ReactChipInput from "react-chip-input";
import Select from 'react-select'
import ResultsActionBar from './Filter/ResultsActionBar'
import ProductInfo from './ProductInfo'
import {fytoIds} from './ProductInfo'
import FytoCards from '../Cards/Cards'
import Headroom from 'react-headroom'
import {reactLocalStorage} from 'reactjs-localstorage';
import MeaningTable from "../MeaningTable/MeaningTable";
import HerbsTable from "../HerbsTable/HerbsTable";
import CompareCards from "../CompareTable/CompareCards";
import {fyto_data} from '../data/Data';

import './Products.css';
import HerbInfo from "./HerbInfo";

const keys = {
    searchString: {value: 'searchstring', label: '', tooltip: ''},
    compareString: {value: 'compareString', label: '', tooltip: ''},
    meaningTableVisibility: {value: 'meaningTableVisibility', label: '', tooltip: ''},
    herbsTableVisibility: {value: 'herbsTableVisibility', label: '', tooltip: ''},
    compareTableVisibility: {value: 'compareTableVisibility', label: '', tooltip: ''},

    all: {value: 'all', label: 'vše', tooltip: ''},
    herb: {value: 'herb', label: 'bylinky', tooltip: ''},
    // keywords: {value: 'all', label: 'vše', tooltip:''}"keywords",
    name: {value: 'name', label: 'název směsi', tooltip: ''},
    other: {value: 'other', label: 'ostatní', tooltip: ''},
    usage: {value: 'usage', label: 'použití', tooltip: ''},
    //description: {value: 'description', label: 'popis', tooltip:''},
    impact: {value: 'impact', label: 'účinky', tooltip: ''},
    // indication: {value: 'indication', label: 'indikace', tooltip:''},
    //combination: {value: 'combination', label: 'kombinace', tooltip:''},
    note: {value: 'note', label: 'poznámka', tooltip: ''},
    // dosage: {value: 'dosage', label: 'dávkování', tooltip:''},
    // explanation: {value: 'explanation', label:'vysvětlení', tooltip:''},
    maciocia: {value: 'maciocia', label: 'Maciocia směsi (Tři poklady)', tooltip: ''},
    maciocialt: {value: 'maciocialt', label: 'Maciocia směsi (Malé poklady)', tooltip: ''},
    maciociawt: {value: 'maciociawt', label: 'Maciocia směsi (ženské poklady)', tooltip: ''},
    sinecura: {value: 'sinecura', label: 'Sinecura & Leungovy směsi', tooltip: ''},
    mushrooms: {value: 'mushrooms', label: 'Houby', tooltip: ''},
    id: {value: 'id', label: 'id', tooltip: ''},
    searchAnd: {value: 'searchAnd', label: 'hledat zároveň', tooltip: ''},
    compareProductsTable: {value: 'compareProductsTable', label: 'porovnat směsi', tooltip: ''}
};

//let CryptoJS = require("crypto-js");

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            herbs: [],
            allProducts: [],
            filteredProducts: [],
            comparedProducts: [],
            filters: {},
            ready: false,
            searchString: "",
            compareString: "",
            visibleProductInfoForm: false,
            visibleHerbInfoForm: false,
            productId: "",
            herbId: "",
            currentPage: 1,
            isJSON: true,
            cryptJSON: false,
            visibleTableOfMeanings: false,
            visibleTableOfHerbs: false,
            visibleCompareTableOfHerbs: false,
            test: false
        };

        //this.onLoadSinecura = this.onLoadSinecura.bind(this);
        //this.onLoadHerbs = this.onLoadHerbs.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.addChip = this.addChip.bind(this);
        this.removeChip = this.removeChip.bind(this);
        this.storeCurrentPage = this.storeCurrentPage.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.fytoOptionChange = this.fytoOptionChange.bind(this);
        this.getCurrentFytoOptions = this.getCurrentFytoOptions.bind(this);
        this.showProductInfoForm = this.showProductInfoForm.bind(this);
        this.showHerbInfoForm = this.showHerbInfoForm.bind(this);
        this.scrollToProduct = this.scrollToProduct.bind(this);
        this.closeProductInfoForm = this.closeProductInfoForm.bind(this);
        this.showHome = this.showHome.bind(this);
        this.handleMeaningTableVisibility = this.handleMeaningTableVisibility.bind(this);
        this.onSearchAnd = this.onSearchAnd.bind(this);
        this.onCompareProducts = this.onCompareProducts.bind(this);
        this.compareProducts = this.compareProducts.bind(this);
        //this.filterSellableItems = this.filterSellableItems.bind(this);
        this.handleHerbsTableVisibility = this.handleHerbsTableVisibility.bind(this);
        this.getHerbsData = this.getHerbsData.bind(this);
        this.getFytoOptions = this.getFytoOptions.bind(this);
        this.saveData = this.saveData.bind(this);
        this.arrayUnique = this.arrayUnique.bind(this);

        //develop
        this.testDuplication = this.testDuplication.bind(this);


    }

    saveData() {
        //save data
        let _filters = this.state.filters;
        reactLocalStorage.set(keys.all.value, _filters[keys.all.value]);
        reactLocalStorage.set(keys.sinecura.value, _filters[keys.sinecura.value]);
        reactLocalStorage.set(keys.maciocia.value, _filters[keys.maciocia.value]);
        reactLocalStorage.set(keys.maciocialt.value, _filters[keys.maciocialt.value]);
        reactLocalStorage.set(keys.maciociawt.value, _filters[keys.maciociawt.value]);
        reactLocalStorage.set(keys.mushrooms.value, _filters[keys.mushrooms.value]);
        reactLocalStorage.set(keys.herb.value, _filters[keys.herb.value]);
        reactLocalStorage.set(keys.name.value, _filters[keys.name.value]);
        reactLocalStorage.set(keys.usage.value, _filters[keys.usage.value]);
        reactLocalStorage.set(keys.impact.value, _filters[keys.impact.value]);
        reactLocalStorage.set(keys.other.value, _filters[keys.other.value]);
        reactLocalStorage.set(keys.note.value, _filters[keys.note.value]);
        reactLocalStorage.set(keys.searchString, this.state.searchString);
        reactLocalStorage.set(keys.compareString, this.state.compareString);
        reactLocalStorage.set(keys.meaningTableVisibility, this.state.visibleTableOfMeanings);
        reactLocalStorage.set(keys.herbsTableVisibility, this.state.visibleTableOfHerbs);
        reactLocalStorage.set(keys.compareTableVisibility, this.state.visibleCompareTableOfHerbs);
        reactLocalStorage.set(keys.searchAnd.value, _filters[keys.searchAnd.value]);
    }

    componentWillMount() {
        let _filters = this.state.filters;
        let all = reactLocalStorage.get(keys.all.value);
        let herb = reactLocalStorage.get(keys.herb.value);
        let name = reactLocalStorage.get(keys.name.value);
        let impact = reactLocalStorage.get(keys.impact.value);
        let usage = reactLocalStorage.get(keys.usage.value);
        let other = reactLocalStorage.get(keys.other.value);
        let note = reactLocalStorage.get(keys.note.value);
        let sinecura = reactLocalStorage.get(keys.sinecura.value);
        let maciocia = reactLocalStorage.get(keys.maciocia.value);
        let maciocialt = reactLocalStorage.get(keys.maciocialt.value);
        let maciociawt = reactLocalStorage.get(keys.maciociawt.value);
        let mushrooms = reactLocalStorage.get(keys.mushrooms.value);
        let searchAnd = reactLocalStorage.get(keys.searchAnd.value);
        let searchString = reactLocalStorage.get(keys.searchString.value);
        let compareString = reactLocalStorage.get(keys.compareString.value);
        let meaningTableVisibility = reactLocalStorage.get(keys.meaningTableVisibility.value);
        let herbsTableVisibility = reactLocalStorage.get(keys.herbsTableVisibility.value);
        let visibleCompareTableOfHerbs = reactLocalStorage.get(keys.compareTableVisibility.value);

        // let _data = data;

        _filters[keys.all.value] = all === undefined ? true : (all === "true");
        _filters[keys.herb.value] = herb === undefined ? true : (herb === "true");
        _filters[keys.name.value] = name === undefined ? true : (name === "true");
        _filters[keys.usage.value] = usage === undefined ? true : (usage === "true");
        _filters[keys.impact.value] = impact === undefined ? true : (impact === "true");
        _filters[keys.other.value] = other === undefined ? true : (other === "true");
        _filters[keys.note.value] = note === undefined ? true : (note === "true");
        _filters[keys.sinecura.value] = sinecura === undefined ? true : (sinecura === "true");
        _filters[keys.maciocia.value] = maciocia === undefined ? true : (maciocia === "true");
        _filters[keys.maciocialt.value] = maciocialt === undefined ? true : (maciocialt === "true");
        _filters[keys.maciociawt.value] = maciociawt === undefined ? true : (maciociawt === "true");
        _filters[keys.mushrooms.value] = mushrooms === undefined ? true : (mushrooms === "true");
        _filters[keys.searchAnd.value] = searchAnd === undefined ? false : (searchAnd === "true");

        let isMeaningTableVisible = meaningTableVisibility === undefined ? false : (meaningTableVisibility === "true");
        let isHerbsTableVisible = herbsTableVisibility === undefined ? false : (herbsTableVisibility === "true");
        let isCompareHerbsTableVisible = visibleCompareTableOfHerbs === undefined ? false : (visibleCompareTableOfHerbs === "true");

        if (searchString === undefined)
            searchString = "";

        let tempData = this.state.allProducts;
        tempData = tempData.concat(fyto_data.sina);
        tempData = tempData.concat(fyto_data.sinb);
        tempData = tempData.concat(fyto_data.sind);
        tempData = tempData.concat(fyto_data.sine);
        tempData = tempData.concat(fyto_data.sinf);
        tempData = tempData.concat(fyto_data.sinh);
        tempData = tempData.concat(fyto_data.sinm);
        tempData = tempData.concat(fyto_data.sinn);
        tempData = tempData.concat(fyto_data.sinw);
        tempData = tempData.concat(fyto_data.sinx);
        tempData = tempData.concat(fyto_data.maci);
        tempData = tempData.concat(fyto_data.mushrooms);

        //text for identity
        this.testDuplication(tempData);

        // comparedProducts

        let that = this;
        that.setState({
            herbs: fyto_data.herbs,
            allProducts: tempData,
            filters: _filters,
            searchString: searchString,
            compareString: compareString,
            visibleTableOfMeanings: isMeaningTableVisible,
            visibleTableOfHerbs: isHerbsTableVisible,
            visibleCompareTableOfHerbs: isCompareHerbsTableVisible,
            ready: true
        }, () => {
            that.onSearch(that.state.searchString);
        });
    };

    testDuplication(data) {
        if (!this.state.test)
            return;

        let arrayLength = data.length;
        for (let i = 0; i < arrayLength; i++) {
            //        console.log(data[i]);
            const item1 = data[i];
            const item1Id = item1[keys.id.value];
            //Do something
            for (let j = i + 1; j < arrayLength; j++) {
                const item2 = data[j];
                const item2Id = item2[keys.id.value];

                if (item1Id === item2Id)
                    console.log(item1Id);

            }
        }
    }

    storeCurrentPage(page) {
        this.setState({
            currentPage: page
        })
    }

    onClearSearch() {
        this.onSearch("");
    }

    getHerbsData(data, id) {
        if (data.length < 1) return undefined;

        let herb = data.Herbs.filter(item => {
            return item.id === id;
        });

        return herb.length > 0 ? herb[0] : undefined;
    }

    addChip(value) {
        let searchText = this.state.searchString;
        searchText = searchText.length > 0 ? searchText + "," + value : value;

        let that = this;
        that.setState({
            searchString: searchText
        }, () => {
            that.onSearch(searchText);
            that.saveData();
        });
    };

    removeChip(index) {
        let chips = this.state.searchString.split(",");
        chips.splice(index, 1);
        let searchText = "";
        chips.forEach(item => {
            searchText = searchText.length > 0 ? searchText + "," + item : item;
        });

        let that = this;
        that.setState({
            searchString: searchText
        }, () => {
            that.onSearch(searchText);
            that.saveData();
        });
    }

    arrayUnique(array) {
        let a = array.concat();
        for (let i = 0; i < a.length; ++i) {
            for (let j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a;
    }

    onSearch(searchString, update = true) {
        let _searchString = searchString;
        //let isBuyable = true;//this.state.filters[keys.buyable];
        let isSinecura = this.state.filters[keys.sinecura.value];
        let isMaciocia = this.state.filters[keys.maciocia.value];
        let isMaciociaLt = this.state.filters[keys.maciocialt.value];
        let isMaciociaWt = this.state.filters[keys.maciociawt.value];
        let isMushrooms = this.state.filters[keys.mushrooms.value];
        if (_searchString.length > 0) {
            let isName = this.state.filters[keys.name.value];
            let isHerb = this.state.filters[keys.herb.value];
            let isUsage = this.state.filters[keys.usage.value];
            let isImpact = this.state.filters[keys.impact.value];
            let isOther = this.state.filters[keys.other.value];
            let isID = this.state.filters[keys.id.value];
            let searchAnd = this.state.filters[keys.searchAnd.value];
            let isNote = this.state.filters[keys.note.value] ? this.state.filters[keys.note.value] : false;

            let _filteredProducts1 = this.state.allProducts;
            // let _filteredProducts3 = this.state.products_tcm;
            // let _filteredProducts2 = this.state.products_maccioccia;
            if (searchAnd)
                _searchString = _searchString.replaceAll(",", "@");
            let bAndOperator = _searchString.indexOf("@") > 0;
            let bOrOperator = _searchString.indexOf(",") > 0;

            let _filteredProducts = [];

            if (bAndOperator) {
                _searchString = _searchString.split('@');
                for (let i = 0; i < _searchString.length; i++) {
                    let __searchString = _searchString[i].trim().toLowerCase();
                    _filteredProducts1 = _filteredProducts1.filter(product => {
                        if (!isSinecura) {
                            if (!product.key)
                                return false;
                        }

                        if (!isMaciocia) {
                            if (product.key && product.key === keys.maciocia.value)
                                return false;
                        }

                        if (!isMaciociaLt) {
                            if (product.key && product.key === keys.maciocialt.value)
                                return false;
                        }

                        if (!isMaciociaWt) {
                            if (product.key && product.key === keys.maciociawt.value)
                                return false;
                        }

                        let isMushroom = (product.key && product.key === keys.mushrooms.value);

                        if (!isMushrooms && isMushroom) {
                            return false;
                        }

                        if (isHerb && !isMushroom) {
                            const items = product.Herbs.filter(_item => {
                                let item = this.getHerbsData(this.state.herbs, _item.id);
                                if (item === undefined)
                                    item = _item;
                                return (item.name && item.name.toLowerCase().match(__searchString)) ||
                                    (item.orig && item.orig.toLowerCase().match(__searchString)) ||
                                    (item.group && item.group.toLowerCase().match(__searchString)) ||
                                    (item.number && item.number.toLowerCase().match(__searchString));
                            });

                            if (items.length > 0)
                                return true;
                        }

                        if (isOther && product.description && product.description.toLowerCase().match(__searchString)) {
                            return true;
                        }

                        if (isName && ((product.name && product.name.toLowerCase().match(__searchString)) ||
                            (product.orig && product.orig.toLowerCase().match(__searchString)))) {
                            return true;
                        }

                        if (isUsage) {
                            if (product.Usage && product.Usage.length) {
                                const items = product.Usage.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Usage && product.Usage.description && product.Usage.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isImpact) {
                            if (product.Impact && product.Impact.length) {
                                const items = product.Impact.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Impact && product.Impact.description && product.Impact.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isOther) {
                            if (product.Combination && product.Combination.length) {
                                const items = product.Combination.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Combination && product.Combination.description && product.Combination.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isNote) {
                            if (product.Note && product.Note.length) {
                                const items = product.Note.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Note && product.Note.description && product.Note.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        return (isOther && product.explanation && product.explanation.toLowerCase().match(__searchString));
                    });
                }

                _filteredProducts = _filteredProducts1;//.concat(_filteredProducts2).concat(_filteredProducts3);
            } else if (bOrOperator) {
                _searchString = _searchString.split(',');
                for (let i = 0; i < _searchString.length; i++) {
                    let __searchString = _searchString[i].trim().toLowerCase();
                    if (__searchString.length === 0) continue;
                    _filteredProducts1 = this.state.allProducts.filter(product => {
                        if (!isSinecura) {
                            if (!product.key)
                                return false;
                        }

                        if (!isMaciocia) {
                            if (product.key && product.key === keys.maciocia.value)
                                return false;
                        }

                        if (!isMaciociaLt) {
                            if (product.key && product.key === keys.maciocialt.value)
                                return false;
                        }

                        if (!isMaciociaWt) {
                            if (product.key && product.key === keys.maciociawt.value)
                                return false;
                        }

                        let isMushroom = (product.key && product.key === keys.mushrooms.value);

                        if (!isMushrooms && isMushroom) {
                            return false;
                        }

                        if (isID && product.id && product.id.toLowerCase().match(__searchString)) {
                            return true;
                        }

                        if (isHerb && !isMushroom) {
                            const items = product.Herbs.filter(_item => {
                                let item = this.getHerbsData(this.state.herbs, _item.id);
                                if (item === undefined)
                                    item = _item;
                                return (item.name && item.name.toLowerCase().match(__searchString)) ||
                                    (item.orig && item.orig.toLowerCase().match(__searchString)) ||
                                    (item.group && item.group.toLowerCase().match(__searchString)) ||
                                    (item.number && item.number.toLowerCase().match(__searchString));
                            });

                            if (items.length > 0)
                                return true;
                        }

                        if (isOther && product.description && product.description.toLowerCase().match(__searchString)) {
                            return true;
                        }

                        if (isOther && product.explanation && product.explanation.toLowerCase().match(__searchString)) {
                            return true;
                        }

                        if (isName && ((product.name && product.name.toLowerCase().match(__searchString)) ||
                            (product.orig && product.orig.toLowerCase().match(__searchString)))) {
                            return true;
                        }

                        if (isUsage) {
                            if (product.Usage && product.Usage.length) {
                                const items = product.Usage.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Usage && product.Usage.description && product.Usage.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isImpact) {
                            if (product.Impact && product.Impact.length) {
                                const items = product.Impact.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Impact && product.Impact.description && product.Impact.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isOther) {
                            if (product.Combination && product.Combination.length) {
                                const items = product.Combination.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Combination && product.Combination.description && product.Combination.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        if (isNote) {
                            if (product.Note && product.Note.length) {
                                const items = product.Note.filter(item => {
                                    return item.description && item.description.toLowerCase().match(__searchString);
                                });

                                if (items.length > 0)
                                    return true;
                            } else {
                                if (product.Note && product.Note.description && product.Note.description.toLowerCase().match(__searchString))
                                    return true;
                            }
                        }

                        return (isOther && product.explanation && product.explanation.toLowerCase().match(__searchString));
                    });

                    _filteredProducts = this.arrayUnique(_filteredProducts.concat(_filteredProducts1));//.concat(_filteredProducts1).concat(_filteredProducts2).concat(_filteredProducts3);
                }
            } else {
                let __searchString = _searchString.trim().toLowerCase();
                _filteredProducts1 = _filteredProducts1.filter(product => {
                    if (!isSinecura) {
                        if (!product.key)
                            return false;
                    }

                    if (!isMaciocia) {
                        if (product.key && product.key === keys.maciocia.value)
                            return false;
                    }

                    if (!isMaciociaLt) {
                        if (product.key && product.key === keys.maciocialt.value)
                            return false;
                    }

                    if (!isMaciociaWt) {
                        if (product.key && product.key === keys.maciociawt.value)
                            return false;
                    }

                    let isMushroom = (product.key && product.key === keys.mushrooms.value);

                    if (!isMushrooms && isMushroom) {
                        return false;
                    }

                    if (isHerb && !isMushroom) {
                        const items = product.Herbs.filter(_item => {
                            let item = this.getHerbsData(this.state.herbs, _item.id);
                            if (item === undefined)
                                item = _item;
                            return (item.name && item.name.toLowerCase().match(__searchString)) ||
                                (item.orig && item.orig.toLowerCase().match(__searchString)) ||
                                (item.group && item.group.toLowerCase().match(__searchString)) ||
                                (item.number && item.number.toLowerCase().match(__searchString));
                        });

                        if (items.length > 0)
                            return true;
                    }

                    if (isOther && product.description && product.description.toLowerCase().match(__searchString)) {
                        return true;
                    }

                    if (isOther && product.explanation && product.explanation.toLowerCase().match(__searchString)) {
                        return true;
                    }

                    if (isName && ((product.name && product.name.toLowerCase().match(__searchString)) ||
                        (product.orig && product.orig.toLowerCase().match(__searchString)))) {
                        return true;
                    }

                    if (isUsage) {
                        if (product.Usage && product.Usage.length) {
                            const items = product.Usage.filter(item => {
                                return item.description && item.description.toLowerCase().match(__searchString);
                            });

                            if (items.length > 0)
                                return true;
                        } else {
                            if (product.Usage && product.Usage.description.toLowerCase().match(__searchString))
                                return true;
                        }
                    }

                    if (isImpact) {
                        if (product.Impact && product.Impact.length) {
                            const items = product.Impact.filter(item => {
                                return item.description && item.description.toLowerCase().match(__searchString);
                            });

                            if (items.length > 0)
                                return true;
                        } else {
                            if (product.Impact && product.Impact.description && product.Impact.description.toLowerCase().match(__searchString))
                                return true;
                        }
                    }

                    if (isOther) {
                        if (product.Combination && product.Combination.length) {
                            const items = product.Combination.filter(item => {
                                return item.description && item.description.toLowerCase().match(__searchString);
                            });

                            if (items.length > 0)
                                return true;
                        } else {
                            if (product.Combination && product.Combination.description && product.Combination.description.toLowerCase().match(__searchString))
                                return true;
                        }
                    }

                    if (isNote) {
                        if (product.Note && product.Note.length) {
                            const items = product.Note.filter(item => {
                                return item.description && item.description.toLowerCase().match(__searchString);
                            });

                            if (items.length > 0)
                                return true;
                        } else {
                            if (product.Note && product.Note.description && product.Note.description.toLowerCase().match(__searchString))
                                return true;
                        }
                    }

                    return (isOther && product.explanation && product.explanation.toLowerCase().match(__searchString));
                });

                _filteredProducts = _filteredProducts1;//.concat(_filteredProducts2).concat(_filteredProducts3);
            }

            //sort
            _filteredProducts.sort((a, b) => a.name.localeCompare(b.name));

            if (update) {
                this.setState({
                    filteredProducts: _filteredProducts,
                    searchString: searchString,
                    currentPage: 1
                })
            } else {
                return _filteredProducts;
            }

        } else {
            let _filteredProducts1 = this.state.allProducts;
            _filteredProducts1 = _filteredProducts1.filter(product => {
                if (!isSinecura) {
                    if (!product.key)
                        return false;
                }

                if (!isMaciocia) {
                    if (product.key && product.key === keys.maciocia.value)
                        return false;
                }

                if (!isMaciociaLt) {
                    if (product.key && product.key === keys.maciocialt.value)
                        return false;
                }

                if (!isMaciociaWt) {
                    if (product.key && product.key === keys.maciociawt.value)
                        return false;
                }

                if (!isMushrooms) {
                    if (product.key && product.key === keys.mushrooms.value)
                        return false;
                }

                return true;
            });
            let _filteredProducts = _filteredProducts1;

            //let _filteredProducts = this.state.allProducts.concat(this.state.products_tcm).concat(this.state.products_maccioccia);
            //sort
            _filteredProducts.sort((a, b) => a.name.localeCompare(b.name));

            if (update) {
                this.setState({
                    filteredProducts: _filteredProducts,
                    searchString: searchString,
                    //allProducts: _filteredProducts,
                    currentPage: 1
                })
            } else {
                return _filteredProducts;
            }
        }
    }

    fytoOptionChange(e) {
        let _filters = this.state.filters;
        _filters[keys.name.value] = false;
        _filters[keys.usage.value] = false;
        _filters[keys.impact.value] = false;
        _filters[keys.herb.value] = false;
        _filters[keys.other.value] = false;
        _filters[keys.note.value] = false;
        _filters[keys.sinecura.value] = false;
        _filters[keys.maciocia.value] = false;
        _filters[keys.maciocialt.value] = false;
        _filters[keys.maciociawt.value] = false;
        _filters[keys.mushrooms.value] = false;

        if (e !== null) {
            for (let id in e) {
                _filters[e[id]["value"]] = true;
            }
        }

        let that = this;
        this.setState({
            filters: _filters
        }, () => {
            that.onSearch(that.state.searchString);
            that.saveData();
        });
    }

    getFytoOptions() {
        let options = [];

        options.push({value: keys.sinecura.value, label: keys.sinecura.label});
        options.push({value: keys.maciocia.value, label: keys.maciocia.label});
        options.push({value: keys.maciocialt.value, label: keys.maciocialt.label});
        options.push({value: keys.maciociawt.value, label: keys.maciociawt.label});
        options.push({value: keys.mushrooms.value, label: keys.mushrooms.label});

        options.push({value: keys.name.value, label: keys.name.label});
        options.push({value: keys.herb.value, label: keys.herb.label});
        options.push({value: keys.usage.value, label: keys.usage.label});
        options.push({value: keys.impact.value, label: keys.impact.label});
        options.push({value: keys.other.value, label: keys.other.label});
        options.push({value: keys.note.value, label: keys.note.label});

        return options;
    }

    getCurrentFytoOptions() {
        let _filters = this.state.filters;
        let fytoOptions = "";
        if (_filters[keys.sinecura.value])
            fytoOptions += keys.sinecura.value + fytoIds.separator;
        if (_filters[keys.maciocia.value])
            fytoOptions += keys.maciocia.value + fytoIds.separator;
        if (_filters[keys.maciocialt.value])
            fytoOptions += keys.maciocialt.value + fytoIds.separator;
        if (_filters[keys.maciociawt.value])
            fytoOptions += keys.maciociawt.value + fytoIds.separator;
        if (_filters[keys.mushrooms.value])
            fytoOptions += keys.mushrooms.value + fytoIds.separator;
        if (_filters[keys.name.value])
            fytoOptions += keys.name.value + fytoIds.separator;
        if (_filters[keys.herb.value])
            fytoOptions += keys.herb.value + fytoIds.separator;
        if (_filters[keys.usage.value])
            fytoOptions += keys.usage.value + fytoIds.separator;
        if (_filters[keys.impact.value])
            fytoOptions += keys.impact.value + fytoIds.separator;
        if (_filters[keys.other.value])
            fytoOptions += keys.other.value + fytoIds.separator;
        if (_filters[keys.note.value])
            fytoOptions += keys.note.value + fytoIds.separator;

        if (fytoOptions.length > 1) fytoOptions = fytoOptions.slice(0, -1);
        return fytoOptions;
    }

    showProductInfoForm(value) {
        const showProductInfoForm = value && value.length > 0;
        this.setState({
            visibleProductInfoForm: showProductInfoForm,
            productId: value
        });
    }

    showHerbInfoForm(value) {
        const showHerbInfoForm = value && value.length > 0;
        this.setState({
            visibleHerbInfoForm: showHerbInfoForm,
            herbId: value
        });
    }

    scrollToProduct(id) {
        window.location.hash = id;
    }

    showHome() {
        this.setState({
            visibleProductInfoForm: false,
            productId: ""
        });

        //this.props.scrollToTop();
    }

    handleMeaningTableVisibility() {
        // if (!this.props.onShowMeaningTable) {
        //     alert("Funkce pro zobrazení informace o písmených kódů není definovaná!!!");
        //     return;
        // }
        // this.props.onShowMeaningTable();
        let visibility = this.state.visibleTableOfMeanings;
        this.setState({
            visibleTableOfMeanings: !visibility
        })
    }

    handleHerbsTableVisibility() {
        // if (!this.props.onShowHerbsTable) {
        //     alert("Funkce pro zobrazení informace o použitých bylinách není definovaná!!!!!!");
        //     return;
        // }
        // this.props.onShowHerbsTable();
        let visibility = this.state.visibleTableOfHerbs;
        this.setState({
            visibleTableOfHerbs: !visibility
        });
    }

    closeProductInfoForm() {
        this.setState({
            visibleProductInfoForm: false,
            productInfo: ""
        });
    }

    onSearchAnd() {
        let _filters = this.state.filters;
        _filters[keys.searchAnd.value] = !_filters[keys.searchAnd.value];

        let that = this;
        this.setState({
            filters: _filters
        }, () => {
            that.onSearch(that.state.searchString);
            that.saveData();
        });
    }

    onCompareProducts() {
        let visibility = this.state.visibleCompareTableOfHerbs;
        this.setState({
            visibleCompareTableOfHerbs: !visibility
        });
    }

    compareProducts(key) {
        let comparedProducts = this.state.comparedProducts;
        let index = comparedProducts.indexOf(key);
        if (index > -1) {
            comparedProducts.splice(index, 1);
        } else {
            comparedProducts.push(key);
        }
        let that = this;
        this.setState({
            comparedProducts: comparedProducts
        }, () => {
            that.saveData();
        });
    }

    render() {
        const isReady = this.state.ready;
        let filteredProducts = this.state.filteredProducts;

        let searchText = this.state.searchString;
        let chips = searchText.length > 0 ? searchText.split(",") : [];

        const hInfo = this.props.productId.match(fytoIds.herbInfo);
        const isHerbInfoFormVisible = this.props.productId.length > 0 && hInfo;
        const isProductInfoFormVisible = !isHerbInfoFormVisible && this.props.productId.length > 0 && this.props.productId;

        let _filters = this.state.filters;
        const isSearchAnd = _filters[keys.searchAnd.value];

        let fytoOptions = this.getFytoOptions();
        let fytoOptionsDefaultvalue = null;
        let currentFytoOptions = this.getCurrentFytoOptions();

        if (currentFytoOptions !== "") {
            fytoOptionsDefaultvalue = fytoOptions.filter(option => {
                return (currentFytoOptions.indexOf(option.value) !== -1);
            });
        }

        const comparedProducts = this.state.comparedProducts;
        const isCompareProductsTable = this.state.visibleCompareTableOfHerbs;
        let tableOfComparedHerbs = <div/>;
        if (isCompareProductsTable) {
            filteredProducts = [];
            tableOfComparedHerbs = <CompareCards
                products={this.state.allProducts}
                herbs={this.state.herbs}
                items={this.state.comparedProducts}/>;
        }

        const isHerbsOfTableVisible = this.state.visibleTableOfHerbs;
        let tableOfHerbs = <div/>;
        if (isHerbsOfTableVisible)
            tableOfHerbs = <HerbsTable/>;

        const isMeaningOfTableVisible = this.state.visibleTableOfMeanings;
        let tableOfMeanings = <div/>;
        if (isMeaningOfTableVisible)
            tableOfMeanings = <MeaningTable/>;
        return (
            <div>
                {isHerbInfoFormVisible ? (
                    <HerbInfo items={this.state.allProducts}
                              herbs={this.state.herbs}
                              itemId={this.props.productId}
                    />
                ) : (
                    <div>
                        {isProductInfoFormVisible ? (
                            <ProductInfo items={this.state.allProducts}
                                         herbs={this.state.herbs}
                                         itemId={this.props.productId}
                            />
                        ) : (
                            <div>
                                {isReady ? (
                                    <div>
                                        <Headroom>
                                            <div className="select-area">
                                                <div className="basic-single ">
                                                    <Select
                                                        className="acu-basic-single-top1"
                                                        classNamePrefix="select"
                                                        name="fyto-selector"
                                                        isMulti={true}
                                                        defaultValue={fytoOptionsDefaultvalue}
                                                        isSearchable={false}
                                                        options={fytoOptions}
                                                        onChange={this.fytoOptionChange}
                                                        isDisabled={false}
                                                        placeholder="kde budeme hledat..."
                                                    />
                                                </div>
                                                <div className="basic-single ">
                                                    <ReactChipInput
                                                        classes="basic-single-top2"
                                                        chips={chips}
                                                        placeholder="co budeme hledat..."
                                                        onSubmit={value => this.addChip(value)}
                                                        onRemove={index => this.removeChip(index)}
                                                    />
                                                </div>
                                            </div>

                                            <ResultsActionBar items={filteredProducts}
                                                // isBuyable={isBuyable}
                                                              isHerbsTable={isHerbsOfTableVisible}
                                                              isMeaningTable={isMeaningOfTableVisible}
                                                              isSearchAnd={isSearchAnd}
                                                              isCompareProductsTable={isCompareProductsTable}
                                                //onFilterSellableItems={this.filterSellableItems}
                                                              onShowMeangsTable={this.handleMeaningTableVisibility}
                                                              onShowHerbsTable={this.handleHerbsTableVisibility}
                                                              onSearchAnd={this.onSearchAnd}
                                                              onCompareProducts={this.onCompareProducts}/>
                                        </Headroom>

                                        {tableOfMeanings}
                                        {tableOfHerbs}
                                        {tableOfComparedHerbs}
                                        <FytoCards
                                            items={filteredProducts}
                                            compareItems={comparedProducts}
                                            scrollToProduct={this.scrollToProduct}
                                            compareProducts={this.compareProducts}
                                        />
                                    </div>

                                ) : (
                                    <div/>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default Products;