import React, {Component} from 'react';
import {ListGroup, ListGroupItem, Card} from 'react-bootstrap'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {getItem} from '../Master/UtilityCalc'
import {ids, data, strings} from "../Configuration/Config";

class HexagramItem extends Component {
    constructor(props) {
        super(props);

        this.getHexagemDescription = this.getHexagemDescription.bind(this);
    }

    getHexagemDescription(key, calcData, results) {
        let heavenTrigram = results[ids.heavenTrigram];
        let earthTrigram = results[ids.earthTrigram];

        const gender = calcData[ids.gender];
        const isYang = results[ids.isYang];

        let topTrigram = earthTrigram;
        let bottomTrigram = heavenTrigram;
        if ((isYang && gender === strings.man) || (!isYang && gender === strings.woman)) {
            topTrigram = heavenTrigram;
            bottomTrigram = earthTrigram;
        }
        const trigrams = data[ids.trigrams];
        const hextrigrams = data[ids.hextrigrams];
        let topDescArr = topTrigram[ids.trigramDesc].split(ids.separator);
        let bottomDescArr = bottomTrigram[ids.trigramDesc].split(ids.separator);

        let bottomKey = bottomDescArr[0] + ids.separator + bottomDescArr[1] + ids.separator + bottomDescArr[2];
        let topKey = topDescArr[0] + ids.separator + topDescArr[1] + ids.separator + topDescArr[2];

        if(key === ids.trigramsMirror) {
            bottomKey = topDescArr[2] + ids.separator + topDescArr[1] + ids.separator + topDescArr[0];
            topKey = bottomDescArr[2] + ids.separator + bottomDescArr[1] + ids.separator + bottomDescArr[0];
        } else if(key === ids.trigramsSubst) {
            let a = (topDescArr[0]==="1" ? "2" : "1");
            let b = (topDescArr[1]==="1" ? "2" : "1");
            let c = (topDescArr[2]==="1" ? "2" : "1");
            topKey = a + ids.separator + b + ids.separator + c;
            a = (bottomDescArr[0]==="1" ? "2" : "1");
            b = (bottomDescArr[1]==="1" ? "2" : "1");
            c = (bottomDescArr[2]==="1" ? "2" : "1");
            bottomKey = a + ids.separator + b + ids.separator + c;
        } else if(key === ids.trigramsSpecial) {
            //2 3 4 3 4 5 => 1 2 0 2 0 1
            bottomKey = bottomDescArr[1] + ids.separator + bottomDescArr[2] + ids.separator + topDescArr[0];
            topKey = bottomDescArr[2] + ids.separator + topDescArr[0] + ids.separator + topDescArr[1];
        }

        for (const [key1, value1] of Object.entries(trigrams)) {
            //console.log(key, value);
            if(value1[ids.trigramDesc] === topKey) {
                topTrigram = value1;
            }
            if(value1[ids.trigramDesc] === bottomKey) {
                bottomTrigram = value1;
            }
        }

        let title = "";
        for (const [key2, value2] of Object.entries(hextrigrams)) {
            //console.log(key, value);
            if(value2[ids.hexagramDesc] === topKey + "," + bottomKey) {
                title = key2;
            }
        }

        let description = {

        };
        description[ids.topImage] = ids.imgTrigramPath + topTrigram[ids.imageKey];// + "?" + dt.getTime();
        description[ids.bottomImage] = ids.imgTrigramPath + bottomTrigram[ids.imageKey];// + "?" + dt.getTime();
        description[ids.hexagramDesc] = title;

        return description;
    }

    render() {
        // The markup for the Step 1 UI
        const keyId = this.props.keyId;
        const calcData = this.props.data;
        const results = calcData[ids.results];

        const hexDesc = this.getHexagemDescription(keyId, calcData, results);
        const topImg = hexDesc[ids.topImage];
        const bottomImg = hexDesc[ids.bottomImage];
        const title = hexDesc[ids.hexagramDesc];

        let headerClassName = "card-header-lucky";
        let ebClassName = "card-body-lucky";
        let hsClassName = "card-body-lucky card-body-border";
        return (
            <Card className="bazi text-center">
                <Card.Header className={headerClassName}>{title}</Card.Header>
                {/*hs*/}
                <Card.Body className={hsClassName}>
                    <Card.Img className={"pillar-img"} variant="top" src={topImg} />
                </Card.Body>
                {/*eb*/}
                <Card.Body className={ebClassName}>
                    <Card.Img className={"pillar-img"} variant="top" src={bottomImg} />
                </Card.Body>
            </Card>
        )
    }
}

export default HexagramItem