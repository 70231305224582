import React, {Component} from 'react'
import Products from "./Products/Products";

class Syndroms extends Component {
     render() {
        return (
            <div id="tcme">
                <div className="content">
                    <div className="workspace">
                        <Products/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Syndroms;