import React, {Component} from 'react';
import {strings, channels, results} from "../Configuration/Config";
import ResultItem from './ResultItem'
import {Form} from 'react-bootstrap'

class Step5 extends Component {
    constructor(props) {
        super(props);

        this.calcValues = this.calcValues.bind(this);
        this.checkHuo = this.checkHuo.bind(this);
        this.checkHan = this.checkHan.bind(this);
        this.getHanHuoRatioErrorId = this.getHanHuoRatioErrorId.bind(this);
        this.getHanHuoRatioClass = this.getHanHuoRatioClass.bind(this);
    }

    getHanHuoRatioClass(data) {
        if(!data) return "";

        if(data[results.hanhuoratio.id]===undefined) return "";

        return data[results.hanhuoratio.id] > 2.2 || data[results.hanhuoratio.id] < 1.2 ? "is-invalid" : "is-valid";
    }

    getHanHuoRatioErrorId(data) {
        if(!data) return "";

        if(data[results.hanhuoratio.id]===undefined) return "";

        if(data[results.hanhuoratio.id] > 2.2) return results.hanhuoratio.error1;
        if(data[results.hanhuoratio.id] < 1.2) return results.hanhuoratio.error2;

        return "";
    }

    checkHuo(data) {
        if(!data) return "";

        if(data[results.huo.id]===undefined || data[results.resultsRatio.id]===undefined) return "";

        return data[results.huo.id] <= data[results.resultsRatio.id] ? "is-valid" : "is-invalid";
    }

    checkHan(data) {
        if(!data) return "";

        if(data[results.huo.id]===undefined || data[results.resultsRatio.id]===undefined) return "";

        return data[results.han.id] >= data[results.resultsRatio.id] ? "is-valid" : "is-invalid";
    }

    calcValues(data) {
        if(!data) return;
        let resultsRatio = 0;
        let alldata = true;

        //zao
        if(data[channels.fei.measureLeft]!==undefined && data[channels.fei.measureRight]!==undefined &&
            data[channels.dachang.measureLeft]!==undefined && data[channels.dachang.measureRight]!==undefined) {
            resultsRatio += (Number(data[channels.fei.measureLeft])+ Number(data[channels.fei.measureRight]) +
                Number(data[channels.dachang.measureLeft]) + Number(data[channels.dachang.measureRight]));
            let result = (Number(data[channels.fei.measureLeft])+ Number(data[channels.fei.measureRight]) +
                Number(data[channels.dachang.measureLeft]) + Number(data[channels.dachang.measureRight])) / 4.0;

            data[results.zao.id] = (Math.round(result* 100) / 100).toString();
        } else alldata = false;

        //huo
        if(data[channels.xinbao.measureLeft]!==undefined && data[channels.xinbao.measureRight]!==undefined &&
            data[channels.sanjiao.measureLeft]!==undefined && data[channels.sanjiao.measureRight]!==undefined &&
            data[channels.xin.measureLeft]!==undefined && data[channels.xin.measureRight]!==undefined &&
            data[channels.xiaochang.measureLeft]!==undefined && data[channels.xiaochang.measureRight]!==undefined) {
            resultsRatio += (Number(data[channels.xinbao.measureLeft])+ Number(data[channels.xinbao.measureRight]) +
                Number(data[channels.sanjiao.measureLeft]) + Number(data[channels.sanjiao.measureRight]) +
                Number(data[channels.xin.measureLeft]) + Number(data[channels.xin.measureRight]) +
                Number(data[channels.xiaochang.measureLeft]) + Number(data[channels.xiaochang.measureRight]));
            let result = (Number(data[channels.xinbao.measureLeft])+ Number(data[channels.xinbao.measureRight]) +
                Number(data[channels.sanjiao.measureLeft]) + Number(data[channels.sanjiao.measureRight]) +
                Number(data[channels.xin.measureLeft]) + Number(data[channels.xin.measureRight]) +
                Number(data[channels.xiaochang.measureLeft]) + Number(data[channels.xiaochang.measureRight])) / 8.0;

            data[results.huo.id] = (Math.round(result * 100) / 100).toString();
        } else alldata = false;

        //shi
        if(data[channels.pi.measureLeft]!==undefined && data[channels.pi.measureRight]!==undefined &&
            data[channels.wei.measureLeft]!==undefined && data[channels.wei.measureRight]!==undefined) {
            resultsRatio += (Number(data[channels.pi.measureLeft])+ Number(data[channels.pi.measureRight]) +
                Number(data[channels.wei.measureLeft]) + Number(data[channels.wei.measureRight]));
            let result = (Number(data[channels.pi.measureLeft])+ Number(data[channels.pi.measureRight]) +
                Number(data[channels.wei.measureLeft]) + Number(data[channels.wei.measureRight])) / 4.0;

            data[results.shi.id] = (Math.round(result* 100) / 100).toString();
        } else alldata = false;

        //feng
        if(data[channels.gan.measureLeft]!==undefined && data[channels.gan.measureRight]!==undefined &&
            data[channels.dan.measureLeft]!==undefined && data[channels.dan.measureRight]!==undefined) {
            resultsRatio += (Number(data[channels.gan.measureLeft])+ Number(data[channels.gan.measureRight]) +
                Number(data[channels.dan.measureLeft]) + Number(data[channels.dan.measureRight]));
            let result = (Number(data[channels.gan.measureLeft])+ Number(data[channels.gan.measureRight]) +
                Number(data[channels.dan.measureLeft]) + Number(data[channels.dan.measureRight])) / 4.0;

            data[results.feng.id] = (Math.round(result* 100) / 100).toString();
        } else alldata = false;

        //han
        if(data[channels.pangguang.measureLeft]!==undefined && data[channels.pangguang.measureRight]!==undefined &&
            data[channels.shen.measureLeft]!==undefined && data[channels.shen.measureRight]!==undefined) {
            resultsRatio += (Number(data[channels.pangguang.measureLeft])+ Number(data[channels.pangguang.measureRight]) +
                Number(data[channels.shen.measureLeft]) + Number(data[channels.shen.measureRight]));
            let result = (Number(data[channels.pangguang.measureLeft])+ Number(data[channels.pangguang.measureRight]) +
                Number(data[channels.shen.measureLeft]) + Number(data[channels.shen.measureRight])) / 4.0;

            data[results.han.id] = (Math.round(result* 100) / 100).toString();
        } else alldata = false;

        //ratio han / huo
        if(data[results.han.id]!==undefined && data[results.huo.id]!==undefined) {
            let result = Number(data[results.han.id]) / Number(data[results.huo.id]);

            data[results.hanhuoratio.id] = (Math.round(result* 100) / 100).toString();
        } else alldata = false;

        //whole ratio
        if(resultsRatio !== "" && alldata) {
            data[results.resultsRatio.id] = (Math.round(resultsRatio / 24 * 100) / 100).toString();
        }
    }

    render() {
        if (this.props.currentStep !== 5 && this.props.data.wizard ) { // Prop: The current step
            return null;
        }

        if(!this.props.wizard) {
            this.calcValues(this.props.data);
        }

        if(!this.props.data[results.resultsRatio.id] || this.props.data[results.resultsRatio.id] === 0) {
            return null;
        }

        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={2}>{strings.results}</Form.Label>
                </Form.Row>
                <ResultItem
                    resultKey={"zao"}
                    class={""}
                    data={this.props.data}
                />
                <ResultItem
                    resultKey={"huo"}
                    class={this.checkHuo(this.props.data)}
                    data={this.props.data}
                />
                <ResultItem
                    resultKey={"shi"}
                    class={""}
                    data={this.props.data}
                />
                <ResultItem
                    resultKey={"feng"}
                    class={""}
                    data={this.props.data}
                />
                <ResultItem
                    resultKey={"han"}
                    class={this.checkHan(this.props.data)}
                    data={this.props.data}
                />
                <ResultItem
                    resultKey={"hanhuoratio"}
                    class={this.getHanHuoRatioClass(this.props.data)}
                    data={this.props.data}
                    errorKey={this.getHanHuoRatioErrorId(this.props.data)}
                />
                <ResultItem
                    resultKey={"resultsRatio"}
                    class={""}
                    data={this.props.data}
                />
            </div>
        )
    }
}

export default Step5