import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {keys} from "../Utils/Constants"
import {matrix_data} from "../data/Data";

//import './PointsTable.css'

class MatrixTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: []
        };

        this.columnClassNameFormat = this.columnClassNameFormat.bind(this);
    }

    columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
        if(fieldValue==="") return "";

        const channelId = fieldValue;
        let style = 'matrix-table-item matrix-table-item-center';
        matrix_data.channels.forEach(itemChannel => {
            if (itemChannel[keys.short.value] !== channelId)
                return;

            if(itemChannel[keys.isYang.value])
                style = 'matrix-table-item-center matrix-table-item-red';
        });
        return style;
    }

    render() {
        const items = this.props.table.map(item => {
            return {
                [keys.id.value]: item[keys.id.value],
                [keys.method6.value]: item[keys.method6.value],
                [keys.method1.value]: item[keys.method1.value],
                [keys.method2.value]: item[keys.method2.value],
                [keys.method3.value]: item[keys.method3.value],
                [keys.method4.value]: item[keys.method4.value],
                [keys.method5.value]: item[keys.method5.value]
            };
        });
        //let empty = "";
        const options = {
            // page: 2,  // which page you want to show as default
            // sizePerPageList: [ {
            //     text: '5', value: 5
            // }, {
            //     text: '10', value: 10
            // }, {
            //     text: 'All', value: products.length
            // } ], // you can change the dropdown list for size per page
            sizePerPage: 15,  // which size per page you want to locate as default
            //    pageStartIndex: 0, // where to start counting the pages
            //    paginationSize: 3,  // the pagination bar size.
            // prePage: 'Prev', // Previous page button text
            // nextPage: 'Next', // Next page button text
            // firstPage: 'First', // First page button text
            // lastPage: 'Last', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            hideSizePerPage: true //> You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };

        return (
            <div className="pointTable">
                <BootstrapTable data={items}
                                striped
                                pagination={false}
                                options={options}
                                trClassName={this.columnClassNameFormat}
                >
                    <TableHeaderColumn isKey
                                       dataField={[keys.id.value]}
                                       hidden
                    >ID</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method6.value}
                    >{keys.method6.label}</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method1.value}
                    >{keys.method1.label}</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method2.value}
                    >{keys.method2.label}</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method3.value}
                    >{keys.method3.label}</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method4.value}
                    >{keys.method4.label}</TableHeaderColumn>
                    <TableHeaderColumn
                        columnClassName={this.columnClassNameFormat}
                        className='matrix-table-item matrix-table-item-center'
                        width='25%'
                        dataSort={false}
                        dataField={keys.method5.value}
                    >{keys.method5.label}</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }


}

export default MatrixTable;