import React, {Component} from 'react'
import ReactCardFlip from 'react-card-flip';
import {keys} from "../Constants"

class Card extends Component {
    constructor(props) {
        super(props);

        this.getItemInfoFromId = this.getItemInfoFromId.bind(this);
        this.linkFormatter = this.linkFormatter.bind(this);
        this.handleOnFlip = this.handleOnFlip.bind(this);

        this.getPointItemName = this.getPointItemName.bind(this);
        this.getPoint = this.getPoint.bind(this);
        this.getFrontItemSummary = this.getFrontItemSummary.bind(this);
        this.getBackItemSummary = this.getBackItemSummary.bind(this);

        this.showInfoForm = this.showInfoForm.bind(this);
        this.isItem = this.isItem.bind(this);
        //this.isVisible = this.isVisible.bind(this);
        this.getItemClasses = this.getItemClasses.bind(this);

        this.state = {
            isFlipped: false
        };
    }

    handleOnFlip(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    getPoint(data, point) {
        let summary = "nejsou data";
        if (!data || data.toString().length === 0) {
            this.props.showSummaryInfo(summary);
            return;
        }

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(_point => {
            return (_point[keys.id.value] === pointId)
        });

        return foundItem[0];
    }

    getPointItemName(data, point) {
        if (!data || data.toString().length === 0) return "?";

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(point => {
            return (point[keys.id.value] === pointId)
        });
        return foundItem[0][keys.name.value];
    }

    showInfoForm(e) {
        if (!this.props.showInfoForm) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        this.props.showInfoForm(e);
    }

    getFrontItemSummary(channelData, pointData, currentItemId, filters) {
        if (pointData===undefined || Object.keys(pointData).length === 0)
            return <div className="acu-summary-heading">žádné info</div>;
        //yuan
        let isYuan = this.isItem(channelData, currentItemId, channelData[keys.yuanxue.value]) && filters[keys.yuanxue.value];

        //luo
        let isLuo = this.isItem(channelData, currentItemId, channelData[keys.luoxue.value]) && filters[keys.luoxue.value];
        //let classLuoItem = (isLuo && filters[keys.luoxue.value] ? "card-upper-1" : "card-hide");
        //xi
        let isXi = this.isItem(channelData, currentItemId, channelData[keys.xixue.value]) && filters[keys.xixue.value];
        //let classXiItem = (isXi && filters[keys.xixue.value] ? "card-upper-2" : "card-hide");
        //shu
        let isBeishu = this.isItem(channelData, currentItemId, channelData[keys.beishuxue.value]) && filters[keys.beishuxue.value];

        //shu
        let isMuShu = this.isItem(channelData, currentItemId, channelData[keys.mu_xue.value]) && filters[keys.mu_xue.value];

        let classNameSpecialItem1 = "acu-card-upper-1 acu-card-hide";
        let classNameSpecialItem2 = "acu-card-upper-2 acu-card-hide";
        let stars = keys.yuanxue.label.toLocaleUpperCase();
        if(isYuan || isLuo || isXi /*|| isBeishu || isMuShu*/) {
            classNameSpecialItem1 = "acu-card-upper-1";
            classNameSpecialItem2 = "acu-card-upper-2";
        }
        if(isLuo) {
            stars = keys.luoxue.label.toLocaleUpperCase();
        }
        if(isXi) {
            stars = keys.xixue.label.toLocaleUpperCase();
        }
        if(isBeishu) {
            stars = keys.beishuxue.label.toLocaleUpperCase();
        }
        if(isMuShu) {
            stars = keys.mu_xue.label.toLocaleUpperCase();
        }

        let i = 0;
        const needling = pointData["needling"].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });

        //let dt = new Date();
        let imgName =  keys.imgPath.value + pointData[keys.name.value].split("-").join("").toLowerCase() + keys.img.value;//  + "?" + dt.getTime();
        return (
            <div>
                <div className="acu-card-summary-heading acu-card-center">{pointData[keys.name.value].toUpperCase() + " - " + pointData[keys.orig.value].toUpperCase() + " (" + pointData[keys.pronuncination.value] + ")"}</div>
                <div className={classNameSpecialItem1}>{stars}</div>
                <div className="acu-card-summary-text acu-card-center">{"(" + pointData["translation"] + ")"}</div>
                <div className={classNameSpecialItem2}>{stars}</div>
                <img className="acu-card-summary-image" src={imgName} alt={pointData[keys.name.value].toUpperCase()}/>
                {/*<div className="summary-heading">Lolizace:</div>*/}
                <div className="acu-card-summary-item">{pointData["localization"]}</div>
                <div className="acu-card-summary-heading acu-card-summary-heading-space">{keys.needling.label}</div>
                <ul className="acu-card-summary-list">
                {needling}
                </ul>
            </div>);
    }

    getBackItemSummary(channelData, pointData, currentItemId, filters) {
        if (pointData===undefined || Object.keys(pointData).length === 0)
            return <div className="acu-summary-heading">žádné info</div>;

        // //yuan
        // let isYuan = this.isItem(channelData, currentItemId, channelData[keys.yuanxue.value]) && filters[keys.yuanxue.value];
        //
        // //luo
        // let isLuo = this.isItem(channelData, currentItemId, channelData[keys.luoxue.value]) && filters[keys.luoxue.value];
        //
        // //xi
        // let isXi = this.isItem(channelData, currentItemId, channelData[keys.xixue.value]) && filters[keys.xixue.value];
        //
        // //shu
        // let isBeishu = this.isItem(channelData, currentItemId, channelData[keys.beishuxue.value]) && filters[keys.beishuxue.value];
        //
        // //shu
        // let isMuShu = this.isItem(channelData, currentItemId, channelData[keys.mu_xue.value]) && filters[keys.mu_xue.value];

        // let classNameSpecialItem1 = "card-upper-1 card-hide";
        // let classNameSpecialItem2 = "card-upper-2 card-hide";
        // let stars = keys.yuanxue.label.toLocaleUpperCase();
        // if(isYuan || isLuo || isXi /*|| isBeishu || isMuShu*/) {
        //     classNameSpecialItem1 = "card-upper-1";
        //     classNameSpecialItem2 = "card-upper-2";
        // }
        // if(isLuo) {
        //     stars = keys.luoxue.label.toLocaleUpperCase();
        // }
        // if(isXi) {
        //     stars = keys.xixue.label.toLocaleUpperCase();
        // }
        // if(isBeishu) {
        //     stars = keys.beishuxue.label.toLocaleUpperCase();
        // }
        // if(isMuShu) {
        //     stars = keys.mu_xue.label.toLocaleUpperCase();
        // }
        let i = 0;
        // const needling = pointData["needling"].map(item => {
        //     return <li className="card-summary-item" key={i++}>{item.description}</li>;
        // });
        const category = pointData[keys.category.value]===undefined ? <div/> : pointData[keys.category.value].map(item => {
            let desc = this.linkFormatter(item.description);
            return <div key={i++}>{desc}</div>
        });
        const functions = pointData[keys.function.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });
        const notes = pointData[keys.note.value]===undefined ? <div/> : pointData[keys.note.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });
        const combinations = pointData[keys.combination.value]===undefined ? <div/> : pointData[keys.combination.value].map(item => {
            let combinationInfo = this.getItemInfoFromId(item.id);
            if(combinationInfo!=="")
                return <li className="acu-card-summary-item" key={i++}>{combinationInfo}</li>;
        });

        const indications = pointData[keys.indication.value]===undefined ? <div/> : pointData[keys.indication.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });
        return (
            <div>
                {/*<div className="card-summary-heading card-center">{pointData[keys.name.value].toUpperCase() + " - " + pointData[keys.orig.value].toUpperCase() + " (" + pointData[keys.pronuncination.value] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem1}>{stars}</div>*/}
                {/*<div className="card-summary-text card-center">{"(" + pointData["translation"] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem2}>{stars}</div>*/}
                {/*<div className="card-margin"></div>*/}
                {category.length > 0 ? <div className="acu-card-summary-heading">{keys.category.label}</div> : <div />}
                {category}
                <div className="acu-card-summary-heading card-margin">{keys.function.label}</div>
                <ul className="acu-card-summary-list">
                {functions}
                </ul>
                {indications.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.indication.label}</div> : <div />}
                <ul className="acu-card-summary-list">
                    {indications}
                </ul>
                {combinations.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.combination.label}</div> : <div />}
                <ul className="acu-card-summary-list">
                {combinations}
                </ul>
                {notes.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.note.label}</div> : <div/>}
                <ul className="acu-card-summary-list">
                {notes}
                </ul>
            </div>);
    }

    linkFormatter(item) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <button className="infoButton normal-color" key={i} data-key={_items[1]} onClick={(event) => {
                        this.showInfoForm(event)
                    }}>{_items[0]}</button>
                );
            } else {
                return token + maybeSpace;
            }
        });

        return (
            <ul className="acu-card-summary-list">
            <li className="acu-card-summary-item normal-color">{contents}</li>
            </ul>
        );
    };

   getItemInfoFromId(id) {
        let combinationsData = this.props.combinationsData;
        if (combinationsData.length === 0) return "";
        let combination = combinationsData["combinations"].filter((item, i) => {
            return (item.id === id);
        });

        if(combination===undefined || combination.length===0) return "";
        return combination[0].combination;
    }

    isItem(channel, item, itemCompare) {
        if(itemCompare === item)
            return true;

        if(itemCompare!== undefined && itemCompare.indexOf(',') > 0) {
            let points = itemCompare.split(',');
            let foundItem = points.filter(id => {
                return (id === item);
            });

            return foundItem.length > 0;
        }

        let point = channel[itemCompare];
        return (point === item);
    }

    // isVisible(channel, item, filters) {
    //     //wushuxue
    //     let isWushuxue = filters[keys.wushuxue.value];
    //     let isAllsue = filters[keys.allxue.value];
    //     let isMuxue = isWushuxue && this.isItem(channel, item, channel[keys.muxue.value]);
    //     let isHuoxue = isWushuxue && this.isItem(channel, item, channel[keys.huoxue.value]);
    //     let isTuxue = isWushuxue && this.isItem(channel, item, channel[keys.tuxue.value]);
    //     let isYinxue = isWushuxue && this.isItem(channel, item, channel[keys.jinxue.value]);
    //     let isShuixue = isWushuxue && this.isItem(channel, item, channel[keys.shuixue.value]);
    //
    //     let isJing = filters[keys.jingxue.value] && this.isItem(channel, item, channel[keys.jingxue.value]);
    //     let isYing = filters[keys.yingxue.value] && this.isItem(channel, item, channel[keys.yingxue.value]);
    //     let isShu = filters[keys.shuxue.value] && this.isItem(channel, item, channel[keys.shuxue.value]);
    //     let isJing2 = filters[keys.jingxue2.value] && this.isItem(channel, item, channel[keys.jingxue2.value]);
    //     let isHe = filters[keys.hexue.value] && this.isItem(channel, item, channel[keys.hexue.value]);
    //
    //     //ben
    //     let isBen = this.isItem(channel, item, channel[keys.benxue.value]) && filters[keys.benxue.value];
    //
    //     //bu
    //     let isBu = this.isItem(channel, item, channel[keys.buxue.value]) && filters[keys.buxue.value];
    //
    //     //xie
    //     let isXie = this.isItem(channel, item, channel[keys.xiexue.value]) && filters[keys.xiexue.value];
    //
    //     //yuan
    //     let isYuan = this.isItem(channel, item, channel[keys.yuanxue.value]) && filters[keys.yuanxue.value];
    //
    //     //luo
    //     let isLuo = this.isItem(channel, item, channel[keys.luoxue.value]) && filters[keys.luoxue.value];
    //
    //     //xi
    //     let isXi = this.isItem(channel, item, channel[keys.xixue.value]) && filters[keys.xixue.value];
    //
    //     //beishuxue
    //     let isBeishu = this.isItem(channel, item, channel[keys.beishuxue.value]) && filters[keys.beishuxue.value];
    //
    //     //mu xue
    //     let isMushuxue = this.isItem(channel, item, channel[keys.mu_xue.value]) && filters[keys.mu_xue.value];
    //
    //     //bahuixue
    //     let isBahuixue = this.isItem(channel, item, channel[keys.bahuixue.value]) && filters[keys.bahuixue.value];
    //
    //     return isAllsue || isMuxue || isMushuxue || isHuoxue || isTuxue || isYinxue || isShuixue || isYuan || isLuo || isXi ||
    //         isJing || isYing || isShu || isJing2 || isHe || isXie || isBu || isBen || isBeishu || isBahuixue;
    // }

    getItemClasses(channel, item, point, filters) {
       //wushuxue
        let isWushuxue = filters[keys.wushuxue.value];
        //color
        let isColored = filters[keys.color.value];

        let isMuxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.muxue.value]);
        let isHuoxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.huoxue.value]);
        let isTuxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.tuxue.value]);
        let isYinxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.jinxue.value]);
        let isShuixue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.shuixue.value]);

        let colorItem = "";
        if(isMuxue)
            colorItem = "acu-card-"+keys.muxue.value;
        if(isHuoxue)
            colorItem = "acu-card-"+keys.huoxue.value;
        if(isTuxue)
            colorItem = "acu-card-"+keys.tuxue.value;
        if(isYinxue)
            colorItem = "acu-card-"+keys.jinxue.value;
        if(isShuixue)
            colorItem = "acu-card-"+keys.shuixue.value;
        //
        // let isJing = filters[keys.jingxue.value] && this.isItem(channel, item, channel[keys.jingxue.value]);
        // let isYing = filters[keys.yingxue.value] && this.isItem(channel, item, channel[keys.yingxue.value]);
        // let isShu = filters[keys.shuxue.value] && this.isItem(channel, item, channel[keys.shuxue.value]);
        // let isJing2 = filters[keys.jingxue2.value] && this.isItem(channel, item, channel[keys.jingxue2.value]);
        // let isHe = filters[keys.hexue.value] && this.isItem(channel, item, channel[keys.hexue.value]);
        //
        // let classWushuItem = "";
        // if(isJing)
        //     classWushuItem = keys.jingxue.value;
        // if(isYing)
        //     classWushuItem = keys.yingxue.value;
        // if(isShu)
        //     classWushuItem = keys.shuxue.value;
        // if(isJing2)
        //     classWushuItem = keys.jingxue2.value;
        // if(isHe)
        //     classWushuItem = keys.hexue.value;

        //ben
        let isBen = this.isItem(channel, item, channel[keys.benxue.value]) && filters[keys.benxue.value];

        //bu
        let isBu = this.isItem(channel, item, channel[keys.buxue.value]) && filters[keys.buxue.value];

        //xie
        let isXie = this.isItem(channel, item, channel[keys.xiexue.value]) && filters[keys.xiexue.value];

        let classBenItem = (isBen ? " acu-card-benxue" : "");
        let classBuItem = (isBu ? " acu-card-buxue" : "");
        let classXieItem = (isXie ? " acu-card-xiexue" : "");

        return colorItem + " " + classBuItem + classXieItem + classBenItem;
    }

    render() {
        //let _currentPage = this.props.currentPage;
        let data = this.props.channel[0];
        let currentItemId = this.props.currentItemId.trim();
        const point = this.getPoint(data, currentItemId);
        let itemFrontSummary = this.getFrontItemSummary(data, point, currentItemId, this.props.filters);
        let itemBackSummary = this.getBackItemSummary(data, point, currentItemId, this.props.filters);

        let classes = this.getItemClasses(data, currentItemId, point, this.props.filters);
        const frontClass = "acu-frontSide normal-color " + classes;
        const backClass = "acu-backSide normal-color " + classes;

        const mainClass = "acu-my-card";// + (this.isVisible(data, currentItemId, this.props.filters)===true ? "" : " card-hide");
        return (
            <div className={mainClass}>
                <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                >
                    <div className={frontClass} onClick={this.handleOnFlip}>
                        {itemFrontSummary}
                    </div>

                    <div className={backClass} onClick={this.handleOnFlip}>
                        {itemBackSummary}
                    </div>
                </ReactCardFlip>
            </div>
        )
    }
}

export default Card
