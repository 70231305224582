import React, {Component} from 'react'
import ReactiveQR from "reactive-qr";
//import Quagga from 'quagga'; // ES6

import '../../styles/custom.css';

class Barcode extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            herbs: "",
        };

    }

    render() {
        // let pathArr = this.props.location["pathname"].split('/');
        // let productId = "";
        // if(pathArr.length === 3)
        //     productId = pathArr[2];
        const data = this.state.herbs;
        let that = this;
        return (
            <div id="tcme">
                <ReactiveQR onCode={code => that.setState({herbs: code})} />
                <p>{data}</p>
            </div>
        )
    }
}

export default Barcode;