import React, {Component} from 'react';
import {ids, results, strings} from "../Configuration/Config";
import ChannleItem from './ChannelItem'
import {Form} from 'react-bootstrap'

class Step7 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.getItems = this.getItems.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    getItems(data) {
        let itemstoCheck = data[ids.itemstoCheck];
        if(!itemstoCheck || itemstoCheck.length === 0) return null;

        let itemstoCheckEx = itemstoCheck.split(ids.separator);
        return itemstoCheckEx.map((item, i) => {
            return <ChannleItem
                key={i}
                channelKey={item+"Check"}
                updateData={this.updateData}
                data={this.props.data}
            />
        });
    }

    render() {
        if (this.props.currentStep !== 7 && this.props.data.wizard) { // Prop: The current step
            return null
        }

        if(!this.props.data[results.resultsRatio.id] || this.props.data[results.resultsRatio.id] === 0) {
            return null;
        }

        let items = this.getItems(this.props.data);
        if(!items) return null;

        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={2}>{strings.postmeasure}</Form.Label>
                </Form.Row>

                {items}
            </div>
        )
    }
}

export default Step7