import React, {Component} from 'react'
import ReactCardFlip from 'react-card-flip';
import { ids } from "../../Utils/Constants";
import {Link} from "react-router-dom";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

class Card extends Component {
    constructor(props) {
        super(props);

        this.linkFormatter = this.linkFormatter.bind(this);
        this.handleOnFlip = this.handleOnFlip.bind(this);
        this.onCompareHerb = this.onCompareHerb.bind(this);

        this.getFrontItemSummary = this.getFrontItemSummary.bind(this);
        this.getBackItemSummary = this.getBackItemSummary.bind(this);

        this.scrollToProduct = this.scrollToProduct.bind(this);

        this.state = {
            isFlipped: false,
            isCompared: false,
            isReady: true
        };
    }

    handleOnFlip(e) {
        e.preventDefault();
        if(!this.state.isReady) return;
        this.setState(prevState => ({isFlipped: !prevState.isFlipped}));
    }

    onCompareHerb(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(prevState => ({isCompared: !prevState.isCompared}));
        let key = e.target.getAttribute("data-key");
        this.props.compareProducts(key);
    }

    scrollToProduct(e) {
        e.stopPropagation();
        if (!this.props.scrollToProduct) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        let key = e.target.getAttribute("data-key");
        this.props.scrollToProduct(key);
    }

    getFrontItemSummary(data) {
        if (data === undefined)
            return <div className="fyto-summary-heading">žádné info</div>;

        if(!this.state.isCompared && this.props.compareItems.length) {
            this.props.compareItems.forEach(product => {
                if(product===data.id) {
                    this.state.isCompared = true;
                    return;
                }
            });
        }

        let orig = data.orig=== "" ? "" : <div className="fyto-card-summary-text fyto-card-center">{"(" + data.orig + ")"}</div>;
        let ext = data.ext=== "" ? "" : <div className="fyto-card-summary-text fyto-card-center">{data.ext}</div>;
        let designation = data.leung=== "" ? "" : <div className="fyto-card-summary-text fyto-card-center">{data.leung}</div>;
        let i = 0;
        const usages = data.Impact.map(item => {
            return <li className="fyto-card-summary-item" key={i++}>{item.description}</li>;
        });
        //let dt = new Date();
        let imgName = ids.imgFytoPath + data.img.toLowerCase();// + "?" + dt.getTime();
        let classNameSpecialItem1 = "fyto-card-upper-2 fa fa-balance-scale";
        if(this.state.isCompared)
            classNameSpecialItem1 = "fyto-card-upper-2 fyto-card-upper-2-down fa fa-balance-scale";
        this.state.isReady = data.ready;
        return (
            <div>
                <button className={classNameSpecialItem1} data-key={data.id} onClick={this.onCompareHerb}></button>
                <div className="fyto-card-summary-heading fyto-card-center">{data.name.toUpperCase()}</div>
                {ext}
                {orig}

                {/*<div className="card-margin"></div>*/}
                {designation}
                {/*<div className="card-syndrome-heading card-summary-heading-space">{data.syndrome}</div>*/}
                <div className="card-margin"></div>

                <img className="fyto-card-summary-image" src={imgName} alt=""/>
                {data.ready ?
                    <div>
                        <div className="fyto-card-margin"></div>
                        <div
                            className="fyto-card-summary-heading-items fyto-card-summary-heading-space">{"Účinky:"}</div>
                        <ul className="fyto-card-summary-list">
                            {usages}
                        </ul>
                        <div className="fyto-card-summary-link-detail card-right">
                            <Link to={"/fyto/" + data.id} className="btn-default button-detail-link" data-key={data.id} >{"více info"}</Link>
                        </div>
                    </div> : <div/>
                }
            </div>);
    }

    getBackItemSummary(data) {
        if (data === undefined)
            return <div className="fyto-summary-heading">žádné info</div>;

        let i = 0;

        let indications = [];
        if(data.Indication) {
            indications = data.Indication.map(item => {
                i += item.description.length;
                if(i > 1000) return <div/>;
                return this.linkFormatter(item.description);;
            });
        }

        let usages = [];
        if(data.Usage) {
            usages = data.Usage.map(item => {
                i += item.description.length;
                if(i > 1000) return <div/>;
                return this.linkFormatter(item.description);;
            });
        }

        // const notes = data.Note.map(item => {
        //     return <li className="card-summary-item-back" key={i++}>{item.description}</li>;
        // });
        return (
            <div>
                {usages.length > 0 ? (
                    <div >
                        <div className="fyto-card-summary-heading-items">{"Použití:"}</div>
                        <ul className="fyto-card-summary-list">
                            {usages}
                        </ul>
                    </div>
                ) : (
                    <div/>
                )}
                {indications.length > 0 ? (
                    <div >
                        <div className="fyto-card-summary-heading-items">{"Indikace:"}</div>
                        <ul className="fyto-card-summary-list">
                            {indications}
                        </ul>
                    </div>
                ) : (
                    <div />
                )}
                {/*{notes.length > 0 ? <div className="card-summary-heading card-margin">{"Poznámky:"}</div> : <div/>}*/}
                {/*<ul className="card-summary-list">*/}
                    {/*{notes}*/}
                {/*</ul>*/}
                <div className="fyto-card-summary-link-detail fyto-card-right">
                    <Link to={"/fyto/" + data.id} className="btn-default button-detail-link" data-key={data.id}>{"více info"}</Link>
                </div>
            </div>);
    }

    linkFormatter(item) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <Link to={"/fyto/" + _items[1]} className="button-link" key={i} data-key={_items[1]}>{_items[0]}</Link>
                );
            } else {
                return token + maybeSpace;
            }
        });

        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 10; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        //s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        //s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        //s[8] = s[13] = s[18] = s[23] = "-";

        s.join("");
        return (
            <li key={s} className="fyto-card-summary-item-back normal-color">{contents}</li>
        );
    };

    render() {
        //let _currentPage = this.props.currentPage;
        let data = this.props.product;
        let itemFrontSummary = this.getFrontItemSummary(data);
        let itemBackSummary = this.getBackItemSummary(data);

//        let classes = this.getItemClasses(data, currentItemId, point, this.props.filters);
        const frontClass = "frontSide normal-color ";
        const backClass = "backSide normal-color ";

        const mainClass = "my-card";// + (this.isVisible(data, currentItemId, this.props.filters)===true ? "" : " card-hide");
        return (
            <div className={mainClass} id={this.props.id}>
                <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                >
                    <div className={frontClass} onClick={this.handleOnFlip}>
                        {itemFrontSummary}
                    </div>

                    <div className={backClass} onClick={this.handleOnFlip}>
                        {itemBackSummary}
                    </div>
                </ReactCardFlip>
            </div>
        )
    }
}

export default Card
