export const keys = {
    name: {value: 'name', label: 'jméno'},
    description: {value: 'description', label: 'popis'},
    header: {value: 'header', label: ''},
    pos: {value: 'pos', label: ''},
    id: {value: 'id', label: ''},
    orig: {value: 'orig', label: ''},
    points: {value: 'points', label: ''},
    short: {value: 'short', label: ''},
    img: {value: '.png', label: ''},
    imgPath: {value: './assets/images/acu/', label: ''},
    imgname: {value: 'imgName', label: ''},
    pronuncination: {value: 'pronuncination', label: ''},
    translation: {value: 'translation', label: ''},
    time: {value: 'time', label: 'čas'},
    color: {value: 'color', label: 'barva', tooltip: 'barva dle prvku'},
    wushuxue: {value: 'wushuxue', label: 'wushuxue', tooltip: 'pět přepravních bodů'},
    beishuxue: {value: 'beishuxue', label: 'beishuxue', tooltip: 'zádové přepravní body'},
    mu_xue: {value: 'mu_xue', label: 'muxue', tooltip: 'přední sběrné, poplašné body'},
    xue: {value: 'xue', label: '', tooltip: ''},
    // isYang: {value: 'isYang', label: 'Yang'},
    alljing: {value: 'alljing', label: 'všechny dráhy'},
    allxue: {value: 'allxue', label: '', tooltip:''},
    yuanxue: {value: 'yuanxue', label: 'yuan', tooltip: 'bod původu'},
    bahuixue: {value: 'bahuixue', label: 'bahuixue', tooltip: 'osm shromažďovacích bodů'},
    jiaohuixue: {value: 'jiaohuixue', label: 'jiaohuixue', tooltip: 'body křížení drah'},
    xixue: {value: 'xixue', label: 'xixue', tooltip:'bod štěrbiny'},
    luoxue: {value: 'luoxue', label: 'luoxue', tooltip:'bod spojnice'},
    benxue: {value: 'benxue', label: 'benxue', tooltip:'kořenový bod'},
    muxue: {value: 'muxue', label: 'muxue'},
    huoxue: {value: 'huoxue', label: 'huoxue'},
    tuxue: {value: 'tuxue', label: 'tuxue'},
    jinxue: {value: 'jinxue', label: 'jinxue'},
    shuixue: {value: 'shuixue', label: 'shuixue'},
    buxue: {value: 'buxue', label: 'buxue', tooltip:'tonifikační bod'},
    xiexue: {value: 'xiexue', label: 'xiexue', tooltip:'vypouštěcí bod'},
    jingxue: {value: 'jingxue', label: 'jingxue', tooltip:'bod studánky'},
    yingxue: {value: 'yingxue', label: 'yingxue', tooltip:'bod mělčiny'},
    shuxue: {value: 'shuxue', label: 'shuxue', tooltip:'bod unášení'},
    jingxue2: {value: 'jingxue2', label: 'jingxue', tooltip:'bod toku'},
    hexue: {value: 'hexue', label: 'hexue', tooltip:'bod spojení'},
    empty: {value: 'empty', label: ''},
    hide: {value: 'hide', label: ''},
    xues: {value: 'xues', label: ''},
    search: {value: 'search', label: 'Hledat'},
    searchText: {value: 'searchText', label: ''},
    checkSearchText: {value: 'checkSearchText', label: ''},
    current: {value: 'current', label: ''},
    localization: {value: 'localization', label: 'Lokalizace:'},
    indication: {value: 'indication', label: 'Indikace:'},
    combination: {value: 'combination', label: 'Kombinace:'},
    function: {value: 'function', label: 'Funkce:'},
    category: {value: 'category', label: 'Kategorie:'},
    needling: {value: 'needling', label: 'Způsob napichování:'},
    note: {value: 'note', label: 'Poznámky:'},
    cards: {value: 'cards', label: 'zobrazit karty', tooltip:'zobrazit jako karty'},
    cookieaccept: {value: 'aku-accepts-cookies', label: ''},
    bamaijiaohuixue: {value: 'bamaijiaohuixue', label: 'bamaijiaohuixue', tooltip:'osm propojovacích bodů mimořádných drah'},
    sunsimiaoguixue: {value: 'sunsimiaoguixue', label: 'sunsimiao guixue', tooltip:'13 Sun Simiaových bodů démonů'},
    qihaixue: {value: 'qihaixue', label: 'qihaixue', tooltip:'body Moře čchi'},
    suihaixue: {value: 'suihaixue', label: 'suihaixue', tooltip:'body Moře dřeně'},
    madanyangxue: {value: 'madanyangxue', label: 'madanyangxue', tooltip:'body Ma Danyang shier xue'},
    windowxue: {value: 'windowxue', label: 'window of heaven', tooltip:'body Okna do nebes'},
    xiahexue: {value: 'xiahexue', label: 'xiahexue', tooltip:'dolní body spojení'},
    extraxue: {value: 'extraxue', label: 'extraxue', tooltip:'extra body'},
    jinglabels: {value: 'jinglabels', label: 'nadpisy drah', tooltip:'zobrazit nadpisy drah'},
    leung: {value: 'leung', label: 'Leungova tabulka', tooltip:'Leungova tabulka boddů v rámci bagang'},
    bagangResult: {value: 'bagangResult', label: 'Leungova tabulka', tooltip:'Leungova tabulka boddů v rámci bagang'},
    organsResult: {value: 'organsResult'},
    bodyPartsResult1: {value: 'bodyPartsResult1'},
    bodyPartsResult2: {value: 'bodyPartsResult2'},
    bagangxue: {value: 'bagangxue'},
    biao: {value: 'biao'},
    li: {value: 'li', label: 'li'},
    re: {value: 're', label: 're'},
    xu: {value: 'xu', label: 'xu'},
    han: {value: 'han', label: 'han'},
    shi: {value: 'shi', label: 'shi'},
    moxa: {value: 'moxa', label: 'moxa'},
    bu: {value: 'bu', label: 'bu'},
    pbpx: {value: 'pbpx', label: 'pbpx'},
    xie: {value: 'xie', label: 'xie'},
    xin: {value: 'xin', label: 'XIN'},
    fei: {value: 'fei', label: 'FEI'},
    gan: {value: 'gan', label: 'GAN'},
    dan: {value: 'dan', label: 'DAN'},
    pi: {value: 'pi', label: 'PI'},
    wei: {value: 'wei', label: 'WEI'},
    shen: {value: 'shen', label: 'shen'},
    dachang: {value: 'dachang', label: 'dachang'},
    xiaochang: {value: 'xiaochang', label: 'xiaochang'},
    pangguang: {value: 'pangguang', label: 'pangguang'},
    head: {value: 'head', label: 'Hlava'},
    eyes: {value: 'eyes', label: 'Oči'},
    nose: {value: 'nose', label: 'Nos'},
    ears: {value: 'ears', label: 'Uši'},
    mouth: {value: 'mouth', label: 'Ústa'},
    neck: {value: 'neck', label: 'Krk'},
    back: {value: 'back', label: 'Horní část zad, těla'},
    thorax: {value: 'thorax', label: 'Hrudník'},
    sides: {value: 'sides', label: 'Boky'},
    lower: {value: 'lower', label: 'Bedra'},
    gyn: {value: 'gyn', label: 'Gynekologie'},
    nearPoints: {value: 'nearPoints', label: 'Blízké body'},
    centerPoints: {value: 'centerPoints', label: 'Středové body'},
    distancePoints: {value: 'distancePoints', label: 'Vzdálené body'},
    organsTable: {value: 'organs', label: 'Orgán'},
    bodyPartsTable: {value: 'bodyParts', label: 'Část těla'},
    nearshu: {value: 'nearshu', label: 'Blízký SHU'},
    nearmu: {value: 'nearmu', label: 'Blízký MU'},
    nearmu1: {value: 'nearmu1', label: 'Blízký MU'},
    nearmu2: {value: 'nearmu2', label: 'Blízký MU'},
    center: {value: 'center', label: 'Středový'},
    distance: {value: 'distance', label: 'Vzdálený'},
    distance1: {value: 'distance1', label: '1. vzdálený'},
    distance2: {value: 'distance2', label: '2. vzdálený'},
    usage: {value: 'usage', label: 'Použití'},
    herbs: {value: 'herbs', label: 'Bylinky'},
};