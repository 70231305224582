import React, {Component} from 'react';
import {ids, strings} from "../Configuration/Config";
import {CardGroup} from 'react-bootstrap'
import PillarItem from './PillarItem'
import {Form/*, OverlayTrigger, Popover*/} from 'react-bootstrap'

//lucky pillars
class Step4 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChecked(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.checked === true ? "1" : "0";

        this.updateData();
    }

    render() {
        if(!this.props.isAdvanced) return null;

        let generated = this.props.data[ids.generated];
        let checked = !generated ? false : this.props.data[ids.luckyPillars] === "1";

        const results = this.props.data[ids.results];
        if (!results) {
            checked = false;
        }
        const activeId = results && results[ids.activeLuckyPillar] ? results[ids.activeLuckyPillar] : "";

        const title = results && results[ids.luckyPillar] ? results[ids.luckyPillar][ids.title] : "";

        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={3}>{strings.luckyPillars}</Form.Label>
                    {/*<OverlayTrigger overlay={<Popover id="popover-contained">*/}
                        {/*/!*<Popover.Title as="h3">Popover bottom</Popover.Title>*!/*/}
                        {/*<Popover.Content>*/}
                            {/*<strong>Holy guacamole!</strong> Check this info.*/}
                        {/*</Popover.Content>*/}
                    {/*</Popover>}>*/}
                    {/*<span className="d-inline-block">*/}
                        <Form.Check
                            className={"item-switcher"}
                            type="switch"
                            id={ids.luckyPillars}
                            label=""
                            disabled={!generated}
                            checked={checked}
                            onChange={this.handleChecked}
                        />
                    {/*</span>*/}
                    {/*</OverlayTrigger>*/}
                </Form.Row>
                {checked ?
                    <div>
                        <Form.Label className="label-right" column="sm" sm={12}>{title}</Form.Label>
                        <CardGroup>
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar9}
                                isHeaderActive={ids.luckyPillar9 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar8}
                                isHeaderActive={ids.luckyPillar8 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar7}
                                isHeaderActive={ids.luckyPillar7 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar6}
                                isHeaderActive={ids.luckyPillar6 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar5}
                                isHeaderActive={ids.luckyPillar5 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar4}
                                isHeaderActive={ids.luckyPillar4 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar3}
                                isHeaderActive={ids.luckyPillar3 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar2}
                                isHeaderActive={ids.luckyPillar2 === activeId}
                            />
                            <PillarItem
                                updateData={this.updateData}
                                data={this.props.data}
                                keyId={ids.luckyPillar1}
                                isHeaderActive={ids.luckyPillar1 === activeId}
                            />
                        </CardGroup></div> : <div/>
                }
            </div>
        )
    }
}

export default Step4