import React, {Component} from 'react';
import './FloatingBtn.css'
import {Link} from "react-router-dom";

class FloatingMenuItem extends Component {

    handleClick() {
        // this.handleMenu();
        this.props.action();
    }

    // handleMenu() {
    //     if(this.props.toggleMenu)
    //         this.props.toggleMenu();
    // }

    render() {
        let label;
        if (this.props.label) {
            label = <label>{this.props.label}</label>;
        }

        let title;
        if (this.props.title) {
            title = this.props.title;
        }

        return <div
            // onClick={this.handleClick.bind(this)}
            className="floating-menu-item">
            {label}
            <Link
                to={"/fyto"}
                title={title}
                className="envelope button-filter">
                <div className="floating-menu-icon">
                    <i className={this.props.icon}></i>
                </div>
            </Link>
        </div>;
    }
}

//export default FloatingMenuItem;

class FloatingMenu extends Component {
    constructor() {
        super();

        this.state = {
             toggled: true
         };

        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({toggled: !this.state.toggled});
    }

    render() {
        let buttons = [];
        let className = this.props.class ? this.props.class : "floating-menu";
        let title = this.props.title ? this.props.title : "zpět";
        let key = this.props.id ? this.props.id : "i3";
        let iconBack = this.props.icon ? this.props.icon : "fa fa-arrow-left";

        // if (this.state.toggled) {
            className += " open";
            buttons.push(
                <FloatingMenuItem title={title} icon={iconBack} /*action={this.props.showBack}*/ key={key}/>);
        // }

       // buttons.push(<FloatingMenuItem /*label="" */ icon={!this.state.toggled ? iconPlus : iconClose} action={this.toggleMenu} key="m"/>);

        return <div className={className}>
                {buttons}
            </div>;

    }
}

export default FloatingMenu;