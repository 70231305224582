export const ids = {

    healthProblem: "healthProblemId",
    print: "printId",
    printPreview: "printPreviewId",
    next: "nextId",
    previous: "previousId",
    back: "backId",
    measure: "measureId",
    name: 'userNameId',
    age: 'ageId',
    date: 'dateId',
    results: "resultsId",
    cure: "cureId",
    channelKey: "channelKeyId",
    imageKey: "imageId",
    printArea: 'printAreaId',
    printTableArea: 'printTableAreaId',
    separator: ",",
    itemstoCheck: "itemsToCheckId",
    key: "AkabaneKeyId"
};

export const imagesKey = {
    fileExt: ".png",
    pagguangLeft: "pagguangLeft",
    pagguangRight: "pagguangRight",
    danLeft: "danLeft",
    danRight: "danRight",
    weiLeft: "weiLeft",
    weiRight: "weiRight",
    xiaochangLeft: "xiaochangLeft",
    xiaochangRight: "xiaochangRight",
    sanjiaoLeft: "sanjiaoLeft",
    sanjiaoRight: "sanjiaoRight",
    dachanLeft: "dachanLeft",
    dachanRight: "dachanRight",
    feiLeft: "feiLeft",
    feiRight: "feiRight",
    xinbaoLeft: "xinbaoLeft",
    xinbaoRight: "xinbaoRight",
    xinLeft: "xinLeft",
    xinRight: "xinRight",
    piLeft: "piLeft",
    piRight: "piRight",
    ganLeft: "ganLeft",
    ganRight: "ganRight",
    shenLeft: "shenLeft",
    shenRight: "shenRight",
};

export const strings = {
    healthProblem: "Zdravotní problém",
    print: "Tisk",
    download: "Stáhnout",
    date: "Datum",
    age: "Věk",
    printPreview: "Náhled",
    next: "Další",
    previous: "Předchozí",
    back: "Zpět",
    title: "Akabaneho test",
    name: 'Jméno',
    measure: "Měření",
    empty: "",
    check: "Kontrola",
    postmeasure: "Přeměření",
    cure: "Ošetření",
    measureLeft: "levá",
    measureRight: "pravá",
    measureRatio: "poměr",
    results: "Výsledky",
};

export const channels = {
    fei: {
        id: "feiId",
        name: "A. Plíce",
        measureLeft: "measureFeiLeftId",
        measureRight: "measureFeiRightId",
        measureRatio: "measureFeiRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    feiCheck: {
        id: "feiCheckId",
        name: "A. Plíce",
        measureLeft: "measureFeiCheckLeftId",
        measureRight: "measureFeiCheckRightId",
        measureRatio: "measureFeiCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    dachang: {
        id: "dachangId",
        name: "B. Tlusté střevo",
        measureLeft: "measureDachangLeftId",
        measureRight: "measureDachangRightId",
        measureRatio: "measureDachangRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    dachangCheck: {
        id: "dachangCheckId",
        name: "B. Tlusté střevo",
        measureLeft: "measureDachangCheckLeftId",
        measureRight: "measureDachangCheckRightId",
        measureRatio: "measureDachangCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xinbao: {
        id: "xinbaoId",
        name: "I. Pericard",
        measureLeft: "measureXinbaoLeftId",
        measureRight: "measureXinbaoRightId",
        measureRatio: "measureXinbaoRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xinbaoCheck: {
        id: "xinbaoCheckId",
        name: "I. Pericard",
        measureLeft: "measureXinbaoCheckLeftId",
        measureRight: "measureXinbaoCheckRightId",
        measureRatio: "measureXinbaoCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    sanjiao: {
        id: "sanjiaoId",
        name: "J. Trojitý ohřívač",
        measureLeft: "measureSanjiaoLeftId",
        measureRight: "measureSanjiaoRightId",
        measureRatio: "measureSanjiaoRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    sanjiaoCheck: {
        id: "sanjiaoCheckId",
        name: "J. Trojitý ohřívač",
        measureLeft: "measureSanjiaoCheckLeftId",
        measureRight: "measureSanjiaoCheckRightId",
        measureRatio: "measureSanjiaoCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xin: {
        id: "xinId",
        name: "E. Srdce",
        measureLeft: "measureXinLeftId",
        measureRight: "measureXinRightId",
        measureRatio: "measureXinRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xinCheck: {
        id: "xinCheckId",
        name: "E. Srdce",
        measureLeft: "measureXinCheckLeftId",
        measureRight: "measureXinCheckRightId",
        measureRatio: "measureXinCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xiaochang: {
        id: "xiaochangId",
        name: "F. Tenké střevo",
        measureLeft: "measureXiaochangLeftId",
        measureRight: "measureXiaochangRightId",
        measureRatio: "measureXiaochangRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    xiaochangCheck: {
        id: "xiaochangCheckId",
        name: "F. Tenké střevo",
        measureLeft: "measureXiaochangCheckLeftId",
        measureRight: "measureXiaochangCheckRightId",
        measureRatio: "measureXiaochangCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    pi: {
        id: "piId",
        name: "D. Slezina",
        measureLeft: "measurePiLeftId",
        measureRight: "measurPiRightId",
        measureRatio: "measurePiRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    piCheck: {
        id: "piCheckId",
        name: "D. Slezina",
        measureLeft: "measurePiCheckLeftId",
        measureRight: "measurPiCheckRightId",
        measureRatio: "measurePiCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    wei: {
        id: "weiId",
        name: "C. Žaludek",
        measureLeft: "measureWeiLeftId",
        measureRight: "measurWeiRightId",
        measureRatio: "measureWeiRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    weiCheck: {
        id: "weiCheckId",
        name: "C. Žaludek",
        measureLeft: "measureWeiCheckLeftId",
        measureRight: "measurWeiCheckRightId",
        measureRatio: "measureWeiCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    dan: {
        id: "danId",
        name: "K. Žlučník",
        measureLeft: "measureDanLeftId",
        measureRight: "measurDanRightId",
        measureRatio: "measureDanRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    danCheck: {
        id: "danCheckId",
        name: "K. Žlučník",
        measureLeft: "measureDanCheckLeftId",
        measureRight: "measurDanCheckRightId",
        measureRatio: "measureDanCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    gan: {
        id: "ganId",
        name: "L. Játra",
        measureLeft: "measureGanLeftId",
        measureRight: "measurGanRightId",
        measureRatio: "measureGanRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    ganCheck: {
        id: "ganCheckId",
        name: "L. Játra",
        measureLeft: "measureGanCheckLeftId",
        measureRight: "measurGanCheckRightId",
        measureRatio: "measureGanCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    pangguang: {
        id: "pangguangId",
        name: "G. Moč. měchýř",
        measureLeft: "measurePangguangLeftId",
        measureRight: "measurePangguangRightId",
        measureRatio: "measurePangguangRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    pangguangCheck: {
        id: "pangguangCheckId",
        name: "G. Moč. měchýř",
        measureLeft: "measurePangguangCheckLeftId",
        measureRight: "measurePangguangCheckRightId",
        measureRatio: "measurePangguangCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    shen: {
        id: "shenId",
        name: "H. Ledviny",
        measureLeft: "measureShenLeftId",
        measureRight: "measureShenRightId",
        measureRatio: "measureShenRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    },
    shenCheck: {
        id: "shenCheckId",
        name: "H. Ledviny",
        measureLeft: "measureShenCheckLeftId",
        measureRight: "measureShenCheckRightId",
        measureRatio: "measureShenCheckRatioId",
        error: "Hodnota je vyšší než 2.5!!",
        error2: "Zde se bude ošetřovat %d s!!",
    }
};


export const results = {
    zao: {
        id: "resultsZaoId",
        name: "Průměrné sucho",
        error: ""
    },
    huo: {
        id: "resultsHuoId",
        name: "Průměrné teplo",
        error: "Teplo má být menší než celkový průměr a má být nejmenší!"
    },
    feng: {
        id: "resultsFengId",
        name: "Průměrný vítr",
        error: ""
    },
    shi: {
        id: "resultsShiId",
        name: "Průměrné vlhko",
        error: ""
    },
    han: {
        id: "resultsHanId",
        name: "Průměrný chlad",
        error: "Chlad má být větší než celkový průměr a má být největší!"
    },
    hanhuoratio: {
        id: "resultRatioHuosHanId",
        name: "Poměr chladu / tepla",
        error1: "Poměr chladu / tepla je větší 2.2, je potřeba moxovat!",
        error2: "Poměr chladu / tepla je menší než 1.2, je potřeba prohřívat obě větve drah, PC, TE, KI a SI, které mají dlouhé časy!"
    },
    resultsRatio: {
        id: "resultsRatio",
        name: "Celkový průměr",
        error: ""
    }
};