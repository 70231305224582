import React, {Component} from 'react'
import Select from 'react-select'
import {syndromIds, syndromStrings} from './Constants'
import ProductInfo from '../../Fyto/Products/ProductInfo'
import FytoCards from '../../Fyto/Cards/Cards'
import AcuCards from '../../Acu/Cards/Cards'
import GeneralCards from "../Cards/Card";
import {reactLocalStorage} from 'reactjs-localstorage';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import {data} from '../data/Data';
import {fyto_data} from '../../Fyto/data/Data';
import {acu_data} from '../../Acu/data/Data';
import {keys} from "../../Acu/Constants";

import "./Products.css"


class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            herbs: [],
            allFytoProducts: [],
            filteredFytoProducts: "",
            allAcuProducts: [],
            // allMushroomsProducts: [],
            filteredAcuProducts: "",
            // filteredMushroomProducts: "",
            ready: false,
            visibleProductInfoForm: false,
            productId: "",
            currentPage: 1,
            currentSyndroms: "",
            acuChecked: false,
            fytoChecked: false
        };

        this.syndromChange = this.syndromChange.bind(this);
        this.showProductInfoForm = this.showProductInfoForm.bind(this);
        this.scrollToProduct = this.scrollToProduct.bind(this);
        this.closeProductInfoForm = this.closeProductInfoForm.bind(this);
        this.showHome = this.showHome.bind(this);
        this.getSyndromOptions = this.getSyndromOptions.bind(this);
        this.getFyto = this.getFyto.bind(this);
        this.getAcuPoints = this.getAcuPoints.bind(this);
        // this.getMushrooms = this.getMushrooms.bind(this);
        this.getSyndrom = this.getSyndrom.bind(this);
        this.showFytoCard = this.showFytoCard.bind(this);
        this.showAcuCard = this.showAcuCard.bind(this);
        this.showMushroomCard = this.showMushroomCard.bind(this);
        this.saveData = this.saveData.bind(this);
        // this.handleChangeAcu = this.handleChangeAcu.bind(this);
        // this.handleChangeFyto = this.handleChangeFyto.bind(this);
    }

    saveData() {
        //save data
        reactLocalStorage.set(syndromIds.syndrom, this.state.currentSyndroms);
        //reactLocalStorage.set(syndromIds.acu, this.state.acuChecked);
        reactLocalStorage.set(syndromIds.acu, this.state.filteredAcuProducts);
        //reactLocalStorage.set(syndromIds.fyto, this.state.fytoChecked);
        reactLocalStorage.set(syndromIds.fyto, this.state.filteredFytoProducts);
        // reactLocalStorage.set(syndromIds.mushrooms, this.state.filteredMushroomProducts);
    }

    componentWillMount() {
        let currentSyndroms = reactLocalStorage.get(syndromIds.syndrom);
        let filteredFytoProducts = reactLocalStorage.get(syndromIds.fyto);
        let filteredAcuProducts = reactLocalStorage.get(syndromIds.acu);
        // let filteredMushroomProducts = reactLocalStorage.get(syndromIds.filteredMushroomProducts);

        if (currentSyndroms === undefined || currentSyndroms === "undefined")
            currentSyndroms = "";

        let tempDataFyto = this.state.allFytoProducts;
        tempDataFyto = tempDataFyto.concat(fyto_data.sina);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinb);
        tempDataFyto = tempDataFyto.concat(fyto_data.sind);
        tempDataFyto = tempDataFyto.concat(fyto_data.sine);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinf);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinh);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinm);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinn);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinw);
        tempDataFyto = tempDataFyto.concat(fyto_data.sinx);
        tempDataFyto = tempDataFyto.concat(fyto_data.maci);

        // let tempDataMushrooms = this.state.allMushroomsProducts;
        tempDataFyto = tempDataFyto.concat(fyto_data.mushrooms);

        let tempDataAcu = this.state.allAcuProducts;
        tempDataAcu = tempDataAcu.concat(acu_data.lu);
        tempDataAcu = tempDataAcu.concat(acu_data.li);
        tempDataAcu = tempDataAcu.concat(acu_data.st);
        tempDataAcu = tempDataAcu.concat(acu_data.sp);
        tempDataAcu = tempDataAcu.concat(acu_data.ht);
        tempDataAcu = tempDataAcu.concat(acu_data.si);
        tempDataAcu = tempDataAcu.concat(acu_data.bl);
        tempDataAcu = tempDataAcu.concat(acu_data.ki);
        tempDataAcu = tempDataAcu.concat(acu_data.pc);
        tempDataAcu = tempDataAcu.concat(acu_data.te);
        tempDataAcu = tempDataAcu.concat(acu_data.gb);
        tempDataAcu = tempDataAcu.concat(acu_data.lr);
        tempDataAcu = tempDataAcu.concat(acu_data.cv);
        tempDataAcu = tempDataAcu.concat(acu_data.gv);
        tempDataAcu = tempDataAcu.concat(acu_data.extra);

        let acuChannels = "";
        let channels = acu_data.configuration[syndromIds.channels];
        for (let _item in channels) {
            if (channels[_item][syndromIds.channel] === "") continue;
            acuChannels += channels[_item][syndromIds.channel] + ",";
        }

        if (!filteredFytoProducts) filteredFytoProducts = "";
        if (!filteredAcuProducts) filteredAcuProducts = "";
        // if (!filteredMushroomProducts) filteredMushroomProducts = "";

        let that = this;
        that.setState({
            herbs: fyto_data.herbs,
            allFytoProducts: tempDataFyto,
            allAcuProducts: tempDataAcu,
            // allMushroomsProducts: tempDataMushrooms,
            acuChannels: acuChannels,
            currentSyndroms: currentSyndroms,
            // acuChecked: acu,
            filteredFytoProducts: filteredFytoProducts,
            filteredAcuProducts: filteredAcuProducts,
        //    filteredMushroomProducts: filteredMushroomProducts,
            ready: true
        }, () => {
            // if (this.state.ready)
            //     that.onSearch(that.state.searchString);
        });
    };

    syndromChange(e) {
        let value = "";
        if (e !== null) {
            value = e["value"];
        }

        let that = this;
        that.setState({
            currentSyndroms: value,
            filteredAcuProducts: "",
            filteredFytoProducts: "",
            // filteredMushroomsProducts: ""
            //filters: _filters
        }, () => {
            that.saveData();
        });
    }

    // handleChangeAcu(e) {
    //     let value = e.target.checked;
    //     let that = this;
    //     that.setState({
    //         acuChecked: value
    //         //filters: _filters
    //     }, () => {
    //         that.saveData();
    //     });
    // }
    //
    // handleChangeFyto(e) {
    //     let value = e.target.checked;
    //     let that = this;
    //     that.setState({
    //         fytoChecked: value
    //         //filters: _filters
    //     }, () => {
    //         that.saveData();
    //     });
    // }

    getSyndromOptions(data) {
        let options = [];
        data.forEach(item => {
            options.push({value: item.id, label: item.number + " " + item.syndrom + " (" + item.name + ")"})
        });

        return options;
    }

    getSyndrom(data, currentSyndroms) {
        let syndrom = null;
        data.forEach(item => {
            if (item.id!=="" && currentSyndroms.match(item.id)) {
                syndrom = item;
                return;
            }
        });
        return syndrom;
    }

    showFytoCard(e) {
        let that = this;
        let key = e.target.getAttribute("data-key");
        this.setState({
            filteredFytoProducts: key
        }, () => {
            that.saveData();
        });
    }

    showAcuCard(e) {
        let that = this;
        let key = e.target.getAttribute("data-key");
        this.setState({
            filteredAcuProducts: key
        }, () => {
            that.saveData();
        });
    }

    showMushroomCard(e) {
        let that = this;
        let key = e.target.getAttribute("data-key");
        this.setState({
            filteredFytoProducts: key
        }, () => {
            that.saveData();
        });
    }

    getFyto() {
        return this.onSearch(this.state.allFytoProducts, this.state.filteredFytoProducts);
    }

    getAcuPoints() {
        return this.state.filteredAcuProducts; //syndrom[syndromIds.acu];
    }

    // getMushrooms() {
    //     return this.state.filteredMushroomProducts;
    // }

    onSearch(data, searchString) {
        let _searchString = searchString;

        if (_searchString.length > 0) {
            let _filteredProducts1 = data;
            let bOrOperator = _searchString.indexOf(",") > 0;
            let _filteredProducts = [];

            if (bOrOperator) {
                _searchString = _searchString.split(',');
                for (let i = 0; i < _searchString.length; i++) {
                    let __searchString = _searchString[i].trim().toLowerCase();
                    if (__searchString.length === 0) continue;
                    _filteredProducts1 = data.filter(product => {
                        if (!product.ready)
                            return false;

                        return (product.id && product.id.toLowerCase().match(__searchString));
                    });

                    _filteredProducts = _filteredProducts.concat(_filteredProducts1);//.concat(_filteredProducts1).concat(_filteredProducts2).concat(_filteredProducts3);
                }
            } else {
                let __searchString = _searchString.trim().toLowerCase();
                _filteredProducts1 = _filteredProducts1.filter(product => {
                    if (!product.ready)
                        return false;

                    return (product.id && product.id.toLowerCase().match(__searchString));
                });

                _filteredProducts = _filteredProducts1;//.concat(_filteredProducts2).concat(_filteredProducts3);
            }

            //sort
            //_filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
            return _filteredProducts;

        }
    }

    showProductInfoForm(value) {
        this.setState({
            visibleProductInfoForm: true,
            productId: value
        });
    }

    scrollToProduct(id) {
        window.location.hash = id;
    }

    showHome() {
        this.setState({
            visibleProductInfoForm: false,
            productId: ""
        });
    }

    closeProductInfoForm() {
        this.setState({
            visibleProductInfoForm: false,
            productInfo: ""
        });
    }

    render() {
        const isReady = this.state.ready;

        let filters = [];
        filters[keys.allxue.value] = true;

        let syndromDefaultvalue = null;
        let syndromOptions = this.getSyndromOptions(data.syndroms);
        let currentSyndroms = this.state.currentSyndroms;//.slice(0, -1);
        let isAnySyndrom = false;
        let syndrom = null;
        let filteredFytoProducts = null;
        let acuChecked = false;//this.state.acuChecked === true;
        let fytoChecked = false;//this.state.fytoChecked === true;
        if (currentSyndroms !== "") {
            syndromDefaultvalue = syndromOptions.filter(option => {
                return (currentSyndroms.indexOf(option.value) !== -1);
            });

            isAnySyndrom = true;
            syndrom = this.getSyndrom(data.syndroms, currentSyndroms);
            filteredFytoProducts = this.getFyto(syndrom);
            fytoChecked = filteredFytoProducts && filteredFytoProducts.length > 0;
            filters[keys.search.value] = true;
            filters[keys.searchText.value] = this.getAcuPoints(syndrom);
            acuChecked = filters[keys.searchText.value] && filters[keys.searchText.value].length > 0;
        }

        const isProductInfoFormVisible = this.state.visibleProductInfoForm;

        return (
            <div>
                {isProductInfoFormVisible ? (
                    <ProductInfo items={this.state.allFytoProducts}
                                 herbs={this.state.herbs}
                                 itemId={this.state.productId}
                                 onShowHome={this.showHome}
                                 onSwitchProductInfoForm={this.showProductInfoForm}
                    />
                ) : (
                    <div>
                        {isReady ? (
                            <div>
                                <div className="select-area">
                                    <div className="basic-single">
                                        <Select
                                            className="basic-single-top3"
                                            classNamePrefix="select"
                                            name="syndrom-selector"
                                            isMulti={false}
                                            defaultValue={syndromDefaultvalue}
                                            isSearchable={false}
                                            isClearable={true}
                                            options={syndromOptions}
                                            onChange={this.syndromChange}
                                        />
                                    </div>
                                    <div className="basic-single">
                                        <Select
                                            className="basic-single-top2"
                                            classNamePrefix="select"
                                            name="syndrom-zang-selector"
                                            isMulti={true}
                                            defaultValue={syndromDefaultvalue}
                                            isSearchable={false}
                                            isClearable={true}
                                            options={syndromOptions}
                                            onChange={this.syndromChange}
                                        />
                                    </div>
                                </div>
                                {isAnySyndrom ?
                                    <div>
                                        <div className="card-margin"></div>
                                        {/*<div className="syndroms-block">*/}
                                            {/*<FormControlLabel*/}
                                                {/*control={<Switch*/}
                                                    {/*checked={acuChecked}*/}
                                                    {/*onChange={this.handleChangeAcu}*/}
                                                    {/*name="checkedAcu"*/}
                                                    {/*color="default"*/}
                                                    {/*inputProps={{'aria-label': 'checkbox with default color'}}*/}
                                                {/*/>}*/}
                                                {/*label={syndromStrings.acu.toUpperCase()}*/}
                                            {/*/>*/}
                                        {/*</div>*/}
                                        {/*<div className="syndroms-block">*/}
                                            {/*<FormControlLabel*/}
                                                {/*control={<Switch*/}
                                                    {/*checked={fytoChecked}*/}
                                                    {/*onChange={this.handleChangeFyto}*/}
                                                    {/*name="checkedFyto"*/}
                                                    {/*color="default"*/}
                                                    {/*inputProps={{'aria-label': 'checkbox with default color'}}*/}
                                                {/*/>}*/}
                                                {/*label={syndromStrings.fyto.toUpperCase()}*/}
                                            {/*/>*/}
                                        {/*</div>*/}
                                        <GeneralCards
                                            syndrom={syndrom}
                                            showFytoCard={this.showFytoCard}
                                            showAcuCard={this.showAcuCard}
                                            showMushroomCard={this.showMushroomCard}
                                        />

                                        {acuChecked ?
                                            <div>
                                                <div className="card-margin"></div>
                                                <div
                                                    className="normal-color card-summary-heading card-center">{syndromStrings.acu.toUpperCase()}</div>
                                                <AcuCards
                                                    currentChannel={this.state.acuChannels}
                                                    filters={filters}
                                                    showInfoForm={this.showInfoForm}
                                                /></div>
                                            : <div/>
                                        }
                                        {fytoChecked ?
                                            <div>
                                                <div className="card-margin"></div>
                                                <div
                                                    className="normal-color card-summary-heading card-center">{syndromStrings.fyto.toUpperCase()}</div>
                                                <FytoCards
                                                    items={filteredFytoProducts}
                                                    showProductInfoForm={this.showProductInfoForm}
                                                    scrollToProduct={this.scrollToProduct}
                                                /></div>
                                            : <div/>
                                        }

                                    </div> : <div/>}
                            </div>

                        ) : (
                            <div/>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default Products;