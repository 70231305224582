import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {Toaster, Position} from '@blueprintjs/core'
//import functions from 'firebase/functions';
//const functions = require('firebase-functions');
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

const paths = {
    images: "/assets/images",
    actions: "actions"
};

export const AppToaster = Toaster.create({
    className: "recipe-toaster",
    position: Position.TOP,
});

//const firebaseadmin = admin.initializeApp();
const fireApp = firebase.initializeApp(config);
//const storage = app.storage().ref();
//const database = fireApp.database();
//app.auth = app.auth();
const session = firebase.auth.Auth.Persistence.SESSION;
//const facebookProvider = new firebase.auth.FacebookAuthProvider();
// Initialize Cloud Functions through Firebase
//const functs = app.functions();

export {fireApp, /*database,*/ paths,/*storage,*/ session/*, functs*//* facebookProvider */}
