export const keys = {
    id: {value: 'id', label: 'identifikátor'},
    separator: {value: ',', label: 'oddělovač'},
    key: {value: 'metrixKeyId', label: 'id for project'},
    name: {value: 'name', label: 'jméno'},
    short: {value: 'short', label: 'zkrácené jméno'},
    isYang: {value: 'yang', label: 'yin/yang'},
    reset: {value: 'reset', label: 'vymazat'},
    value: {value: 'value', label: 'hodnota'},
    description: {value: 'description', label: 'popis'},
    links: {value: 'links', label: 'vztahy'},
    methods: {value: 'methods', label: 'Metody'},
    channels: {value: 'channels', label: 'Dráhy'},
    filters: {value: 'filters', label: 'Filtry'},
    method1: {value: 'method1', label: 'Metoda 1'},
    method2: {value: 'method2', label: 'Metoda 2'},
    method3: {value: 'method3', label: 'Metoda 3'},
    method4: {value: 'method4', label: 'Metoda 4'},
    method5: {value: 'method5', label: 'Metoda 5'},
    method6: {value: 'method6', label: 'Metoda 6'},
    method6Id: {value: 'd5814c12', label: 'Metoda 6'},
    currentMethods: {value: 'currentMethods', label: 'použité metody'},
    currentChannels: {value: 'currentChannels', label: 'použité dráhy'},
    currentFilters: {value: 'currentFilters', label: 'použité filtry'},
}