import herbs from './herbs.json';
import meaningtable from './meaningtable.json';
import sina from './sinecuraa.json';
import sinb from './sinecurab.json';
import sind from './sinecurad.json';
import sine from './sinecurae.json';
import sinf from './sinecuraf.json';
import sinh from './sinecurah.json';
import sinm from './sinecuram.json';
import sinn from './sinecuran.json';
import sinw from './sinecuraw.json';
import sinx from './sinecurax.json';
import maci from './maciocia.json';
import mushrooms from './mashrooms.json';

export const fyto_data = {
    sina: sina,
    sinb: sinb,
    sind: sind,
    sine: sine,
    sinf: sinf,
    sinh: sinh,
    sinm: sinm,
    sinn: sinn,
    sinw: sinw,
    sinx: sinx,
    maci: maci,
    mushrooms: mushrooms,
    herbs: herbs,
    meaningtable: meaningtable,
};