import React, {Component} from 'react';
import {Col, Form} from 'react-bootstrap'

import {strings, results} from "../Configuration/Config";

class ResultItem extends Component {

    render() {
        // The markup for the Step 1 UI
        return (
            <div>
                <Form.Row>
                    <Form.Label column sm={2}>{results[this.props.resultKey].name}</Form.Label>
                    <Form.Group as={Col} controlId={results[this.props.resultKey].id}>
                        <Form.Control placeholder={strings.result}
                                      readOnly
                                      className={this.props.class}
                                      defaultValue={this.props.data[results[this.props.resultKey].id]}/>
                        <Form.Control.Feedback type="invalid" tooltip>{this.props.errorKey ? this.props.errorKey : results[this.props.resultKey].error}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </div>
        )
    }
}

export default ResultItem