import React, {Component}  from "react";
import { ReCaptcha } from 'react-recaptcha-google'
import {ids} from "../../Utils/Constants";

class MyRecaptcha extends Component {
    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
        this.onExpiredRecaptcha = this.onExpiredRecaptcha.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }

        this.props.onChange(false);
    }

    onVerifyRecaptcha(recaptchaToken) {
        this.props.onChange(true);
    }

    onExpiredRecaptcha() {
        this.props.onChange(false);
    }

    render() {
        return (
                <ReCaptcha
                    ref={(el) => {this.captchaDemo = el;}}
                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    sitekey={ids.siteKey}
                    hl="cs"
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.onVerifyRecaptcha}
                    expiredCallback={this.onExpiredRecaptcha}
                />
        );
    };
};

export default MyRecaptcha;