import React, {Component} from 'react';
import Master from './Master/Master'
import {Spinner} from '@blueprintjs/core';
import {ids, strings} from "./Configuration/Config";
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown'
// import Button from 'react-bootstrap/Button'

import './Bazi.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {reactLocalStorage} from "reactjs-localstorage";

class Bazi extends Component {
    constructor() {
        super();

        //this.childMaster = React.createRef();
        this.state = {
            loading: false,
            isInPrintMode: false,
            [ids.ids]: [],
            [ids.activeId]: "",
            [ids.nameId]: [],
            ready: false,
            [ids.maxItems]: 4
        };

        this.master = React.createRef();

        this._printPreview = this._printPreview.bind(this);
        this._print = this._print.bind(this);
        this._back = this._back.bind(this);
        this._download = this._download.bind(this);
        this._generate = this._generate.bind(this);

        this.createUUID = this.createUUID.bind(this);
        this.onAddTab = this.onAddTab.bind(this);
        this.onRemoveTab = this.onRemoveTab.bind(this);
        this.onActivate = this.onActivate.bind(this);
        this.getTabs = this.getTabs.bind(this);
        this.getContent = this.getContent.bind(this);
        this.loadData = this.loadData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
        this.loadData();

        this.setState({
            ready: true
        })
    }

    _download() {
        this.master.current.download();
    }

    _generate() {
        this.master.current.generate();
    }

    _printPreview() {
        this.setState({
            isInPrintMode: true
        });
    }

    _back() {
        this.setState({
            isInPrintMode: false
        });
    }

    _print() {
        window.print();
        // const content = document.getElementById(Messages.ids.printArea);
        // let uniqueIframeId= "printer";
        //     const iframe = document.createElement('iframe');
        //     iframe.setAttribute('title', uniqueIframeId);
        //     iframe.setAttribute('id', uniqueIframeId);
        //     iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
        //     document.body.appendChild(iframe);
        // let pri = iframe.contentWindow;
        // pri.document.open();
        // pri.document.write(content.innerHTML);
        // pri.document.close();
        //
        // for (let thisSheet=0;thisSheet<document.styleSheets.length;thisSheet++){
        //     pri.document.styleSheets.push(document.styleSheets[thisSheet]);
        // }
        // pri.focus();
        // pri.print();
    }

    getKeyId(id) {
        let key = "bazi";
        return key + "_" + id;
    }

    createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 8; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        // s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        // s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        // s[8] = s[13] = s[18] = s[23] = "-";

        return s.join("");
    }

    loadData() {
        let _data = this.state;

        let names = reactLocalStorage.get(this.getKeyId(ids.nameId));
        let itemIds = reactLocalStorage.get(this.getKeyId(ids.itemIds));
        let _activeid = reactLocalStorage.get(this.getKeyId(ids.activeId));

        if(!_activeid) {
            _activeid = this.createUUID();
            itemIds = _activeid;
        }

        if(!names) names = strings.unnamed;

        _data[ids.activeId] = _activeid;
        _data[ids.itemIds] = itemIds;
        _data[ids.nameId] = names;
    }

    saveData() {
        let _data = this.state;

        if(_data[ids.nameId]!==undefined) reactLocalStorage.set(this.getKeyId(ids.nameId), _data[ids.nameId]);
        if(_data[ids.activeId]!==undefined) reactLocalStorage.set(this.getKeyId(ids.activeId), _data[ids.activeId]);
        if(_data[ids.itemIds]!==undefined) reactLocalStorage.set(this.getKeyId(ids.itemIds), _data[ids.itemIds]);
    }

    updateData(info) {
        let _data = this.state;
        let newName = info[ids.nameId];

        let itemIds = _data[ids.itemIds].split(ids.separator);
        let names = _data[ids.nameId].split(ids.separator);
        let _activeId =  _data[ids.activeId];
        itemIds.forEach((itemId, i) => {
            if(itemId===_activeId) {
                names[i] = newName;
            }
        });

        let newNames = "";
        names.forEach((name, i) => {
            newNames = newNames + ids.separator + name;
        });
        newNames = newNames.replace(",,", ",");
        newNames = newNames.replace(/^,/, '');
        newNames = newNames.replace(/,\s*$/, "");

        _data[ids.nameId] = newNames;
        this.saveData();
        this.setState({
            update: true,
        });
    }

    onActivate(evt) {
        this.setState({
            [ids.activeId]: evt,
        });
    }

    onAddTab(evt) {
        if(evt!==ids.add) return;

        let _data = this.state;
        const itemIds = _data[ids.itemIds].split(ids.separator).length;
        if(itemIds+1 >_data[ids.maxItems]) return;
        let _ids = _data[ids.itemIds];
        let newId = this.createUUID();
        _ids += ids.separator + newId;

        let _names = _data[ids.nameId];
        _names += ids.separator + strings.unnamed;

        this.setState({
            [ids.itemIds]: _ids,
            [ids.activeId]: newId,
            [ids.nameId]: _names
        });
    }

    onRemoveTab(evt) {
        if(evt!==ids.removeEvt) return;

        let _data = this.state;
        let _ids = _data[ids.itemIds];
        const activeid = _data[ids.activeId];
        _ids = _ids.replace(activeid, "");
        _ids = _ids.replace(",,", ",");
        _ids = _ids.replace(/^,/, '');
        _ids = _ids.replace(/,\s*$/, "");
        const itemIds = _ids.split(ids.separator);
        this.setState({
            [ids.itemIds]: _ids,
            [ids.activeId]: itemIds[0],
        });
    }

    getTabs() {
        let _data = this.state;

        const activeid = _data[ids.activeId];
        const itemIds = _data[ids.itemIds].split(ids.separator);
        const names = _data[ids.nameId].split(ids.separator);
        let items = [];
        itemIds.forEach((itemId, i) => {
            items.push(
                <Nav.Item key={i}>
                    <Nav.Link
                        eventKey={itemId}
                        onSelect={this.onActivate}
                    >{names[i]}</Nav.Link>
                </Nav.Item>
            );
        });

        const itemsNumber = itemIds.length;
        const itemsNumberMax = _data[ids.maxItems];
        items.push(<Nav.Item key={100}>
            <Nav.Link
                className="bazi-btn btn-dark"
                eventKey={ids.add}
                onSelect={this.onAddTab}
                disabled={itemsNumber===itemsNumberMax}
            >+</Nav.Link>
        </Nav.Item>);
        items.push(<Nav.Item key={200}>
            <Nav.Link
                className="bazi-btn btn-dark"
                eventKey={ids.removeEvt}
                onSelect={this.onRemoveTab}
                disabled={itemsNumber<=1}
            >-</Nav.Link>
        </Nav.Item>);

        return (
            <Nav
                variant="tabs"
                defaultActiveKey={activeid}
                activeKey={activeid}
            >
                {items}

        </Nav>
        );
    }

    getContent() {
        let _data = this.state;
        const isPrintPreviewMode =_data["isInPrintMode"];
        const activeid = _data[ids.activeId];
        const itemIds = _data[ids.itemIds].split(ids.separator);
        let items = [];
        itemIds.forEach((itemId, i) => {
            items.push(
                <Master
                    key={i}
                    keyID={itemId}
                    activeKeyID={activeid}
                    ref={this.master}
                    printPreviewMode={isPrintPreviewMode}
                    printPreview={this.printPreview}
                    updateData={this.updateData}
                    isAdvanced={this.props.isAdvanced}
                />
            );
       });
        return items;
        // return <Master
        //     // key={i}
        //     keyID={activeid}
        //     activeKeyID={activeid}
        //     ref={this.master}
        //     printPreviewMode={isPrintPreviewMode}
        //     printPreview={this.printPreview}
        // />;
    }

    render() {
        const isReady = this.state.ready;
        if (this.state.loading === true || !isReady) {
            return (
                <div style={{textAlign: "center", position: "absolute", top: "25%", left: "50%"}}>
                    <h3>Loading</h3>
                    <Spinner/>
                </div>
            )
        }

        const tabs = this.getTabs();
        const content = this.getContent();
        let isPrintPreviewMode = this.state.isInPrintMode;
        return (
            <div className={isPrintPreviewMode ? "bazi-app-print" : "bazi-app"}>
                <div className="bazi-app-heading-wrapper">
                    {isPrintPreviewMode ?
                        <button className="download_btn fa fa-download" onClick={this._download}></button> : <div/>
                    }
                    <h2 className="app-heading-text bazi-no-print">{strings.title}</h2>
                    {!isPrintPreviewMode ?
                        <div>
                            {/*<button className="generate_btn fa fa-check" onClick={this._generate}></button>*/}
                            {/*<button className="preview_btn fa fa-search" onClick={this._printPreview}></button>*/}
                        </div>

                        :

                        <div>
                            <button className="bazi-back_btn fa fa-undo" onClick={this._back}></button>
                            <button className="bazi-preview_btn fa fa-print" onClick={this._print}></button>
                        </div>
                    }

                </div>
                {tabs}
                {content}
            </div>
        );
    }
}

export default Bazi;
