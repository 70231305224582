import React, {Component} from 'react';
import {strings} from "../Configuration/Config";
import ChannleItem from './ChannelItem'
import {Form} from 'react-bootstrap'

class Step3 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    render() {
        if (this.props.currentStep !== 3 && this.props.data.wizard) { // Prop: The current step
            return null
        }


        // The markup for the Step 1 UI
        return(
            <div>
                {this.props.data.wizard===true &&
                (<Form.Row>
                    <Form.Label>{strings.measure}</Form.Label>
                </Form.Row>) }
                <ChannleItem
                    channelKey="xin"
                    updateData={this.updateData}
                    data={this.props.data}
                />
                <ChannleItem
                    channelKey="xiaochang"
                    updateData={this.updateData}
                    data={this.props.data}
                />
                <ChannleItem
                    channelKey="pi"
                    updateData={this.updateData}
                    data={this.props.data}
                />
                <ChannleItem
                    channelKey="gan"
                    updateData={this.updateData}
                    data={this.props.data}
                />
            </div>
        )
    }
}

export default Step3