import React, {Component} from 'react';
import {ids, strings, imagesKey, results} from "../Configuration/Config";
import ChannleItem from './ChannelItem'
import {Form} from 'react-bootstrap'

class Step6 extends Component {
    render() {
        if (this.props.currentStep !== 6 && this.props.data.wizard) { // Prop: The current step
            return null
        }

        if(!this.props.data[results.resultsRatio.id] || this.props.data[results.resultsRatio.id] === 0) {
            return null;
        }

        //let dt = new Date();
        let imgName = "./assets/images/akabane/" + this.props.data[ids.imageKey].toLowerCase() + imagesKey.fileExt;// + "?" + dt.getTime();

        // The markup for the Step 1 UI
        return(
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={2}>{strings.cure}</Form.Label>
                </Form.Row>
                <ChannleItem
                    channelKey={this.props.data[ids.channelKey]}
                    data={this.props.data}
                />
                <img className="cure-image" src={imgName} alt=""/>
            </div>
        )
    }
}

export default Step6