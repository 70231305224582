import React from 'react';
import { AuthContext } from "../../Authentication";
import Card from './Card';
import {ids, strings } from "../Utils/Constants"
import Toolbar from "../Toolbar/Toolbar"
import { fireApp } from '../../base'
import './Home.css';

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 * */
const tileData = [
    {
        img: ids.imgAppPath + ids.imgFyto,
        title: strings.fyto,
        author: strings.author,
        link: "/fyto",
    },
    {
        img: ids.imgAppPath + ids.imgAcu,
        title: strings.acu,
        author: strings.author,
        link: "/acu"
    },
    {
        img: ids.imgAppPath + ids.imgEarAcu,
        title: strings.earacu,
        author: strings.author,
        link: "/earacu"
    },
    // {
    //     img: ids.imgAppPath + ids.imgSyndroms,
    //     title: strings.syndroms,
    //     author: strings.author,
    //     link: "/syndroms"
    // },
    {
        img: ids.imgAppPath + ids.imgSyndroms,
        title: strings.matrix,
        author: strings.author,
        link: "/matrix"
    },
    {
        img: ids.imgAppPath + ids.imgLeung,
        title: strings.leung,
        author: strings.author,
        link: "/leung"
    },
    {
        img: ids.imgAppPath + ids.imgTao,
        title: strings.tao,
        author: strings.author,
        link: "/tao"
    },
    {
        img: ids.imgAppPath + ids.imgAkabane,
        title: strings.akabane,
        author: strings.author,
        link: "/akabane",
    },
    {
        img: ids.imgAppPath + ids.imgBach,
        title: strings.bach,
        author: strings.author,
        link: "/bachovky",
        center: true
    },
    {
        img: ids.imgAppPath + ids.imgBazi,
        title: strings.bazi,
        author: strings.author,
        link: "/bazi",
        locked: false,
        center: true
    }
];

function Copyright() {
    return (
        <div>

        {/*<div className="welcome-copyright-line"/>*/}
        <div className="welcome-copyright" align="center">
            {'Copyright © '}
            <span>
                {strings.author}
            </span>{' '}
            {new Date().getFullYear()}
        </div>
        </div>
    );
}

class Welcome extends React.Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
    }

    logout() {
        fireApp.auth().signOut().then((user) => {
            //this.setState({ authenticated: false })
        })
    }

    render() {
        const context = this.context;
        let i = 0;
        return (
            <div id="tcme">
                <Toolbar
                    logout={this.logout}
                    visible={context.currentUser}
                />
                <div className="welcome-container">
                    {tileData.map((tile) => (
                        <Card key={i++}
                              image={tile.img}
                              title={tile.title}
                              link={tile.link}
                              locked={tile.locked!==undefined ? tile.locked : !context.currentUser}
                              centered={tile.center}
                        />
                    ))}
                </div>
                <div mt={8}>
                    <Copyright/>
                </div>
            </div>
        )
    }
}

export default Welcome;