import React, {Component} from 'react'
import {keys} from "../Constants"
//import InfoForm from '../../Controls/Info/Info'
//import Rodal from 'rodal';
import EarCard from "./Card";
import {earacu_data} from "../data/Data"

import './Cards.css';

class EarAcuCards extends Component {
    constructor(props) {
        super(props);

        this.getAreaData = this.getAreaData.bind(this);

        this.getCards = this.getCards.bind(this);
        this.getAllCards = this.getAllCards.bind(this);

        this.getItemName = this.getItemName.bind(this);
        this.getItemOrigName = this.getItemOrigName.bind(this);
        this.getItemPoints = this.getItemPoints.bind(this);
        this.getItemDescription = this.getItemDescription.bind(this);
        this.getHeading = this.getHeading.bind(this);

        this.isItem = this.isItem.bind(this);
        this.isVisible = this.isVisible.bind(this);
        this.isFound = this.isFound.bind(this);

        this.state = {
            areas: [],
            loaded: 0,
            visibleInfo: false
        }
    }

    componentDidMount() {

        let tempData = this.state.areas;
        tempData = tempData.concat(earacu_data.helix);
        tempData = tempData.concat(earacu_data.fossascafoidea);
        tempData = tempData.concat(earacu_data.antihelix);
        tempData = tempData.concat(earacu_data.fossatriangularis);
        tempData = tempData.concat(earacu_data.koncha);
        tempData = tempData.concat(earacu_data.tragus);
        tempData = tempData.concat(earacu_data.antitragus);
        tempData = tempData.concat(earacu_data.acinus);
        tempData = tempData.concat(earacu_data.back);

        let that = this;
        that.setState({
            areas: tempData
        });
    }

    componentWillUnmount() {
        //this.removeAuthPlayer();
        // database.removeBinding(this.songsRef);
    }

    getAreaData(id) {
        let ids = id.split(",");
        let areas = this.state.areas;
        if (areas.length === 0) return;
        if (ids.length === 1) {
            return areas.filter(area => {
                return (area["id"] === ids[0]);
            });
        }

        return areas.filter(area => {
            return ids.map(_id => {
                return (area["id"] === _id);
            });
        });
    }

    getItemName(data) {
        if (!data || data.toString().length === 0) return "";

        let name = data[0][keys.name.value];

        return name.toUpperCase();
    }

    getItemDescription(data) {
        if (!data || data.toString().length === 0) return "";

        let description = data[0][keys.description.value];

        return description;
    }

    getItemOrigName(data) {
        if (!data || data.toString().length === 0) return "";

        let orig = "( " + data[0][keys.orig.value] + " )";

        return orig.toUpperCase();
    }

    getItemPoints(data) {
        if (!data || data.toString().length === 0) return "";

        let points = data[0][keys.points.value];
        if(points==="") return "";
        return ("počet bodů: " + points).toUpperCase();
    }

    isItem(area, item, itemCompare) {
        if(itemCompare === item)
            return true;

        if(itemCompare!== undefined && itemCompare.indexOf(',') > 0) {
            let points = itemCompare.split(',');
            let foundItem = points.filter(id => {
                return (id === item);
            });

            return foundItem.length > 0;
        }

        let point = area[itemCompare];
        return (point === item);
    }

    isFound(area, item, searchText) {
        let points = area[keys.xues.value];
        let pointItem = points.filter(point => {
            return (point[keys.id.value] === item)
        });

        if(pointItem.length === 0) return false;

        let chips = searchText.split(",");
        let foundItem = chips.filter(chip => {
            chip = chip.trim().toLocaleLowerCase();
            if(pointItem[0][keys.name.value] && pointItem[0][keys.name.value].toLowerCase().match(chip))
                return true;
            else if(pointItem[0][keys.orig.value] && pointItem[0][keys.orig.value].toLowerCase().match(chip))
                return true;
            else if(pointItem[0][keys.id.value] && pointItem[0][keys.id.value].toLowerCase().match(chip))
                return true;

            if (pointItem[0][keys.function.value] && pointItem[0][keys.function.value].length) {
                const items = pointItem[0][keys.function.value].filter(item => {
                    return item.description && item.description.toLowerCase().match(chip);
                });

                if (items.length > 0)
                    return true;
            }

            if (pointItem[0][keys.usage.value] && pointItem[0][keys.usage.value].length) {
                const items = pointItem[0][keys.usage.value].filter(item => {
                    return item.description && item.description.toLowerCase().match(chip);
                });

                if (items.length > 0)
                    return true;
            }
            return false;
        });

        return foundItem.length > 0;
    }

    isVisible(area, item, filters) {
        //wushuxue

        let isAllsue = filters[keys.allxue.value];

        return isAllsue;
    }

    getAllCards(currentAreasId, filters) {
        let areasId = currentAreasId.split(',');
        let cards = [];
        areasId.forEach(area => (
            this.getCards(area, filters, cards)
        ));

        return cards;
    }

    getCards(currentAreaId, filters, cards) {
        if(currentAreaId==="")
            return ;
        let area = this.getAreaData(currentAreaId);
        if(!area || area.length === 0)
            return ;
        let allxue = area[0][keys.allxue.value].split(",");

        let filteredXues = [];
        if(this.props.filters[keys.search.value]) {
            filteredXues = allxue.filter(xueId => {
                return (this.isFound(area[0], xueId.trim(), this.props.filters[keys.searchText.value]));
            });
        } else {
            filteredXues = this.props.filters[keys.allxue.value] ? allxue : allxue.filter(xueId => {
                return (this.isVisible(area[0], xueId.trim(), this.props.filters));
            });

            if(filters[keys.jinglabels.value]) {
                let heading = this.getHeading(area, filters);
                cards.push(heading);
                // return (
                //     <div>
                //         {heading}
                //         {xues}
                //     </div>
                // )
            }


            if(filters[keys.allxue.value] && filteredXues.length > 1) {
                filteredXues.sort(function (a,b) {
                    let points = area[0][keys.xues.value];

                    points.filter(point => {
                        return (point[keys.id.value] === a.trim());
                    });

                    points.filter(point => {
                        return (point[keys.id.value] === b.trim());
                    });

                    // let pointA = Number(pointa[0][keys.name.value].replace(area[0][keys.short.value], ""));
                    // let pointB = Number(pointb[0][keys.name.value].replace(area[0][keys.short.value], ""));
                    // if (pointA < pointB)
                    //     return -1;
                    // if (pointA > pointB)
                    //     return 1;
                    return 0;
                });
            }
        }

        filteredXues.forEach(item => {
            let length = cards.length;
            cards.push( <EarCard key={length++}
                area={area}
                currentItemId={item.trim()}
                filters={this.props.filters}
            />)
        });
    }

    getHeading(channel, filters) {
        let heading = this.getItemName(channel);
        let orig = this.getItemOrigName(channel);
        let description = this.getItemDescription(channel);
        let points = this.getItemPoints(channel);

        let showHeading = filters[keys.jinglabels.value];
        if(showHeading) {
            return (
                <div key={heading}>
                    <div className="channel-heading channel-heading-top normal-color">{heading}</div>
                    <div className="channel-heading normal-color">{orig}</div>
                    <div className="channel-heading-points normal-color">{points}</div>
                    <div className="channel-description normal-color">{description}</div>
                </div>
            )
        }

        return <div/>;
    }

    render() {
        // const loaded = this.state.loaded;
        // if (loaded < 11) return (<div />);

        let currentAreaId = this.props.currentArea;
        let cards = this.getAllCards(currentAreaId, this.props.filters);

        return (
            <div className="card-container">
                {cards}
            </div>
        )
    }
}

export default EarAcuCards
