import React, {Component} from 'react';
import Master from './Master/Master'
import {Spinner} from '@blueprintjs/core';
import {strings} from "./Configuration/Config";

import './Akabane.css';
import '@blueprintjs/core/dist/blueprint.css';

class Akabane extends Component {
    constructor() {
        super();

        //this.childMaster = React.createRef();
        this.state = {
            loading: false,
            isInPrintMode: false,
        };

        this.master = React.createRef();

        this._printPreview = this._printPreview.bind(this);
        this._print = this._print.bind(this);
        this._back = this._back.bind(this);
        this._clear = this._clear.bind(this);
        this._download = this._download.bind(this);
    }

    _download() {
        this.master.current.download();
    }

    _clear() {
        this.master.current.clear();
    }

    _printPreview() {
        this.setState({
            isInPrintMode: true
        });
    }

    _back() {
        this.setState({
            isInPrintMode: false
        });
    }

    _print() {
        window.print();
        // const content = document.getElementById(Messages.ids.printArea);
        // let uniqueIframeId= "printer";
        //     const iframe = document.createElement('iframe');
        //     iframe.setAttribute('title', uniqueIframeId);
        //     iframe.setAttribute('id', uniqueIframeId);
        //     iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
        //     document.body.appendChild(iframe);
        // let pri = iframe.contentWindow;
        // pri.document.open();
        // pri.document.write(content.innerHTML);
        // pri.document.close();
        //
        // for (let thisSheet=0;thisSheet<document.styleSheets.length;thisSheet++){
        //     pri.document.styleSheets.push(document.styleSheets[thisSheet]);
        // }
        // pri.focus();
        // pri.print();
    }

    render() {
        if (this.state.loading === true) {
            return (
                <div style={{textAlign: "center", position: "absolute", top: "25%", left: "50%"}}>
                    <h3>Loading</h3>
                    <Spinner />
                </div>
            )
        }

        let isPrintPreviewMode = this.state.isInPrintMode;
        return (
            <div className={isPrintPreviewMode ? "app-print" : "akabane-app"}>
                <div className="app-heading-wrapper">
                    { isPrintPreviewMode ?
                        <button title="Stáhnout" className="download_btn fa fa-download" onClick={this._download}></button> : <div/> }
                    <h2 className="app-heading-text no-print">{strings.title}</h2>
                    { !isPrintPreviewMode ?
                        <div>
                        <button title="Vymazat" className="reset_btn fa fa-trash" onClick={this._clear}></button>
                            <button title="Náhled" className="preview_btn fa fa-search" onClick={this._printPreview}></button> </div>:
                        <div>
                            {/*<Button*/}
                                {/*className="app-heading-control-back no-print"*/}
                                {/*variant="info"*/}
                                {/*type="button"*/}
                                {/*onClick={this._back}*/}
                            {/*>*/}
                                {/*<i class="fa fa-undo-alt" aria-hidden="true"></i>*/}

                            {/*</Button>*/}
                            {/*<Button*/}
                                {/*className="app-heading-control-print no-print"*/}
                                {/*variant="info"*/}
                                {/*type="button"*/}
                                {/*onClick={this._print}*/}
                            {/*>*/}
                                {/*<i className="fa fa-print" aria-hidden="true"></i>*/}

                            {/*</Button>*/}

                            <button title="Zpět"className="back_btn fa fa-undo" onClick={this._back}></button>
                            <button title="Tisk" className="preview_btn fa fa-print" onClick={this._print}></button>
                        </div>
                    }

                </div>

                <Master
                    ref={this.master}
                    printPreviewMode={isPrintPreviewMode}
                        printPreview={this.printPreview}/>
            </div>
        );
    }
}

export default Akabane;
