import React, {Component} from 'react';
import Chart from 'chart.js/auto';
import {Card} from 'react-bootstrap'
import { Bar } from 'react-chartjs-2';
import {ids, data, strings} from "../Configuration/Config";

class HexagramChart extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        // The markup for the Step 1 UI
        // const keyId = this.props.keyId;
        const calcData = this.props.data;
        const results = calcData[ids.results];

        // const hexDesc = this.getHexagemDescription(keyId, calcData, results);
        // const topImg = hexDesc[ids.topImage];
        // const bottomImg = hexDesc[ids.bottomImage];
        // const title = hexDesc[ids.hexagramDesc];
        //
        // let headerClassName = "card-header-lucky";
        // let ebClassName = "card-body-lucky";
        // let hsClassName = "card-body-lucky card-body-border";
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                    text: 'test',
                },
            },
        };
        const labels = [strings.heaven, strings.earth];
        const data = {
            labels,
            datasets: [
                {
                    label: '',
                    data: [results[ids.heaven], results[ids.earth]],
                    backgroundColor: ['rgba(255, 99, 132, 0.5)','rgba(53, 162, 235, 0.5)'],
                    borderColor: ['rgb(255, 99, 132)','rgb(53, 162, 235)'],
                    borderWidth: 2,
                },
            ],
        };
        return (
            <Card className="chart bazi text-center">
            <Bar options={options} data={data} />
            </Card>
        )
    }
}

export default HexagramChart