import React, {useCallback, useState} from "react";
import {AppToaster} from "../../base.js";
import {Link} from 'react-router-dom'
import {Intent} from '@blueprintjs/core'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {errorsMessages} from '../Utils/errorMessages'
import {strings} from '../Utils/Constants'
import MyRecaptcha from '../Controls/Recaptcha/Recaptcha'
import axios from 'axios'

import './Signup.css';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <span>
                {strings.author}
            </span>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function SignUp(history) {
    const classes = useStyles();
    // call your hook here
    const [isBtnDisables, setBtnDisabled] = useState(true);

    function onRecaptchaChanged(value) {
        setBtnDisabled(!value);
    }

    function isDisabled(){
        return isBtnDisables;   // for disable button return true otherwise false
    }

    const handleSignUp = useCallback(async event => {
        event.preventDefault();
        const {firstName, lastName, email, notes} = event.target.elements;

        if (email === undefined || email.value === "" || email.value.indexOf('@') === -1) {
            AppToaster.show({intent: Intent.DANGER, message: errorsMessages.isEmailSpecialChar});
            return;
        }

        let id = email.value.indexOf('@');
        let res = email.value.substring(id);

        if (res.indexOf('.') === -1) {
            AppToaster.show({intent: Intent.DANGER, message: errorsMessages.isEmailSpecialChar});
            return;
        }

        let from = email.value;
        let to = strings.email;
        let first = firstName.value;
        let last = lastName.value;
        let subject = strings.emailSignSubject;

        axios.post(
            '../../php/contacts.php', {
                data: {
                    'to': to,
                    'from': from,
                    'name': first + " " + last,
                    'subject': subject,
                    'messages': notes.value
                }
            })
            .then((response) => {
                //console.log(response.data);
                if(response.data["nf_status"]==="error") {
                    AppToaster.show({intent: Intent.DANGER, message: strings.badEmailSent});
                } else {
                    AppToaster.show({intent: Intent.SUCCESS, message: strings.correctEmailSent});
                    history.history.push("/");
                }

            }, (error) => {
                //console.log(error.toString());
                AppToaster.show({intent: Intent.DANGER, message: error.toString()});
            })
            .catch(e => {
                // console.log(e);
                AppToaster.show({intent: Intent.DANGER, message: strings.badEmailSent + " " + e.toString()});
            });

        // try {
        //     await fireApp
        //         .auth()
        //         .createUserWithEmailAndPassword(email.value, password.value).then(function() {
        //             history.history.push("/");
        //         });
        // } catch (error) {
        //     AppToaster.show({intent: Intent.DANGER, message: error.message})
        // }
    }, [history]);
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Registrace
                </Typography>
                <form onSubmit={handleSignUp} className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required={true}
                                fullWidth
                                id="firstName"
                                label="Jméno"
                                autoFocus
                                // error={false}
                                // helperText={ "Perfect!"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Příjmení"
                                name="lastName"
                                autoComplete="lname"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="notes"
                                label="Poznámka - proč chcete přístup"
                                id="notes"
                                autoComplete="notes"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isDisabled()}
                    >
                        Registrovat
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/login" variant="body2">
                                Již máš účet? Přihlásit
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid className="recaptcha" item xs={12}>
                        <MyRecaptcha onChange={onRecaptchaChanged}/>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
}