import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {keys} from "../../Constants"

// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//styles
import './PointsTable.css'

class MethodTable extends Component {
    constructor(props) {
        super(props);

        this.format = this.format.bind(this);

        this.state = {
            table: []
        };
    }
    componentWillMount() {

    }

    format(cell, row){
        return cell===true ? <i className=" fa fa-check"></i> : <i/>;
    }

    render() {
        const items = this.props.table.map(item => {
            return {
                "id": item.name,
                "name": item.name,
                "moxa": item.moxa,
                "bu": item.bu,
                "pbpx": item.pbpx,
                "xie": item.xie
            };
        });
        let empty = "";
        const options = {
            // page: 2,  // which page you want to show as default
            // sizePerPageList: [ {
            //     text: '5', value: 5
            // }, {
            //     text: '10', value: 10
            // }, {
            //     text: 'All', value: products.length
            // } ], // you can change the dropdown list for size per page
            sizePerPage: 15,  // which size per page you want to locate as default
        //    pageStartIndex: 0, // where to start counting the pages
        //    paginationSize: 3,  // the pagination bar size.
            // prePage: 'Prev', // Previous page button text
            // nextPage: 'Next', // Next page button text
            // firstPage: 'First', // First page button text
            // lastPage: 'Last', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            hideSizePerPage: true //> You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };

        return (
            <div>
                <BootstrapTable data={items}
                                striped
                                hover
                                pagination={ false }
                                options={ options }
                                tableHeaderClass='leung-table'
                                tableBodyClass='leung-table'
                                trClassName='leung-table'
                                tableStyle={ { backgroundColor: 'transparent', width: '100%', marginBottom: '0px',  marginLeft: 'auto', marginRight: 'auto', marginTop: '5px'} }
                >
                    <TableHeaderColumn isKey
                                       dataField='id'
                                       hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       className='leung-table'
                                       width='35%'
                                       dataSort={ false }
                                       dataField={keys.name.value}
                    >{empty}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='17%'
                                       dataSort={ false }
                                       dataField={keys.moxa.value}
                                       dataFormat={this.format}
                    >{keys.moxa.label}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='17%'
                                       dataField={keys.bu.value}
                                       dataFormat={this.format}
                    >{keys.bu.label}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='17%'
                                       dataField={keys.pbpx.value}
                                       dataFormat={this.format}
                    >{keys.pbpx.label}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       dataField={keys.xie.value}
                                       dataFormat={this.format}
                                       width='17%'
                    >{keys.xie.label}</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }


}

export default MethodTable;