import React, {Component} from 'react'
import {keys} from "../Constants"
import {acu_data} from "../data/Data"
import {reactLocalStorage} from 'reactjs-localstorage';
//import ToggleButton from 'react-bootstrap/ToggleButton';
//import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Select from 'react-select'
import PointsTable from './PointsTable/PointsTable'
import Cards from "../Cards/Cards";
import './TaoTable.css';
import '../Client.css';
import '../Cards/Cards.css';

class TaoTable extends Component {
    constructor(props) {
        super(props);

        this.saveData = this.saveData.bind(this);
        this.getPoints = this.getPoints.bind(this);
        this.getXues = this.getXues.bind(this);
        this.getBodyParts = this.getBodyParts.bind(this);
        this.bodyPartsChange = this.bodyPartsChange.bind(this);

        this.state = {
            ready: false,
            table: [],
            filters: [],
            channels: [],
            configuration: [],
            currentBodyParts: "",
        }
    }

    saveData() {
        reactLocalStorage.set(keys.bodyPartsResult2.value, this.state.currentBodyParts);
    }

    componentDidMount() {
        let bodyPartsValue = reactLocalStorage.get(keys.bodyPartsResult2.value, "");

        let tempData = this.state.channels;
        tempData = tempData.concat(acu_data.lu);
        tempData = tempData.concat(acu_data.li);
        tempData = tempData.concat(acu_data.st);
        tempData = tempData.concat(acu_data.sp);
        tempData = tempData.concat(acu_data.ht);
        tempData = tempData.concat(acu_data.si);
        tempData = tempData.concat(acu_data.bl);
        tempData = tempData.concat(acu_data.ki);
        tempData = tempData.concat(acu_data.pc);
        tempData = tempData.concat(acu_data.te);
        tempData = tempData.concat(acu_data.gb);
        tempData = tempData.concat(acu_data.lr);
        tempData = tempData.concat(acu_data.cv);
        tempData = tempData.concat(acu_data.gv);
        tempData = tempData.concat(acu_data.extra);

        let taoTable = acu_data.tao;

        let configuration = acu_data.configuration;

        this.setState({
            channels: tempData,
            table: taoTable,
            configuration: configuration,
            currentBodyParts: bodyPartsValue,
            ready: true
        });
    }

    bodyPartsChange(e) {
        let current = "";
        if (e !== null) {
            for (let id in e) {
                if (current.length > 0)
                    current += ",";
                current += e[id]["value"];
            }
        }

        let that = this;
        this.setState({
            currentBodyParts: current
        }, () => {
            that.saveData();
        });
    }

    getBodyParts() {
        let bodyParts = this.state.table;
        return bodyParts.map(item => {
            let name = item[keys.name.value];
            let id = item[keys.id.value];
            return {value: id, label: name};
        });
    }

    getPoints(bodyPart) {
        if (bodyPart === "")
            return <div/>;

        let table = this.state.table;

        //organ
        let pointsTable = [];
        let points = [];
        let i = 0;
        table.forEach(item => {
            if (!bodyPart.match(item[keys.id.value])) return;

            let nearPoints = item[keys.nearPoints.value].split(",");
            let distancePoints = item[keys.distancePoints.value].split(",");

            //near points
            let _nearPoints = "";
            //distance points
            let _distancePoints = "";

            this.state.channels.forEach(channel => {
                let allxue = channel[keys.allxue.value].split(",");
                //near points
                nearPoints.forEach(id => {
                    allxue.forEach(xueId => {
                        if (xueId.trim() === id) {
                            let points = channel[keys.xues.value];
                            let foundItem = points.filter(_point => {
                                return (_point[keys.id.value] === id)
                            });
                            if (_nearPoints.length > 0)
                                _nearPoints += ", ";
                            _nearPoints += foundItem[0][keys.name.value];//.push(foundItem[0][keys.name.value]);
                        }
                    });
                });
                //distance points
                distancePoints.forEach(id => {
                    allxue.forEach(xueId => {
                        if (xueId.trim() === id) {
                            let points = channel[keys.xues.value];
                            let foundItem = points.filter(_point => {
                                return (_point[keys.id.value] === id)
                            });
                            if (_distancePoints.length > 0)
                                _distancePoints += ", ";
                            _distancePoints += foundItem[0][keys.name.value];///;.push(foundItem[0][keys.name.value]);
                        }
                    });
                });
            });
            points.push(
                {
                    name: item[keys.name.value].toUpperCase(),
                    nearPoints: _nearPoints,
                    distancePoints: _distancePoints,
                }
            );

        });

        pointsTable.push(
            <PointsTable
                key={i++}
                table={points}
            />
        );

        return pointsTable;
    }

    getXues(bodyPart) {
        if (bodyPart === "")
            return "";

        let table = this.state.table;

        let xues = "";
        //body parts
        //let bodyItems = bodyPart.split(",");
        table.forEach(item => {
            if (!bodyPart.match(item[keys.id.value])) return;

            let nearPoints = item[keys.nearPoints.value].split(",");
            let distancePoints = item[keys.distancePoints.value].split(",");

            this.state.channels.forEach(channel => {
                let allxue = channel[keys.allxue.value].split(",");
                //near points
                nearPoints.forEach(id => {
                    allxue.forEach(xueId => {
                        if (xueId.trim() === id) {
                            let points = channel[keys.xues.value];
                            let foundItem = points.filter(_point => {
                                return (_point[keys.id.value] === id)
                            });
                            if (xues.length > 0)
                                xues += ", ";
                            xues += foundItem[0][keys.id.value]//.push(foundItem[0][keys.name.value]);
                        }
                    });
                });
                //distance points
                distancePoints.forEach(id => {
                    allxue.forEach(xueId => {
                        if (xueId.trim() === id) {
                            let points = channel[keys.xues.value];
                            let foundItem = points.filter(_point => {
                                return (_point[keys.id.value] === id)
                            });
                            if (xues.length > 0)
                                xues += ", ";
                            xues += foundItem[0][keys.id.value];//;.push(foundItem[0][keys.name.value]);
                        }
                    });
                });
            });
        });

        return xues;
    }

    render() {
        if (!this.state.ready) return <div/>;

        let heading = "Tabulka bodů dle Mudr. Tao";
//        let heading2 = "Použití";

        let currentChannel = "";
        let channels = this.state.configuration["channels"];
        for (let _item in channels) {
            if (channels[_item]["channel"] === "") continue;
            currentChannel += channels[_item]["channel"] + ",";
        }

        //body parts
        const bodyPartsOptions = this.getBodyParts();
        if (bodyPartsOptions.length === 0)
            return (<div/>);

        let bodyPartsDefaultvalue = null;
        let currentBP = this.state.currentBodyParts;

        if (currentBP !== "") {
            bodyPartsDefaultvalue = bodyPartsOptions.filter(option => {
                return (currentBP.indexOf(option.value) !== -1);
            });
        }

        let points = this.getPoints(currentBP);
        let xues = this.getXues(currentBP);
        let filters = this.state.filters;
        filters[keys.bagangxue.value] = xues;
        return (
            <div id="tcme">
                <div className="channel-heading channel-heading-top normal-color">{heading}</div>
                <div className="leung-basic-single">
                    <Select
                        className="acu-basic-single-top-p"
                        classNamePrefix="select"
                        name="bodyPart-selector"
                        isMulti={true}
                        defaultValue={bodyPartsDefaultvalue}
                        isSearchable={false}
                        options={bodyPartsOptions}
                        onChange={this.bodyPartsChange}
                    />
                </div>
                <div className="leung-basic-single">
                    {points}
                </div>
                <Cards
                    currentChannel={currentChannel}
                    filters={filters}
                    showInfoForm={this.showInfoForm}
                />
            </div>
        )
    }
}

export default TaoTable