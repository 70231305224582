import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom'
import './NotFound.css';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        let that = this;
        setTimeout(() => {
            that.handleLoad();
        }, 100)
    }

    handleLoad() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const ratio = 10 / (width / height);
        const visual = ReactDOM.findDOMNode(this.myRef.current);
        visual.style.transform = "translate(-50%, -50%) rotate(-" + ratio + "deg) scale(0.7)";
    }

    render() {
        return (
            <div className="tcme notfound-body">
                <Link className="link" to="/" variant="body2">
                    <svg className=".svg" height="0.8em" width="0.8em" viewBox="0 0 2 1" preserveAspectRatio="none">
                        <polyline
                            fill="none"
                            stroke="#777777"
                            strokeWidth="0.1"
                            points="0.9,0.1 0.1,0.5 0.9,0.9"
                        />
                    </svg>
                    Zpět
                </Link>
                <div className="background-wrapper">
                    <h1 className="header" ref={this.myRef} id="visual">404</h1>
                </div>
                <p className="notfound">Stránku kterou chcete zobrazit, zřejmně neexistuje!</p>

            </div>
        );
    }
}

export default NotFound;