import React, {Component} from 'react'
import Select from 'react-select'
import ReactChipInput from "react-chip-input";
import {keys} from "./Constants"
import {earacu_data} from "./data/Data"
import EarAcuCards from "./Cards/Cards";
import {reactLocalStorage} from 'reactjs-localstorage';

import './Client.css';
import 'rodal/lib/rodal.css';

class EarAcu extends Component {
    constructor(props) {
        super(props);

        this.onLoadConfiguration = this.onLoadConfiguration.bind(this);
        this.onShowArea = this.onShowArea.bind(this);
        this.getAreas = this.getAreas.bind(this);
        this.getXuesOptions = this.getXuesOptions.bind(this);
        this.areaChange = this.areaChange.bind(this);
        this.xueChange = this.xueChange.bind(this);
        this.isAnyXueOption = this.isAnyXueOption.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);

        this.saveData = this.saveData.bind(this);
        this.areas = React.createRef();

        this.state = {
            configuration: [],
            currentArea: "",
            filters: {}
        }
    }

    saveData() {
        //save data
        let _filters = this.state.filters;
        reactLocalStorage.set(keys.allxue.value, _filters[keys.allxue.value]);

        reactLocalStorage.set(keys.current.value, this.state.currentArea);
        //reactLocalStorage.set(keys.cards.value, _filters[keys.cards.value]);
        reactLocalStorage.set(keys.jinglabels.value, _filters[keys.jinglabels.value]);
        reactLocalStorage.set(keys.search.value, _filters[keys.search.value]);
        reactLocalStorage.set(keys.searchText.value, _filters[keys.searchText.value]);
    }

    componentDidMount() {
        this.onLoadConfiguration(earacu_data.configuration);
    }

    onLoadConfiguration(data) {
        let helixxue = reactLocalStorage.get(keys.helixxue.value);
        let fossaxue = reactLocalStorage.get(keys.fossaxue.value);
        let antihelix = reactLocalStorage.get(keys.antihelixxue.value);
        let fossatriangxue = reactLocalStorage.get(keys.fossatriangxue.value);
        let konchaxue = reactLocalStorage.get(keys.konchaxue.value);
        let tragxue = reactLocalStorage.get(keys.tragxue.value);
        let antitragxue = reactLocalStorage.get(keys.antitragxue.value);
        let acinusxue = reactLocalStorage.get(keys.acinusxue.value);
        let backxue = reactLocalStorage.get(keys.backxue.value);
        let allxue = reactLocalStorage.get(keys.allxue.value);

        //let isCards = reactLocalStorage.get(keys.cards.value);
        let isLabels = reactLocalStorage.get(keys.jinglabels.value);
        let isSearching = reactLocalStorage.get(keys.search.value);
        let searchText = reactLocalStorage.get(keys.searchText.value);

        let currentArea = reactLocalStorage.get(keys.current.value);
        if (currentArea === undefined) currentArea = "";

        let _filters = this.state.filters;
        _filters[keys.helixxue.value] = helixxue === undefined ? true : (helixxue === "true");
        _filters[keys.fossaxue.value] = fossaxue === undefined ? true : (fossaxue === "true");
        _filters[keys.antihelixxue.value] = antihelix === undefined ? true : (antihelix === "true");
        _filters[keys.fossatriangxue.value] = fossatriangxue === undefined ? true : (fossatriangxue === "true");
        _filters[keys.konchaxue.value] = konchaxue === undefined ? true : (konchaxue === "true");
        _filters[keys.tragxue.value] = tragxue === undefined ? true : (tragxue === "true");
        _filters[keys.antitragxue.value] = antitragxue === undefined ? true : (antitragxue === "true");
        _filters[keys.acinusxue.value] = acinusxue === undefined ? true : (acinusxue === "true");
        _filters[keys.backxue.value] = backxue === undefined ? true : (backxue === "true");
        _filters[keys.color.value] = true;

        _filters[keys.search.value] = isSearching === undefined ? false : (isSearching === "true");
        _filters[keys.searchText.value] = searchText === undefined ? "" : (searchText);
        _filters[keys.cards.value] = true;//isCards===undefined ? true : (isCards==="true");

        let _allxue = allxue === undefined ? true : (allxue === "true");
        if (!_allxue && !this.isAnyXueOption())
            _allxue = true;
        _filters[keys.allxue.value] = _allxue;
        _filters[keys.jinglabels.value] = isLabels === undefined ? true : (isLabels === "true" || _filters[keys.allxue.value]);

        let that = this;
        let tempData = this.state.configuration;
        that.setState({
            configuration: tempData.concat(data),
            filters: _filters,
            currentArea: currentArea
        }, () => {
            that.onShowArea(this.state.currentArea);
        });
    };

    getAreas() {
        if (this.state.configuration.length === 0) return [];
        let areas = this.state.configuration[0]["areas"];
        return areas.map(item => {
            let id = item["area"];
            let name = item["name"];
            return {value: id, label: name};
        });
    }

    getXuesOptions(filters) {
        let options = [];

        options.push({value: keys.jinglabels.value, label: keys.jinglabels.label});

        // options.push({value: keys.helixxue.value, label: keys.helixxue.label});
        // options.push({value: keys.fossaxue.value, label: keys.fossaxue.label});

        return options;
    }

    onShowArea(item) {
        let that = this;
        if (item === "") {
            let areas = this.state.configuration[0]["areas"];
            for (let _item in areas) {
                if (areas[_item]["area"] === "") continue;
                item += areas[_item]["area"] + ",";
            }
        }
        that.setState({
            currentArea: item,
            //filters: _filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    onSearch() {
        let filters = this.state.filters;
        filters[keys.search.value] = !filters[keys.search.value];
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    addChip(value) {
        let filters = this.state.filters;
        let searchText = filters[keys.searchText.value];
        searchText = searchText.length > 0 ? searchText + "," + value : value;
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    };

    removeChip(index) {
        let filters = this.state.filters;
        let chips = filters[keys.searchText.value].split(",");
        chips.splice(index, 1);
        let searchText = "";
        chips.forEach(item => (searchText = searchText.length > 0 ? searchText + "," + item : item));
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    onSearchTextChange(e) {
        let filters = this.state.filters;
        filters[keys.searchText.value] = e.target.value;
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    areaChange(e) {
        let value = ""; //all areas
        if (e !== null) {
            for (let id in e) {
                value += e[id]["value"] + ",";
            }
        }
        this.onShowArea(value);
    }

    xueChange(e) {
        //let value = ""; //all channells
        let filters = this.state.filters;

        filters[keys.helixxue.value] = false;
        filters[keys.fossaxue.value] = false;
        filters[keys.antihelixxue.value] = false;
        filters[keys.fossatriangxue.value] = false;
        filters[keys.konchaxue.value] = false;
        filters[keys.tragxue.value] = false;
        filters[keys.antitragxue.value] = false;
        filters[keys.acinusxue.value] = false;
        filters[keys.backxue.value] = false;
        filters[keys.jinglabels.value] = false;

        if (e !== null) {
            for (let id in e) {
                filters[e[id]["value"]] = true;
            }
        }

        filters[keys.allxue.value] = e === null || e.length === 0 || !this.isAnyXueOption();
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    isAnyXueOption() {
        //let value = ""; //all channells
        let filters = this.state.filters;
        return (filters[keys.helixxue.value] || filters[keys.fossaxue.value] || filters[keys.antihelixxue.value] ||
            filters[keys.fossatriangxue.value] || filters[keys.konchaxue.value] || filters[keys.tragxue.value] ||
            filters[keys.antitragxue.value] || filters[keys.acinusxue.value] || filters[keys.backxue.value]);
    }

    render() {
        let _filters = this.state.filters;
        const areasOptions = this.getAreas();
        if (areasOptions.length === 0)
            return (<div/>);

        const xuesOptions = this.getXuesOptions(_filters);
        if (xuesOptions.length === 0)
            return (<div/>);

        let areasDefaultvalue = null;
        let current = this.state.currentArea.slice(0, -1);

        if (current !== "") {
            areasDefaultvalue = areasOptions.filter(option => {
                return (current.indexOf(option.value) !== -1);
            });
        }

        let xuesDefaultvalue = null;
        //if (this.state.currentChannel !== "" && !(this.state.currentChannel.length > 0 && (this.state.currentChannel.indexOf(',') > 0))) {
        xuesDefaultvalue = xuesOptions.filter(option => {
            return (_filters[option.value]);
        });
        //}

        let searchText = _filters[keys.searchText.value];
        let chips = searchText.length > 0 ? searchText.split(",") : [];

        let isSearching = _filters[keys.search.value];
        let searchClass = !isSearching ? "acu-sk-search fa fa-search" : "acu-sk-search-down fa fa-search";
        return (
            <div id="tcme">
                <div className="acu-select-area">
                    <div className="acu-basic-single ">
                        <Select
                            className="acu-basic-single-top1"
                            classNamePrefix="select"
                            name="channels-selector"
                            isMulti={true}
                            defaultValue={areasDefaultvalue}
                            isSearchable={false}
                            options={areasOptions}
                            onChange={this.areaChange}
                            isDisabled={false}
                        />
                    </div>
                    <div className="acu-basic-single ">
                        {isSearching ?
                            (<ReactChipInput
                                classes="acu-basic-single-top2"
                                chips={chips}
                                placeholder="co budeme hledat..."
                                onSubmit={value => this.addChip(value)}
                                onRemove={index => this.removeChip(index)}
                            />)


                            //         <input className="basic-single-top2 basic-input pt-input" name="search" type="search" ref={(input) => {
                            //     this.searchInput = input
                            // }} placeholder={"Hledat"} defaultValue={searchText.length > 0 ? searchText : "Hledat"} onChange={this.onSearchTextChange}/>)
                            :
                            (<Select
                                className="acu-basic-single-top2"
                                classNamePrefix="select"
                                name="xue-selector"
                                isMulti={true}
                                defaultValue={xuesDefaultvalue}
                                isSearchable={false}
                                options={xuesOptions}
                                onChange={this.xueChange}
                            />)}
                        <button className={searchClass} onClick={this.onSearch}></button>
                    </div>
                </div>
                <EarAcuCards
                    ref={this.areas}
                    currentArea={this.state.currentArea}
                    filters={this.state.filters}
                />}

            </div>
        )
    }
}

export default EarAcu