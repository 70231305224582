import comb from './combinations.json';
import config from './configuration.json';
import info from './info.json';
import bl from './bl.json';
import cv from './cv.json';
import gv from './gv.json';
import gb from './gb.json';
import ht from './ht.json';
import ki from './ki.json';
import li from './li.json';
import lr from './lr.json';
import lu from './lu.json';
import pc from './pc.json';
import si from './si.json';
import sp from './sp.json';
import st from './st.json';
import te from './te.json';
import extra from './extra.json';
import leung from './leung.json';
import tao from './tao.json';


export const acu_data = {
    combinations: comb,
    configuration: config,
    info: info,
    bl: bl,
    gb: gb,
    ht: ht,
    li: li,
    lr: lr,
    lu: lu,
    pc: pc,
    si: si,
    ki: ki,
    sp: sp,
    st: st,
    te: te,
    cv: cv,
    gv: gv,
    extra: extra,
    leung: leung,
    tao: tao
};