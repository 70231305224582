import React, {Component} from 'react';
import {ids, strings, data} from "../Configuration/Config";
import {Col, Form} from 'react-bootstrap'
import {OverlayTrigger, Popover} from 'react-bootstrap'

class Step1 extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        // this.handleSwitched = this.handleSwitched.bind(this);
        //
        this.getDefaultGender = this.getDefaultGender.bind(this);

        this.getDays = this.getDays.bind(this);
        this.getMonths = this.getMonths.bind(this);
        this.getYears = this.getYears.bind(this);
        this.getHours = this.getHours.bind(this);
        this.getMinutes = this.getMinutes.bind(this);

        this.getDefaultYear = this.getDefaultYear.bind(this);
        this.getDefaultMonth = this.getDefaultMonth.bind(this);
        this.getDefaultDay = this.getDefaultDay.bind(this);

        this.getDefaultMinute = this.getDefaultMinute.bind(this);
        this.getDefaultHour = this.getDefaultHour.bind(this);
        this.getDefaultUknown = this.getDefaultUknown.bind(this);
        this.getDefaultChineseCalendar = this.getDefaultChineseCalendar.bind(this);

        this.getTowns = this.getTowns.bind(this);
        this.getDefaultTown = this.getDefaultTown.bind(this);

        this.getAdvancedOptions = this.getAdvancedOptions.bind(this);
    }

    handleChange(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.value;

        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChecked(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.checked === true ? "1" : "0";

        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    getDefaultGender(_data, values) {
        if (!_data || !values[ids.gender]) {
            return strings.man;
        }

        return values[ids.gender];
    }

    getDays(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        let days = _data.date.days;
        let daysArr = days.split(ids.separator);
        daysArr.forEach((day, i) => {
            options.push(
                <option
                    key={i}
                >
                    {day}
                </option>
            );
        });
        return options;
    }

    getDefaultDay(_data, values) {
        if (!_data || !values[ids.day]) {
            let now = new Date();
            return now.getDate();
        }

        return Number(values[ids.day]);
    }

    getMonths(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        let months = _data.date.months;
        let monthsArr = months.split(ids.separator);
        monthsArr.forEach((month, i) => {
            options.push(
                <option
                    key={i}
                >
                    {month}
                </option>
            );
        });
        return options;
    }

    getDefaultMonth(_data, values) {
        if (!_data || !values[ids.month]) {
            let now = new Date();
            let currMonth = now.getMonth();
            if (!_data)
                return "Leden";

            const monthsArr = _data.date.months.split(ids.separator);
            return monthsArr[currMonth];
        }

        return values[ids.month];
    }

    getYears(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        //options.push(<option>Choose...</option>);
        let now = new Date();
        let currYear = now.getFullYear();
        let startYear = Number(data.config[ids.startYear1]);
        let i = 0;
        for (i = startYear; i <= currYear+1; i++) {
            options.push(
                <option
                    key={i}
                >
                    {i}
                </option>
            );
        }

        return options;
    }

    getDefaultYear(_data, values) {
        if (!_data || !values[ids.year]) {
            let now = new Date();
            return now.getFullYear();
        }

        return Number(values[ids.year]);
    }

    getHours(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        let hours = _data.date.hours;
        let hoursArr = hours.split(ids.separator);
        hoursArr.forEach((hour, i) => {
            options.push(
                <option
                    key={i}
                >
                    {hour}
                </option>
            );
        });
        return options;
    }

    getDefaultHour(_data, values) {
        if (!_data || !values[ids.hour]) {
            let now = new Date();
            let currHour = now.getHours().toLocaleString();
            if (currHour.length === 1)
                currHour = "0" + currHour;
            return currHour;
        }

        return (values[ids.hour]);
    }

    getMinutes(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        let minutes = _data.date.minutes;
        let minutesArr = minutes.split(ids.separator);
        minutesArr.forEach((minute, i) => {
            options.push(
                <option
                    key={i}
                >
                    {minute}
                </option>
            );
        });
        return options;
    }

    getDefaultMinute(_data, values) {
        if (!_data || !values[ids.minute]) {
            let now = new Date();
            let currMinutes = now.getMinutes().toLocaleString();
            if (currMinutes.length === 1)
                currMinutes = "0" + currMinutes;

            return currMinutes;
        }

        return values[ids.minute];
    }

    getDefaultUknown(_data, values) {
        if (!_data || !values[ids.unknown]) {
            return false;
        }

        return values[ids.unknown] === "1" ? true : false;
    }

    getDefaultChineseCalendar(_data, values) {
        if (!_data || !values[ids.chineseCalendar]) {
            return false;
        }

        return values[ids.chineseCalendar] === "1" ? true : false;
    }

    getDefaultTown(_data, values) {
        if (!_data || !values[ids.town]) {
            return ids.empty;
        }

        return values[ids.town];
    }

    getTowns(_data) {
        if (!_data)
            return <option>{strings.errorReadingData}</option>;

        let options = [];
        let townsArr = _data.towns;
        for (const [id, value] of Object.entries(townsArr)) {
            //console.log(key, value);
            options.push(
                <option key={"town" + value[ids.itemId]}>
                    {id}
                </option>
            );
        }
        return options;
    }

    getAdvancedOptions() {
        if (!this.props.isAdvanced) return null;

        const heavenCombChecked = this.props.data[ids.heavenCombination] === "1";
        const directCombChecked = this.props.data[ids.directCombination] === "1";
        const harmonicCombChecked = this.props.data[ids.harmonicCombination] === "1";
        const sixCollisionChecked = this.props.data[ids.sixCollision] === "1";
        //shensha
        const fengKongChecked = this.props.data[ids.fengKong] === "1";
        const fuYinChecked = this.props.data[ids.fuYin] === "1";
        const tianYiGuiRenChecked = this.props.data[ids.tianYiGuiRen] === "1";
        const taoHuaChecked = this.props.data[ids.taoHua] === "1";
        const yiMaChecked = this.props.data[ids.yiMa] === "1";
        const tianDeChecked = this.props.data[ids.tianDe] === "1";
        const tianYiChecked = this.props.data[ids.tianYi] === "1";
        const wenChangChecked = this.props.data[ids.wenChang] === "1";
        const taiJiChecked = this.props.data[ids.taiJi] === "1";

        return (<div>
            <Form.Row>
                <Form.Label className="label-empty">{strings.options}</Form.Label>
            </Form.Row>
            <Form.Row>
                <Form.Group column="lg" lg={4} as={Col} controlId={ids.options}>
                    <Form.Check
                        // className={"item-switcher-options"}
                        type="switch"
                        id={ids.heavenCombination}
                        label={strings.heavenCombination}
                        disabled={false}
                        checked={heavenCombChecked}
                        onChange={this.handleChecked}
                    />
                    <Form.Check
                        // className={"item-switcher-options"}
                        type="switch"
                        id={ids.directCombination}
                        label={strings.directCombination}
                        disabled={false}
                        checked={directCombChecked}
                        onChange={this.handleChecked}
                    />
                    <Form.Check
                        // className={"item-switcher-options"}
                        type="switch"
                        id={ids.harmonicCombination}
                        label={strings.harmonicCombination}
                        disabled={false}
                        checked={harmonicCombChecked}
                        onChange={this.handleChecked}
                    />
                    <Form.Check
                        // className={"item-switcher-options"}
                        type="switch"
                        id={ids.sixCollision}
                        label={strings.sixCollision}
                        disabled={false}
                        checked={sixCollisionChecked}
                        onChange={this.handleChecked}
                    />
                </Form.Group>
                <Form.Group column="lg" lg={4} as={Col} controlId={ids.options}>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.fengKongDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                                        <Form.Check
                                            className={"shensha_0_color"}
                                            type="switch"
                                            id={ids.fengKong}
                                            label={strings.fengKong}
                                            disabled={false}
                                            checked={fengKongChecked}
                                            onChange={this.handleChecked}
                                        />
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.fuYinDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_10_color"}
                        type="switch"
                        id={ids.fuYin}
                        label={strings.fuYin}
                        disabled={false}
                        checked={fuYinChecked}
                        onChange={this.handleChecked}
                    />
                                                    </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.tianYiGuiRenDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_20_color"}
                        type="switch"
                        id={ids.tianYiGuiRen}
                        label={strings.tianYiGuiRen}
                        disabled={false}
                        checked={tianYiGuiRenChecked}
                        onChange={this.handleChecked}
                    />
                                                                              </span>
                    </OverlayTrigger>

                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.taoHuaDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_30_color"}
                        type="switch"
                        id={ids.taoHua}
                        label={strings.taoHua}
                        disabled={false}
                        checked={taoHuaChecked}
                        onChange={this.handleChecked}
                    />
                                                                                                       </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.yiMaDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_40_color"}
                        type="switch"
                        id={ids.yiMa}
                        label={strings.yiMa}
                        disabled={false}
                        checked={yiMaChecked}
                        onChange={this.handleChecked}
                    />
                    </span>
                    </OverlayTrigger>
                </Form.Group>
                <Form.Group column="lg" lg={4} as={Col} controlId={ids.options}>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.tianDeDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_50_color"}
                        type="switch"
                        id={ids.tianDe}
                        label={strings.tianDe}
                        disabled={false}
                        checked={tianDeChecked}
                        onChange={this.handleChecked}
                    />
                                       </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.tianYiDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_60_color"}
                        type="switch"
                        id={ids.tianYi}
                        label={strings.tianYi}
                        disabled={false}
                        checked={tianYiChecked}
                        onChange={this.handleChecked}
                    />
                                               </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.wenChangDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_70_color"}
                        type="switch"
                        id={ids.wenChang}
                        label={strings.wenChang}
                        disabled={false}
                        checked={wenChangChecked}
                        onChange={this.handleChecked}
                    />
                                                                           </span>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Popover id="popover-contained">
                        <Popover.Content>
                            {strings.taijiDesc}
                        </Popover.Content>
                    </Popover>}>
                        <span>
                    <Form.Check
                        className={"shensha_80_color"}
                        type="switch"
                        id={ids.taiJi}
                        label={strings.taiJi}
                        disabled={false}
                        checked={taiJiChecked}
                        onChange={this.handleChecked}
                    />
                                                                                                       </span>
                    </OverlayTrigger>
                </Form.Group>
            </Form.Row>
        </div>)
    }

    render() {
        // if (this.props.currentStep !== 1 && this.props.data.wizard) { // Prop: The current step
        //     return null
        // }
        const days = this.getDays(data);
        const months = this.getMonths(data);
        const years = this.getYears(data);
        const towns = this.getTowns(data);

        const defaultGender = this.getDefaultGender(data, this.props.data);

        const defaultYear = this.getDefaultYear(data, this.props.data);
        const defaultMonth = this.getDefaultMonth(data, this.props.data);
        const defaultDay = this.getDefaultDay(data, this.props.data);
        const defaultTown = this.getDefaultTown(data, this.props.data);

        const hours = this.getHours(data);
        const minutes = this.getMinutes(data);

        const defaultHour = this.getDefaultHour(data, this.props.data);
        const defaultMinute = this.getDefaultMinute(data, this.props.data);
        const defaultUnknown = this.getDefaultUknown(data, this.props.data);
        const defaultChineseCalendar = this.getDefaultChineseCalendar(data, this.props.data);

        const defaultName = this.props.data[ids.name];

        const advOptions = this.getAdvancedOptions();
        // The markup for the Step 1 UI
        return (
            <div>
                <Form.Row>
                    <Form.Group column="lg" lg={8} as={Col} controlId={ids.name}>
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={this.handleChange}
                            value={defaultName}
                        />
                    </Form.Group>
                    <Form.Group column="lg" lg={4} as={Col} controlId={ids.gender}>
                        <Form.Label>{strings.gender}</Form.Label>
                        <Form.Control onChange={this.handleChange} value={defaultGender} as="select">
                            <option>{strings.man}</option>
                            <option>{strings.woman}</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group column="lg" lg={6} as={Col}>
                        <Form.Row>
                            <Form.Label className="label-empty">{strings.date}</Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group column="lg" lg={3} as={Col} controlId={ids.day}>
                                <Form.Label>{strings.day}</Form.Label>
                                <Form.Control onChange={this.handleChange} value={defaultDay} as="select">
                                    {days}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group column="lg" lg={5} as={Col} controlId={ids.month}>
                                <Form.Label>{strings.month}</Form.Label>
                                <Form.Control onChange={this.handleChange} value={defaultMonth} as="select">
                                    {months}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group column="lg" lg={4} as={Col} controlId={ids.year}>
                                <Form.Label>{strings.year}</Form.Label>
                                <Form.Control onChange={this.handleChange} value={defaultYear} as="select">
                                    {years}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group column="lg" lg={6} as={Col}>
                        <Form.Row>
                            <Form.Label className="label-empty">{strings.time}</Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group column="lg" lg={4} as={Col} controlId={ids.hour}>
                                <Form.Label>{strings.hour}</Form.Label>
                                <Form.Control onChange={this.handleChange} value={defaultHour} as="select">
                                    {hours}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group column="lg" lg={4} as={Col} controlId={ids.minute}>
                                <Form.Label>{strings.minute}</Form.Label>
                                <Form.Control onChange={this.handleChange} value={defaultMinute} as="select">
                                    {minutes}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group column="lg" lg={4} as={Col} controlId={ids.unknown}>
                                <Form.Label>{strings.empty}</Form.Label>
                                <Form.Check type="checkbox" id={ids.unknown} checked={defaultUnknown}
                                            onChange={this.handleChecked} label={strings.unknown}/>
                                {/*<Form.Check type="checkbox" id={ids.chineseCalendar} checked={defaultChineseCalendar} onChange={this.handleChecked} label={strings.chineseCalendar}/>*/}
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group column="lg" lg={12} as={Col} controlId={ids.town}>
                        <Form.Label>{strings.town}</Form.Label>
                        <Form.Control onChange={this.handleChange} value={defaultTown} as="select">
                            {towns}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                {advOptions}
            </div>
        )
    }
}

export default Step1