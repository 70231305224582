import React, {Component} from 'react';
import {ListGroup, ListGroupItem, Card} from 'react-bootstrap'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {getItem} from '../Master/UtilityCalc'
import {ids, data, strings} from "../Configuration/Config";

class PillarItem extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.updateData = this.updateData.bind(this);

        this.getEBItem = this.getEBItem.bind(this);
        this.getEBItemColor = this.getEBItemColor.bind(this);
        this.getEBEnergyItem = this.getEBEnergyItem.bind(this);
        this.getItemLabelColor = this.getItemLabelColor.bind(this);
        this.getEBHSItem = this.getEBHSItem.bind(this);
        this.getShenShaEBItems = this.getShenShaEBItems.bind(this);
        this.getShenShaHSItems = this.getShenShaHSItems.bind(this);
        this.getHSItem = this.getHSItem.bind(this);
        this.getItemTitle = this.getItemTitle.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChange(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.value;

        this.calcRatios(this.props.data, true);
    }

    getItemTitle(_data, key) {
        if(!_data || !key)
            return null;

        const results = _data[ids.results];
        if(!results || !results[key]) return null;

        return results[key][ids.title];
    }

    getEBItem(_data, key) {
        if(!_data || !key)
            return null;

        const results = _data[ids.results];
        if(!results || !results[key]) return null;

        let itemEB = getItem(data.eb, results[key][ids.eb]);
        if(!itemEB || itemEB.length===0) return null;

        return itemEB;
    }

    getEBItemColor(_data, key) {
        let colorCSS = "card-item-default";
        if(!_data || !key)
            return colorCSS;

        const results = _data[ids.results];
        if(!results || !results[key] || !results[key][ids.color]) return colorCSS;

        if(results[key][ids.color]===ids.wood) return "card-item-wood";
        else if(results[key][ids.color]===ids.fire) return "card-item-fire";
        else if(results[key][ids.color]===ids.earth) return "card-item-earth";
        else if(results[key][ids.color]===ids.metal) return "card-item-metal";
        else if(results[key][ids.color]===ids.water) return "card-item-water";

        return colorCSS;
    }

    getEBEnergyItem(_data, key) {
        if(!_data || !key)
            return null;

        let itemHS = getItem(data.hs, key);
        if(!itemHS || itemHS.length===0) return null;

        return itemHS;
    }

    getEBHSItem(_data, key) {
        if(!_data || !key)
            return null;

        let itemHS = getItem(data.hs, key);
        if(!itemHS || itemHS.length===0) return null;

        return itemHS;
    }

    getHSItem(_data, key) {
        if(!_data || !key)
            return null;

        const results = _data[ids.results];
        if(!results || !results[key]) return null;

        let itemHS = getItem(data.hs, results[key][ids.hs]);
        if(!itemHS || itemHS.length===0) return null;

        return itemHS;
    }

    getItemLabelColor(energyItem) {
        if(!energyItem) return "";

        if(energyItem[ids.element]===ids.water) {
            return "card-item-label-water";
        } else if(energyItem[ids.element]===ids.metal) {
            return "card-item-label-metal";
        }  else if(energyItem[ids.element]===ids.earth) {
            return "card-item-label-earth";
        } else if(energyItem[ids.element]===ids.fire) {
            return "card-item-label-fire";
        } else if(energyItem[ids.element]===ids.wood) {
            return "card-item-label-wood";
        }

        return "";
    }

    getShenShaEBItems(_data, key, isLuckyPilar) {
        if(!_data || !key)
            return null;

        const results = _data[ids.results];
        if(!results || !results[key]) return null;

        let options = [];
        let shenshaClassName = "d-inline-block " + isLuckyPilar ? "shensha_lucky" : "shensha";

        //feng kong
        let fengKongClassName = shenshaClassName + " shensha_hidden";
        let fengKong = results[key][ids.fengKong];
        if(fengKong) {
            fengKongClassName = shenshaClassName + " shensha_0_color shensha_0" + (isLuckyPilar ? "_lucky" : "");
        }
        let i = 0;
        options.push(
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.fengKong}
                </Popover.Content>
            </Popover>}>
                <span className={fengKongClassName}>**</span>
            </OverlayTrigger>
        );

        //fuyin
        let fuYinClassName = shenshaClassName + " shensha_hidden";
        let fuYin = results[key][ids.fuYin];
        if(fuYin) {
            fuYinClassName = shenshaClassName + " shensha_10_color shensha_10" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.fuYin}
                </Popover.Content>
            </Popover>}>
                <span className={fuYinClassName}>**</span>
            </OverlayTrigger>
        );

        //tianYiGuiRen
        let tianYiGuiRenClassName = shenshaClassName + " shensha_hidden";
        let tianYiGuiRen = results[key][ids.tianYiGuiRen];
        if(tianYiGuiRen) {
            tianYiGuiRenClassName = shenshaClassName + " shensha_20_color shensha_20" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.tianYiGuiRen}
                </Popover.Content>
            </Popover>}>
                <span className={tianYiGuiRenClassName}>**</span>
            </OverlayTrigger>
        );

        //taohua
        let taoHuaClassName = shenshaClassName + " shensha_hidden";
        let taoHua = results[key][ids.taoHua];
        if(taoHua) {
            taoHuaClassName = shenshaClassName + " shensha_30_color shensha_30" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.taoHua}
                </Popover.Content>
            </Popover>}>
                <span className={taoHuaClassName}>**</span>
            </OverlayTrigger>
        );

        //yiMa
        let yiMaClassName = shenshaClassName + " shensha_hidden";
        let yiMa = results[key][ids.yiMa];
        if(yiMa) {
            yiMaClassName = shenshaClassName + " shensha_40_color shensha_40" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.yiMa}
                </Popover.Content>
            </Popover>}>
                <span className={yiMaClassName}>**</span>
            </OverlayTrigger>
        );

        //tianDe
        let tianDeClassName = shenshaClassName + " shensha_hidden";
        let tianDe = results[key][ids.tianDe];
        if(tianDe && tianDe[ids.eb]) {
            tianDeClassName = shenshaClassName + " shensha_50_color shensha_50" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.tianDe}
                </Popover.Content>
            </Popover>}>
                <span className={tianDeClassName}>**</span>
            </OverlayTrigger>
        );

        //tianYi
        let tianYiClassName = shenshaClassName + " shensha_hidden";
        let tianYi = results[key][ids.tianYi];
        if(tianYi) {
            tianYiClassName = shenshaClassName + " shensha_60_color shensha_60" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.tianYi}
                </Popover.Content>
            </Popover>}>
                <span className={tianYiClassName}>**</span>
            </OverlayTrigger>
        );

        //wenChang
        let wenChangClassName = shenshaClassName + " shensha_hidden";
        let wenChang = results[key][ids.wenChang];
        if(wenChang) {
            wenChangClassName = shenshaClassName + " shensha_70_color shensha_70" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.wenChang}
                </Popover.Content>
            </Popover>}>
                <span className={wenChangClassName}>**</span>
            </OverlayTrigger>
        );

        //taiJi
        let taiJiClassName = shenshaClassName + " shensha_hidden";
        let taiJi = results[key][ids.taiJi];
        if(taiJi) {
            taiJiClassName = shenshaClassName + " shensha_80_color shensha_80" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.taiJi}
                </Popover.Content>
            </Popover>}>
                <span className={taiJiClassName}>**</span>
            </OverlayTrigger>
        );

        return options;
    }

    getShenShaHSItems(_data, key, isLuckyPilar) {
        if(!_data || !key)
            return null;

        const results = _data[ids.results];
        if(!results || !results[key]) return null;

        let i = 0;
        let options = [];
        let shenshaClassName = "d-inline-block " + isLuckyPilar ? "shensha_lucky" : "shensha";

        //fuyin
        let fuYinClassName = shenshaClassName + " shensha_hidden";
        let fuYin = results[key][ids.fuYin];
        if(fuYin) {
            fuYinClassName = shenshaClassName + " shensha_10_colorshensha_10" + (isLuckyPilar ? "_lucky" : "");
        }

        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.fuYin}
                </Popover.Content>
            </Popover>}>
                <span className={fuYinClassName}>**</span>
            </OverlayTrigger>
        );

        //tianDe
        let tianDeClassName = shenshaClassName + " shensha_hidden";
        let tianDe = results[key][ids.tianDe];
        if(tianDe && tianDe[ids.hs]) {
            tianDeClassName = shenshaClassName + " shensha_50_colorshensha_50" + (isLuckyPilar ? "_lucky" : "");
        }
        options.push (
            <OverlayTrigger key={i++} overlay={<Popover id="popover-contained">
                <Popover.Content>
                    {strings.tianDe}
                </Popover.Content>
            </Popover>}>
                <span className={tianDeClassName}>**</span>
            </OverlayTrigger>
        );

        return options;
    }

    render() {
        // The markup for the Step 1 UI
        const keyId = this.props.keyId;
        let ebItem = this.getEBItem(this.props.data, keyId);
        let hsItem = this.getHSItem(this.props.data, keyId);
        if(!ebItem || !hsItem) return null;

        const ebItemColor = this.getEBItemColor(this.props.data, keyId);

        let title = this.getItemTitle(this.props.data, this.props.keyId);
        const isLuckyPilar = keyId.match(ids.luckyPillar);
        //const itemActive = this.props.isItemActive;
        const headerActive = this.props.isHeaderActive;
        //const itemActiveColor = this.props.itemColor;
        //let dt = new Date();
        const ebImg = ids.imgEBPath + ebItem[ids.imageKey];// + "?" + dt.getTime();
        const ebName = ebItem[ids.nameId].length > 0 ? ebItem[ids.nameId] + " - " + ebItem[ids.origId] : "";
        const ebEnergyItem = this.getEBEnergyItem(this.props.data, ebItem[ids.energy]) /*+ " - " + ebItem[ids.origId]*/;
        let ebEnergyItemName = "";
        if(ebEnergyItem) ebEnergyItemName = ebEnergyItem[ids.nameId];
        const ebItemLabelColor = this.getItemLabelColor(ebEnergyItem) + " " + (isLuckyPilar ? "h7" : "");
        const hsItemLabelColor = this.getItemLabelColor(hsItem) + " " + (isLuckyPilar ? "h7" : "");

        const hsImg = ids.imgHSPath + hsItem[ids.imageKey];// + "?" + dt.getTime();
        const hsName = hsItem[ids.nameId];
        const hsOrigName = hsItem[ids.origId];

        const hhs1 = this.getEBHSItem(this.props.data,ebItem[ids.hhs1]);
        const hhs2 = this.getEBHSItem(this.props.data,ebItem[ids.hhs2]);
        const hhs3 = this.getEBHSItem(this.props.data,ebItem[ids.hhs3]);

        const hhs1Img = hhs1 ? ids.imgHSPath + hhs1[ids.imageKey] /*+ "?" + dt.getTime()*/ : "";
        const hss1OrigName = hhs1 ? hhs1[ids.origId] : "";
        const hss1ItemLabelColor = this.getItemLabelColor(hhs1) + " " + (isLuckyPilar ? "h7" : "");

        const hhs2Img = hhs2 ? ids.imgHSPath + hhs2[ids.imageKey] /*+ "?" + dt.getTime()*/ : "";
        const hss2OrigName = hhs2 ? hhs2[ids.origId] : "";
        const hss2ItemLabelColor = this.getItemLabelColor(hhs2) + " " + (isLuckyPilar ? "h7" : "");

        const hhs3Img = hhs3 ? ids.imgHSPath + hhs3[ids.imageKey] /*+ "?" + dt.getTime()*/ : "";
        const hss3OrigName = hhs3 ? hhs3[ids.origId] : "";
        const hss3ItemLabelColor = this.getItemLabelColor(hhs3) + " " + (isLuckyPilar ? "h7" : "");

        let hsClassName = isLuckyPilar ? "card-body-lucky card-body-border" : "card-body-border";
        //hsClassName += itemActive ? /*itemActiveColor ? " " + itemActiveColor :*/ " card-item-active" : "";

        let headerClassName = isLuckyPilar ? "card-header-lucky" : "";
        headerClassName += headerActive ? " card-item-active" : "";

        let ebClassName = isLuckyPilar ? "card-body-lucky" : ebItemColor;

        let tooltip = this.props.tooltip ? this.props.tooltip : null;

        //shensha
        let shenShaED = this.getShenShaEBItems(this.props.data, keyId, keyId.match(ids.luckyPillar));
        let shenShaHS = this.getShenShaHSItems(this.props.data, keyId, keyId.match(ids.luckyPillar));

        return (
            <Card className="bazi text-center">
                {tooltip ?
                <OverlayTrigger overlay={<Popover id="popover-contained">
                    <Popover.Content>
                        {tooltip}
                    </Popover.Content>
                </Popover>}>
                    <span className="d-inline-block">
                <Card.Header className={headerClassName}>{title}</Card.Header>
                                                                    </span>
                </OverlayTrigger> :
                <Card.Header className={headerClassName}>{title}</Card.Header>}
                {/*hs*/}
                <Card.Body className={hsClassName}>
                    <Card.Img className={"pillar-img"} variant="top" src={hsImg} />
                    {shenShaHS}
                    <Card.Title className={hsItemLabelColor}>{hsOrigName}</Card.Title>
                    <Card.Text className={hsItemLabelColor}>{hsName}</Card.Text>
                </Card.Body>
                {/*eb*/}
                <Card.Body className={ebClassName}>
                    <Card.Img className={"pillar-img"} variant="top" src={ebImg} />
                    {shenShaED}
                    <Card.Title  className={ebItemLabelColor}>{ebName}</Card.Title>
                    <Card.Text  className={ebItemLabelColor}>{ebEnergyItemName}</Card.Text>
                </Card.Body>
                    <ListGroup className="list-group-right">
                        <ListGroupItem className={"list-group-item-right"}>
                            <Card.Img className={"hidden-steams-img"} variant="top" src={hhs1Img} />
                            <Card.Text  className={hss1ItemLabelColor}>{hss1OrigName}</Card.Text>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Card.Img variant="top" src={hhs2Img} />
                            <Card.Title  className={hss2ItemLabelColor}>{hss2OrigName}</Card.Title>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Card.Img className={"hidden-steams-img"} variant="top" src={hhs3Img} />
                            <Card.Text  className={hss3ItemLabelColor}>{hss3OrigName}</Card.Text>
                        </ListGroupItem>
                    </ListGroup>
            </Card>
        )
    }
}

export default PillarItem