import React, {Component} from 'react'
import Select from 'react-select'
import ReactChipInput from "react-chip-input";
import {keys} from "./Constants"
import {data} from "./data/Data"
import Cards from "./Cards/Cards";
import {reactLocalStorage} from 'reactjs-localstorage';

import './BachEssemce.css';

class BachEssence extends Component {
    constructor(props) {
        super(props);

        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.categoryChange = this.categoryChange.bind(this);

        this.getKeyId = this.getKeyId.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.saveData = this.saveData.bind(this);

        this.state = {
            currentCategory: "",
            loaded: false,
            filters: {},
        }
    }

    componentDidMount() {
        // fetch('/data/configuration.json')
        //     .then(res => res.json())
        this.onLoadData();
    }

    getKeyId(id) {
        let key = "ba";
        return key + "_" + id;
    }

    saveData() {
        //save data
        let _filters = this.state.filters;
        reactLocalStorage.set(this.getKeyId(keys.searchText.value), _filters[keys.searchText.value]);
        reactLocalStorage.set(this.getKeyId(keys.current.value), this.state.currentCategory);
    }

    onLoadData() {
        let searchText = reactLocalStorage.get(this.getKeyId(keys.searchText.value));
        let currentCategory = reactLocalStorage.get(this.getKeyId(keys.current.value));

        let _filters = this.state.filters;
        _filters[keys.searchText.value] = searchText === undefined ? "" : (searchText);
        _filters[keys.cards.value] = true;//isCards===undefined ? true : (isCards==="true");
        if (currentCategory === undefined) currentCategory = "";
        let that = this;
        this.setState({
            filters: _filters,
            loaded: true,
            currentCategory: currentCategory,
            isLoading: false,
        }, () => {
            that.onShowCategory(this.state.currentCategory);
        });
    };

    getCategories() {
        let categories = data.config[keys.categories.value];
        return categories.map(item => {
            let id = item[keys.id.value];
            let name = item[keys.name.value];
            return {value: id, label: name};
        });
    }

    categoryChange(e) {
        let value = ""; //all channells
        if (e !== null) {
            for (let id in e) {
                value += e[id]["value"] + ",";
            }
        }
        this.onShowCategory(value);
    }

    onShowCategory(item) {
        let that = this;
        if (item === "") {
            let categories = data.config[keys.categories.value];
            for (let _item in categories) {
                if (categories[_item][keys.id.value] === "") continue;
                item += categories[_item][keys.id.value] + ",";
            }
        }
        that.setState({
            currentCategory: item,
            //filters: _filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    addChip(value) {
        let filters = this.state.filters;
        let searchText = filters[keys.searchText.value];
        searchText = searchText.length > 0 ? searchText + "," + value : value;
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    };

    removeChip(index) {
        let filters = this.state.filters;
        let chips = filters[keys.searchText.value].split(",");
        chips.splice(index, 1);
        let searchText = "";
        chips.forEach(item => {
            searchText = searchText.length > 0 ? searchText + "," + item : item;
        });
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    onSearchTextChange(e) {
        let filters = this.state.filters;
        filters[keys.searchText.value] = e.target.value;
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    render() {
        if (!this.state.loaded) return <div/>;

        let _filters = this.state.filters;
        let searchText = _filters[keys.searchText.value];
        let chips = searchText && searchText.length > 0 ? searchText.split(",") : [];

        const categoriesOptions = this.getCategories();
        if (categoriesOptions.length === 0)
            return (<div/>);

        let categoriesDefaultvalue = null;
        let current = this.state.currentCategory.slice(0, -1);

        if (current !== "") {
            categoriesDefaultvalue = categoriesOptions.filter(option => {
                return (current.indexOf(option.value) !== -1);
            });
        }

        return (
            <div id="tcme">
                <div className="content">
                    <div className="workspace">
                        <div className="bach-select-area">
                            <div className="bach-basic-single ">
                                <Select
                                    className="bach-basic-single-top1"
                                    classNamePrefix="select"
                                    name="categories-selector"
                                    isMulti={true}
                                    defaultValue={categoriesDefaultvalue}
                                    isSearchable={false}
                                    options={categoriesOptions}
                                    onChange={this.categoryChange}
                                />
                                <ReactChipInput
                                    classes="bach-basic-single-top2"
                                    chips={chips}
                                    placeholder="co budeme hledat..."
                                    onSubmit={value => this.addChip(value)}
                                    onRemove={index => this.removeChip(index)}
                                />
                            </div>
                        </div>
                        <Cards
                            ref={this.channels}
                            filters={this.state.filters}
                            currentCategory={this.state.currentCategory}
                            items={data.items}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default BachEssence