import React, {Component} from 'react'
import ReactCardFlip from 'react-card-flip';
import {keys} from "../Constants"

class Card extends Component {
    constructor(props) {
        super(props);

        this.getItemInfoFromId = this.getItemInfoFromId.bind(this);
        this.linkFormatter = this.linkFormatter.bind(this);
        this.handleOnFlip = this.handleOnFlip.bind(this);

        this.getPointItemName = this.getPointItemName.bind(this);
        this.getPoint = this.getPoint.bind(this);
        this.getFrontItemSummary = this.getFrontItemSummary.bind(this);
        this.getBackItemSummary = this.getBackItemSummary.bind(this);

        this.showInfoForm = this.showInfoForm.bind(this);
        this.isItem = this.isItem.bind(this);
        this.getItemClasses = this.getItemClasses.bind(this);

        this.state = {
            isFlipped: false
        };
    }

    handleOnFlip(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    getPoint(data, point) {
        let summary = "nejsou data";
        if (!data || data.toString().length === 0) {
            this.props.showSummaryInfo(summary);
            return;
        }

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(_point => {
            return (_point[keys.id.value] === pointId)
        });

        return foundItem[0];
    }

    getPointItemName(data, point) {
        if (!data || data.toString().length === 0) return "?";

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(point => {
            return (point[keys.id.value] === pointId)
        });
        return foundItem[0][keys.name.value];
    }

    showInfoForm(e) {
        if (!this.props.showInfoForm) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        this.props.showInfoForm(e);
    }

    getFrontItemSummary(itemData, filters) {
        if (itemData===undefined || Object.keys(itemData).length === 0)
            return <div className="summary-heading">žádné info</div>;

        //let dt = new Date();
        let imgName = keys.imgPath.value + itemData[keys.imgName.value] + keys.img.value;// + "?" + dt.getTime();
        let classNameSpecialItem1 = "card-upper-1";
        let classNameSpecialItem2 = "card-upper-2";
        return (
            <div>
                <div className="card-summary-heading card-center">{itemData[keys.name.value].toUpperCase()}</div>
                <div className={classNameSpecialItem1}>{itemData[keys.number.value]}</div>
                <div className="card-summary-text card-center">{"(" + itemData[keys.orig.value] + ")"}</div>
                <div className={classNameSpecialItem2}>{itemData[keys.number.value]}</div>
                <img className="card-summary-image" src={imgName} alt=""/>
                <div className="summary-heading  card-summary-heading-space">{keys.categories.label}</div>
                <div className="card-summary-item">{itemData[keys.categories.value]}</div>
                <div className="summary-heading card-summary-heading-space">{keys.mission.label}</div>
                <div className="card-summary-item">{itemData[keys.mission.value]}</div>
            </div>);
    }

    getBackItemSummary(itemData, filters) {
        if (itemData===undefined || Object.keys(itemData).length === 0)
            return <div className="summary-heading">žádné info</div>;

        let i = 0;
        const functions = itemData[keys.function.value].map(item => {
            return <li className="card-summary-item" key={i++}>{item.description}</li>;
        });
        const notes = itemData[keys.note.value]===undefined ? <div/> : itemData[keys.note.value].map(item => {
            return <li className="card-summary-item" key={i++}>{item.description}</li>;
        });

        return (
            <div>
                {/*<div className="card-summary-heading card-center">{pointData[keys.name.value].toUpperCase() + " - " + pointData[keys.orig.value].toUpperCase() + " (" + pointData[keys.pronuncination.value] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem1}>{stars}</div>*/}
                {/*<div className="card-summary-text card-center">{"(" + pointData["translation"] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem2}>{stars}</div>*/}
                {/*<div className="card-margin"></div>*/}
                <div className="card-summary-heading card-margin">{keys.function.label}</div>
                <ul className="card-summary-list">
                {functions}
                </ul>
                {notes.length > 0 ? <div className="card-summary-heading card-margin">{keys.note.label}</div> : <div/>}
                <ul className="card-summary-list">
                {notes}
                </ul>
            </div>);
    }

    linkFormatter(item) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <button className="infoButton normal-color" key={i} data-key={_items[1]} onClick={(event) => {
                        this.showInfoForm(event)
                    }}>{_items[0]}</button>
                );
            } else {
                return token + maybeSpace;
            }
        });

        return (
            <ul className="card-summary-list">
            <li className="card-summary-item normal-color">{contents}</li>
            </ul>
        );
    };

   getItemInfoFromId(id) {
        let combinationsData = this.props.combinationsData;
        if (combinationsData.length === 0) return "";
        let combination = combinationsData["combinations"].filter((item, i) => {
            return (item.id === id);
        });

        if(combination===undefined || combination.length===0) return "";
        return combination[0].combination;
    }

    isItem(channel, item, itemCompare) {
        if(itemCompare === item)
            return true;

        if(itemCompare!== undefined && itemCompare.indexOf(',') > 0) {
            let points = itemCompare.split(',');
            let foundItem = points.filter(id => {
                return (id === item);
            });

            return foundItem.length > 0;
        }

        let point = channel[itemCompare];
        return (point === item);
    }

    getItemClasses(channel, item, point, filters) {
       //wushuxue
        let isWushuxue = filters[keys.wushuxue.value];
        //color
        let isColored = filters[keys.color.value];

        let isMuxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.muxue.value]);
        let isHuoxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.huoxue.value]);
        let isTuxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.tuxue.value]);
        let isYinxue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.jinxue.value]);
        let isShuixue = isColored && isWushuxue && this.isItem(channel, item, channel[keys.shuixue.value]);

        let colorItem = "";
        if(isMuxue)
            colorItem = "card-"+keys.muxue.value;
        if(isHuoxue)
            colorItem = "card-"+keys.huoxue.value;
        if(isTuxue)
            colorItem = "card-"+keys.tuxue.value;
        if(isYinxue)
            colorItem = "card-"+keys.jinxue.value;
        if(isShuixue)
            colorItem = "card-"+keys.shuixue.value;

        //ben
        let isBen = this.isItem(channel, item, channel[keys.benxue.value]) && filters[keys.benxue.value];

        //bu
        let isBu = this.isItem(channel, item, channel[keys.buxue.value]) && filters[keys.buxue.value];

        //xie
        let isXie = this.isItem(channel, item, channel[keys.xiexue.value]) && filters[keys.xiexue.value];

        let classBenItem = (isBen ? " card-benxue" : "");
        let classBuItem = (isBu ? " card-buxue" : "");
        let classXieItem = (isXie ? " card-xiexue" : "");

        return colorItem + " " + classBuItem + classXieItem + classBenItem;
    }

    render() {
        //let _currentPage = this.props.currentPage;
        let data = this.props.item;
        let itemFrontSummary = this.getFrontItemSummary(data, this.props.filters);
        let itemBackSummary = this.getBackItemSummary(data, this.props.filters);

        //let classes = this.getItemClasses(data, currentItemId, point, this.props.filters);
        const frontClass = "frontSide normal-color " ;//+ classes;
        const backClass = "backSide normal-color " ;//+ classes;

        const mainClass = "my-card";// + (this.isVisible(data, currentItemId, this.props.filters)===true ? "" : " card-hide");
        return (
            <div className={mainClass}>
                <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                >
                    <div className={frontClass} onClick={this.handleOnFlip}>
                        {itemFrontSummary}
                    </div>

                    <div className={backClass} onClick={this.handleOnFlip}>
                        {itemBackSummary}
                    </div>
                </ReactCardFlip>
            </div>
        )
    }
}

export default Card
