import React, {Component} from 'react';
import {ids, strings, data} from "../Configuration/Config";
import {reactLocalStorage} from 'reactjs-localstorage';
import Form from 'react-bootstrap/Form'
import Step1 from '../Steps/Step1'
import Step2 from '../Steps/Step2'
import Step3 from '../Steps/Step3'
import Step4 from '../Steps/Step4'
import Step5 from '../Steps/Step5'
import Step6 from '../Steps/Step6'
import Step7 from '../Steps/Step7'
import Step8 from '../Steps/Step8'

import {calculateBazi} from './UtilityCalc'

// import PrintView from '../Print/PrintView'

import './Master.css';

//const [validated, setValidated] = useState(false);
class MasterForm extends Component {
    constructor(props) {
        super(props);
        // Set the initial input values
        this.state = {
            ready: false
        };

        this.preview = React.createRef();

        this.updateData = this.updateData.bind(this);
        this.generate = this.generate.bind(this);
        // this.generateAnnualPillars = this.generateAnnualPillars.bind(this);
        // this.generateLuckPillars = this.generateLuckPillars.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.getKeyId = this.getKeyId.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.generate(false);

        this.setState({
            ready: true
        })
    }

    download() {
        this.preview.current.download();
    }

    getKeyId(id) {
        let key = this.props.keyID;
        return key + "_" + id;
    }

    loadData() {
        let _data = this.state;
        /////////
        //step1//
        /////////
        let name = reactLocalStorage.get(this.getKeyId(ids.name));
        let gender = reactLocalStorage.get(this.getKeyId(ids.gender));

        //date
        let day = reactLocalStorage.get(this.getKeyId(ids.day));
        let month = reactLocalStorage.get(this.getKeyId(ids.month));
        let year = reactLocalStorage.get(this.getKeyId(ids.year));

        //time
        let hour = reactLocalStorage.get(this.getKeyId(ids.hour));
        let minute = reactLocalStorage.get(this.getKeyId(ids.minute));
        let unknown = reactLocalStorage.get(this.getKeyId(ids.unknown));
        let chineseCalendar = reactLocalStorage.get(this.getKeyId(ids.chineseCalendar));

        //town
        let town = reactLocalStorage.get(this.getKeyId(ids.town));

        //options
        let heavenCombination = reactLocalStorage.get(this.getKeyId(ids.heavenCombination));
        let directCombination = reactLocalStorage.get(this.getKeyId(ids.directCombination));
        let harmonixCombination = reactLocalStorage.get(this.getKeyId(ids.harmonicCombination));
        let sixCollision = reactLocalStorage.get(this.getKeyId(ids.sixCollision));
        let taiYuanMingGong = reactLocalStorage.get(this.getKeyId(ids.taiYuanMingGong));
        let annualPillars = reactLocalStorage.get(this.getKeyId(ids.annualPillars));
        let luckyPillars = reactLocalStorage.get(this.getKeyId(ids.luckyPillars));
        let fiveFactors = reactLocalStorage.get(this.getKeyId(ids.fiveFactors));
        //shensha
        let fengKong = reactLocalStorage.get(this.getKeyId(ids.fengKong));
        let fuYin = reactLocalStorage.get(this.getKeyId(ids.fuYin));
        let tianYiGuiRen = reactLocalStorage.get(this.getKeyId(ids.tianYiGuiRen));
        let taoHua = reactLocalStorage.get(this.getKeyId(ids.taoHua));
        let yiMa = reactLocalStorage.get(this.getKeyId(ids.yiMa));
        let tianDe = reactLocalStorage.get(this.getKeyId(ids.tianDe));
        let tianYi = reactLocalStorage.get(this.getKeyId(ids.tianYi));
        let wenChang = reactLocalStorage.get(this.getKeyId(ids.wenChang));
        let taiJi = reactLocalStorage.get(this.getKeyId(ids.taiJi));
        let yiJing = reactLocalStorage.get(this.getKeyId(ids.yiJing));
        let tongShu = reactLocalStorage.get(this.getKeyId(ids.tongShu));

        //defaults
        if(!name) name = strings.empty;
        let now = new Date();
        if(!day) day = now.getDate();
        if(!month) {
            let currMonth = now.getMonth();
            const monthsArr = data.date.months.split(ids.separator);
            month = monthsArr[currMonth];
        }

        if(!year) year = now.getFullYear();
        if(!hour) hour = now.getHours();
        if(!minute) minute = now.getMinutes();
        if(!unknown) unknown = false;
        if(!chineseCalendar) chineseCalendar = false;
        if(!gender) gender = strings.man;
        if(!heavenCombination) heavenCombination = false;
        if(!directCombination) directCombination = false;
        if(!harmonixCombination) harmonixCombination = false;
        if(!sixCollision) sixCollision = false;
        if(!taiYuanMingGong) taiYuanMingGong = false;
        if(!annualPillars) annualPillars = false;
        if(!luckyPillars) luckyPillars = false;
        if(!fiveFactors) fiveFactors = false;
        if(!fengKong) fengKong = false;
        if(!fuYin) fuYin = false;
        if(!tianYiGuiRen) tianYiGuiRen = false;
        if(!taoHua) taoHua = false;
        if(!yiMa) yiMa = false;
        if(!tianDe) tianDe = false;
        if(!tianYi) tianYi = false;
        if(!wenChang) wenChang = false;
        if(!taiJi) taiJi = false;
        if(!yiJing) yiJing = false;
        if(!tongShu) tongShu = false;

        if(!town || town === undefined) {
            const towns = data.towns;
            for (const [key, value] of Object.entries(towns)) {
                town = key;
                break;
            }
        }

        _data[ids.name] = name;
        _data[ids.gender] = gender;
        _data[ids.day] = day;
        _data[ids.month] = month;
        _data[ids.year] = year;
        _data[ids.hour] = hour;
        _data[ids.minute] = minute;
        _data[ids.unknown] = unknown;
        _data[ids.chineseCalendar] = chineseCalendar;
        _data[ids.town] = town;
        _data[ids.heavenCombination] = heavenCombination;
        _data[ids.directCombination] = directCombination;
        _data[ids.harmonicCombination] = harmonixCombination;
        _data[ids.sixCollision] = sixCollision;
        _data[ids.taiYuanMingGong] = taiYuanMingGong;
        _data[ids.annualPillars] = annualPillars;
        _data[ids.luckyPillars] = luckyPillars;
        _data[ids.fiveFactors] = fiveFactors;
        _data[ids.fengKong] = fengKong;
        _data[ids.fuYin] = fuYin;
        _data[ids.tianYiGuiRen] = tianYiGuiRen;
        _data[ids.taoHua] = taoHua;
        _data[ids.yiMa] = yiMa;
        _data[ids.tianDe] = tianDe;
        _data[ids.tianYi] = tianYi;
        _data[ids.wenChang] = wenChang;
        _data[ids.taiJi] = taiJi;
        _data[ids.yiJing] = yiJing;
        _data[ids.tongShu] = tongShu;
    }

    saveData() {
        const _data = this.state;

        /////////
        //step1//
        /////////
        if(_data[ids.name]!==undefined) reactLocalStorage.set(this.getKeyId(ids.name), _data[ids.name]);
        if(_data[ids.gender]!==undefined) reactLocalStorage.set(this.getKeyId(ids.gender), _data[ids.gender]);

        //date
        if(_data[ids.day]!==undefined) reactLocalStorage.set(this.getKeyId(ids.day), _data[ids.day]);
        if(_data[ids.month]!==undefined) reactLocalStorage.set(this.getKeyId(ids.month), _data[ids.month]);
        if(_data[ids.year]!==undefined) reactLocalStorage.set(this.getKeyId(ids.year), _data[ids.year]);

        //time
        if(_data[ids.hour]!==undefined) reactLocalStorage.set(this.getKeyId(ids.hour), _data[ids.hour]);
        if(_data[ids.minute]!==undefined) reactLocalStorage.set(this.getKeyId(ids.minute), _data[ids.minute]);

        //town
        if(_data[ids.town]!==undefined) reactLocalStorage.set(this.getKeyId(ids.town), _data[ids.town]);

        //options
        if(_data[ids.heavenCombination]!==undefined) reactLocalStorage.set(this.getKeyId(ids.heavenCombination), _data[ids.heavenCombination]);
        if(_data[ids.directCombination]!==undefined) reactLocalStorage.set(this.getKeyId(ids.directCombination), _data[ids.directCombination]);
        if(_data[ids.harmonicCombination]!==undefined) reactLocalStorage.set(this.getKeyId(ids.harmonicCombination), _data[ids.harmonicCombination]);
        if(_data[ids.sixCollision]!==undefined) reactLocalStorage.set(this.getKeyId(ids.sixCollision), _data[ids.sixCollision]);
        if(_data[ids.taiYuanMingGong]!==undefined) reactLocalStorage.set(this.getKeyId(ids.taiYuanMingGong), _data[ids.taiYuanMingGong]);
        if(_data[ids.annualPillars]!==undefined) reactLocalStorage.set(this.getKeyId(ids.annualPillars), _data[ids.annualPillars]);
        if(_data[ids.luckyPillars]!==undefined) reactLocalStorage.set(this.getKeyId(ids.luckyPillars), _data[ids.luckyPillars]);
        if(_data[ids.fiveFactors]!==undefined) reactLocalStorage.set(this.getKeyId(ids.fiveFactors), _data[ids.fiveFactors]);
        //shensha
        if(_data[ids.fengKong]!==undefined) reactLocalStorage.set(this.getKeyId(ids.fengKong), _data[ids.fengKong]);
        if(_data[ids.fuYin]!==undefined) reactLocalStorage.set(this.getKeyId(ids.fuYin), _data[ids.fuYin]);
        if(_data[ids.tianYiGuiRen]!==undefined) reactLocalStorage.set(this.getKeyId(ids.tianYiGuiRen), _data[ids.tianYiGuiRen]);
        if(_data[ids.taoHua]!==undefined) reactLocalStorage.set(this.getKeyId(ids.taoHua), _data[ids.taoHua]);
        if(_data[ids.yiMa]!==undefined) reactLocalStorage.set(this.getKeyId(ids.yiMa), _data[ids.yiMa]);
        if(_data[ids.tianDe]!==undefined) reactLocalStorage.set(this.getKeyId(ids.tianDe), _data[ids.tianDe]);
        if(_data[ids.tianYi]!==undefined) reactLocalStorage.set(this.getKeyId(ids.tianYi), _data[ids.tianYi]);
        if(_data[ids.wenChang]!==undefined) reactLocalStorage.set(this.getKeyId(ids.wenChang), _data[ids.wenChang]);
        if(_data[ids.taiJi]!==undefined) reactLocalStorage.set(this.getKeyId(ids.taiJi), _data[ids.taiJi]);
        //rest
        if(_data[ids.yiJing]!==undefined) reactLocalStorage.set(this.getKeyId(ids.yiJing), _data[ids.yiJing]);
        if(_data[ids.tongShu]!==undefined) reactLocalStorage.set(this.getKeyId(ids.tongShu), _data[ids.tongShu]);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

       const _data = this.state;
       let info = [];
       info[ids.nameId] = _data[ids.name];
       this.props.updateData(info);
       this.saveData();
       this.generate(true);
    }

    reloadData() {
        this.loadData();
        this.saveData();
        this.generate(false);
    }

    generate(bIsInside) {
        let _data = this.state;
        let results = calculateBazi(this.state, data);
        if (bIsInside) {
            this.setState({
                [ids.results]: results,
                [ids.generated]: true,
                [ids.insideUpdate]: true
            })
        } else {
            _data[ids.results] = results;
            _data[ids.generated] = true;
            _data[ids.insideUpdate] = false;
        }
    }

    // Render UI will go here...
    render() {
        if(this.props.keyID !== this.props.activeKeyID) return null;

        let _data = this.state;
        if(!_data[ids.insideUpdate])
            this.reloadData();
        _data[ids.insideUpdate] = false;
        //const showPrintPage = this.props.printPreviewMode ? this.props.printPreviewMode : false;
        // if(showPrintPage) {
        //     return (
        //         <PrintView
        //             ref ={this.preview}
        //             data={data}/>
        //     );
        // }

        const isReady = this.state.ready;
        if(!isReady)
            return <div/>;
        return (
            <div className="bazi-main">
                <Form noValidate id="form1">
                    <Step1
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step2
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step6
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step3
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step4
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step5
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step7
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                    <Step8
                        updateData={this.updateData}
                        data={_data}
                        isAdvanced={this.props.isAdvanced}
                    />
                </Form>
            </div>
        )
    }
}


export default MasterForm
