import React, {Component} from 'react'
import {keys} from "../Constants"
import InfoForm from '../../Controls/Info/Info'
import Rodal from 'rodal';
import Card from "./Card";
import {acu_data} from "../data/Data"

import './Cards.css';

class AcuCards extends Component {
    constructor(props) {
        super(props);

        //this.onLoadChannel = this.onLoadChannel.bind(this);
        //this.onLoadExtraData = this.onLoadExtraData.bind(this);
        //this.onLoadCombinationsData = this.onLoadCombinationsData.bind(this);
        this.getChannelData = this.getChannelData.bind(this);

        this.closeInfoForm = this.closeInfoForm.bind(this);
        this.showInfoForm = this.showInfoForm.bind(this);
        this.getCards = this.getCards.bind(this);
        this.getAllCards = this.getAllCards.bind(this);

        this.getItemName = this.getItemName.bind(this);
        this.getItemTime = this.getItemTime.bind(this);
        this.getItemPoints = this.getItemPoints.bind(this);
        this.getHeading = this.getHeading.bind(this);

        this.isItem = this.isItem.bind(this);
        this.isVisible = this.isVisible.bind(this);
        this.isFound = this.isFound.bind(this);

        this.state = {
            channels: [],
            extraData: [],
            combinationsData: [],
            loaded: 0,
            visibleInfo: false,
            extraDataKey: ""
        }
    }

    componentDidMount() {

        let tempData = this.state.channels;
        tempData = tempData.concat(acu_data.lu);
        tempData = tempData.concat(acu_data.li);
        tempData = tempData.concat(acu_data.st);
        tempData = tempData.concat(acu_data.sp);
        tempData = tempData.concat(acu_data.ht);
        tempData = tempData.concat(acu_data.si);
        tempData = tempData.concat(acu_data.bl);
        tempData = tempData.concat(acu_data.ki);
        tempData = tempData.concat(acu_data.pc);
        tempData = tempData.concat(acu_data.te);
        tempData = tempData.concat(acu_data.gb);
        tempData = tempData.concat(acu_data.lr);
        tempData = tempData.concat(acu_data.cv);
        tempData = tempData.concat(acu_data.gv);
        tempData = tempData.concat(acu_data.extra);

        let info = acu_data.info;
        let combination = acu_data.combinations;
        let that = this;
        that.setState({
            channels: tempData,
            extraData: info,
            combinationsData: combination
        });
    }

    componentWillUnmount() {
        //this.removeAuthPlayer();
        // database.removeBinding(this.songsRef);
    }

    getChannelData(id) {
        let ids = id.split(",");
        let channels = this.state.channels;
        if (channels.length === 0) return;
        if (ids.length === 1) {
            return channels.filter(channel => {
                return (channel["id"] === ids[0]);
            });
        }

        return channels.filter(channel => {
            return ids.map(_id => {
                return (channel["id"] === _id);
            });
        });
    }

    showInfoForm(e) {
        e.stopPropagation();
        let key = e.target.getAttribute("data-key");
        this.setState({
            extraDataKey: key,
            visibleInfo: true
        })
    }

    closeInfoForm() {
        if(!this.state.visibleInfo)
            return;
        this.setState({
            visibleInfo: false
        });
    }

    getItemName(data) {
        if (!data || data.toString().length === 0) return "";

        let short = data[0][keys.short.value];
        let name = data[0][keys.name.value];
        let orig = data[0][keys.orig.value];

        return (short.length > 0 && orig.length > 0 ? (short + " - " + name + " (" + orig + ") ") : name).toUpperCase();
    }

    getItemTime(data) {
        if (!data || data.toString().length === 0) return "";

        let time = data[0][keys.time.value];
        if(time==="")
            return "";
        return ("( " + time + " ) ").toUpperCase();
    }

    getItemPoints(data) {
        if (!data || data.toString().length === 0) return "";

        let points = data[0][keys.points.value];
        if(points==="") return "";
        return ("počet bodů: " + points).toUpperCase();
    }

    isItem(channel, item, itemCompare) {
        if(itemCompare === item)
            return true;

        if(itemCompare!== undefined && itemCompare.indexOf(',') > 0) {
            let points = itemCompare.split(',');
            let foundItem = points.filter(id => {
                return (id === item);
            });

            return foundItem.length > 0;
        }

        let point = channel[itemCompare];
        return (point === item);
    }

    isFound(channel, item, searchText) {
        let points = channel[keys.xues.value];
        let pointItem = points.filter(point => {
            return (point[keys.id.value] === item)
        });

        if(pointItem.length === 0) return false;

        let chips = searchText.split(",");
        let foundItem = chips.filter(chip => {
            chip = chip.trim();
            if(pointItem[0][keys.name.value].toLowerCase().match(chip.toLocaleLowerCase()))
                return true;
            else if(pointItem[0][keys.orig.value].toLowerCase().match(chip.toLocaleLowerCase()))
                return true;
            else if(pointItem[0][keys.id.value].toLowerCase().match(chip.toLocaleLowerCase()))
                return true;

            return false;
        });

        return foundItem.length > 0;
    }

    isVisible(channel, item, filters) {
        //wushuxue
        let isWushuxue = filters[keys.wushuxue.value];
        let isAllsue = filters[keys.allxue.value];
        let isMuxue = isWushuxue && this.isItem(channel, item, channel[keys.muxue.value]);
        let isHuoxue = isWushuxue && this.isItem(channel, item, channel[keys.huoxue.value]);
        let isTuxue = isWushuxue && this.isItem(channel, item, channel[keys.tuxue.value]);
        let isYinxue = isWushuxue && this.isItem(channel, item, channel[keys.jinxue.value]);
        let isShuixue = isWushuxue && this.isItem(channel, item, channel[keys.shuixue.value]);

        let isJing = filters[keys.jingxue.value] && this.isItem(channel, item, channel[keys.jingxue.value]);
        let isYing = filters[keys.yingxue.value] && this.isItem(channel, item, channel[keys.yingxue.value]);
        let isShu = filters[keys.shuxue.value] && this.isItem(channel, item, channel[keys.shuxue.value]);
        let isJing2 = filters[keys.jingxue2.value] && this.isItem(channel, item, channel[keys.jingxue2.value]);
        let isHe = filters[keys.hexue.value] && this.isItem(channel, item, channel[keys.hexue.value]);

        //ben
        let isBen = this.isItem(channel, item, channel[keys.benxue.value]) && filters[keys.benxue.value];

        //bu
        let isBu = this.isItem(channel, item, channel[keys.buxue.value]) && filters[keys.buxue.value];

        //xie
        let isXie = this.isItem(channel, item, channel[keys.xiexue.value]) && filters[keys.xiexue.value];

        //yuan
        let isYuan = this.isItem(channel, item, channel[keys.yuanxue.value]) && filters[keys.yuanxue.value];

        //luo
        let isLuo = this.isItem(channel, item, channel[keys.luoxue.value]) && filters[keys.luoxue.value];

        //xi
        let isXi = this.isItem(channel, item, channel[keys.xixue.value]) && filters[keys.xixue.value];

        //beishuxue
        let isBeishu = this.isItem(channel, item, channel[keys.beishuxue.value]) && filters[keys.beishuxue.value];

        //mu xue
        let isMushuxue = this.isItem(channel, item, channel[keys.mu_xue.value]) && filters[keys.mu_xue.value];

        //bahuixue
        let isBahuixue = this.isItem(channel, item, channel[keys.bahuixue.value]) && filters[keys.bahuixue.value];

        //bamaijiaohuixue
        let isBamaijiaohuixue = this.isItem(channel, item, channel[keys.bamaijiaohuixue.value]) && filters[keys.bamaijiaohuixue.value];

        //jiaohuixue
        let isJiaohuixue = this.isItem(channel, item, channel[keys.jiaohuixue.value]) && filters[keys.jiaohuixue.value];

        //xiahexue
        let isXiahexue = this.isItem(channel, item, channel[keys.xiahexue.value]) && filters[keys.xiahexue.value];

        //madayang xue
        let ismadanyangxue = this.isItem(channel, item, channel[keys.madanyangxue.value]) && filters[keys.madanyangxue.value];

        //sun simiao
        let isSunSimiaoxue = this.isItem(channel, item, channel[keys.sunsimiaoguixue.value]) && filters[keys.sunsimiaoguixue.value];

        //windowof heaven xue
        let isWindowOfHeavenxue = this.isItem(channel, item, channel[keys.windowxue.value]) && filters[keys.windowxue.value];

        //extra xue
        let isExtraxue = this.isItem(channel, item, channel[keys.extraxue.value]) && filters[keys.extraxue.value];

        return isAllsue || isMuxue || isMushuxue || isHuoxue || isTuxue || isYinxue || isShuixue || isYuan || isLuo || isXi ||
            isJing || isYing || isShu || isJing2 || isHe || isXie || isBu || isBen || isBeishu || isBahuixue || isBamaijiaohuixue ||
            isXiahexue || isJiaohuixue || isExtraxue || ismadanyangxue || isSunSimiaoxue || isWindowOfHeavenxue;
    }

    getAllCards(currentChannelsId, filters) {
        let channelsId = currentChannelsId.split(',');
        let cards = [];
        channelsId.forEach(channel => (
            this.getCards(channel, filters, cards)
        ));

        if(cards.length === 0)
            return cards;

        //sort
         if (filters[keys.mu_xue.value] || filters[keys.bahuixue.value] ||
            filters[keys.bamaijiaohuixue.value] || filters[keys.xiahexue.value] ||
            filters[keys.jiaohuixue.value] /*|| (filters[keys.xixue.value] && !filters[keys.wushuxue.value] && !filters[keys.luoxue.value])*/ ||
            filters[keys.beishuxue.value] || filters[keys.sunsimiaoguixue.value]) {
            let config = acu_data.configuration;
            let key = keys.mu_xue.value;
            if(filters[keys.bahuixue.value])
                key = keys.bahuixue.value;
            if(filters[keys.bamaijiaohuixue.value])
                key = keys.bamaijiaohuixue.value;
            if(filters[keys.jiaohuixue.value])
                key = keys.jiaohuixue.value;
            if(filters[keys.xiahexue.value])
                key = keys.xiahexue.value;
            if(filters[keys.xixue.value])
                key = keys.xixue.value;
            if(filters[keys.beishuxue.value])
                key = keys.beishuxue.value;
            if(filters[keys.sunsimiaoguixue.value])
                key = keys.sunsimiaoguixue.value;
            let xues = config[keys.xues.value];
            xues.forEach(item => {
                let findItems = item[key];
                if(!findItems) return;

                findItems = findItems.split(',');
                cards.sort(function (a,b) {
                    let key1 = -1;
                    let key2 = -1;
                    if(!a || !a.props || !b || !b.props) return false;
                    let id1 = a.props.currentItemId;
                    let id2 = b.props.currentItemId;
                    let i = 0;
                    for (let xue in findItems) {
                        i++;
                        if(findItems[xue]===id1) {
                            key1 = i;
                        }

                        if(findItems[xue]===id2) {
                            key2 = i;
                        }
                    }

                    return key1===-1 || key2===-1 ? false : key1 - key2;
                });
            });
        }
        return cards;
    }

    getCards(currentChannelId, filters, cards) {
        if(currentChannelId==="")
            return ;
        let channel = this.getChannelData(currentChannelId);
        if(!channel || channel.length === 0)
            return ;
        let allxue = channel[0][keys.allxue.value].split(",");
        const combinationsData = this.state.combinationsData;

        let filteredXues = [];
        if(this.props.filters[keys.search.value]) {
            filteredXues = allxue.filter(xueId => {
                return (this.isFound(channel[0], xueId.trim(), this.props.filters[keys.searchText.value]));
            });
        } else if(this.props.filters[keys.bagangxue.value]) {
            filteredXues = allxue.filter(xueId => {
                return (this.isFound(channel[0], xueId.trim(), this.props.filters[keys.bagangxue.value]));
            });
        } else {
            filteredXues = this.props.filters[keys.allxue.value] ? allxue : allxue.filter(xueId => {
                return (this.isVisible(channel[0], xueId.trim(), this.props.filters));
            });

            if(filters[keys.jinglabels.value]) {
                let heading = this.getHeading(channel, filters);
                cards.push(heading);
                // return (
                //     <div>
                //         {heading}
                //         {xues}
                //     </div>
                // )
            }


            if(filters[keys.allxue.value] && filteredXues.length > 1) {
                filteredXues.sort(function (a,b) {
                    let points = channel[0][keys.xues.value];

                    let pointa = points.filter(point => {
                        return (point[keys.id.value] === a.trim());
                    });

                    let pointb = points.filter(point => {
                        return (point[keys.id.value] === b.trim());
                    });

                    let pointA = Number(pointa[0][keys.name.value].replace(channel[0][keys.short.value], ""));
                    let pointB = Number(pointb[0][keys.name.value].replace(channel[0][keys.short.value], ""));
                    if (pointA < pointB)
                        return -1;
                    if (pointA > pointB)
                        return 1;
                    return 0;
                });
            }
        }

        filteredXues.forEach(item => {
            let length = cards.length;
            cards.push( <Card key={length++}
                channel={channel}
                combinationsData={combinationsData}
                currentItemId={item.trim()}
                showInfoForm={this.showInfoForm}
                filters={this.props.filters}
            />)
        });
    }

    getHeading(channel, filters) {
        let heading = this.getItemName(channel);
        let time = this.getItemTime(channel);
        let points = this.getItemPoints(channel);

        let showHeading = filters[keys.jinglabels.value];
        if(showHeading) {
            return (
                <div key={heading}>
                    <div className="channel-heading channel-heading-top normal-color">{heading}</div>
                    <div className="channel-heading normal-color">{time}</div>
                    <div className="channel-heading normal-color">{points}</div>
                </div>
            )
        }

        return <div/>;
    }

    render() {
        // const loaded = this.state.loaded;
        // if (loaded < 11) return (<div />);

        let currentChannelId = this.props.currentChannel;
        let cards = this.getAllCards(currentChannelId, this.props.filters);

        let advertismentMaskStyles = {
            borderRadius: "10px",
            backgroundColor: "#404040",
            marginTop: "100px",
            padding: "10px",
            zIndex: "1000000"
        };
        return (
            <div className="card-container">
                <Rodal duration={1000}
                       customStyles={advertismentMaskStyles}
                       animation={"slideUp"}
                       visible={this.state.visibleInfo}
                       onClose={this.closeInfoForm}
                       closeMaskOnClick={true}
                       showCloseButton={true}
                       measure={"%"}
                       height={66}
                       width={50}>
                    <InfoForm
                        extraData={this.state.extraData}
                        extraDataKey={this.state.extraDataKey}
                        onClose={this.closeInfoForm}
                    />
                </Rodal>
                {cards}
            </div>
        )
    }
}

export default AcuCards
