import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {fyto_data} from '../../Fyto/data/Data';

//styles
import './MeaningTable.css'

class MeaningTable extends Component {
    constructor(props) {
        super(props);

        this.onClickHandler =  this.onClickHandler.bind(this);
        this.onLoadTable = this.onLoadTable.bind(this);
        this.columnClassNameFormat = this.columnClassNameFormat.bind(this);

        this.state = {
            table: []
        };
    }
    componentWillMount() {
        // fetch('/data/meaningtable.json')
        //     .then(res => res.json())
        this.onLoadTable(fyto_data.meaningtable);
    }

    onClickHandler () {
        this.props.onClose();
    }

    onLoadTable(data) {
        this.setState({
            table: data
        });
    }

    columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
        // fieldValue is column value
        // row is whole row object
        // rowIdx is index of row
        // colIdx is index of column
        if(row.id==="A") return "meaning-A";
        if(row.id==="B") return "meaning-B";
        //if(row.id==="C") return "meaning-A";
        if(row.id==="D") return "meaning-D";
        if(row.id==="E") return "meaning-E";
        if(row.id==="F") return "meaning-F";
        if(row.id==="H") return "meaning-H";
        if(row.id==="M") return "meaning-M";
        if(row.id==="N") return "meaning-N";
        if(row.id==="W") return "meaning-W";
        if(row.id==="X") return "meaning-X";
        return '';
    }

    render() {
        const items = this.state.table.map(item => {
            return {
                "id": item.name,
                "name": item.name,
                "first": item.first,
                "second": item.second,
                "third": item.third
            };
        });

        return (
            <div>
                <BootstrapTable data={items}
                                striped
                                hover
                                pagination={ false }
                                tableStyle={ {border: '#000 2.5px solid', width: '98%', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px'} }
                >
                    <TableHeaderColumn isKey
                                       dataField='id'
                                       hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='90'
                                       dataSort={ false }
                                       dataField='name'
                                       columnClassName={ this.columnClassNameFormat }
                    >Písmeno</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='350'
                                       dataSort={ false }
                                       dataField='first'
                                       columnClassName={ this.columnClassNameFormat }
                    >První</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='350'
                                       dataField='second'
                    >Druhé</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       dataField='third'
                    >Třetí</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }


}

export default MeaningTable;