import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import {ids, strings, results, channels, imagesKey} from "../Configuration/Config";
import {reactLocalStorage} from 'reactjs-localstorage';
import Step1 from '../Steps/Step1'
import Step2 from '../Steps/Step2'
import Step3 from '../Steps/Step3'
import Step4 from '../Steps/Step4'
import Step5 from '../Steps/Step5'
import Step6 from '../Steps/Step6'
import Step7 from '../Steps/Step7'

import PrintView from '../Print/PrintView'

import './Master.css';

//const [validated, setValidated] = useState(false);
class MasterForm extends Component {
    constructor(props) {
        super(props);
        // Set the initial input values
        this.state = {
            currentStep: 1, // Default is Step 1
            maxSteps: 7,
            validated: false,
            submitFromOutside: false,
            wizard: false
        };

        this.preview = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.isNextStepValid = this.isNextStepValid.bind(this);
        this.updateData = this.updateData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getKeyId = this.getKeyId.bind(this);
        this.createUUID = this.createUUID.bind(this);
        this.download = this.download.bind(this);
        this.clear = this.clear.bind(this);

        // Bind new functions for next and previous
        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);

        this.loadData();
    }

    download() {
        this.preview.current.download();
    }

    clear() {
        reactLocalStorage.clear();
        window.location.reload();
        // let cleanState = {};
        // Object.keys(this.state).forEach(x => cleanState[x] = null);
        // this.setState(cleanState);
        //
        // this.setState({//default values
        //     currentStep: 1, // Default is Step 1
        //     maxSteps: 7,
        //     validated: false,
        //     submitFromOutside: false,
        //     wizard: false
        // });
    }

    getKeyId(id) {
        let data = this.state;
        let key = data[ids.key];
        //key = "asdsadsdsda";//for debug purpose
        if(!key || key==="") {
            key = this.createUUID();
            data[ids.key] = key;
        }
        return key + "_" + id;
    }

    createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 10; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        //s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        //s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        //s[8] = s[13] = s[18] = s[23] = "-";

        return s.join("");
    }

    loadData() {
        let data = this.state;

        //general
        let keyId = reactLocalStorage.get(ids.key);
        if(keyId) data[ids.key] = keyId;
        //////////////////////////////////////////////////////
        let cureId = reactLocalStorage.get(this.getKeyId(ids.cure));
        let imageKey = reactLocalStorage.get(this.getKeyId(ids.imageKey));
        let channelKey = reactLocalStorage.get(this.getKeyId(ids.channelKey));
        let itemsToCheck = reactLocalStorage.get(this.getKeyId(ids.itemstoCheck));

        if(cureId) data[ids.cure] = cureId;
        if(imageKey) data[ids.imageKey] = imageKey;
        if(channelKey) data[ids.channelKey] = channelKey;
        if(itemsToCheck) data[ids.itemstoCheck] = itemsToCheck;

        //step1
        let name = reactLocalStorage.get(this.getKeyId(ids.name));
        let age = reactLocalStorage.get(this.getKeyId(ids.age));
        let date = reactLocalStorage.get(this.getKeyId(ids.date));
        let healthProblem = reactLocalStorage.get(this.getKeyId(ids.healthProblem));

        if(name) data[ids.name] = name;
        if(age) data[ids.age] = age;
        if(date) data[ids.date] = date;
        if(healthProblem) data[ids.healthProblem] = healthProblem;

        //fei
        let feiLeft = reactLocalStorage.get(this.getKeyId(channels.fei.measureLeft));
        let feiRight = reactLocalStorage.get(this.getKeyId(channels.fei.measureRight));
        let feiRatio = reactLocalStorage.get(this.getKeyId(channels.fei.measureRatio));

        if(feiLeft) data[channels.fei.measureLeft] = feiLeft;
        if(feiRight) data[channels.fei.measureRight] = feiRight;
        if(feiRatio) data[channels.fei.measureRatio] = feiRatio;

        //dachang
        let dachangLeft = reactLocalStorage.get(this.getKeyId(channels.dachang.measureLeft));
        let dachangRight = reactLocalStorage.get(this.getKeyId(channels.dachang.measureRight));
        let dachangRatio = reactLocalStorage.get(this.getKeyId(channels.dachang.measureRatio));

        if(dachangLeft) data[channels.dachang.measureLeft] = dachangLeft;
        if(dachangRight) data[channels.dachang.measureRight] = dachangRight;
        if(dachangRatio) data[channels.dachang.measureRatio] = dachangRatio;

        //xinbao
        let xinbaoLeft = reactLocalStorage.get(this.getKeyId(channels.xinbao.measureLeft));
        let xinbaoRight = reactLocalStorage.get(this.getKeyId(channels.xinbao.measureRight));
        let xinbaoRatio = reactLocalStorage.get(this.getKeyId(channels.xinbao.measureRatio));

        if(xinbaoLeft) data[channels.xinbao.measureLeft] = xinbaoLeft;
        if(xinbaoRight) data[channels.xinbao.measureRight] = xinbaoRight;
        if(xinbaoRatio) data[channels.xinbao.measureRatio] = xinbaoRatio;

        //sanjiao
        let sanjiaoLeft = reactLocalStorage.get(this.getKeyId(channels.sanjiao.measureLeft));
        let sanjiaoRight = reactLocalStorage.get(this.getKeyId(channels.sanjiao.measureRight));
        let sanjiaoRatio = reactLocalStorage.get(this.getKeyId(channels.sanjiao.measureRatio));

        if(sanjiaoLeft) data[channels.sanjiao.measureLeft] = sanjiaoLeft;
        if(sanjiaoRight) data[channels.sanjiao.measureRight] = sanjiaoRight;
        if(sanjiaoRatio) data[channels.sanjiao.measureRatio] = sanjiaoRatio;

        //xin
        let xinLeft = reactLocalStorage.get(this.getKeyId(channels.xin.measureLeft));
        let xinRight = reactLocalStorage.get(this.getKeyId(channels.xin.measureRight));
        let xinRatio = reactLocalStorage.get(this.getKeyId(channels.xin.measureRatio));

        if(xinLeft) data[channels.xin.measureLeft] = xinLeft;
        if(xinRight) data[channels.xin.measureRight] = xinRight;
        if(xinRatio) data[channels.xin.measureRatio] = xinRatio;

        //xiaochang
        let xiaochangLeft = reactLocalStorage.get(this.getKeyId(channels.xiaochang.measureLeft));
        let xiaochangRight = reactLocalStorage.get(this.getKeyId(channels.xiaochang.measureRight));
        let xiaochangRatio = reactLocalStorage.get(this.getKeyId(channels.xiaochang.measureRatio));

        if(xiaochangLeft) data[channels.xiaochang.measureLeft] = xiaochangLeft;
        if(xiaochangRight) data[channels.xiaochang.measureRight] = xiaochangRight;
        if(xiaochangRatio) data[channels.xiaochang.measureRatio] = xiaochangRatio;

        //pi
        let piLeft = reactLocalStorage.get(this.getKeyId(channels.pi.measureLeft));
        let piRight = reactLocalStorage.get(this.getKeyId(channels.pi.measureRight));
        let piRatio = reactLocalStorage.get(this.getKeyId(channels.pi.measureRatio));

        if(piLeft) data[channels.pi.measureLeft] = piLeft;
        if(piRight) data[channels.pi.measureRight] = piRight;
        if(piRatio) data[channels.pi.measureRatio] = piRatio;

        //gan
        let ganLeft = reactLocalStorage.get(this.getKeyId(channels.gan.measureLeft));
        let ganRight = reactLocalStorage.get(this.getKeyId(channels.gan.measureRight));
        let ganRatio = reactLocalStorage.get(this.getKeyId(channels.gan.measureRatio));

        if(ganLeft) data[channels.gan.measureLeft] = ganLeft;
        if(ganRight) data[channels.gan.measureRight] = ganRight;
        if(ganRatio) data[channels.gan.measureRatio] = ganRatio;

        //wei
        let weiLeft = reactLocalStorage.get(this.getKeyId(channels.wei.measureLeft));
        let weiRight = reactLocalStorage.get(this.getKeyId(channels.wei.measureRight));
        let weiRatio = reactLocalStorage.get(this.getKeyId(channels.wei.measureRatio));

        if(weiLeft) data[channels.wei.measureLeft] = weiLeft;
        if(weiRight) data[channels.wei.measureRight] = weiRight;
        if(weiRatio) data[channels.wei.measureRatio] = weiRatio;

        //dan
        let danLeft = reactLocalStorage.get(this.getKeyId(channels.dan.measureLeft));
        let danRight = reactLocalStorage.get(this.getKeyId(channels.dan.measureRight));
        let danRatio = reactLocalStorage.get(this.getKeyId(channels.dan.measureRatio));

        if(danLeft) data[channels.dan.measureLeft] = danLeft;
        if(danRight) data[channels.dan.measureRight] = danRight;
        if(danRatio) data[channels.dan.measureRatio] = danRatio;

        //pangguang
        let pangguangLeft = reactLocalStorage.get(this.getKeyId(channels.pangguang.measureLeft));
        let pangguangRight = reactLocalStorage.get(this.getKeyId(channels.pangguang.measureRight));
        let pangguangRatio = reactLocalStorage.get(this.getKeyId(channels.pangguang.measureRatio));

        if(pangguangLeft) data[channels.pangguang.measureLeft] = pangguangLeft;
        if(pangguangRight) data[channels.pangguang.measureRight] = pangguangRight;
        if(pangguangRatio) data[channels.pangguang.measureRatio] = pangguangRatio;

        //shen
        let shenLeft = reactLocalStorage.get(this.getKeyId(channels.shen.measureLeft));
        let shenRight = reactLocalStorage.get(this.getKeyId(channels.shen.measureRight));
        let shenRatio = reactLocalStorage.get(this.getKeyId(channels.shen.measureRatio));

        if(shenLeft) data[channels.shen.measureLeft] = shenLeft;
        if(shenRight) data[channels.shen.measureRight] = shenRight;
        if(shenRatio) data[channels.shen.measureRatio] = shenRatio;
    }

    saveData() {
        const data = this.state;

        //step1
        if(data[ids.name]!==undefined) reactLocalStorage.set(this.getKeyId(ids.name), data[ids.name]);
        if(data[ids.age]!==undefined) reactLocalStorage.set(this.getKeyId(ids.age), data[ids.age]);
        if(data[ids.date]!==undefined) reactLocalStorage.set(this.getKeyId(ids.date), data[ids.date]);
        if(data[ids.healthProblem]!==undefined) reactLocalStorage.set(this.getKeyId(ids.healthProblem), data[ids.healthProblem]);

        //fei
        if(data[channels.fei.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.fei.measureLeft), data[channels.fei.measureLeft]);
        if(data[channels.fei.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.fei.measureRight), data[channels.fei.measureRight]);
        if(data[channels.fei.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.fei.measureRatio), data[channels.fei.measureRatio]);

        //dachang
        if(data[channels.dachang.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dachang.measureLeft), data[channels.dachang.measureLeft]);
        if(data[channels.dachang.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dachang.measureRight), data[channels.dachang.measureRight]);
        if(data[channels.dachang.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dachang.measureRatio), data[channels.dachang.measureRatio]);

        //xinbao
        if(data[channels.xinbao.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xinbao.measureLeft), data[channels.xinbao.measureLeft]);
        if(data[channels.xinbao.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xinbao.measureRight), data[channels.xinbao.measureRight]);
        if(data[channels.xinbao.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xinbao.measureRatio), data[channels.xinbao.measureRight]);

        //sanjiao
        if(data[channels.sanjiao.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.sanjiao.measureLeft), data[channels.sanjiao.measureLeft]);
        if(data[channels.sanjiao.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.sanjiao.measureRight), data[channels.sanjiao.measureRight]);
        if(data[channels.sanjiao.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.sanjiao.measureRatio), data[channels.sanjiao.measureRatio]);

        //xin
        if(data[channels.xin.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xin.measureLeft), data[channels.xin.measureLeft]);
        if(data[channels.xin.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xin.measureRight), data[channels.xin.measureRight]);
        if(data[channels.xin.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xin.measureRatio), data[channels.xin.measureRatio]);

        //xiaochang
        if(data[channels.xiaochang.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xiaochang.measureLeft), data[channels.xiaochang.measureLeft]);
        if(data[channels.xiaochang.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xiaochang.measureRight), data[channels.xiaochang.measureRight]);
        if(data[channels.xiaochang.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.xiaochang.measureRatio), data[channels.xiaochang.measureRight]);

        //pi
        if(data[channels.pi.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pi.measureLeft), data[channels.pi.measureLeft]);
        if(data[channels.pi.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pi.measureRight), data[channels.pi.measureRight]);
        if(data[channels.pi.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pi.measureRatio), data[channels.pi.measureRatio]);

        //gan
        if(data[channels.gan.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.gan.measureLeft), data[channels.gan.measureLeft]);
        if(data[channels.gan.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.gan.measureRight), data[channels.gan.measureRight]);
        if(data[channels.gan.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.gan.measureRatio), data[channels.gan.measureRatio]);

        //wei
        if(data[channels.wei.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.wei.measureLeft), data[channels.wei.measureLeft]);
        if(data[channels.wei.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.wei.measureRight), data[channels.wei.measureRight]);
        if(data[channels.wei.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.wei.measureRatio), data[channels.wei.measureRatio]);

        //dan
        if(data[channels.dan.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dan.measureLeft), data[channels.dan.measureLeft]);
        if(data[channels.dan.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dan.measureRight), data[channels.dan.measureRight]);
        if(data[channels.dan.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.dan.measureRatio), data[channels.dan.measureRatio]);

        //pangguang
        if(data[channels.pangguang.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pangguang.measureLeft), data[channels.pangguang.measureLeft]);
        if(data[channels.pangguang.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pangguang.measureRight), data[channels.pangguang.measureRight]);
        if(data[channels.pangguang.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.pangguang.measureRatio), data[channels.pangguang.measureRight]);

        //shen
        if(data[channels.shen.measureLeft]!==undefined) reactLocalStorage.set(this.getKeyId(channels.shen.measureLeft), data[channels.shen.measureLeft]);
        if(data[channels.shen.measureRight]!==undefined) reactLocalStorage.set(this.getKeyId(channels.shen.measureRight), data[channels.shen.measureRight]);
        if(data[channels.shen.measureRatio]!==undefined) reactLocalStorage.set(this.getKeyId(channels.shen.measureRatio), data[channels.shen.measureRatio]);

        //general
        if(data[ids.cure]!==undefined) reactLocalStorage.set(this.getKeyId(ids.cure), data[ids.cure]);
        if(data[ids.key]!==undefined) reactLocalStorage.set(ids.key, data[ids.key]);
        if(data[ids.imageKey]!==undefined) reactLocalStorage.set(this.getKeyId(ids.imageKey), data[ids.imageKey]);
        if(data[ids.channelKey]!==undefined) reactLocalStorage.set(this.getKeyId(ids.channelKey), data[ids.channelKey]);
        if(data[ids.itemstoCheck]!==undefined) reactLocalStorage.set(this.getKeyId(ids.itemstoCheck), data[ids.itemstoCheck]);
    }

    // _next and _previous functions will be called on button click
    _next() {
        let currentStep = this.state.currentStep;
        let maxSteps = this.state.maxSteps;
        // If the current step is 1 or 2, then add one on "next" button click
        currentStep = currentStep >= maxSteps ? maxSteps : currentStep + 1;
        this.setState({
            currentStep: currentStep
        })
    }

    _prev() {
        let currentStep = this.state.currentStep;
        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        })
    }

    // The "next" and "previous" button functions
    get previousButton() {
        let currentStep = this.state.currentStep;
        if(!this.state.wizard) return null;
        return (
            <button
                className="btn btn-primary"
                type="button"
                onClick={this._prev}
                disabled={currentStep === 1}
            >
                {strings.previous}

            </button>
        )
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        if(!this.state.wizard) return null;
        return (
            <button
                className="btn btn-primary float-right"
                type="button"
                onClick={this._next}
                disabled={this.isNextStepValid(currentStep)}
            >
                {strings.next}
            </button>
        )
    }

    isNextStepValid(currentStep) {
        let valid = currentStep === this.state.maxSteps;
        if(valid) return true;

        let data = this.state;
        if(currentStep===4) {
            this.step5.current.calcValues(data);
            return !(data[results.resultsRatio.id] && data[results.resultsRatio.id] > 0);
        }
        else if(currentStep===5) {//results
            //check if we need to display the solution for cure
            return data[ids.cure]==="";
        }

        return false;
    }

    updateData() {
        const data = this.state;
        let id = "";
        let channelId = "";
        let imageId="";
        let compareValue = 2.5;
        let itemsToCheck = "";

        //fei
        if(data[channels.fei.measureRatio]!==undefined && Number(data[channels.fei.measureRatio]) > compareValue) {
            if(Number(data[channels.fei.measureLeft]) > Number(data[channels.fei.measureRight])) {
                compareValue = Number(data[channels.fei.measureRatio]);
                id = channels.fei.measureLeft;
                channelId="fei";
                imageId = imagesKey.feiLeft;
            } else {
                compareValue = Number(data[channels.fei.measureRatio]);
                id = channels.fei.measureRight;
                channelId="fei";
                imageId = imagesKey.feiRight;
            }
        }

        //dachang
        if(data[channels.dachang.measureRatio]!==undefined && Number(data[channels.dachang.measureRatio]) > compareValue) {
            if(Number(data[channels.dachang.measureLeft]) > Number(data[channels.dachang.measureRight])) {
                compareValue = Number(data[channels.dachang.measureRatio]);
                id = channels.dachang.measureLeft;
                channelId="dachang";
                imageId = imagesKey.dachanLeft;
            } else {
                compareValue = Number(data[channels.dachang.measureRatio]);
                id = channels.dachang.measureRight;
                channelId="dachang";
                imageId = imagesKey.dachanRight
            }
        }

        //xinbao
        if(data[channels.xinbao.measureRatio]!==undefined && Number(data[channels.xinbao.measureRatio]) > compareValue) {
            if(Number(data[channels.xinbao.measureLeft]) > Number(data[channels.xinbao.measureRight])) {
                compareValue = Number(data[channels.xinbao.measureRatio]);
                id = channels.xinbao.measureLeft;
                channelId="xinbao";
                imageId = imagesKey.xinbaoLeft;
            } else {
                compareValue = Number(data[channels.xinbao.measureRatio]);
                id = channels.xinbao.measureRight;
                channelId="xinbao";
                imageId = imagesKey.xinbaoRight;
            }
        }

        //sanjiao
        if(data[channels.sanjiao.measureRatio]!==undefined && Number(data[channels.sanjiao.measureRatio]) > compareValue) {
            if(Number(data[channels.sanjiao.measureLeft]) > Number(data[channels.sanjiao.measureRight])) {
                compareValue = Number(data[channels.sanjiao.measureRatio]);
                id = channels.sanjiao.measureLeft;
                channelId="sanjiao";
                imageId = imagesKey.sanjiaoLeft;
            } else {
                compareValue = Number(data[channels.sanjiao.measureRatio]);
                id = channels.sanjiao.measureRight;
                channelId="sanjiao";
                imageId = imagesKey.sanjiaoRight;
            }
        }

        //xin
        if(data[channels.xin.measureRatio]!==undefined && Number(data[channels.xin.measureRatio]) > compareValue) {
            if(Number(data[channels.xin.measureLeft]) > Number(data[channels.xin.measureRight])) {
                compareValue = Number(data[channels.xin.measureRatio]);
                id = channels.xin.measureLeft;
                channelId="xin";
                imageId = imagesKey.xinLeft;
            } else {
                compareValue = Number(data[channels.xin.measureRatio]);
                id = channels.xin.measureRight;
                channelId="xin";
                imageId = imagesKey.xinRight;
            }
        }

        //xiaochang
        if(data[channels.xiaochang.measureRatio]!==undefined && Number(data[channels.xiaochang.measureRatio]) > compareValue) {
            if(Number(data[channels.xiaochang.measureLeft]) > Number(data[channels.xiaochang.measureRight])) {
                compareValue = Number(data[channels.xiaochang.measureRatio]);
                id = channels.xiaochang.measureLeft;
                channelId="xiaochang";
                imageId = imagesKey.xiaochangLeft;
            } else {
                compareValue = Number(data[channels.xiaochang.measureRatio]);
                id = channels.xiaochang.measureRight;
                channelId="xiaochang";
                imageId = imagesKey.xiaochangRight;
            }
        }

        //pi
        if(data[channels.pi.measureRatio]!==undefined && Number(data[channels.pi.measureRatio]) > compareValue) {
            if(Number(data[channels.pi.measureLeft]) > Number(data[channels.pi.measureRight])) {
                compareValue = Number(data[channels.pi.measureRatio]);
                id = channels.pi.measureLeft;
                channelId="pi";
                imageId = imagesKey.piLeft
            } else {
                compareValue = Number(data[channels.pi.measureRatio]);
                id = channels.pi.measureRight;
                channelId="pi";
                imageId = imagesKey.piRight;
            }
        }

        //gan
        if(data[channels.gan.measureRatio]!==undefined && Number(data[channels.gan.measureRatio]) > compareValue) {
            if(Number(data[channels.gan.measureLeft]) > Number(data[channels.gan.measureRight])) {
                compareValue = Number(data[channels.gan.measureRatio]);
                id = channels.gan.measureLeft;
                channelId="gan";
                imageId = imagesKey.ganLeft;
            } else {
                compareValue = Number(data[channels.gan.measureRatio]);
                id = channels.gan.measureRight;
                channelId="gan";
                imageId = imagesKey.ganRight;
            }
        }

        //wei
        if(data[channels.wei.measureRatio]!==undefined && Number(data[channels.wei.measureRatio]) > compareValue) {
            if(Number(data[channels.wei.measureLeft]) > Number(data[channels.wei.measureRight])) {
                compareValue = Number(data[channels.wei.measureRatio]);
                id = channels.wei.measureLeft;
                channelId="wei";
                imageId = imagesKey.weiLeft;
            } else {
                compareValue = Number(data[channels.wei.measureRatio]);
                id = channels.wei.measureRight;
                channelId="wei";
                imageId = imagesKey.weiRight;
            }
        }

        //dan
        if(data[channels.dan.measureRatio]!==undefined && Number(data[channels.dan.measureRatio]) > compareValue) {
            if(Number(data[channels.dan.measureLeft]) > Number(data[channels.dan.measureRight])) {
                compareValue = Number(data[channels.dan.measureRatio]);
                id = channels.dan.measureLeft;
                channelId="dan";
                imageId = imagesKey.danLeft;
            } else {
                compareValue = Number(data[channels.dan.measureRatio]);
                id = channels.dan.measureRight;
                channelId="dan";
                imageId = imagesKey.danRight;
            }
        }

        //pangguang
        if(data[channels.pangguang.measureRatio]!==undefined && Number(data[channels.pangguang.measureRatio]) > compareValue) {
            if(Number(data[channels.pangguang.measureLeft]) > Number(data[channels.pangguang.measureRight])) {
                compareValue = Number(data[channels.pangguang.measureRatio]);
                id = channels.pangguang.measureLeft;
                channelId="pangguang";
                imageId = imagesKey.pagguangLeft;
            } else {
                compareValue = Number(data[channels.pangguang.measureRatio]);
                id = channels.pangguang.measureRight;
                channelId="pangguang";
                imageId = imagesKey.pagguangRight;
            }
        }

        //shen
        if(data[channels.shen.measureRatio]!==undefined && Number(data[channels.shen.measureRatio]) > compareValue) {
            if(Number(data[channels.shen.measureLeft]) > Number(data[channels.shen.measureRight])) {
                compareValue = Number(data[channels.shen.measureRatio]);
                id = channels.shen.measureLeft;
                channelId="shen";
                imageId = imagesKey.shenLeft;
            } else {
                compareValue = Number(data[channels.shen.measureRatio]);
                id = channels.shen.measureRight;
                channelId="shen";
                imageId = imagesKey.shenRight;
            }
        }

        compareValue = 2.5;
        //fei
        if(data[channels.fei.measureRatio]!==undefined && Number(data[channels.fei.measureRatio]) > compareValue) {
            if(Number(data[channels.fei.measureLeft]) > Number(data[channels.fei.measureRight])) {
                itemsToCheck+="fei,";
            } else {
                itemsToCheck+="fei,";
            }
        }

        //dachang
        if(data[channels.dachang.measureRatio]!==undefined && Number(data[channels.dachang.measureRatio]) > compareValue) {
            if(Number(data[channels.dachang.measureLeft]) > Number(data[channels.dachang.measureRight])) {
                itemsToCheck+="dachang,";
            } else {
                itemsToCheck+="dachang,";
            }
        }

        //xinbao
        if(data[channels.xinbao.measureRatio]!==undefined && Number(data[channels.xinbao.measureRatio]) > compareValue) {
            if(Number(data[channels.xinbao.measureLeft]) > Number(data[channels.xinbao.measureRight])) {
                itemsToCheck+="xinbao,";
            } else {
                itemsToCheck+="xinbao,";
            }
        }

        //sanjiao
        if(data[channels.sanjiao.measureRatio]!==undefined && Number(data[channels.sanjiao.measureRatio]) > compareValue) {
            if(Number(data[channels.sanjiao.measureLeft]) > Number(data[channels.sanjiao.measureRight])) {
                itemsToCheck+="sanjiao,";
            } else {
                itemsToCheck+="sanjiao,";
            }
        }

        //xin
        if(data[channels.xin.measureRatio]!==undefined && Number(data[channels.xin.measureRatio]) > compareValue) {
            if(Number(data[channels.xin.measureLeft]) > Number(data[channels.xin.measureRight])) {
                itemsToCheck+="xin,";
            } else {
                itemsToCheck+="xin,";
            }
        }

        //xiaochang
        if(data[channels.xiaochang.measureRatio]!==undefined && Number(data[channels.xiaochang.measureRatio]) > compareValue) {
            if(Number(data[channels.xiaochang.measureLeft]) > Number(data[channels.xiaochang.measureRight])) {
                itemsToCheck+="xiaochang,";
            } else {
                itemsToCheck+="xiaochang,";
            }
        }

        //pi
        if(data[channels.pi.measureRatio]!==undefined && Number(data[channels.pi.measureRatio]) > compareValue) {
            if(Number(data[channels.pi.measureLeft]) > Number(data[channels.pi.measureRight])) {
                itemsToCheck+="pi,";
            } else {
                itemsToCheck+="pi,";
            }
        }

        //gan
        if(data[channels.gan.measureRatio]!==undefined && Number(data[channels.gan.measureRatio]) > compareValue) {
            if(Number(data[channels.gan.measureLeft]) > Number(data[channels.gan.measureRight])) {
                itemsToCheck+="gan,";
            } else {
                itemsToCheck+="gan,";
            }
        }

        //wei
        if(data[channels.wei.measureRatio]!==undefined && Number(data[channels.wei.measureRatio]) > compareValue) {
            if(Number(data[channels.wei.measureLeft]) > Number(data[channels.wei.measureRight])) {
                itemsToCheck+="wei,";
            } else {
                itemsToCheck+="wei,";
            }
        }

        //dan
        if(data[channels.dan.measureRatio]!==undefined && Number(data[channels.dan.measureRatio]) > compareValue) {
            if(Number(data[channels.dan.measureLeft]) > Number(data[channels.dan.measureRight])) {
                itemsToCheck+="dan,";
            } else {
                itemsToCheck+="dan,";
            }
        }

        //pangguang
        if(data[channels.pangguang.measureRatio]!==undefined && Number(data[channels.pangguang.measureRatio]) > compareValue) {
            if(Number(data[channels.pangguang.measureLeft]) > Number(data[channels.pangguang.measureRight])) {
                itemsToCheck+="pangguang,";
            } else {
                itemsToCheck+="pangguang,";
            }
        }

        //shen
        if(data[channels.shen.measureRatio]!==undefined && Number(data[channels.shen.measureRatio]) > compareValue) {
            if(Number(data[channels.shen.measureLeft]) > Number(data[channels.shen.measureRight])) {
                itemsToCheck+="shen,";
            } else {
                itemsToCheck+="shen,";
            }
        }

        if(itemsToCheck.length > 0) itemsToCheck = itemsToCheck.slice(0, -1); //remove separator

        let that = this;
        this.setState({
            [ids.cure]: id,
            [ids.imageKey]: imageId,
            [ids.channelKey]: channelId,
            [ids.itemstoCheck]: itemsToCheck
        }, () => {
            that.saveData();
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // } else {
                if(!this.props.printPreview) {
                    alert("Funkce není definovaná!!!");
                    return;
                }
                this.props.printPreview();
        // }

        // this.setState({
        //     validated: true
        // });
    };

    // Render UI will go here...
    render() {
        const data = this.state;

        const showPrintPage = this.props.printPreviewMode ? this.props.printPreviewMode : false;
        if(showPrintPage) {
            return (
                <PrintView
                    ref ={this.preview}
                    data={data}/>
            );
        }

        const validated = this.state.validated;
        return (
            <div className="main">
                <Form noValidate validated={validated} onSubmit={this.handleSubmit} id="form1">
                    <Step1
                        currentStep={this.state.currentStep}
                        updateData={this.updateData}
                        data={data}
                    />
                    <Step2
                        currentStep={this.state.currentStep}
                        updateData={this.updateData}
                        data={data}
                    />
                    <Step3
                        currentStep={this.state.currentStep}
                        updateData={this.updateData}
                        data={data}
                    />
                    <Step4
                        currentStep={this.state.currentStep}
                        updateData={this.updateData}
                        data={data}
                    />
                    <Step5
                        currentStep={this.state.currentStep}
                        data={data}
                    />

                    <Step6
                        currentStep={this.state.currentStep}
                        data={data}
                    />
                    <Step7
                        currentStep={this.state.currentStep}
                        updateData={this.updateData}
                        data={data}
                    />

                    {this.previousButton}
                    {this.nextButton}

                </Form>
            </div>
        )
    }
}


export default MasterForm
