import React, {Component} from 'react';
import {data, ids, strings, imagesKey} from "../Configuration/Config";
import {Form} from 'react-bootstrap'
import {getItem} from "../Master/UtilityCalc";

class Step5 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.getFactorsImage = this.getFactorsImage.bind(this);
        this.calcFactors = this.calcFactors.bind(this);
        this.roundThisNumber = this.roundThisNumber.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChecked(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.checked===true ? "1" : "0";

        this.updateData();
    }

    getFactorsImage() {
        const _data = this.props.data;

        const results = _data[ids.results];
        if(!results || !results[ids.day] || !results[ids.month]) return null;

        let itemHS = getItem(data.hs, results[ids.day][ids.hs]);
        const dayItem = itemHS[ids.element];

        if(dayItem === ids.wood) {
            return imagesKey.dmWood;
        } else if(dayItem===ids.fire) {
            return imagesKey.dmFire;
        } else if(dayItem===ids.earth) {
            return imagesKey.dmEarth;
        } else if(dayItem===ids.metal) {
            return imagesKey.dmMetal;
        } else { //dayItem===ids.water
            return imagesKey.dmWater;
        }
    }

    calcFactors() {
        const _data = this.props.data;

        const results = _data[ids.results];
        if(!results || !results[ids.day] || !results[ids.month] ||
            !results[ids.year] || !results[ids.hour]) return null;

        //hs
        let itemYearHS = getItem(data.hs, results[ids.year][ids.hs]);
        let itemMonthHS = getItem(data.hs, results[ids.month][ids.hs]);
        let itemDayHS = getItem(data.hs, results[ids.day][ids.hs]);
        let itemHourHS = getItem(data.hs, results[ids.hour][ids.hs]);

        const yearItemHS = itemYearHS[ids.element];
        const monthItemHS = itemMonthHS[ids.element];
        const dayItemHS = itemDayHS[ids.element];
        const hourItemHS = itemHourHS[ids.element];

        //do not calculate for empty hour of birth
        if(hourItemHS===strings.empty) return null;

        let woodNum = 0, fireNum = 0, earthNum = 0, metalNum = 0, waterNum = 0;
        let woodJinNum = 0, fireJinNum = 0, earthJinNum = 0, metalJinNum = 0, waterJinNum = 0;
        let woodYangNum = 0, fireYangNum = 0, earthYangNum = 0, metalYangNum = 0, waterYangNum = 0;
        ////YEAR////
        if(yearItemHS===ids.wood) {
            if(itemYearHS[ids.isYang])
                woodYangNum=woodYangNum+100
            else
                woodJinNum=woodJinNum+100;
        }
        else if(yearItemHS===ids.fire) {
            if(itemYearHS[ids.isYang])
                fireYangNum=fireYangNum+100;
            else
                fireJinNum=fireJinNum+100;
        }
        else if(yearItemHS===ids.earth) {
            if(itemYearHS[ids.isYang])
                earthYangNum=earthYangNum+100;
            else
                earthJinNum=earthJinNum+100;
        }
        else if(yearItemHS===ids.metal) {
            if(itemYearHS[ids.isYang])
                metalYangNum = metalYangNum + 100;
            else
                metalJinNum = metalJinNum + 100;
        }
        else if(yearItemHS===ids.water) {
            if(itemYearHS[ids.isYang])
                waterYangNum=waterYangNum+100;
            else
                waterJinNum=waterJinNum+100;
        }
        else {
            console.log("wrong year item")
        }
        //////////////MONTH/////////////////////////////////
        if(monthItemHS===ids.wood) {
            if(itemMonthHS[ids.isYang])
                woodYangNum=woodYangNum+100
            else
                woodJinNum=woodJinNum+100;
        }
        else if(monthItemHS===ids.fire) {
            if(itemMonthHS[ids.isYang])
                fireYangNum=fireYangNum+100;
            else
                fireJinNum=fireJinNum+100;
        }
        else if(monthItemHS===ids.earth) {
            if(itemMonthHS[ids.isYang])
                earthYangNum=earthYangNum+100;
            else
                earthJinNum=earthJinNum+100;
        }
        else if(monthItemHS===ids.metal) {
            if(itemMonthHS[ids.isYang])
                metalYangNum = metalYangNum + 100;
            else
                metalJinNum = metalJinNum + 100;
        }
        else if(monthItemHS===ids.water) {
            if(itemMonthHS[ids.isYang])
                waterYangNum=waterYangNum+100;
            else
                waterJinNum=waterJinNum+100;
        }
        else {
            console.log("wrong month item")
        }
        //////////////DAY/////////////////////////////////
        if(dayItemHS===ids.wood) {
            if(itemDayHS[ids.isYang])
                woodYangNum=woodYangNum+100
            else
                woodJinNum=woodJinNum+100;
        }
        else if(dayItemHS===ids.fire) {
            if(itemDayHS[ids.isYang])
                fireYangNum=fireYangNum+100;
            else
                fireJinNum=fireJinNum+100;
        }
        else if(dayItemHS===ids.earth) {
            if(itemDayHS[ids.isYang])
                earthYangNum=earthYangNum+100;
            else
                earthJinNum=earthJinNum+100;
        }
        else if(dayItemHS===ids.metal) {
            if(itemDayHS[ids.isYang])
                metalYangNum = metalYangNum + 100;
            else
                metalJinNum = metalJinNum + 100;
        }
        else if(dayItemHS===ids.water) {
            if(itemDayHS[ids.isYang])
                waterYangNum=waterYangNum+100;
            else
                waterJinNum=waterJinNum+100;
        }
        else {
            console.log("wrong day item")
        }
        //////////////HOUR/////////////////////////////////
        if(hourItemHS===ids.wood) {
            if(itemHourHS[ids.isYang])
                woodYangNum=woodYangNum+100
            else
                woodJinNum=woodJinNum+100;
        }
        else if(hourItemHS===ids.fire) {
            if(itemHourHS[ids.isYang])
                fireYangNum=fireYangNum+100;
            else
                fireJinNum=fireJinNum+100;
        }
        else if(hourItemHS===ids.earth) {
            if(itemHourHS[ids.isYang])
                earthYangNum=earthYangNum+100;
            else
                earthJinNum=earthJinNum+100;
        }
        else if(hourItemHS===ids.metal) {
            if(itemHourHS[ids.isYang])
                metalYangNum = metalYangNum + 100;
            else
                metalJinNum = metalJinNum + 100;
        }
        else if(hourItemHS===ids.water) {
            if(itemHourHS[ids.isYang])
                waterYangNum=waterYangNum+100;
            else
                waterJinNum=waterJinNum+100;
        }
        else {
            console.log("wrong hour item")
        }
        //eb -> hhs
        let itemYearEB = getItem(data.eb, results[ids.year][ids.eb]);
        let itemYearHHS1 = getItem(data.hs, itemYearEB[ids.hhs1]);
        let itemYearHHS2 = getItem(data.hs, itemYearEB[ids.hhs2]);
        let itemYearHHS3 = getItem(data.hs, itemYearEB[ids.hhs3]);

        const yearItemHHS1 = itemYearHHS1[ids.element];
        const yearItemHHS2 = itemYearHHS2[ids.element];
        const yearItemHHS3 = itemYearHHS3[ids.element];
        const yearItemHHSValue = itemYearEB[ids.hhs].split(ids.separator);

        ///////////////////HHS1////////////////////
        if(yearItemHHS1===ids.wood) {
            if(itemYearHHS1[ids.isYang])
                woodYangNum=woodYangNum+Number(yearItemHHSValue[0]);
            else
                woodJinNum=woodJinNum+Number(yearItemHHSValue[0]);
        }
        else if(yearItemHHS1===ids.fire) {
            if(itemYearHHS1[ids.isYang])
                fireYangNum=fireYangNum+Number(yearItemHHSValue[0]);
            else
                fireJinNum=fireJinNum+Number(yearItemHHSValue[0]);
        }
        else if(yearItemHHS1===ids.earth) {
            if(itemYearHHS1[ids.isYang])
                earthYangNum=earthYangNum+Number(yearItemHHSValue[0]);
            else
                earthJinNum=earthJinNum+Number(yearItemHHSValue[0]);
        }
        else if(yearItemHHS1===ids.metal) {
            if(itemYearHHS1[ids.isYang])
                metalYangNum=metalYangNum+Number(yearItemHHSValue[0]);
            else
                metalJinNum=metalJinNum+Number(yearItemHHSValue[0]);
        }
        else if(yearItemHHS1===ids.water) {
            if(itemYearHHS1[ids.isYang])
                waterYangNum=waterYangNum+Number(yearItemHHSValue[0]);
            else
                waterJinNum=waterJinNum+Number(yearItemHHSValue[0]);
        }
        else {
            console.log("missing year1 item")
        }
        ///////////////////HHS2////////////////////
        if(yearItemHHS2===ids.wood) {
            if(itemYearHHS2[ids.isYang])
                woodYangNum=woodYangNum+Number(yearItemHHSValue[1]);
            else
                woodJinNum=woodJinNum+Number(yearItemHHSValue[1]);
        }
        else if(yearItemHHS2===ids.fire) {
            if(itemYearHHS2[ids.isYang])
                fireYangNum=fireYangNum+Number(yearItemHHSValue[1]);
            else
                fireJinNum=fireJinNum+Number(yearItemHHSValue[1]);
        }
        else if(yearItemHHS2===ids.earth) {
            if(itemYearHHS2[ids.isYang])
                earthYangNum=earthYangNum+Number(yearItemHHSValue[1]);
            else
                earthJinNum=earthJinNum+Number(yearItemHHSValue[1]);
        }
        else if(yearItemHHS2===ids.metal) {
            if(itemYearHHS1[ids.isYang])
                metalYangNum=metalYangNum+Number(yearItemHHSValue[1]);
            else
                metalJinNum=metalJinNum+Number(yearItemHHSValue[1]);
        }
        else if(yearItemHHS2===ids.water) {
            if(itemYearHHS2[ids.isYang])
                waterYangNum=waterYangNum+Number(yearItemHHSValue[1]);
            else
                waterJinNum=waterJinNum+Number(yearItemHHSValue[1]);
        }
        else {
            console.log("wrong year2 item")
        }
        ///////////////////HHS3////////////////////
        if(yearItemHHS3===ids.wood) {
            if(itemYearHHS3[ids.isYang])
                woodYangNum=woodYangNum+Number(yearItemHHSValue[2]);
            else
                woodJinNum=woodJinNum+Number(yearItemHHSValue[2]);
        }
        else if(yearItemHHS3===ids.fire) {
            if(itemYearHHS3[ids.isYang])
                fireYangNum=fireYangNum+Number(yearItemHHSValue[2]);
            else
                fireJinNum=fireJinNum+Number(yearItemHHSValue[2]);
        }
        else if(yearItemHHS3===ids.earth) {
            if(itemYearHHS3[ids.isYang])
                earthYangNum=earthYangNum+Number(yearItemHHSValue[2]);
            else
                earthJinNum=earthJinNum+Number(yearItemHHSValue[2]);
        }
        else if(yearItemHHS3===ids.metal) {
            if(itemYearHHS3[ids.isYang])
                metalYangNum=metalYangNum+Number(yearItemHHSValue[2]);
            else
                metalJinNum=metalJinNum+Number(yearItemHHSValue[2]);
        }
        else if(yearItemHHS3===ids.water) {
            if(itemYearHHS3[ids.isYang])
                waterYangNum=waterYangNum+Number(yearItemHHSValue[2]);
            else
                waterJinNum=waterJinNum+Number(yearItemHHSValue[2]);
        }
        else {
            console.log("missing year3 item")
        }
        ////////////////////////////////////////////////////////////////////////
        let itemMonthEB = getItem(data.eb, results[ids.month][ids.eb]);
        let itemMonthHHS1 = getItem(data.hs, itemMonthEB[ids.hhs1]);
        let itemMonthHHS2 = getItem(data.hs, itemMonthEB[ids.hhs2]);
        let itemMonthHHS3 = getItem(data.hs, itemMonthEB[ids.hhs3]);

        const monthItemHHS1 = itemMonthHHS1[ids.element];
        const monthItemHHS2 = itemMonthHHS2[ids.element];
        const monthItemHHS3 = itemMonthHHS3[ids.element];
        const monthItemHHSValue = itemMonthEB[ids.hhs].split(ids.separator);

        ///////////////////HHS1////////////////////
        if(monthItemHHS1===ids.wood) {
            if(monthItemHHS1[ids.isYang])
                woodYangNum=woodYangNum+Number(monthItemHHSValue[0]);
            else
                woodJinNum=woodJinNum+Number(monthItemHHSValue[0]);
        }
        else if(monthItemHHS1===ids.fire) {
            if(itemMonthHHS1[ids.isYang])
                fireYangNum=fireYangNum+Number(monthItemHHSValue[0]);
            else
                fireJinNum=fireJinNum+Number(monthItemHHSValue[0]);
        }
        else if(monthItemHHS1===ids.earth) {
            if(itemMonthHHS1[ids.isYang])
                earthYangNum=earthYangNum+Number(monthItemHHSValue[0]);
            else
                earthJinNum=earthJinNum+Number(monthItemHHSValue[0]);
        }
        else if(monthItemHHS1===ids.metal) {
            if(itemMonthHHS1[ids.isYang])
                metalYangNum=metalYangNum+Number(monthItemHHSValue[0]);
            else
                metalJinNum=metalJinNum+Number(monthItemHHSValue[0]);
        }
        else if(monthItemHHS1===ids.water) {
            if(itemMonthHHS1[ids.isYang])
                waterYangNum=waterYangNum+Number(monthItemHHSValue[0]);
            else
                waterJinNum=waterJinNum+Number(monthItemHHSValue[0]);
        }
        else {
            console.log("missing month1 item")
        }
        ///////////////////HHS2////////////////////
        if(itemMonthHHS2===ids.wood) {
            if(yearItemHHS2[ids.isYang])
                woodYangNum=woodYangNum+Number(monthItemHHSValue[1]);
            else
                woodJinNum=woodJinNum+Number(monthItemHHSValue[1]);
        }
        else if(monthItemHHS2===ids.fire) {
            if(itemMonthHHS2[ids.isYang])
                fireYangNum=fireYangNum+Number(monthItemHHSValue[1]);
            else
                fireJinNum=fireJinNum+Number(monthItemHHSValue[1]);
        }
        else if(monthItemHHS2===ids.earth) {
            if(itemMonthHHS2[ids.isYang])
                earthYangNum=earthYangNum+Number(monthItemHHSValue[1]);
            else
                earthJinNum=earthJinNum+Number(monthItemHHSValue[1]);
        }
        else if(monthItemHHS2===ids.metal) {
            if(itemMonthHHS2[ids.isYang])
                metalYangNum=metalYangNum+Number(monthItemHHSValue[1]);
            else
                metalJinNum=metalJinNum+Number(monthItemHHSValue[1]);
        }
        else if(monthItemHHS2===ids.water) {
            if(itemMonthHHS2[ids.isYang])
                waterYangNum=waterYangNum+Number(monthItemHHSValue[1]);
            else
                waterJinNum=waterJinNum+Number(monthItemHHSValue[1]);
        }  else {
            console.log("wrong month2 item")
        }
        ///////////////////HHS3////////////////////
        if(monthItemHHS3===ids.wood) {
            if(itemMonthHHS3[ids.isYang])
                woodYangNum=woodYangNum+Number(monthItemHHSValue[2]);
            else
                woodJinNum=woodJinNum+Number(monthItemHHSValue[2]);
        }
        else if(monthItemHHS3===ids.fire) {
            if(itemMonthHHS3[ids.isYang])
                fireYangNum=fireYangNum+Number(monthItemHHSValue[2]);
            else
                fireJinNum=fireJinNum+Number(monthItemHHSValue[2]);
        }
        else if(monthItemHHS3===ids.earth) {
            if(itemMonthHHS3[ids.isYang])
                earthYangNum=earthYangNum+Number(monthItemHHSValue[2]);
            else
                earthJinNum=earthJinNum+Number(monthItemHHSValue[2]);
        }
        else if(monthItemHHS3===ids.metal) {
            if(itemMonthHHS3[ids.isYang])
                metalYangNum=metalYangNum+Number(monthItemHHSValue[2]);
            else
                metalJinNum=metalJinNum+Number(monthItemHHSValue[2]);
        }
        else if(monthItemHHS3===ids.water) {
            if(itemMonthHHS3[ids.isYang])
                waterYangNum=waterYangNum+Number(yearItemHHSValue[2]);
            else
                waterJinNum=waterJinNum+Number(yearItemHHSValue[2]);
        }
        else {
            console.log("missing month3 item")
        }
        //////////////////////////////////////////////////////////////////////////
        let itemDayEB = getItem(data.eb, results[ids.day][ids.eb]);
        let itemDayHHS1 = getItem(data.hs, itemDayEB[ids.hhs1]);
        let itemDayHHS2 = getItem(data.hs, itemDayEB[ids.hhs2]);
        let itemDayHHS3 = getItem(data.hs, itemDayEB[ids.hhs3]);

        const dayItemHHS1 = itemDayHHS1[ids.element];
        const dayItemHHS2 = itemDayHHS2[ids.element];
        const dayItemHHS3 = itemDayHHS3[ids.element];
        const dayItemHHSValue = itemDayEB[ids.hhs].split(ids.separator);
        ///////////////////HHS1////////////////////
        if(dayItemHHS1===ids.wood) {
            if(itemDayHHS1[ids.isYang])
                woodYangNum=woodYangNum+Number(dayItemHHSValue[0]);
            else
                woodJinNum=woodJinNum+Number(dayItemHHSValue[0]);
        }
        else if(dayItemHHS1===ids.fire) {
            if(itemDayHHS1[ids.isYang])
                fireYangNum=fireYangNum+Number(dayItemHHSValue[0]);
            else
                fireJinNum=fireJinNum+Number(dayItemHHSValue[0]);
        }
        else if(dayItemHHS1===ids.earth) {
            if(itemDayHHS1[ids.isYang])
                earthYangNum=earthYangNum+Number(dayItemHHSValue[0]);
            else
                earthJinNum=earthJinNum+Number(dayItemHHSValue[0]);
        }
        else if(dayItemHHS1===ids.metal) {
            if(itemDayHHS1[ids.isYang])
                metalYangNum=metalYangNum+Number(dayItemHHSValue[0]);
            else
                metalJinNum=metalJinNum+Number(dayItemHHSValue[0]);
        }
        else if(dayItemHHS1===ids.water) {
            if(itemDayHHS1[ids.isYang])
                waterYangNum=waterYangNum+Number(dayItemHHSValue[0]);
            else
                waterJinNum=waterJinNum+Number(dayItemHHSValue[0]);
        }
        else {
            console.log("missing day1 item")
        }
        ///////////////////HHS2////////////////////
        if(dayItemHHS2===ids.wood) {
            if(itemDayHHS2[ids.isYang])
                woodYangNum=woodYangNum+Number(dayItemHHSValue[1]);
            else
                woodJinNum=woodJinNum+Number(dayItemHHSValue[1]);
        }
        else if(dayItemHHS2===ids.fire) {
            if(itemDayHHS2[ids.isYang])
                fireYangNum=fireYangNum+Number(dayItemHHSValue[1]);
            else
                fireJinNum=fireJinNum+Number(dayItemHHSValue[1]);
        }
        else if(dayItemHHS2===ids.earth) {
            if(itemDayHHS2[ids.isYang])
                earthYangNum=earthYangNum+Number(dayItemHHSValue[1]);
            else
                earthJinNum=earthJinNum+Number(dayItemHHSValue[1]);
        }
        else if(dayItemHHS2===ids.metal) {
            if(itemDayHHS2[ids.isYang])
                metalYangNum=metalYangNum+Number(dayItemHHSValue[1]);
            else
                metalJinNum=metalJinNum+Number(dayItemHHSValue[1]);
        }
        else if(dayItemHHS2===ids.water) {
            if(itemDayHHS2[ids.isYang])
                waterYangNum=waterYangNum+Number(dayItemHHSValue[1]);
            else
                waterJinNum=waterJinNum+Number(dayItemHHSValue[1]);
        }
        else {
            console.log("wrong day2 item")
        }
        ///////////////////HHS3////////////////////
        if(dayItemHHS3===ids.wood) {
            if(itemDayHHS3[ids.isYang])
                woodYangNum=woodYangNum+Number(dayItemHHSValue[2]);
            else
                woodJinNum=woodJinNum+Number(dayItemHHSValue[2]);
        }
        else if(dayItemHHS3===ids.fire) {
            if(itemDayHHS3[ids.isYang])
                fireYangNum=fireYangNum+Number(dayItemHHSValue[2]);
            else
                fireJinNum=fireJinNum+Number(dayItemHHSValue[2]);
        }
        else if(dayItemHHS3===ids.earth) {
            if(itemDayHHS3[ids.isYang])
                earthYangNum=earthYangNum+Number(dayItemHHSValue[2]);
            else
                earthJinNum=earthJinNum+Number(dayItemHHSValue[2]);
        }
        else if(dayItemHHS3===ids.metal) {
            if(itemDayHHS3[ids.isYang])
                metalYangNum=metalYangNum+Number(dayItemHHSValue[2]);
            else
                metalJinNum=metalJinNum+Number(dayItemHHSValue[2]);
        }
        else if(dayItemHHS3===ids.water) {
            if(itemDayHHS3[ids.isYang])
                waterYangNum=waterYangNum+Number(dayItemHHSValue[2]);
            else
                waterJinNum=waterJinNum+Number(dayItemHHSValue[2]);
        }
        else {
            console.log("missing day3 item")
        }
        //////////////////////////////////////////////////////////////////////////
        let itemHourEB = getItem(data.eb, results[ids.hour][ids.eb]);
        let itemHourHHS1 = getItem(data.hs, itemHourEB[ids.hhs1]);
        let itemHourHHS2 = getItem(data.hs, itemHourEB[ids.hhs2]);
        let itemHourHHS3 = getItem(data.hs, itemHourEB[ids.hhs3]);

        const hourItemHHS1 = itemHourHHS1[ids.element];
        const hourItemHHS2 = itemHourHHS2[ids.element];
        const hourItemHHS3 = itemHourHHS3[ids.element];
        const hourItemHHSValue = itemHourEB[ids.hhs].split(ids.separator);
        ///////////////////HHS1////////////////////
        if(hourItemHHS1===ids.wood) {
            if(itemHourHHS1[ids.isYang])
                woodYangNum=woodYangNum+Number(hourItemHHSValue[0]);
            else
                woodJinNum=woodJinNum+Number(hourItemHHSValue[0]);
        }
        else if(hourItemHHS1===ids.fire) {
            if(itemHourHHS1[ids.isYang])
                fireYangNum=fireYangNum+Number(hourItemHHSValue[0]);
            else
                fireJinNum=fireJinNum+Number(hourItemHHSValue[0]);
        }
        else if(hourItemHHS1===ids.earth) {
            if(itemHourHHS1[ids.isYang])
                earthYangNum=earthYangNum+Number(hourItemHHSValue[0]);
            else
                earthJinNum=earthJinNum+Number(hourItemHHSValue[0]);
        }
        else if(hourItemHHS1===ids.metal) {
            if(itemHourHHS1[ids.isYang])
                metalYangNum=metalYangNum+Number(hourItemHHSValue[0]);
            else
                metalJinNum=metalJinNum+Number(hourItemHHSValue[0]);
        }
        else if(hourItemHHS1===ids.water) {
            if(itemHourHHS1[ids.isYang])
                waterYangNum=waterYangNum+Number(hourItemHHSValue[0]);
            else
                waterJinNum=waterJinNum+Number(hourItemHHSValue[0]);
        }
        else {
            console.log("missing hour1 item")
        }
        ///////////////////HHS2////////////////////
        if(hourItemHHS2===ids.wood) {
            if(itemHourHHS2[ids.isYang])
                woodYangNum=woodYangNum+Number(hourItemHHSValue[1]);
            else
                woodJinNum=woodJinNum+Number(hourItemHHSValue[1]);
        }
        else if(hourItemHHS2===ids.fire) {
            if(itemHourHHS2[ids.isYang])
                fireYangNum=fireYangNum+Number(hourItemHHSValue[1]);
            else
                fireJinNum=fireJinNum+Number(hourItemHHSValue[1]);
        }
        else if(hourItemHHS2===ids.earth) {
            if(itemHourHHS2[ids.isYang])
                earthYangNum=earthYangNum+Number(hourItemHHSValue[1]);
            else
                earthJinNum=earthJinNum+Number(hourItemHHSValue[1]);
        }
        else if(hourItemHHS2===ids.metal) {
            if(itemHourHHS2[ids.isYang])
                metalYangNum=metalYangNum+Number(hourItemHHSValue[1]);
            else
                metalJinNum=metalJinNum+Number(hourItemHHSValue[1]);
        }
        else if(hourItemHHS2===ids.water) {
            if(itemHourHHS2[ids.isYang])
                waterYangNum=waterYangNum+Number(hourItemHHSValue[1]);
            else
                waterJinNum=waterJinNum+Number(hourItemHHSValue[1]);
        }
        else {
            console.log("wrong hour2 item")
        }
        ///////////////////HHS3////////////////////
        if(hourItemHHS3===ids.wood) {
            if(itemHourHHS3[ids.isYang])
                woodYangNum=woodYangNum+Number(hourItemHHSValue[2]);
            else
                woodJinNum=woodJinNum+Number(hourItemHHSValue[2]);
        }
        else if(hourItemHHS3===ids.fire) {
            if(itemHourHHS3[ids.isYang])
                fireYangNum=fireYangNum+Number(hourItemHHSValue[2]);
            else
                fireJinNum=fireJinNum+Number(hourItemHHSValue[2]);
        }
        else if(hourItemHHS3===ids.earth) {
            if(itemHourHHS3[ids.isYang])
                earthYangNum=earthYangNum+Number(hourItemHHSValue[2]);
            else
                earthJinNum=earthJinNum+Number(hourItemHHSValue[2]);
        }
        else if(hourItemHHS3===ids.metal) {
            if(itemHourHHS3[ids.isYang])
                metalYangNum=metalYangNum+Number(hourItemHHSValue[2]);
            else
                metalJinNum=metalJinNum+Number(hourItemHHSValue[2]);
        }
        else if(hourItemHHS3===ids.water) {
            if(itemHourHHS3[ids.isYang])
                waterYangNum=waterYangNum+Number(hourItemHHSValue[2]);
            else
                waterJinNum=waterJinNum+Number(hourItemHHSValue[2]);
        }
        else {
            console.log("missing hour3 item")
        }
        //////////////////////////////////////////////////////////////////////////

        // woodNum = woodYangNum + woodJinNum;
        // fireNum = fireYangNum + fireJinNum;
        // earthNum = earthYangNum + earthJinNum;
        // metalNum = metalYangNum + metalJinNum;
        // waterNum = waterYangNum + waterJinNum;

        if((woodYangNum + woodJinNum + fireYangNum + fireJinNum + earthYangNum + earthJinNum + metalYangNum + metalJinNum + waterYangNum + waterJinNum) !== 800)
            console.log("Wrong factor calculation");

        const isDirectCombination = results[ids.directCombination];

        let woodFactor = 1;
        let fireFactor = 1;
        let earthFactor = 1;
        let metalFactor = 1;
        let waterFactor = 1;
        let numero = 0;
        if(isDirectCombination) {
            numero = 1;
            const directCombinationElement = results[ids.directCombinationElement];
            if(directCombinationElement === ids.wood)
                woodFactor += 0.5;
            else if(directCombinationElement === ids.fire)
                fireFactor += 0.5;
            else if(directCombinationElement === ids.earth)
                earthFactor += 0.5;
            else if(directCombinationElement === ids.metal)
                metalFactor += 0.5;
            else if(directCombinationElement === ids.water)
                waterFactor += 0.5;
        }

        const isHeavenCombination = results[ids.heavenCombination];
        if(isHeavenCombination) {
            numero = 1;
            const heavenCombinationElement = results[ids.heavenCombinationElelement];
            if(heavenCombinationElement === ids.wood)
                woodFactor += numero + 0.5;
            else if(heavenCombinationElement === ids.fire)
                fireFactor += numero + 0.5;
            else if(heavenCombinationElement === ids.earth)
                earthFactor += numero + 0.5;
            else if(heavenCombinationElement === ids.metal)
                metalFactor += numero + 0.5;
            else if(heavenCombinationElement === ids.water)
                waterFactor += numero + 0.5;
        }
        //hss factors
        const monthItemHSSPowerFactor = itemMonthEB[ids.hhsPower].split(ids.separator);
        woodYangNum = woodYangNum * Number(monthItemHSSPowerFactor[0])/100 * woodFactor;
        woodJinNum = woodJinNum * Number(monthItemHSSPowerFactor[0])/100 * woodFactor;
        fireYangNum = fireYangNum * Number(monthItemHSSPowerFactor[1])/100 * fireFactor;
        fireJinNum = fireJinNum * Number(monthItemHSSPowerFactor[1])/100 * fireFactor;
        earthYangNum = earthYangNum * Number(monthItemHSSPowerFactor[2])/100 * earthFactor;
        earthJinNum = earthJinNum * Number(monthItemHSSPowerFactor[2])/100 * earthFactor;
        metalYangNum = metalYangNum * Number(monthItemHSSPowerFactor[3])/100 * metalFactor;
        metalJinNum = metalJinNum * Number(monthItemHSSPowerFactor[3])/100 * metalFactor;
        waterYangNum = waterYangNum * Number(monthItemHSSPowerFactor[4])/100 * waterFactor;
        waterJinNum = waterJinNum * Number(monthItemHSSPowerFactor[4])/100 * waterFactor;

        let suma = woodYangNum + woodJinNum + fireYangNum + fireJinNum + earthYangNum + earthJinNum + metalYangNum + metalJinNum + waterYangNum + waterJinNum;
        woodYangNum = this.roundThisNumber((woodYangNum / suma) * 100, 0);
        woodJinNum = this.roundThisNumber((woodJinNum / suma) * 100, 0);
        fireYangNum = this.roundThisNumber((fireYangNum / suma) * 100, 0);
        fireJinNum = this.roundThisNumber((fireJinNum / suma) * 100, 0);
        earthYangNum = this.roundThisNumber((earthYangNum / suma) * 100, 0);
        earthJinNum = this.roundThisNumber((earthJinNum / suma) * 100, 0);
        metalYangNum = this.roundThisNumber((metalYangNum / suma) * 100, 0);
        metalJinNum = this.roundThisNumber((metalJinNum / suma) * 100, 0);
        waterYangNum = this.roundThisNumber((waterYangNum / suma) * 100, 0);
        waterJinNum = this.roundThisNumber((waterJinNum / suma) * 100, 0);

        woodNum = woodYangNum + woodJinNum;
        fireNum = fireYangNum + fireJinNum;
        earthNum = earthYangNum + earthJinNum;
        metalNum = metalYangNum + metalJinNum;
        waterNum = waterYangNum + waterJinNum;

        let factors = [];
        factors.push(dayItemHS);

        if(dayItemHS===ids.wood) {
            factors.push(woodNum);//DM
            factors.push(woodYangNum);//DM
            factors.push(woodJinNum);//DM
            factors.push(fireNum);//TF
            factors.push(fireYangNum);//TF
            factors.push(fireJinNum);//TF
            factors.push(earthNum);//FB
            factors.push(earthYangNum);//FB
            factors.push(earthJinNum);//FB
            factors.push(metalNum);//FK
            factors.push(metalYangNum);//FK
            factors.push(metalJinNum);//FK
            factors.push(waterNum);//FP
            factors.push(waterYangNum);//FP
            factors.push(waterJinNum);//FP
        }
        else if(dayItemHS===ids.fire)
        {
            factors.push(fireNum);
            factors.push(fireYangNum);
            factors.push(fireJinNum);
            factors.push(earthNum);
            factors.push(earthYangNum);
            factors.push(earthJinNum);
            factors.push(metalNum);
            factors.push(metalYangNum);
            factors.push(metalJinNum);
            factors.push(waterNum);
            factors.push(waterYangNum);
            factors.push(waterJinNum);
            factors.push(woodNum);
            factors.push(woodYangNum);
            factors.push(woodJinNum);
        }
        else if(dayItemHS===ids.earth)
        {
            factors.push(earthNum);
            factors.push(earthYangNum);
            factors.push(earthJinNum);
            factors.push(metalNum);
            factors.push(metalYangNum);
            factors.push(metalJinNum);
            factors.push(waterNum);
            factors.push(waterYangNum);
            factors.push(waterJinNum);
            factors.push(woodNum);
            factors.push(woodYangNum);
            factors.push(woodJinNum);
            factors.push(fireNum);
            factors.push(fireYangNum);
            factors.push(fireJinNum);
        }
        else if(dayItemHS===ids.metal)
        {
            factors.push(metalNum);
            factors.push(metalYangNum);
            factors.push(metalJinNum);
            factors.push(waterNum);
            factors.push(waterYangNum);
            factors.push(waterJinNum);
            factors.push(woodNum);
            factors.push(woodYangNum);
            factors.push(woodJinNum);
            factors.push(fireNum);
            factors.push(fireYangNum);
            factors.push(fireJinNum);
            factors.push(earthNum);
            factors.push(earthYangNum);
            factors.push(earthJinNum);
        }
        else if(dayItemHS===ids.water)
        {
            factors.push(waterNum);
            factors.push(waterYangNum);
            factors.push(waterJinNum);
            factors.push(woodNum);
            factors.push(woodYangNum);
            factors.push(woodJinNum);
            factors.push(fireNum);
            factors.push(fireYangNum);
            factors.push(fireJinNum);
            factors.push(earthNum);
            factors.push(earthYangNum);
            factors.push(earthJinNum);
            factors.push(metalNum);
            factors.push(metalYangNum);
            factors.push(metalJinNum);
        }

        return factors;
    }

    roundThisNumber(value, precision) {
        let multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    render() {
        if(!this.props.isAdvanced) return null;

        let generated = this.props.data[ids.generated];
        let checked = !generated ? false : this.props.data[ids.fiveFactors]==="1";

        let factors = this.calcFactors();
        if(!factors) {
            checked = false;
            generated = false;
        }
        //let dt = new Date();
        let imgName = ids.imgPath + this.getFactorsImage() + imagesKey.fileExt;// + "?" + dt.getTime();
        let i = 1; //positions
        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={2}>{strings.fiveFateFactors}</Form.Label>
                    <Form.Check
                        className={"item-switcher"}
                        type="switch"
                        id={ids.fiveFactors}
                        label=""
                        disabled={!generated}
                        checked={checked}
                        onChange={this.handleChecked}
                    />
                </Form.Row>
                {checked ?
                    <Form.Row>
                        <img className="col-sm-3 dm-image" src={imgName} alt=""/>
                        <div className="col-sm-9">
                            <table className="table factor-table">
                                <thead>
                                <tr>
                                    <th scope="col">{strings.labelFactorName}</th>
                                    <th scope="col">{strings.labelFactorValue}</th>
                                    <th scope="col">{strings.labelFactorValueJang}</th>
                                    <th scope="col">{strings.labelFactorValueJin}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">{strings.dm}</th>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{strings.tf}</th>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{strings.fb}</th>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{strings.fk}</th>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{strings.fp}</th>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                    <td>{factors[i++] + " %"}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Form.Row> : <div/>
                }
            </div>
        )
    }
}

export default Step5