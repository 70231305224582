import React, {Component} from 'react';
import {data, ids, strings} from "../Configuration/Config";
import {CardGroup} from 'react-bootstrap'
import PillarItem from './PillarItem'
import {Form} from 'react-bootstrap'
import {getItem} from "../Master/UtilityCalc";

class Step2 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.getDMStrength = this.getDMStrength.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    getDMStrength() {
        const _data = this.props.data;

        const results = _data[ids.results];
        if(!results || !results[ids.day] || !results[ids.month]) return null;

        const hsItem = results[ids.day][ids.hs];
        let itemHS = getItem(data.hs, hsItem);

        const ebItem = results[ids.month][ids.eb];
        let itemEB = getItem(data.eb, ebItem);

        const key = itemEB[ids.monthEnergy];
        let itemEBEnergy = getItem(data.hs, key);

        const dayItem = itemHS[ids.element];
        const monthItem = itemEBEnergy[ids.element];

        if(dayItem === ids.wood) {
            if(monthItem===ids.wood || monthItem===ids.water)
                return ids.strongDM;
            else
                return ids.weakDM;
        } else if(dayItem===ids.fire) {
            if(monthItem===ids.fire || monthItem===ids.wood)
                return ids.strongDM;
            else
                return ids.weakDM;
        } else if(dayItem===ids.earth) {
            if(monthItem===ids.fire || monthItem===ids.earth)
                return ids.strongDM;
            else
                return ids.weakDM;
        } else if(dayItem===ids.metal) {
            if(monthItem===ids.metal || monthItem===ids.earth)
                return ids.strongDM;
            else
                return ids.weakDM;
        } else { //dayItem===ids.water
            if(monthItem===ids.water || monthItem===ids.metal)
                return ids.strongDM;
            else
                return ids.weakDM;
        }
    }

    render() {
        if (!this.props.data[ids.generated] ) { // Prop: The current step
            return null
        }

        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={2}>{strings.empty}</Form.Label>
                </Form.Row>
                <CardGroup>
                    {/*<PillarItem*/}
                        {/*updateData={this.updateData}*/}
                        {/*data={this.props.data}*/}
                        {/*keyId={ids.taiYuan}*/}
                        {/*tooltip={strings.taiYuanDesc}*/}
                    {/*/>*/}
                    {/*<PillarItem*/}
                        {/*updateData={this.updateData}*/}
                        {/*data={this.props.data}*/}
                        {/*keyId={ids.mingGong}*/}
                        {/*tooltip={strings.mingGongDesc}*/}
                    {/*/>*/}
                    <PillarItem
                        updateData={this.updateData}
                        data={this.props.data}
                        keyId={ids.hour}
                    />
                    <PillarItem
                        updateData={this.updateData}
                        data={this.props.data}
                        keyId={ids.day}
                        isItemActive={true}
                        // itemColor={this.getDMStrength()}
                    />
                    <PillarItem
                        updateData={this.updateData}
                        data={this.props.data}
                        keyId={ids.month}
                    />
                    <PillarItem
                        updateData={this.updateData}
                        data={this.props.data}
                        keyId={ids.year}
                    />
                </CardGroup>
                {/*<Form.Row className={"top-3"}>*/}
                    {/*<div className="colorBox card-item-active-strong" />*/}
                    {/*<span className="boxSpan">/</span>*/}
                    {/*<div className="colorBox card-item-active-weak" />*/}
                    {/*<Form.Label column="sm" lg={2}>{strings.weakStrongDM}</Form.Label>*/}
                {/*</Form.Row>*/}
            </div>
        )
    }
}

export default Step2