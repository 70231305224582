export const syndromIds = {
    "syndrom": "syndrom",
    "addtoback": "addtoback",
    "orig": "orig",
    "name": "name",
    "number": "number",
    "ready": "ready",
    "fyto": "fyto",
    "acu": "acu",
    "mushrooms": "mushrooms",
    "etiology": "etiology",
    "symptom": "symptoms",
    "keysymptom": "keysymptom",
    "keyprincip": "keyprincip",
    "pulse": "pulse",
    "channels": "channels",
    "channel": "channel"
};

export const syndromStrings = {
  "etiology": "Etiologie",
  "symptoms": "Příznaky",
    "tongue": "Jazyk",
    "pulse": "Pulz",
    "keysymptom": "Klíčové příznaky",
    "fyto": "Fyto",
    "acu": "Acu body",
    "mushrooms": "Houby",
    "keyprincip": "Terapeutický princip",
    "note": "Poznámky"
};
