import React, {Component} from 'react';
import './Toolbar.css';

class Toolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        //let active = 'pt-button pt-minimal pt-active';
        let normal = 'pt-button pt-minimal';
        const isLogoutVisible = this.props.visible;
        return (
            <div id="navbar">
                <nav className="navbar right">
                    {isLogoutVisible ?
                    <div className="navbar-group">
                        <a href="!#" title="odhlásit" onClick={this.props.logout}>
                            <div className={(normal + " pt-icon-log-out")}></div>
                        </a>
                    </div>
                    : <div/>}
                </nav>
            </div>
        );
    }
}

export default Toolbar;
