import React, {Component} from 'react'
import {keys} from "../Constants"
import Card from "./Card";

import './Cards.css';

class Cards extends Component {
    constructor(props) {
        super(props);

        this.getCards = this.getCards.bind(this);
        this.isFound = this.isFound.bind(this);
    }

    isFound(item, searchText) {
        if(searchText.length === 0) return true;

        let chips = searchText.split(",");
        let foundItem = chips.filter(chip => {
            chip = chip.trim();
            if(item[keys.name.value].toLowerCase().match(chip.toLocaleLowerCase()))
                return true;
            else if(item[keys.orig.value].toLowerCase().match(chip.toLocaleLowerCase()))
                 return true;
            else if(item[keys.categories.value].toLowerCase().match(chip.toLocaleLowerCase()))
                 return true;
            else if(item[keys.mission.value].toLowerCase().match(chip.toLocaleLowerCase()))
                 return true;
            else {
                //functions
                let functions = item[keys.function.value];
                let _filteredItems1 = functions.filter(fItem => {
                    return (fItem[keys.description.value].toLowerCase().match(chip.toLocaleLowerCase()));
                });
                if(_filteredItems1.length > 0)
                    return true;
                let notes = item[keys.note.value];
                let _filteredItems2 = notes.filter(nItem => {
                    return (nItem[keys.description.value].toLowerCase().match(chip.toLocaleLowerCase()));
                });
                if(_filteredItems2.length > 0)
                    return true;
            }

            return false;
        });

        return foundItem.length > 0;
    }

    getCards(items, groups, filters) {
        let cards = [];
        let filteredItems = items.filter(item => {
                return (this.isFound(item, filters[keys.searchText.value]));
            });

        filteredItems = filteredItems.filter(item => {
            return (item[keys.id.value].length > 0);
        });

        let groupsIds = groups.split(',');
        if(groupsIds.length > 0) {
            filteredItems = filteredItems.filter(item => {
                return (groups.indexOf(item[keys.categoryId.value]) !== -1);
            });
        }


        // if(filters[keys.allxue.value] && filteredXues.length > 1) {
        //     filteredXues.sort(function (a,b) {
        //         let points = channel[0][keys.xues.value];
        //
        //         let pointa = points.filter(point => {
        //             return (point[keys.id.value] === a.trim());
        //         });
        //
        //         let pointb = points.filter(point => {
        //             return (point[keys.id.value] === b.trim());
        //         });
        //
        //         let pointA = Number(pointa[0][keys.name.value].replace(channel[0][keys.short.value], ""));
        //         let pointB = Number(pointb[0][keys.name.value].replace(channel[0][keys.short.value], ""));
        //         if (pointA < pointB)
        //             return -1;
        //         if (pointA > pointB)
        //             return 1;
        //         return 0;
        //     });
        // }
        let i = 0;
        filteredItems.forEach(item => {
            cards.push( <Card key={i++}
                item={item}
                filters={this.props.filters}
            />)
        });

        return cards;
    }

    render() {
        let cards = this.getCards(this.props.items, this.props.currentCategory, this.props.filters);

        return (
            <div className="card-container">
                {cards}
            </div>
        )
    }
}

export default Cards
