import React, {Component} from 'react'
import Select from 'react-select'
import MatrixTable from './Table/MatrixTable'

import {keys} from "./Utils/Constants"
import {matrix_data} from "./data/Data"

import {reactLocalStorage} from 'reactjs-localstorage';

import './Styles/Matrix.css';

class Matrix extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentChannels: "",
            currentMethods: "",
            currentFilters: "",
            reset: false
        }

        this.getChannelsOptions = this.getChannelsOptions.bind(this);
        this.channelChange = this.channelChange.bind(this);

        this.getMatrixTable = this.getMatrixTable.bind(this);

        this.getMethodsOptions = this.getMethodsOptions.bind(this);
        this.methodChange = this.methodChange.bind(this);

        this.getFiltersOptions = this.getFiltersOptions.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);

        this.getKeyId = this.getKeyId.bind(this);
        this.createUUID = this.createUUID.bind(this);

        this.loadData();
    }

    getKeyId(id) {
        let data = this.state;
        let key = data[keys.key.value];
        //key = "asdsadsdsda";//for debug purpose
        if(!key || key==="") {
            key = this.createUUID();
            data[keys.key.value] = key;
        }
        return key + "_" + id;
    }

    createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 10; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        //s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        //s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        //s[8] = s[13] = s[18] = s[23] = "-";

        return s.join("");
    }

    loadData() {
        let data = this.state;

        let keyId = reactLocalStorage.get(keys.key.value);
        if(data[keys.reset.value]) keyId = "";
        if(keyId) data[keys.key.value] = keyId;

        let methods = reactLocalStorage.get(this.getKeyId(keys.methods.value));
        let channels = reactLocalStorage.get(this.getKeyId(keys.channels.value));
        let filters = reactLocalStorage.get(this.getKeyId(keys.filters.value));

        if(!methods) methods = "";
        if(!channels) channels = "";
        if(!filters) filters = "";

        if(!methods.match(keys.method6Id.value)) {
            if(methods.length > 0)
                methods += keys.separator.value;
            methods += keys.method6Id.value;
        }

        data[keys.currentChannels.value] = channels;
        data[keys.currentMethods.value] = methods;
        data[keys.currentFilters.value] = filters;

        if(data[keys.reset.value]) this.saveData();
    }

    saveData() {
        let data = this.state;
        if(data[keys.key.value]!==undefined) reactLocalStorage.set(keys.key.value, data[keys.key.value]);
        reactLocalStorage.set(this.getKeyId(keys.methods.value), data[keys.currentMethods.value]);
        reactLocalStorage.set(this.getKeyId(keys.channels.value), data[keys.currentChannels.value]);
        reactLocalStorage.set(this.getKeyId(keys.filters.value), data[keys.currentFilters.value]);
    }

    getChannelsOptions() {
        let options = [];
        matrix_data.channels.forEach(item => {
            options.push({value: item[keys.id.value], label: item[keys.short.value] + " - " + item[keys.name.value].toLowerCase()});
        });

        return options;
    }

    channelChange(e) {
        let value = "";
        if (e !== null) {
            for (let id in e) {
               value += e[id][keys.value.value] + keys.separator.value;
            }
        }

        let that = this;
        that.setState({
            currentChannels: value
        }, () => {
            that.saveData();
        });
    }

    getMethodsOptions() {
        let options = [];
        matrix_data.methods.forEach(item => {
            options.push({value: item[keys.id.value], label: item[keys.name.value] + " - " + item[keys.description.value].toLowerCase()});
        });

        return options;
    }

    methodChange(e) {
        let value = "";
        if (e !== null) {
            for (let id in e) {
                // if(value.length > 0)
                //     value += keys.separator.value;
                // value += e[id]["value"];
                value += e[id][keys.value.value] + keys.separator.value;
            }
        }

        if(!value.match(keys.method6Id.value)) {
            value += keys.method6Id.value + keys.separator.value;
        }

        let that = this;
        that.setState({
            currentMethods: value,
            currentFilters: ""
        }, () => {
            that.saveData();
        });
    }

    getFiltersOptions() {
        let options = [];
        matrix_data.filters.forEach(item => {
            options.push({value: item[keys.id.value], label: item[keys.name.value] + " - " + item[keys.description.value].toLowerCase()});
        });

        return options;
    }

    filterChange(e) {
        let data = this.state;
        let _value = data[keys.currentFilters.value];
        let value = "";
        if (e !== null) {
            for (let id in e) {
                if(_value.match(e[id][keys.value.value]))
                    continue;
                value += e[id][keys.value.value] + keys.separator.value;
            }
        }

        let currentMethods = "";
        matrix_data.filters.forEach(item => {
            const id = item[keys.id.value];
            if(!value.match(id)) return;

            currentMethods = item[keys.methods.value];
        });

        if(!currentMethods.match(keys.method6Id.value)) {
            currentMethods += keys.method6Id.value + keys.separator.value;
        }
        let that = this;
        that.setState({
            currentFilters: value,
            currentMethods: currentMethods
        }, () => {
            that.saveData();
        });
    }

    getMatrixTable() {
        let data = this.state;
        let matrixTable = [];
        let _matrixTable = [];
        let i = 0;

        const currentMethodsArr = data[keys.currentMethods.value].split(keys.separator.value);
        const currentChannelsArr = data[keys.currentChannels.value].split(keys.separator.value);

        currentChannelsArr.forEach(channelId => {
            let method1 = "";
            let method2 = "";
            let method3 = "";
            let method4 = "";
            let method5 = "";
            let method6 = "";
            if(channelId==="") return;
            matrix_data.channels.forEach(itemChannel => {
                if (itemChannel[keys.id.value] !== channelId)
                    return;

                if (method6.length > 0)
                    method6 += ", ";
                method6 += itemChannel[keys.short.value];
            });
            currentMethodsArr.forEach(methodId => {
                if(methodId==="") return;
                let pos = 0;
                // method1 = "";
                // method2 = "";
                // method3 = "";
                // method4 = "";
                // method5 = "";
                matrix_data.methods.forEach(itemMethod => {
                    pos++;
                    if(itemMethod[keys.id.value] !== methodId)
                        return;

                    let links = itemMethod[keys.links.value];
                    let _channelId = links[channelId];

                    matrix_data.channels.forEach(itemChannel => {
                       if(itemChannel[keys.id.value]!==_channelId)
                           return;
                       switch(pos) {
                           case 1:
                               if (method1.length > 0)
                                   method1 += ", ";
                               method1 += itemChannel[keys.short.value];
                               break;
                           case 2:
                               if (method2.length > 0)
                                   method2 += ", ";
                               method2 += itemChannel[keys.short.value];
                               break;
                           case 3:
                               if (method3.length > 0)
                                   method3 += ", ";
                               method3 += itemChannel[keys.short.value];
                               break;
                           case 4:
                               if (method4.length > 0)
                                   method4 += ", ";
                               method4 += itemChannel[keys.short.value];
                               break;
                           case 5:
                               if (method5.length > 0)
                                   method5 += ", ";
                               method5 += itemChannel[keys.short.value];
                               break;
                           default:
                               break;
                       }

                    });
                });
            });
            _matrixTable.push(
                {
                    [keys.id.value]: channelId,
                    [keys.method6.value]: method6,
                    [keys.method1.value]: method1,
                    [keys.method2.value]: method2,
                    [keys.method3.value]: method3,
                    [keys.method4.value]: method4,
                    [keys.method5.value]: method5,
                }
            );
        });

        matrixTable.push(
            <MatrixTable
                key={i++}
                table={_matrixTable}
            />
        );

        return matrixTable;
    }

    render() {
        let data = this.state;

        const channelsOptions = this.getChannelsOptions();
        if (channelsOptions.length === 0)
            return (<div/>);

        let channelsDefaultvalue = null;
        let currentChannels = data[keys.currentChannels.value];
        if (currentChannels !== "" && (currentChannels.length > 0 && (currentChannels.indexOf(',') > 0))) {
             channelsDefaultvalue = channelsOptions.filter(option => {
                 return (data[keys.currentChannels.value].match(option.value));
            });
        }

        const methodsOptions = this.getMethodsOptions();
        if (methodsOptions.length === 0)
            return (<div/>);

        let methodsDefaultvalue = null;
        let currentMethods = data[keys.currentMethods.value];
        if (currentMethods !== "" && (currentMethods.length > 0 && (currentMethods.indexOf(',') > 0))) {
            methodsDefaultvalue = methodsOptions.filter(option => {
                return (data[keys.currentMethods.value].match(option.value));
            });
        }

        let filtersDefaultvalue = null;
        const filtersOptions = this.getFiltersOptions();
        if (filtersOptions.length !== 0)
        {
            let currentFilters = data[keys.currentFilters.value];
            if (currentFilters !== "" && (currentFilters.length > 0 && (currentFilters.indexOf(',') > 0))) {
                filtersDefaultvalue = filtersOptions.filter(option => {
                    return (data[keys.currentFilters.value].match(option.value));
                });
            }
        }

        const matrixTable = this.getMatrixTable();

        return (
            <div id="tcme">
                <div className="acu-select-area">
                    <div className="acu-basic-single">
                        <Select
                            className="matrix-basic-single-top1"
                            classNamePrefix="select"
                            name="channel-selector"
                            isMulti={true}
                            defaultValue={channelsDefaultvalue}
                            isSearchable={false}
                            options={channelsOptions}
                            onChange={this.channelChange}
                        />
                        <Select
                            className="matrix-basic-single-top2"
                            classNamePrefix="select"
                            name="filter-selector"
                            isMulti={true}
                            value={filtersDefaultvalue}
                            defaultValue={filtersDefaultvalue}
                            isSearchable={false}
                            isClearable={true}
                            options={filtersOptions}
                            onChange={this.filterChange}
                        />
                        <Select
                            className="matrix-basic-single-top3"
                            classNamePrefix="select"
                            name="method-selector"
                            isMulti={true}
                            // isClearable={true}
                            defaultValue={methodsDefaultvalue}
                            value={methodsDefaultvalue}
                            isSearchable={false}
                            options={methodsOptions}
                            onChange={this.methodChange}
                        />
                    </div>
                    <div className="leung-basic-single">
                    {matrixTable}
                    </div>
                </div>
            </div>
        )
    }
}

export default Matrix