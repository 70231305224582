import React from 'react';
import {Link} from 'react-router-dom'

class CardHeader extends React.Component {
    render() {
        const image = this.props.image;
        const locked = this.props.locked;
        let style = {
            backgroundImage: 'url(' + image + ')',
        };

        let className = "lock-icon fa fa-unlock fa-2x";
        if(locked) className = "lock-icon fa fa-lock fa-2x";
        return (
            <header style={style} id={image} className="welcome-card-header">
                <i className={className} aria-hidden="true"></i>
            </header>
        )
    }
}

class CardBody extends React.Component {
    render() {
        return (
            <div className="welcome-card-body">
                <h2>{this.props.title}</h2>
            </div>
        )
    }
}

class Card extends React.Component {
    render() {
        let className = "welcome-column";
        if(this.props.centered)
            className += " welcome-card-centered";
        return (
            <Link to={this.props.link} variant="body2" className={className}>
                <div className="welcome-card">
                    <CardHeader image={this.props.image} locked={this.props.locked}/>
                    <CardBody title={this.props.title}/>
                </div>
            </Link>
        )
    }
}

export default Card;