export const strings = {
    email: "tcmencyklopedie@gmail.com",
    emailSignSubject: "Registrace",
    correctEmailSent: "registrační email byl úspěšně odeslán..., vyčkejte prosím na zřízení přístupu, cca 24h",
    badEmailSent: "registrační email se z nějakého důvodu nepodařilo odeslat..., kontaktujte mne prosím na emailu: tcmencyklopedie@gmail.com",
    fyto: "Fyto",
    acu: "Aku body",
    earacu: "Ušní Aku body",
    syndroms: "Syndromy",
    matrix: "Vyrovnávací metoda",
    akabane: "Akabane formulář",
    bach: "Bachovy esence",
    bazi: "BaZi",
    baziAdv: "BaZi advanced",
    leung: "Leungova tabulka",
    tao: "Tabulka Aku bodů",
    author: "jk"
};

export const ids = {
    imgAppPath: "./assets/images/app/",
    imgFytoPath: "./assets/images/fyto/",
    imgFyto: "fyto.jpg",
    imgAcu: "acu.jpg",
    imgEarAcu: "earacu.png",
    imgLeung: "leung.jpg",
    imgTao: "tao.jpg",
    imgAkabane: "akabane.jpg",
    imgBach: "bach.jpg",
    imgBazi: "bazi.jpg",
    imgBaziAdv: "baziAdv.jpg",
    imgSyndroms: "syndroms.jpg",
    siteKey: "6LdPLksaAAAAAACEHq3UXcSbAdYtQzg1xmZTEIXg"
};