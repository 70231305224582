import React, {Component} from 'react';
import {ids, strings, channels, results, imagesKey} from "../Configuration/Config";
import Table from 'react-bootstrap/Table'
import domtoimage from 'dom-to-image';

import './PrintView.css';

class PrintView extends Component {
    constructor(props) {
        super(props);
        // Set the initial input values
        this.state = {};

        this.getResultMsg = this.getResultMsg.bind(this);
        this.download = this.download.bind(this);
    }

    download() {
        let node = document.getElementById(ids.printTableArea);
        domtoimage.toPng(node, { quality: 0.95, bgcolor: 'white', height: 1800, width:1400 })
            .then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = 'my-image-name.png';
                link.href = dataUrl;
                link.click();
            });
    }

    getResultMsg(data) {
        let msg = "";

        if(Number(data[results.huo.id])>Number(data[results.resultsRatio.id])) {
            msg = results.huo.error;
        }

        if(Number(data[results.han.id])<Number(data[results.resultsRatio.id])) {
            msg += " " + results.han.error;
        }

        if(Number(data[results.hanhuoratio.id])<1.2) {
            msg += " " + results.hanhuoratio.error2;
        }

        if(Number(data[results.hanhuoratio.id])>2.2) {
            msg += " " + results.hanhuoratio.error1;
        }

        return msg;
    }

    // Render UI will go here...
    render() {
        const data = this.props.data;
        //if(!data[results.resultsRatio.id]) return null;
        return (
            <div id={ids.printArea} className="printarea">
                <Table id={ids.printTableArea}  className="print-table" bordered>
                    <tbody>
                    <tr>
                        <td className="print-table-bold" width="80%" colSpan="4">{strings.name + ":"}
                            <span className="print-table-td-align-right">{data[ids.name]}</span>
                        </td>
                        <td className="print-table-bold" colSpan="2">{strings.age + ":"}
                            <span className="print-table-td-align-right">{data[ids.age]}</span>
                        </td>
                        <td className="print-table-bold" colSpan="2">{strings.date + ":"}
                            <span className="print-table-td-align-right">{data[ids.date]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="8">{strings.healthProblem + ":"}
                            <span className="print-table-td-newline">{data[ids.healthProblem]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" width="12%" colSpan="2">{strings.empty}</td>
                        <td className="print-table-bold" width="44%" colSpan="3">{strings.measure.toLowerCase() + ":"}</td>
                        <td className="print-table-bold" colSpan="44%">{strings.check.toLowerCase() + ":"}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" >{strings.empty}</td>
                        <td className="print-table-bold">{strings.empty}</td>
                        <td className="print-table-bold print-table-td-align-center" width="12%">{strings.measureLeft + " (s) :"}</td>
                        <td className="print-table-bold print-table-td-align-center" width="12%">{strings.measureRight + " (s) :"}</td>
                        <td className="print-table-bold print-table-td-align-center" width="15%">{strings.measureRatio + ":"}</td>
                        <td className="print-table-bold print-table-td-align-center" width="12%">{strings.measureLeft + " (s) :"}</td>
                        <td className="print-table-bold print-table-td-align-center" width="12%">{strings.measureRight + " (s) :"}</td>
                        <td className="print-table-bold print-table-td-align-center" width="15%">{strings.measureRatio + ":"}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"1."}</td>
                        <td className="print-table-bold">{channels.fei.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.fei.measureLeft ? " print-table-cell-error" : "")}>{data[channels.fei.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.fei.measureRight ? " print-table-cell-error" : "")}>{data[channels.fei.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.fei.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.fei.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.feiCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.feiCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.feiCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.feiCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"2."}</td>
                        <td className="print-table-bold">{channels.dachang.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.dachang.measureLeft ? " print-table-cell-error" : "")}>{data[channels.dachang.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.dachang.measureRight ? " print-table-cell-error" : "")}>{data[channels.dachang.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.dachang.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.dachang.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.dachangCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.dachangCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.dachangCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.dachangCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"3."}</td>
                        <td className="print-table-bold">{channels.xinbao.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xinbao.measureLeft ? " print-table-cell-error" : "")}>{data[channels.xinbao.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xinbao.measureRight ? " print-table-cell-error" : "")}>{data[channels.xinbao.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xinbao.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xinbao.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xinbaoCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xinbaoCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xinbaoCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xinbaoCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"4."}</td>
                        <td className="print-table-bold">{channels.sanjiao.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.sanjiao.measureLeft ? " print-table-cell-error" : "")}>{data[channels.sanjiao.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.sanjiao.measureRight ? " print-table-cell-error" : "")}>{data[channels.sanjiao.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.sanjiao.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.sanjiao.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.sanjiaoCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.sanjiaoCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.sanjiaoCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.sanjiaoCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"5."}</td>
                        <td className="print-table-bold">{channels.xin.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xin.measureLeft ? " print-table-cell-error" : "")}>{data[channels.xin.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xin.measureRight ? " print-table-cell-error" : "")}>{data[channels.xin.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xin.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xin.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xinCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xinCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xinCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xinCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"6."}</td>
                        <td className="print-table-bold">{channels.xiaochang.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xiaochang.measureLeft ? " print-table-cell-error" : "")}>{data[channels.xiaochang.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.xiaochang.measureRight ? " print-table-cell-error" : "")}>{data[channels.xiaochang.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xiaochang.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xiaochang.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xiaochangCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.xiaochangCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.xiaochangCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.xiaochangCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"7."}</td>
                        <td className="print-table-bold">{channels.pi.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.pi.measureLeft ? " print-table-cell-error" : "")}>{data[channels.pi.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.pi.measureRight ? " print-table-cell-error" : "")}>{data[channels.pi.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.pi.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.pi.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.piCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.piCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.piCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.piCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"8."}</td>
                        <td className="print-table-bold">{channels.gan.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.gan.measureLeft ? " print-table-cell-error" : "")}>{data[channels.gan.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.gan.measureRight ? " print-table-cell-error" : "")}>{data[channels.gan.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.gan.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.gan.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.ganCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.ganCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.ganCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.ganCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"9."}</td>
                        <td className="print-table-bold">{channels.wei.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.wei.measureLeft ? " print-table-cell-error" : "")}>{data[channels.wei.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.wei.measureRight ? " print-table-cell-error" : "")}>{data[channels.wei.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.wei.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.wei.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.weiCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.weiCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.weiCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.weiCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"10."}</td>
                        <td className="print-table-bold">{channels.dan.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.dan.measureLeft ? " print-table-cell-error" : "")}>{data[channels.dan.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.dan.measureRight ? " print-table-cell-error" : "")}>{data[channels.dan.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.dan.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.dan.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.danCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.danCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.danCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.danCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"11."}</td>
                        <td className="print-table-bold">{channels.pangguang.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.pangguang.measureLeft ? " print-table-cell-error" : "")}>{data[channels.pangguang.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.pangguang.measureRight ? " print-table-cell-error" : "")}>{data[channels.pangguang.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.pangguang.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.pangguang.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.pangguangCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.pangguangCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.pangguangCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.pangguangCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold print-table-td-align-center" width="1%">{"12."}</td>
                        <td className="print-table-bold">{channels.shen.name}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.shen.measureLeft ? " print-table-cell-error" : "")}>{data[channels.shen.measureLeft]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (data[ids.cure]===channels.shen.measureRight ? " print-table-cell-error" : "")}>{data[channels.shen.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.shen.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.shen.measureRatio]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.shenCheck.measureLeft]}</td>
                        <td className="print-table-bold print-table-td-align-center">{data[channels.shenCheck.measureRight]}</td>
                        <td className={"print-table-bold print-table-td-align-center" + (Number(data[channels.shenCheck.measureRatio]) > 2.5 ? " print-table-cell-error" : "")}>{data[channels.shenCheck.measureRatio]}</td>
                    </tr>
                    <tr>
                        <td className="print-table-high" width="44%" colSpan="8">{strings.results + ":"}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="4">{results.zao.name + ":"}
                            <span className="print-table-td-align-right">{data[results.zao.id]}</span>
                        </td>
                        <td className="print-table-bold" colSpan="4">{results.huo.name + ":"}
                            <span className={"print-table-td-align-right" + (Number(data[[results.huo.id]]>Number(data[results.resultsRatio.id])) ? " print-table-cell-text-error" : "")}>{data[results.huo.id]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="4">{results.shi.name + ":"}
                            <span className="print-table-td-align-right">{data[results.shi.id]}</span>
                        </td>
                        <td className="print-table-bold" colSpan="4">{results.feng.name + ":"}
                            <span className="print-table-td-align-right">{data[results.feng.id]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="4">{results.han.name + ":"}
                            <span className={"print-table-td-align-right" + (Number(data[[results.han.id]]<Number(data[results.resultsRatio.id])) ? " print-table-cell-text-error" : "")}>{data[results.han.id]}</span>
                        </td>
                        <td className="print-table-bold" colSpan="4">{results.hanhuoratio.name + ":"}
                            <span className={"print-table-td-align-right" + (Number(data[[results.hanhuoratio.id]]<1.2) || Number(data[[results.hanhuoratio.id]]>2.2) ? " print-table-cell-text-error" : "")}>{data[results.hanhuoratio.id]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="8">{results.resultsRatio.name + ":"}
                            <span className="print-table-td-align-right">{data[results.resultsRatio.id]}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="8">{this.getResultMsg(data)}</td>
                    </tr>
                    <tr>
                        <td className="print-table-high" width="44%" colSpan="8">{strings.cure + ":"}</td>
                    </tr>
                    <tr>
                        <td className="print-table-bold" colSpan="8">{results.empty}
                            <img className="cure-image" src={"./assets/images/akabane/" + (data[ids.imageKey]!==undefined ? data[ids.imageKey].toLowerCase() : "") + imagesKey.fileExt} alt=""/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}


export default PrintView
