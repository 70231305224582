export const keys = {
    name: {value: 'name', label: 'jméno'},
    imgname: {value: 'imgname', label: 'jméno'},
    description: {value: 'description', label: 'popis'},
    header: {value: 'header', label: ''},
    pos: {value: 'pos', label: ''},
    id: {value: 'id', label: ''},
    orig: {value: 'orig', label: ''},
    points: {value: 'points', label: ''},
    short: {value: 'short', label: ''},
    img: {value: '.png', label: ''},
    imgPath: {value: './assets/images/earacu/', label: ''},
    pronuncination: {value: 'pronuncination', label: ''},
    translation: {value: 'translation', label: ''},
    time: {value: 'time', label: 'čas'},
    color: {value: 'color', label: 'barva', tooltip: 'barva dle prvku'},
    helixxue: {value: 'helixxue', label: 'body helixu', tooltip: 'body helixu'},
    antihelixxue: {value: 'antihelixxue', label: 'body antihelixu', tooltip: 'body antihelixu'},
    fossaxue: {value: 'fossaxue', label: 'body fossa scafoidea', tooltip: 'body fossa scafoidea'},
    fossatriangxue: {value: 'fossatriangxue', label: 'body fossa triangularis', tooltip: 'body fossa triangularis'},
    konchaxue: {value: 'konchaxue', label: 'body koncha', tooltip: 'body koncha'},
    tragxue: {value: 'tragxue', label: 'body tragu', tooltip: 'body tragu'},
    antitragxue: {value: 'antitragxue', label: 'body antitragu', tooltip: 'body antitragu'},
    acinusxue: {value: 'acinusxue', label: 'body lalůčku', tooltip: 'body lalůčku'},
    backxue: {value: 'backxue', label: 'zadní strana ucha', tooltip: 'zadní strana ucha'},
    alljing: {value: 'alljing', label: 'všechny dráhy'},
    allxue: {value: 'allxue', label: '', tooltip:''},
    jinglabels: {value: 'jinglabels', label: 'nadpisy drah', tooltip:'zobrazit nadpisy drah'},
    empty: {value: 'empty', label: ''},
    hide: {value: 'hide', label: ''},
    xues: {value: 'xues', label: ''},
    search: {value: 'search', label: 'Hledat'},
    searchText: {value: 'searchText', label: ''},
    checkSearchText: {value: 'checkSearchText', label: ''},
    current: {value: 'current', label: ''},
    localization: {value: 'localization', label: 'Lokalizace:'},
    usage: {value: 'usage', label: 'Použití:'},
    combination: {value: 'combination', label: 'Kombinace:'},
    function: {value: 'function', label: 'Funkce:'},
    category: {value: 'category', label: 'Kategorie:'},
    needling: {value: 'needling', label: 'Způsob napichování:'},
    note: {value: 'note', label: 'Poznámky:'},
    cards: {value: 'cards', label: 'zobrazit karty', tooltip:'zobrazit jako karty'},
    cookieaccept: {value: 'aku-accepts-cookies', label: ''}
};