import React, {Component} from 'react'
import ReactCardFlip from 'react-card-flip';
import {keys} from "../Constants"

class EarCard extends Component {
    constructor(props) {
        super(props);

        this.getItemInfoFromId = this.getItemInfoFromId.bind(this);
        this.linkFormatter = this.linkFormatter.bind(this);
        this.handleOnFlip = this.handleOnFlip.bind(this);

        this.getPointItemName = this.getPointItemName.bind(this);
        this.getPoint = this.getPoint.bind(this);
        this.getFrontItemSummary = this.getFrontItemSummary.bind(this);
        this.getBackItemSummary = this.getBackItemSummary.bind(this);

        this.isItem = this.isItem.bind(this);
        //this.isVisible = this.isVisible.bind(this);
        this.getItemClasses = this.getItemClasses.bind(this);

        this.state = {
            isFlipped: false
        };
    }

    handleOnFlip(event) {
        event.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    }

    getPoint(data, point) {
        let summary = "nejsou data";
        if (!data || data.toString().length === 0) {
            this.props.showSummaryInfo(summary);
            return;
        }

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(_point => {
            return (_point[keys.id.value] === pointId)
        });

        return foundItem[0];
    }

    getPointItemName(data, point) {
        if (!data || data.toString().length === 0) return "?";

        let pointId = data[point];
        if(pointId === undefined)
            pointId = point;
        if (data[pointId] !== undefined)
            pointId = data[pointId];
        let points = data[keys.xues.value];
        let foundItem = points.filter(point => {
            return (point[keys.id.value] === pointId)
        });
        return foundItem[0][keys.name.value];
    }

    getFrontItemSummary(areaData, pointData, currentItemId, filters) {
        if (pointData===undefined || Object.keys(pointData).length === 0)
            return <div className="acu-summary-heading">žádné info</div>;

        //let dt = new Date();
        let imgName =  keys.imgPath.value + pointData[keys.imgname.value].toLowerCase() + keys.img.value;//  + "?" + dt.getTime();
        return (
            <div>
                <div className="acu-card-summary-heading acu-card-center">{pointData[keys.name.value].toUpperCase()}</div>
                <div className="acu-card-summary-text acu-card-center">{"(" + pointData["translation"] + ")"}</div>
                <img className="acu-card-summary-image" src={imgName} alt={pointData[keys.name.value].toUpperCase()}/>
                <div className="acu-card-summary-heading card-margin">Lolizace:</div>
                <div className="acu-card-summary-item">{pointData["localization"]}</div>
            </div>);
    }

    getBackItemSummary(channelData, pointData, currentItemId, filters) {
        if (pointData===undefined || Object.keys(pointData).length === 0)
            return <div className="acu-summary-heading">žádné info</div>;

        let i = 0;
        const category = pointData[keys.category.value]===undefined ? <div/> : pointData[keys.category.value].map(item => {
            let desc = this.linkFormatter(item.description);
            return <div key={i++}>{desc}</div>
        });
        const functions = pointData[keys.function.value]===undefined ? <div/> : pointData[keys.function.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });
        const notes = pointData[keys.note.value]===undefined ? <div/> : pointData[keys.note.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });
        const usages = pointData[keys.usage.value]===undefined ? <div/> : pointData[keys.usage.value].map(item => {
            return <li className="acu-card-summary-item" key={i++}>{item.description}</li>;
        });

        return (
            <div>
                {/*<div className="card-summary-heading card-center">{pointData[keys.name.value].toUpperCase() + " - " + pointData[keys.orig.value].toUpperCase() + " (" + pointData[keys.pronuncination.value] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem1}>{stars}</div>*/}
                {/*<div className="card-summary-text card-center">{"(" + pointData["translation"] + ")"}</div>*/}
                {/*<div className={classNameSpecialItem2}>{stars}</div>*/}
                {/*<div className="card-margin"></div>*/}
                {category.length > 0 ? <div className="acu-card-summary-heading">{keys.category.label}</div> : <div />}
                {category}
                {functions.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.function.label}</div> : <div />}
                <ul className="acu-card-summary-list">
                {functions}
                </ul>
                {usages.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.usage.label}</div> : <div />}
                <ul className="acu-card-summary-list">
                    {usages}
                </ul>
                {notes.length > 0 ? <div className="acu-card-summary-heading card-margin">{keys.note.label}</div> : <div/>}
                <ul className="acu-card-summary-list">
                {notes}
                </ul>
            </div>);
    }

    linkFormatter(item) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <button className="infoButton normal-color" key={i} data-key={_items[1]} onClick={(event) => {
                        this.showInfoForm(event)
                    }}>{_items[0]}</button>
                );
            } else {
                return token + maybeSpace;
            }
        });

        return (
            <ul className="acu-card-summary-list">
            <li className="acu-card-summary-item normal-color">{contents}</li>
            </ul>
        );
    };

   getItemInfoFromId(id) {
        let combinationsData = this.props.combinationsData;
        if (combinationsData.length === 0) return "";
        let combination = combinationsData["combinations"].filter((item, i) => {
            return (item.id === id);
        });

        if(combination===undefined || combination.length===0) return "";
        return combination[0].combination;
    }

    isItem(channel, item, itemCompare) {
        if(itemCompare === item)
            return true;

        if(itemCompare!== undefined && itemCompare.indexOf(',') > 0) {
            let points = itemCompare.split(',');
            let foundItem = points.filter(id => {
                return (id === item);
            });

            return foundItem.length > 0;
        }

        let point = channel[itemCompare];
        return (point === item);
    }

    getItemClasses(channel, item, point, filters) {
        //color
        //let isColored = filters[keys.color.value];

        let colorItem = "";
        return colorItem;
    }

    render() {
        //let _currentPage = this.props.currentPage;
        let data = this.props.area[0];
        let currentItemId = this.props.currentItemId.trim();
        const point = this.getPoint(data, currentItemId);
        let itemFrontSummary = this.getFrontItemSummary(data, point, currentItemId, this.props.filters);
        let itemBackSummary = this.getBackItemSummary(data, point, currentItemId, this.props.filters);

        let classes = this.getItemClasses(data, currentItemId, point, this.props.filters);
        const frontClass = "acu-frontSide normal-color " + classes;
        const backClass = "acu-backSide normal-color " + classes;

        const mainClass = "acu-my-card";// + (this.isVisible(data, currentItemId, this.props.filters)===true ? "" : " card-hide");
        return (
            <div className={mainClass}>
                <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                >
                    <div className={frontClass} onClick={this.handleOnFlip}>
                        {itemFrontSummary}
                    </div>

                    <div className={backClass} onClick={this.handleOnFlip}>
                        {itemBackSummary}
                    </div>
                </ReactCardFlip>
            </div>
        )
    }
}

export default EarCard
