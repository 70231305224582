import config from './configuration.json';
import helix from './helix.json';
import fossascafoidea from './fossascafoidea.json';
import fossatriangularis from './fossatriangularis.json';
import antihelix from './antihelix.json';
import koncha from './koncha.json';
import tragus from './tragus.json';
import antitragus from './antitragus.json';
import acinus from './acinus.json';
import back from './back.json';

export const earacu_data = {
    helix: helix,
    fossascafoidea: fossascafoidea,
    antihelix: antihelix,
    fossatriangularis: fossatriangularis,
    koncha: koncha,
    tragus: tragus,
    antitragus: antitragus,
    acinus: acinus,
    back: back,
    configuration: config

};