import {CalendarChinese} from 'date-chinese'
import {data, ids, strings} from "../Configuration/Config";

const yearDivNumber = 60;
const ebNumber = 12;
const hsNumber = 10;
const dayStartsAt = 23;

export function getItem(arr, id) {
    let item;
    for (const [key, value] of Object.entries(arr)) {
        //console.log(key, value);
        if(value[ids.itemId] === id) {
            item = arr[key];
            break;
        }
    }

    return item;
}

function isOdd(num) { return num % 2;}

function calculateEarthTrigram(calcData, configData, itemYearHS, itemYearEB, itemMonthHS, itemMonthEB, itemDayHS, itemDayEB, itemHourHS, itemHourEB)
{
    const s_hs1 = itemYearHS[ids.yijingNum];
    const s_eb1 = itemYearEB[ids.yijingNum].split(ids.separator);
    const s_hs2 = itemMonthHS[ids.yijingNum];
    const s_eb2 = itemMonthEB[ids.yijingNum].split(ids.separator);
    const s_hs3 = itemDayHS[ids.yijingNum];
    const s_eb3 = itemDayEB[ids.yijingNum].split(ids.separator);
    const s_hs4 = itemHourHS[ids.yijingNum];
    const s_eb4 = itemHourEB[ids.yijingNum].split(ids.separator);

    const hs1 = Number(s_hs1);
    const eb1a = Number(s_eb1[0]);
    const eb1b = Number(s_eb1[1]);

    const hs2 = Number(s_hs2);
    const eb2a = Number(s_eb2[0]);
    const eb2b = Number(s_eb2[1]);

    const hs3 = Number(s_hs3);
    const eb3a = Number(s_eb3[0]);
    const eb3b = Number(s_eb3[1]);

    const hs4 = Number(s_hs4);
    const eb4a = Number(s_eb4[0]);
    const eb4b = Number(s_eb4[1]);

    let birthYear = Number(calcData[ids.year]);
    const trigramsArr = configData[ids.trigrams];

    let results = [];
    let result = 0;
    if(!isOdd(hs1)) result += hs1;
    if(!isOdd(eb1a)) result += eb1a;
    if(!isOdd(eb1b)) result += eb1b;
    if(!isOdd(hs2)) result += hs2;
    if(!isOdd(eb2a)) result += eb2a;
    if(!isOdd(eb2b)) result += eb2b;
    if(!isOdd(hs3)) result += hs3;
    if(!isOdd(eb3a)) result += eb3a;
    if(!isOdd(eb3b)) result += eb3b;
    if(!isOdd(hs4)) result += hs4;
    if(!isOdd(eb4a)) result += eb4a;
    if(!isOdd(eb4b)) result += eb4b;

    results[0] = result;

    const earthMiddle = Number(configData.config[ids.earth][ids.center]);
    if(result > earthMiddle)
        result -= earthMiddle;
    else if(result === earthMiddle)
        result = 5;

    let str = result.toString().split('');

    if(result === 10 || result === 20 || result < 10)
        result = Number(str[0]);
    else
        result = Number(str[1]);

    if(result === 5) {
        const gender = calcData[ids.gender];
        const isYang = itemYearHS[ids.isYang];

        if(birthYear < 1924) {
            if(gender===strings.man)
                result = 8;
            else
                result = 2;
        } else if(birthYear > 1983) {
            if(gender===strings.man)
                result = 9;
            else
                result = 7;
        } else if(birthYear > 1923 && birthYear < 2044) {
            if ((isYang && gender === strings.man) || (!isYang && gender === strings.woman))
                result = 8;
            else
                result = 2;
        }
    }

    let trigram;
    for (const [key, value] of Object.entries(trigramsArr)) {
        //console.log(key, value);
        if(Number(value[ids.trigramNum]) === result) {
            trigram = value;
            break;
        }
    }

    results[1] = trigram;
    results[2] = itemMonthEB[ids.earth];

    return results;
}

function calculateHeavenTrigram(calcData, configData, itemYearHS, itemYearEB, itemMonthHS, itemMonthEB, itemDayHS, itemDayEB, itemHourHS, itemHourEB)
{
    const s_hs1 = itemYearHS[ids.yijingNum];
    const s_eb1 = itemYearEB[ids.yijingNum].split(ids.separator);
    const s_hs2 = itemMonthHS[ids.yijingNum];
    const s_eb2 = itemMonthEB[ids.yijingNum].split(ids.separator);
    const s_hs3 = itemDayHS[ids.yijingNum];
    const s_eb3 = itemDayEB[ids.yijingNum].split(ids.separator);
    const s_hs4 = itemHourHS[ids.yijingNum];
    const s_eb4 = itemHourEB[ids.yijingNum].split(ids.separator);

    let birthYear = Number(calcData[ids.year]);
    const trigramsArr = configData[ids.trigrams];

    const hs1 = Number(s_hs1);
    const eb1a = Number(s_eb1[0]);
    const eb1b = Number(s_eb1[1]);

    const hs2 = Number(s_hs2);
    const eb2a = Number(s_eb2[0]);
    const eb2b = Number(s_eb2[1]);

    const hs3 = Number(s_hs3);
    const eb3a = Number(s_eb3[0]);
    const eb3b = Number(s_eb3[1]);

    const hs4 = Number(s_hs4);
    const eb4a = Number(s_eb4[0]);
    const eb4b = Number(s_eb4[1]);

    let results = [];
    let result = 0;
    if(isOdd(hs1)) result += hs1;
    if(isOdd(eb1a)) result += eb1a;
    if(isOdd(eb1b)) result += eb1b;
    if(isOdd(hs2)) result += hs2;
    if(isOdd(eb2a)) result += eb2a;
    if(isOdd(eb2b)) result += eb2b;
    if(isOdd(hs3)) result += hs3;
    if(isOdd(eb3a)) result += eb3a;
    if(isOdd(eb3b)) result += eb3b;
    if(isOdd(hs4)) result += hs4;
    if(isOdd(eb4a)) result += eb4a;
    if(isOdd(eb4b)) result += eb4b;

    results[0] = result;

    const heavenMiddle = configData.config[ids.heaven][ids.center];;
    if(result > heavenMiddle)
        result -= heavenMiddle;
    else if(result === heavenMiddle)
        result = 5;

    let str = result.toString().split('');

    if(result === 10 || result === 20 || result < 10)
        result = Number(str[0]);
    else
        result = Number(str[1]);

    if(result === 5) {
        const gender = calcData[ids.gender];
        const isYang = itemYearHS[ids.isYang];

        if(birthYear < 1924) {
            if(gender===strings.man)
                result = 8;
            else
                result = 2;
        } else if(birthYear > 1983) {
            if(gender===strings.man)
                result = 9;
            else
                result = 7;
        } else if(birthYear > 1923 && birthYear < 2044) {
            if ((isYang && gender === strings.man) || (!isYang && gender === strings.woman))
                result = 8;
            else
                result = 2;
        }
    }

    let trigram;
    for (const [key, value] of Object.entries(trigramsArr)) {
        //console.log(key, value);
        if(Number(value[ids.trigramNum]) === result) {
            trigram = value;
            break;
        }
    }

    results[1] = trigram;
    results[2] = itemMonthEB[ids.heaven];

    return results;
}

function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    //console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    //console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    //diffInMilliSeconds -= minutes * 60;
    //console.log('minutes', minutes);

    return days + ids.separator + hours + ids.separator + minutes;
}

function calculateTaiJi(hsYearItem, hsDayItem, configData) {
    let taiJi = configData.config[ids.taiJi];

    const taiJi1 = taiJi[hsYearItem].split(ids.separator);
    const taiJi2 = taiJi[hsDayItem].split(ids.separator);

    return taiJi1.concat(taiJi2);
}

function findTaiJi(arr, itemEB) {
    return arr.includes(itemEB);
}

function calculateWenChang(hsYearItem, hsDayItem, configData) {
    let wengChang = configData.config[ids.wenChang];

    const wengChang1 = wengChang[hsYearItem];
    const wengChang2 = wengChang[hsDayItem];

    let returnedArray = [];
    returnedArray.push(wengChang1);
    returnedArray.push(wengChang2);
    return returnedArray;
}

function findWenChang(arr, itemHS) {
    return arr.includes(itemHS);
}

function calculateTianYi(ebMonthItem, configData) {
    let tianYi = configData.config[ids.tianYi];

    const tianYi1 = tianYi[ebMonthItem];

    let returnedArray = [];
    returnedArray.push(tianYi1);
    return returnedArray;
}

function findTianYi(arr, itemEB) {
    return arr.includes(itemEB);
}

function calculateTianDe(ebMonthItem, configData) {
    let tianDe = configData.config[ids.tianDe];

    const tianDe1 = tianDe[ebMonthItem];

    let returnedArray = [];
    returnedArray.push(tianDe1);
    return returnedArray;
}

function findTianDe(arr, itemEB, itemHS) {
    let isEB = arr.includes(itemEB);
    let isHS = arr.includes(itemHS);

    let returnedArray = [];
    returnedArray.push(isEB);
    returnedArray.push(isHS);
    return returnedArray;
}

function calculateYiMa(ebYearItem, ebDayItem, configData) {
    let yiMa = configData.config[ids.yiMa];

    const yiMa1 = yiMa[ebYearItem];
    const yiMa2 = yiMa[ebDayItem];

    let returnedArray = [];
    returnedArray.push(yiMa1);
    returnedArray.push(yiMa2);
    return returnedArray;
}

function findYiMa(arr, eb) {
    return arr.includes(eb);
}

function calculateTaoHua(ebYearItem, ebDayItem, configData) {
    let taoHua = configData.config[ids.taoHua];

    const taoHua1 = taoHua[ebYearItem];
    const taoHua2 = taoHua[ebDayItem];

    let returnedArray = [];
    returnedArray.push(taoHua1);
    returnedArray.push(taoHua2);
    return returnedArray;
}

function findTaoHua(arr, eb) {
    return arr.includes(eb);
}

function calculateTianYiGuiRen(hsYearItem, hsDayItem, configData) {
    let tianYi = configData.config[ids.tianYiGuiRen];

    const ebYearItemsArr = tianYi[hsYearItem].split(ids.separator);
    const ebDayItemsArr = tianYi[hsDayItem].split(ids.separator);

    return ebYearItemsArr.concat(ebDayItemsArr);
}

function findTianYiGuiRen(arr, eb) {
    return arr.includes(eb);
}

function calculateFuYin(hs1, eb1, hs2, eb2) {
    return (hs1===hs2 && eb1===eb2);
}

function calculateFengKong(yearSCCPos, daySCCPos, configData) {
    let fengkong = configData.config[ids.fengKong];
    let yearFengKong, monthFengKong;
    if(yearSCCPos >= 1 && yearSCCPos <= 10) {
        yearFengKong = 1;
    } else if(yearSCCPos >= 11 && yearSCCPos <= 20) {
        yearFengKong = 11;
    } else if(yearSCCPos >= 21 && yearSCCPos <= 30) {
        yearFengKong = 21;
    } else if(yearSCCPos >= 31 && yearSCCPos <= 40) {
        yearFengKong = 31;
    } else if(yearSCCPos >= 41 && yearSCCPos <= 50) {
        yearFengKong = 41;
    } else if(yearSCCPos >= 51 && yearSCCPos <= 60) {
        yearFengKong = 51;
    } else {
        yearFengKong = 1;
    }
    if(daySCCPos >= 1 && daySCCPos <= 10) {
        monthFengKong = 1;
    } else if(daySCCPos >= 11 && daySCCPos <= 20) {
        monthFengKong = 11;
    } else if(daySCCPos >= 21 && daySCCPos <= 30) {
        monthFengKong = 21;
    } else if(daySCCPos >= 31 && daySCCPos <= 40) {
        monthFengKong = 31;
    } else if(daySCCPos >= 41 && daySCCPos <= 50) {
        monthFengKong = 41;
    } else if(daySCCPos >= 51 && daySCCPos <= 60) {
        monthFengKong = 51;
    } else {
        monthFengKong = 1;
    }

    let yearFengKongItem = fengkong[yearFengKong];
    let monthFengKongItem = fengkong[monthFengKong];

    let returnedArray = [];
    returnedArray.push(yearFengKongItem);
    returnedArray.push(monthFengKongItem);

    return returnedArray;
}

function calculateMingGong(hsYearItemPos, ebMonthItemPos, ebHourItemPos, calcData, configData) {
    let ebArr = configData.eb;
    let hsArr = configData.hs;

    //calculation data
    const birthYear = Number(calcData[ids.year]);
    const birthMonth = calcData[ids.month];
    const birthDay = Number(calcData[ids.day]);
    const birthHour = Number(calcData[ids.hour]);
    const birthMinute = Number(calcData[ids.minute]);
    const jieqiTable = configData[ids.jieqi];

    const jieqiItem = jieqiTable[birthYear][birthMonth];
    const jieqiMidMonthDayArr = jieqiItem[ids.mData].split(ids.separator);
    const jieqiStartMonthDayArr = jieqiItem[ids.sData].split(ids.separator);
    const jieqiStartMonthDay = jieqiStartMonthDayArr[0];
    const jieqiMidMonthDay = jieqiMidMonthDayArr[0];
    const jieqiMidMonthTimeArr = jieqiMidMonthDayArr[1].split(ids.timeSeparator);
    const jieqiMidMonthTimeHour = jieqiMidMonthTimeArr[0];
    const jieqiMidMonthTimeMinute = jieqiMidMonthTimeArr[1];

    //eb
    const bAfterMidPoint  = ((birthDay < Number(jieqiStartMonthDay)) || (birthDay > Number(jieqiMidMonthDay)) ||
        (birthDay === Number(jieqiMidMonthDay) && birthHour > Number(jieqiMidMonthTimeHour)) ||
        (birthDay === Number(jieqiMidMonthDay) && birthHour === Number(jieqiMidMonthTimeHour) && birthMinute > Number(jieqiMidMonthTimeMinute)));
    let ebMonthItemPosMG = 32 - (ebMonthItemPos + ebHourItemPos + (bAfterMidPoint ? 1 : 0));
    //if(ebMonthItemPosMG >= ebNumber) ebMonthItemPosMG = ebMonthItemPosMG - hsNumber;
    while(ebMonthItemPosMG > ebNumber)
        ebMonthItemPosMG -= ebNumber;
    let ebMonthItem = ebArr[ebMonthItemPosMG][ids.itemId];

    //hs
    let value = (ebMonthItemPosMG===ids.January || ebMonthItemPosMG===ids.February) ? 0 : 2;
    let hsMonthItemPosMG = (hsYearItemPos*2) + ebMonthItemPosMG - value;
    while(hsMonthItemPosMG > hsNumber)
        hsMonthItemPosMG -= hsNumber;
    //if(hsMonthItemPosMG > hsNumber) hsMonthItemPosMG = hsMonthItemPosMG - hsNumber;
    let hsMonthItem = hsArr[hsMonthItemPosMG][ids.itemId];

    let returnedArray = [];
    returnedArray.push(hsMonthItem);
    returnedArray.push(ebMonthItem);

    return returnedArray;
}

function calculateTaiYuan(hsMonthItemPos, ebMonthItemPos, configData) {
    let ebArr = configData.eb;
    let hsArr = configData.hs;

    //hs +1
    let hsMonthItemPosTY = hsMonthItemPos+1;
    if(hsMonthItemPosTY > hsNumber) hsMonthItemPosTY = 1;
    let hsMonthItem = hsArr[hsMonthItemPosTY][ids.itemId];
    //eb +3
    let ebMonthItemPosTY = ebMonthItemPos+3;
    if(ebMonthItemPosTY > ebNumber) ebMonthItemPosTY = ebMonthItemPosTY - ebNumber;
    let ebMonthItem = ebArr[ebMonthItemPosTY][ids.itemId];

    let returnedArray = [];
    returnedArray.push(hsMonthItem);
    returnedArray.push(ebMonthItem);

    return returnedArray;
}

function calculateLuckPillars(hsYearItemPos, hsMonthItemPos, ebMonthItemPos, calcData, configData) {
    // The gender of the person
    // The Yin or Yang of the Heavenly Stem of the year of birth
    // The Monthly Pillar of the Ba Zi of the person
    // The number of days to the preceding or following Hsia Season, from the date of birth
    const gender = calcData[ids.gender];

    let birthYear = Number(calcData[ids.year]);
    const birthMonth = calcData[ids.month];
    const birthDay = Number(calcData[ids.day]);
    const birthHour = Number(calcData[ids.hour]);
    const birthMinute = calcData[ids.minute];

    const jieqiTable = configData[ids.jieqi];
    const jieqiItem = jieqiTable[birthYear][birthMonth];
    const jieqiStartMonthDayArr = jieqiItem[ids.sData].split(ids.separator);
    const jieqiStartMonthDay = jieqiStartMonthDayArr[0];
    // const jieqiStartMonthTimeArr = jieqiStartMonthDayArr[1].split(ids.timeSeparator);
    // const jieqiStartMonthTimeHour = jieqiStartMonthTimeArr[0];
    // const jieqiStartMonthTimeMinute = jieqiStartMonthTimeArr[1];

    const monthArr = configData.months;
    let birthMonthEN = birthMonth;
    let monthPos = 1;
    for (const [key, value] of Object.entries(monthArr)) {
        //console.log(key, value);
        if(key===birthMonth) {
            birthMonthEN = value[ids.en];
            monthPos = Number(value[ids.itemId]);
            break;
        }
    }

    let timeDate1Str = birthMonthEN + " " + birthDay + ", " + birthYear + " " + birthHour + ":" + birthMinute + ":00"; //'August 19, 1975 23:15:30'
    let timeDate1 = new Date(timeDate1Str);

    let ebArr = configData.eb;
    let hsArr = configData.hs;
    let hsYearItem = hsArr[hsYearItemPos];
    const isYang = hsYearItem[ids.isYang];

    if((isYang && gender===strings.man) || (!isYang && gender===strings.woman)) { //increasing
        if(birthDay > Number(jieqiStartMonthDay)) {
            monthPos++;
            if(monthPos > ebNumber) {
                monthPos = 1; //december==1
            } else if(monthPos === 2) { //january==2
                birthYear += 1;
            }
        }
    } else {//decreasing
        if(birthDay < Number(jieqiStartMonthDay)) {
            monthPos--; // 1 == december
            if(monthPos <= 0) {
                monthPos = ebNumber;//november
            } else if(monthPos === 1) { //2 == january
                birthYear -= 1;
            }
        }
    }

    let newMonth = birthMonth;
    let newMonthEN = birthMonth;
    for (const [key, value] of Object.entries(monthArr)) {
        //console.log(key, value);
        if(Number(value[ids.itemId])===monthPos) {
            newMonth = key;
            newMonthEN = value[ids.en];
            break;
        }
    }

    const newJieqiItem = jieqiTable[birthYear][newMonth];
    const newJieqiStartMonthDayArr = newJieqiItem[ids.sData].split(ids.separator);
    const newJieqiStartMonthDay = newJieqiStartMonthDayArr[0];
    const newJieqiStartMonthTimeArr = newJieqiStartMonthDayArr[1].split(ids.timeSeparator);
    const newJieqiStartMonthTimeHour = newJieqiStartMonthTimeArr[0];
    const newJieqiStartMonthTimeMinute = newJieqiStartMonthTimeArr[1];

    let timeDate2Str = newMonthEN + " " + newJieqiStartMonthDay + ", " + birthYear + " " + newJieqiStartMonthTimeHour + ":" + newJieqiStartMonthTimeMinute + ":00"; //'August 19, 1975 23:15:30'
    let timeDate2 = new Date(timeDate2Str);
    let diffInTimeArr = timeDiffCalc(timeDate2, timeDate1).split(ids.separator);
    // 3 days/1 year - 4 months/1 day - 5 days/1 hour - 2 hours/1 minute
    let year = Math.floor(Number(diffInTimeArr[0])/3);
    let remDays = Number(diffInTimeArr[0]) % 3;
    let months = remDays * 4;
    let days = Number(diffInTimeArr[1]) * 5;
    let hours = Number(diffInTimeArr[2]) * 2;

    let addToYear = 0;
    let addToMonth = 0;
    let addToDay = 0;

    let dayunMinutes = birthMinute;
    let dayunHours = hours;
    while(dayunHours >= 24) {
        dayunHours -=24;
        addToDay++;
    }

    let dayunDays = days + addToDay;
    while(dayunDays >= 30) { //simplified to have 30 days for all month
        dayunDays -=30;
        addToMonth++;
    }

    let dayunMonth = months + addToMonth;
    while(dayunMonth > 12) {
        dayunMonth -=12;
        addToYear++;
    }

    let dayunYear = birthYear + year + addToYear;

    // dayunMonth--;
    // if(dayunMonth <= 0) {
    //     dayunMonth = ebNumber + dayunMonth;
    // }
    let i;
    let returnValue = [];
    returnValue.push(dayunYear);
    returnValue.push(dayunMonth);
    returnValue.push(dayunDays);
    let dayunTime = dayunHours.toString() + ":" + dayunMinutes.toString();
    returnValue.push(dayunTime);

    if((isYang && gender===strings.man) || (!isYang && gender===strings.woman)) { //increasing
        //hsMonthItemPos, ebMonthItemPos
        for(i = 0; i < 9; i++) {
            ebMonthItemPos++; hsMonthItemPos++;
            if(ebMonthItemPos > 12) ebMonthItemPos = 1;
            if(hsMonthItemPos > 10) hsMonthItemPos = 1;
            let ebMonthItem = ebArr[ebMonthItemPos][ids.itemId];
            let hsMonthItem = hsArr[hsMonthItemPos][ids.itemId];

            returnValue.push(dayunYear+(i*10));
            returnValue.push(ebMonthItem);
            returnValue.push(hsMonthItem);
        }
    } else {//decreasing
        for (i = 0; i < 9; i++) {
            ebMonthItemPos--;
            hsMonthItemPos--;
            if (ebMonthItemPos <= 0) ebMonthItemPos = ebNumber;
            if (hsMonthItemPos <= 0) hsMonthItemPos = hsNumber;
            let ebMonthItem = ebArr[ebMonthItemPos][ids.itemId];
            let hsMonthItem = hsArr[hsMonthItemPos][ids.itemId];

            returnValue.push(dayunYear+(i*10));
            returnValue.push(ebMonthItem);
            returnValue.push(hsMonthItem);
        }
    }

    return returnValue;
}

function hasDST(date = new Date()) {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(january, july) !== date.getTimezoneOffset();
}

function calculateAnnualPillars(yearPos, configData) {
    const actualDate = new Date();
    let year = actualDate.getFullYear();
    if(yearPos===-1) {
        year--;
    } else if(yearPos===1) {
        year++;
    } else if(yearPos===2) {
        year++;
        year++;
    }

    let remainder = (year % yearDivNumber) - 3;
    if(remainder === 0 ) remainder = yearDivNumber;
    if(remainder < 0 ) remainder = yearDivNumber+remainder;

    const YP = configData[ids.scChart][remainder];
    let itemYearEBId = YP[ids.eb];
    let itemYearHSId = YP[ids.hs];

    let returnedArray = [];
    returnedArray.push(itemYearEBId);
    returnedArray.push(itemYearHSId);
    returnedArray.push(year);

    return returnedArray;
}

export function calculateTongShuAlmanach(calcData, configData) {
    let results = [];
    if(!calcData || ! configData)
        return results;

    if(!calcData.hasOwnProperty(ids.year) || !calcData.hasOwnProperty(ids.month))
        return results;

    //calculation data
    let year = Number(calcData[ids.year]);
    let month = calcData[ids.month];
    let itemBirthYearEBId = calcData[ids.birthYearTongShu];
    let itemBirthMonthEBId = calcData[ids.birthMonthTongShu];

    //months
    let monthsArr = configData.months;
    let monthStr = "";
    for (const [key, value] of Object.entries(monthsArr)) {
        //console.log(key, value);
        if(Number(value[ids.pos])===month) {
            monthStr = key;
            break;
        }
    }

    //recalculate birth hour based on time transition
    let ebMonthItemPos = Number(monthsArr[monthStr][ids.itemId]);

    // const chineseYear = new CalendarChinese();
    // const newYear = chineseYear.newYear(year);
    // chineseYear.fromJDE(newYear);
    // let gDate = chineseYear.toGregorian();
    // let nDate = new Date(year, 1, 4, 6, 0, 0, 0);
    // //let date = chineseYear.toDate();

    //eb
    let ebArr = configData.eb;

    //hs
    let hsArr = configData.hs;

    const jieqiTable = configData[ids.jieqi];
    const jieqiItem = jieqiTable[year][monthStr];
    const jieqiStartMonthDayArr = jieqiItem[ids.sData].split(ids.separator);
    const jieqiStartMonthDay = jieqiStartMonthDayArr[0];
    const jieqiStartMonthTimeArr = jieqiStartMonthDayArr[1].split(ids.timeSeparator);
    //const jieqiStartMonthTimeHour = jieqiStartMonthTimeArr[0];
    //const jieqiStartMonthTimeMinute = jieqiStartMonthTimeArr[1];

    ////////////////////////////////////////////
    //////////////year pillar///////////////////
    ////////////////////////////////////////////
    // let monthPos = ebMonthItemPos-1;
    // if(monthPos <= 0)
    //     monthPos = ebNumber;
    // if((monthPos<gDate.month) || (monthPos===gDate.month && birthDay<gDate.day))
    //     year--;
    let remainder = (year % yearDivNumber) - 3;
    if(remainder === 0 ) remainder = yearDivNumber;
    if(remainder < 0 ) remainder = yearDivNumber+remainder;

    const YP = configData[ids.scChart][remainder];
    let itemYearEBId = YP[ids.eb];
    let itemYearHSId = YP[ids.hs];

    ////////////////////////////////////////////
    ///////////month pillar/////////////////////
    ////////////////////////////////////////////
    //eb
    const itemMonthEBId = ebArr[ebMonthItemPos][ids.itemId];

    //hs
    let hsYearItemPos = 1;
    for (const [key1, value1] of Object.entries(hsArr)) {
        //console.log(key, value);
        if(value1[ids.itemId]===itemYearHSId) {
            hsYearItemPos = Number(key1);
            break;
        }
    }

    let hsMonthItemPos = (hsYearItemPos * 2) + ebMonthItemPos - (ebMonthItemPos>=ids.February ? 2 : 0);
    while(hsMonthItemPos > hsNumber)
        hsMonthItemPos -= hsNumber;
    //const itemMonthHSId = hsArr[hsMonthItemPos][ids.itemId];

    let birthYearItemEB = {};
    let birthMonthItemEB = {};
    for (const [key, value] of Object.entries(ebArr)) {
        //console.log(key, value);
        if(value[ids.itemId]===itemBirthYearEBId) {
            birthYearItemEB = value;
        }

        if(value[ids.itemId]===itemBirthMonthEBId) {
            birthMonthItemEB = value;
        }
    }

    let killersYear = birthYearItemEB[ids.killers].split(ids.separator);
    let killersMonth = birthMonthItemEB[ids.killers].split(ids.separator);

    //////////////////////////////////////////////
    ////////////day pillar////////////////////////
    //////////////////////////////////////////////
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
    for( let day = 1; day <= daysInMonth; day++) {
        const bm = Number(monthsArr[monthStr][ids.bigMonth]);
        const lValue = Number(monthsArr[monthStr][ids.lValue]);
        let _year = Number(calcData[ids.year].toString().slice(-2));
        _year = year > 1999 ? _year + 100 : _year;
        _year = (ebMonthItemPos === ids.January || ebMonthItemPos === ids.February) ? _year - 1 : _year;
        let remainder2 = ((_year * 5) + Math.floor(_year / 4) + 9 + day + bm + lValue) % yearDivNumber;
        if (remainder2 === 0) remainder2 = yearDivNumber;

        const master = configData[ids.scChart][remainder2];
        let dm = {};
        dm[ids.hs] = master[ids.hs];
        dm[ids.eb] = master[ids.eb];
        dm[ids.description] = master[ids.description];

        //day of the year collision
        let yearCollision = false;
        for (const [key, value] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value[ids.itemId]===dm[ids.eb]) {
                if(itemYearEBId===value[ids.destruction]) {
                    yearCollision = true;
                }
                break;
            }
        }

        //days of three killers - year
        let killersYear1 = false;
        let killersYear2 = false;
        let killersYear3 = false;
        for (const [key, value] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value[ids.itemId]===dm[ids.eb]) {
                const pos = killersYear.indexOf(dm[ids.eb]);
                if(pos===0) {
                    killersYear1 = true;
                } else if(pos ===1) {
                    killersYear2 = true;
                } else if(pos===2) {
                    killersYear3 = true;
                } else {
                    continue;
                }
                break;
            }
        }

        //days of three killers - year
        let killersMonth1 = false;
        let killersMonth2 = false;
        let killersMonth3 = false;
        for (const [key, value] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value[ids.itemId]===dm[ids.eb]) {
                const pos = killersMonth.indexOf(dm[ids.eb]);
                if(pos===0) {
                    killersMonth1 = true;
                } else if(pos ===1) {
                    killersMonth2 = true;
                } else if(pos===2) {
                    killersMonth3 = true;
                } else {
                    continue;
                }
                break;
            }
        }

        //day of the month collision
        let _ebMonthItemPos = ebMonthItemPos;
        if(day < Number(jieqiStartMonthDay)) {
            _ebMonthItemPos--;
        }
        if(_ebMonthItemPos <= 0)
            _ebMonthItemPos = ebNumber + _ebMonthItemPos;
        //eb
        const _itemMonthEBId = ebArr[_ebMonthItemPos][ids.itemId];
        let _itemMonthEB = {};
        let monthCollision = false;
        for (const [key, value] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value[ids.itemId]===dm[ids.eb]) {
                if(_itemMonthEBId===value[ids.destruction]) {
                    monthCollision = true;
                }
                break;
            }
        }

        //day of the personal collision
        let dayCollision = false;
        for (const [key, value] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value[ids.itemId]===dm[ids.eb]) {
                if(itemBirthYearEBId===value[ids.destruction]) {
                    dayCollision = true;
                }
                break;
            }
        }

        //12 officers
        let establishDay = false;
        let destructionDay = false;
        let removeDay = false;
        let fullDay = false;
        let balanceDay = false;
        let stableDay = false;
        let initiateDay = false;
        let dangerDay = false;
        let successDay = false;
        let receiveDay = false;
        let openDay = false;
        let closeDay = false;

        for (const [key, value12] of Object.entries(ebArr)) {
            //console.log(key, value);
            if(value12[ids.itemId]===_itemMonthEBId) {
                _itemMonthEB = value12[ids.itemId];
                if(dm[ids.eb]===value12[ids.establish]) {
                    establishDay = true;
                }
                if(dm[ids.eb]===value12[ids.remove]) {
                    removeDay = true;
                }
                if(dm[ids.eb]===value12[ids.full]) {
                    fullDay = true;
                }
                if(dm[ids.eb]===value12[ids.balance]) {
                    balanceDay = true;
                }
                if(dm[ids.eb]===value12[ids.stable]) {
                    stableDay = true;
                }
                if(dm[ids.eb]===value12[ids.initiate]) {
                    initiateDay = true;
                }
                if(dm[ids.eb]===value12[ids.destruction]) {
                    destructionDay = true;
                }
                if(dm[ids.eb]===value12[ids.danger]) {
                    dangerDay = true;
                }
                if(dm[ids.eb]===value12[ids.success]) {
                    successDay = true;
                }
                if(dm[ids.eb]===value12[ids.receive]) {
                    receiveDay = true;
                }
                if(dm[ids.eb]===value12[ids.open]) {
                    openDay = true;
                }
                if(dm[ids.eb]===value12[ids.close]) {
                    closeDay = true;
                }
                break;
            }
        }

        dm[ids.month] = _itemMonthEB;
        dm[ids.dayCollision] = dayCollision;
        dm[ids.yearCollision] = yearCollision;
        dm[ids.monthCollision] = monthCollision;
        dm[ids.killersYear1] = killersYear1;
        dm[ids.killersYear2] = killersYear2;
        dm[ids.killersYear3] = killersYear3;
        dm[ids.killersMonth1] = killersMonth1;
        dm[ids.killersMonth2] = killersMonth2;
        dm[ids.killersMonth3] = killersMonth3;
        dm[ids.establish] = establishDay;
        dm[ids.remove] = removeDay;
        dm[ids.full] = fullDay;
        dm[ids.balance] = balanceDay;
        dm[ids.stable] = stableDay;
        dm[ids.initiate] = initiateDay;
        dm[ids.destruction] = destructionDay;
        dm[ids.danger] = dangerDay;
        dm[ids.success] = successDay;
        dm[ids.receive] = receiveDay;
        dm[ids.open] = openDay;
        dm[ids.close] = closeDay;
        results.push(dm);
    }

    return results;
}


function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
}
////////////////////////////////////////////////////////////////////////////
///////////////main calculation/////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
export function calculateBazi(calcData, configData) {
    let results = [];
    if(!calcData || ! configData)
        return results;

    if(!calcData[ids.year] || !calcData[ids.month] || !calcData[ids.day])
        return results;

    //calculation data
    let birthYear = Number(calcData[ids.year]);
    let birthMonth = calcData[ids.month]; //string
    let birthDay = Number(calcData[ids.day]);
    let birthHour = Number(calcData[ids.hour]);
    let birthMinute = Number(calcData[ids.minute]);

    //months
    const months = configData[ids.months];
    let month = months[birthMonth];

    let monthsArr = configData.months;

    //recalculate birth hour based on time transition
    let ebMonthItemPos = Number(monthsArr[birthMonth][ids.itemId]);
    let birthMonthNum = 0;
    for (const [key, value] of Object.entries(monthsArr)) {
        //console.log(key, value);
        if(Number(value[ids.itemId])===ebMonthItemPos) {
            birthMonthNum = value[ids.itemId]-1;
            break;
        }
    }
    const isDST = hasDST( new Date(birthYear, birthMonthNum, birthDay));
    if(isDST) {
        birthHour -= 1;
    }

    //town
    const townId = calcData[ids.town];
    const towns = configData[ids.towns];

    const town = towns[townId];
    const timeDiffArr = town[ids.timediff].split(ids.timeSeparator);
    const timeTownMinute = Number(timeDiffArr[1]);
    let dayWasShifted = false;
    if(timeDiffArr[0] > 0) {//then plus
        birthMinute += timeTownMinute;
        if(birthMinute >= 60 ) {
            birthMinute -= 60;
            birthHour += 1;

            if(birthHour > dayStartsAt) {
                birthDay += 1;
                birthHour -= 1;

                if(birthDay > Number(month[ids.days])) {
                    let mID = Number(month[ids.itemId]);
                    mID += 1;
                    birthDay -= Number(month[ids.days]);

                    if(mID > ebNumber) {
                        mID -= ebNumber;
                        birthYear += 1;
                    }

                    for (const [key, value] of Object.entries(monthsArr)) {
                        //console.log(key, value);
                        if(Number(value[ids.itemId])===mID) {
                            birthMonth = key;
                            dayWasShifted = true;
                            break;
                        }
                    }
                }
            }
        }
    } else { //then minus time
        const x = 60 - timeTownMinute;
        birthMinute -= x;
        if(birthMinute < 0 ) {
            birthMinute += 60;
            birthHour -= 1;
            if(birthHour < 0)
                birthHour = dayStartsAt;

            // if(birthHour === dayStartsAt-1) {
            //     birthDay -= 1;
            //
            //     if(birthDay <= 0) {
            //         let monthID = Number(month[ids.itemId]);
            //         monthID -= 1;
            //         if(monthID < 1) {
            //             monthID = ebNumber;
            //             birthYear -= 1;
            //         }
            //
            //         for (const [key, value] of Object.entries(monthsArr)) {
            //             //console.log(key, value);
            //             if(Number(value[ids.itemId])===monthID) {
            //                 birthMonth = key;
            //                 dayWasShifted = true;
            //                 break;
            //             }
            //         }
            //
            //         month = months[birthMonth];
            //         birthDay = Number(month[ids.days]);
            //     }
            // }
        }
    }

    //check for hour, if > 23 new day started
    if(birthHour === dayStartsAt && birthMinute >= 0 && !dayWasShifted) {
        birthDay += 1;
        if(birthDay > Number(month[ids.days])) {
            let mID = Number(month[ids.itemId]);
            mID += 1;
            birthDay -= Number(month[ids.days]);

            if (mID > ebNumber) {
                mID -= ebNumber;
                birthYear += 1;
            }

            for (const [key, value] of Object.entries(monthsArr)) {
                //console.log(key, value);
                if (Number(value[ids.itemId]) === mID) {
                    birthMonth = key;
                    break;
                }
            }
        }
    }

    //reassing back
    let _calcData = {

    };
    _calcData[ids.gender] = calcData[ids.gender];
    _calcData[ids.year] = birthYear.toString();
    _calcData[ids.month] = birthMonth.toString();
    _calcData[ids.day] = birthDay.toString();
    _calcData[ids.hour] = birthHour.toString();
    _calcData[ids.minute] = birthMinute.toString() ;

    //reassign
    ebMonthItemPos = Number(monthsArr[birthMonth][ids.itemId]);

    const chineseYear = new CalendarChinese();
    const newYear = chineseYear.newYear(birthYear);
    chineseYear.fromJDE(newYear);
    let gDate = chineseYear.toGregorian();

    //eb
    let ebArr = configData.eb;

    //hs
    let hsArr = configData.hs;

    const jieqiTable = configData[ids.jieqi];
    const jieqiItem = jieqiTable[birthYear][birthMonth];
    const jieqiStartMonthDayArr = jieqiItem[ids.sData].split(ids.separator);
    let jieqiStartMonthDay = jieqiStartMonthDayArr[0];
    const jieqiStartMonthTimeArr = jieqiStartMonthDayArr[1].split(ids.timeSeparator);
    let jieqiStartMonthTimeHour = jieqiStartMonthTimeArr[0];
    let jieqiStartMonthTimeMinute = jieqiStartMonthTimeArr[1];

    let monthPos = ebMonthItemPos-1;
    if(monthPos <= 0) monthPos = ebNumber;
    //the time is in Pekings time so we need to translate it
    const date1 = changeTimeZone(new Date(birthYear, Number(jieqiStartMonthDay), monthPos, Number(jieqiStartMonthTimeHour), Number(jieqiStartMonthTimeMinute), 0), 'Asia/Shanghai');
    const date2 = changeTimeZone(new Date(birthYear, Number(jieqiStartMonthDay), monthPos, Number(jieqiStartMonthTimeHour), Number(jieqiStartMonthTimeMinute), 0), 'Europe/Prague');
    const offset = Math.abs(date1.getTime() - date2.getTime()) / 3600000;

    let dateLocal = new Date(birthYear, Number(jieqiStartMonthDay), monthPos, Number(jieqiStartMonthTimeHour), Number(jieqiStartMonthTimeMinute), 0);
    dateLocal.setTime( dateLocal.getTime() - offset * 60 * 60 * 1000 );

    jieqiStartMonthDay = dateLocal.getDate();
    jieqiStartMonthTimeHour = dateLocal.getHours();
    jieqiStartMonthTimeMinute = dateLocal.getMinutes();
    ///////////////////////////////////////////////////////////////////////////////////////////
    if(birthDay < Number(jieqiStartMonthDay) ||
        (birthDay === Number(jieqiStartMonthDay) && birthHour < Number(jieqiStartMonthTimeHour)) ||
        (birthDay === Number(jieqiStartMonthDay) && birthHour === Number(jieqiStartMonthTimeHour) && birthMinute < Number(jieqiStartMonthTimeMinute))) {
        ebMonthItemPos--;
    }
    if(ebMonthItemPos <= 0) ebMonthItemPos = ebNumber + ebMonthItemPos;

    ////////////////////////////////////////////
    //////////////year pillar///////////////////
    ////////////////////////////////////////////
    const bIsLunar = false;
    //lunar
    if(bIsLunar) {
        if ((monthPos < gDate.month) || (monthPos === gDate.month && birthDay < gDate.day))
            birthYear--;
    } else {
        //solar
        const solarMonths = configData.date.months;
        const solarMonthsArr = solarMonths.split(ids.separator);
        const jieqiSolarItem = jieqiTable[birthYear][solarMonthsArr[1]]; //Februar
        const jieqiSolarStartMonthDayArr = jieqiSolarItem[ids.sData].split(ids.separator);
        const jieqiSolarStartMonthDay = Number(jieqiSolarStartMonthDayArr[0]);

        if ((monthPos < 1) || (monthPos === 1 && birthDay < jieqiSolarStartMonthDay))
            birthYear--;
    }
    //////////////////////////////////////////////////////////////////////
    let remainder = (birthYear % yearDivNumber) - 3;
    if(remainder === 0 ) remainder = yearDivNumber;
    if(remainder < 0 ) remainder = yearDivNumber+remainder;

    const YP = configData[ids.scChart][remainder];
    let itemYearEBId = YP[ids.eb];
    let itemYearHSId = YP[ids.hs];
    ////////////////////////////////////////////
    ///////////month pillar/////////////////////
    ////////////////////////////////////////////
    //eb
    const itemMonthEBId = ebArr[ebMonthItemPos][ids.itemId];

    //hs
    let hsYearItemPos = 1;
    for (const [key, value] of Object.entries(hsArr)) {
        //console.log(key, value);
        if(value[ids.itemId]===itemYearHSId) {
            hsYearItemPos = Number(key);
            break;
        }
    }

    let hsMonthItemPos = (hsYearItemPos * 2) + ebMonthItemPos - (ebMonthItemPos>=ids.February ? 2 : 0);
    while(hsMonthItemPos > hsNumber)
        hsMonthItemPos -= hsNumber;
    const itemMonthHSId = hsArr[hsMonthItemPos][ids.itemId];

    //////////////////////////////////////////////
    ////////////day pillar////////////////////////
    //////////////////////////////////////////////
    const bm = Number(monthsArr[birthMonth][ids.bigMonth]);
    const lValue = Number(monthsArr[birthMonth][ids.lValue]);
    let _birthYear = Number(calcData[ids.year].toString().slice(-2));
    _birthYear = birthYear > 1999 ? _birthYear + 100 : _birthYear;
    _birthYear = (ebMonthItemPos===ids.January || ebMonthItemPos===ids.February) ? _birthYear - 1 : _birthYear;
    let remainder2 = ((_birthYear*5) + Math.floor(_birthYear/4) + 9 + birthDay + bm + lValue) % yearDivNumber;
    if(remainder2 === 0) remainder2 = yearDivNumber;

    const DP = configData[ids.scChart][remainder2];
    const itemDayEBId = DP[ids.eb];
    const itemDayHSId = DP[ids.hs];

    ////////////////////////////////////////////////
    ///////////////hour pillar//////////////////////
    ////////////////////////////////////////////////
    let itemHourHSId = ids.hsEmptyId;
    let itemHourEBId = ids.ebEmptyId;
    const isNotKnownHour = calcData[ids.unknown] && calcData[ids.unknown]==="1" ? true : false;
    let ebHourItemPos = 1;
    if(!isNotKnownHour) {
        const timeArr = configData.hours;
        for (const [key, value] of Object.entries(timeArr)) {
            //console.log(key, value);
            let min = value[ids.min];
            let max = value[ids.max];
            if(birthHour >= min && birthHour < max) {
                ebHourItemPos = Number(key);
                break;
            }
        }

        itemHourEBId = ebArr[ebHourItemPos][ids.itemId];

        let hsDayItemPos = 1;
        for (const [key, value] of Object.entries(hsArr)) {
            //console.log(key, value);
            if(value[ids.itemId] === itemDayHSId) {
                hsDayItemPos = Number(key);
                break;
            }
        }

        let itemHourHSNumber = ((hsDayItemPos * 2) + ebHourItemPos - 2);
        while(itemHourHSNumber > hsNumber)
            itemHourHSNumber -= hsNumber;
        itemHourHSId = hsArr[itemHourHSNumber][ids.itemId];
    }

    /////////////////////////
    ////annunal pillars//////
    /////////////////////////
    let retValues = calculateAnnualPillars(-1, configData);
    let ebYear1Item = retValues[0];
    let hsYear1Item = retValues[1];
    let hsYear1ItemTitle = retValues[2];
    retValues = calculateAnnualPillars(0, configData);
    let ebYear2Item = retValues[0];
    let hsYear2Item = retValues[1];
    let hsYear2ItemTitle = retValues[2];
    retValues = calculateAnnualPillars(1, configData);
    let ebYear3Item = retValues[0];
    let hsYear3Item = retValues[1];
    let hsYear3ItemTitle = retValues[2];
    retValues = calculateAnnualPillars(2, configData);
    let ebYear4Item = retValues[0];
    let hsYear4Item = retValues[1];
    let hsYear4ItemTitle = retValues[2];

    ///////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////lucky pillars////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////
    const now = new Date();
    const nowYear = now.getFullYear();
    const age = nowYear - birthYear;

    let activeLuckyPillar;
    let luckyPillars = calculateLuckPillars(hsYearItemPos, hsMonthItemPos, ebMonthItemPos, _calcData, configData);
    let j = 0;
    //1 start year, 2 start month, 3 start day
    let luckyPillarsStartYearItem = luckyPillars[j++];
    let luckyPillarsStartMonthItem = luckyPillars[j++];
    let luckyPillarsStartDayItem = luckyPillars[j++];
    let luckyPillarsStartTimeItem = luckyPillars[j++];

    //luck pillar age starts at 9 Years 9 Months
    const difluckyAge = luckyPillarsStartYearItem - Number(calcData[ids.year]);
    let luckyDesc = difluckyAge === 1 ? strings.luckyPilarsStartDesc1 : strings.luckyPilarsStartDesc2;
    let luckyPillarsStartItem = luckyDesc.replace("%1", difluckyAge).replace("%2",luckyPillarsStartMonthItem).replace("%3", luckyPillarsStartDayItem).replace("%4", luckyPillarsStartTimeItem);

    let luckyPillarsYear1Item = luckyPillars[j++];
    if(age >= luckyPillarsYear1Item-birthYear)
        activeLuckyPillar = ids.luckyPillar1;
    let hsluckyPillarsYear1ItemTitle = luckyPillarsYear1Item + "  " + difluckyAge;
    let ebluckyPillarsYear1Item = luckyPillars[j++];
    let hsluckyPillarsYear1Item = luckyPillars[j++];
    //
    let luckyPillarsYear2Item = luckyPillars[j++];
    if(age >= luckyPillarsYear2Item-birthYear)
        activeLuckyPillar = ids.luckyPillar2;
    let hsluckyPillarsYear2ItemTitle = luckyPillarsYear2Item + "  " + (luckyPillarsYear2Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear2Item = luckyPillars[j++];
    let hsluckyPillarsYear2Item = luckyPillars[j++];
    //
    let luckyPillarsYear3Item = luckyPillars[j++];
    if(age >= luckyPillarsYear3Item-birthYear)
        activeLuckyPillar = ids.luckyPillar3;
    let hsluckyPillarsYear3ItemTitle = luckyPillarsYear3Item + "  " + (luckyPillarsYear3Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear3Item = luckyPillars[j++];
    let hsluckyPillarsYear3Item = luckyPillars[j++];
    //
    let luckyPillarsYear4Item = luckyPillars[j++];
    if(age >= luckyPillarsYear4Item-birthYear)
        activeLuckyPillar = ids.luckyPillar4;
    let hsluckyPillarsYear4ItemTitle = luckyPillarsYear4Item + "  " + (luckyPillarsYear4Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear4Item = luckyPillars[j++];
    let hsluckyPillarsYear4Item = luckyPillars[j++];
    //
    let luckyPillarsYear5Item = luckyPillars[j++];
    if(age >= luckyPillarsYear5Item-birthYear)
        activeLuckyPillar = ids.luckyPillar5;
    let hsluckyPillarsYear5ItemTitle = luckyPillarsYear5Item + "  " + (luckyPillarsYear5Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear5Item = luckyPillars[j++];
    let hsluckyPillarsYear5Item = luckyPillars[j++];
    //
    let luckyPillarsYear6Item = luckyPillars[j++];
    if(age >= luckyPillarsYear6Item-birthYear)
        activeLuckyPillar = ids.luckyPillar6;
    let hsluckyPillarsYear6ItemTitle = luckyPillarsYear6Item + "  " + (luckyPillarsYear6Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear6Item = luckyPillars[j++];
    let hsluckyPillarsYear6Item = luckyPillars[j++];
    //
    let luckyPillarsYear7Item = luckyPillars[j++];
    if(age >= luckyPillarsYear7Item-birthYear)
        activeLuckyPillar = ids.luckyPillar7;
    let hsluckyPillarsYear7ItemTitle = luckyPillarsYear7Item + "  " + (luckyPillarsYear7Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear7Item = luckyPillars[j++];
    let hsluckyPillarsYear7Item = luckyPillars[j++];
    //
    let luckyPillarsYear8Item = luckyPillars[j++];
    if(age >= luckyPillarsYear8Item-birthYear)
        activeLuckyPillar = ids.luckyPillar8;
    let hsluckyPillarsYear8ItemTitle = luckyPillarsYear8Item + "  " + (luckyPillarsYear8Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear8Item = luckyPillars[j++];
    let hsluckyPillarsYear8Item = luckyPillars[j++];
    //
    let luckyPillarsYear9Item = luckyPillars[j++];
    if(age >= luckyPillarsYear9Item-birthYear)
        activeLuckyPillar = ids.luckyPillar9;
    let hsluckyPillarsYear9ItemTitle = luckyPillarsYear9Item + "  " + (luckyPillarsYear9Item-luckyPillarsYear1Item+difluckyAge);
    let ebluckyPillarsYear9Item = luckyPillars[j++];
    let hsluckyPillarsYear9Item = luckyPillars[j];

    ///////////////////////////////////////////////////////////
    //combination
    //////////////////////////////////////////////////////////
    let itemYearEB = getItem(ebArr, itemYearEBId);
    let itemYear1EB = getItem(ebArr, ebYear1Item);
    let itemYear2EB = getItem(ebArr, ebYear2Item);
    let itemYear3EB = getItem(ebArr, ebYear3Item);
    let itemYear4EB = getItem(ebArr, ebYear4Item);
    let itemMonthEB = getItem(ebArr, itemMonthEBId);
    let itemDayEB = getItem(ebArr, itemDayEBId);
    let itemHourEB = getItem(ebArr, itemHourEBId);

    const cYID = itemYearEB[ids.itemId];
    const cMID = itemMonthEB[ids.itemId];
    const cDID = itemDayEB[ids.itemId];
    const cHID = itemHourEB[ids.itemId];

    const cY1ID = itemYear1EB[ids.itemId];
    const cY2ID = itemYear2EB[ids.itemId];
    const cY3ID = itemYear3EB[ids.itemId];
    const cY4ID = itemYear4EB[ids.itemId];
    //heaven combination
    let isHeavenCombination = false;
    let heavenCombinationElement = "";
    let itemDayEBColor, itemYearEBColor, itemMonthEBColor, itemHourEBColor;
    let ebYear1ItemColor, ebYear2ItemColor, ebYear3ItemColor, ebYear4ItemColor;
    if(calcData[ids.heavenCombination]==="1") {
        const cY = itemYearEB[ids.heavenCombination];
        const cM = itemMonthEB[ids.heavenCombination];
        const cD = itemDayEB[ids.heavenCombination];
        const cH = itemHourEB[ids.heavenCombination];

        const cY1 = itemYear1EB[ids.heavenCombination];
        const cY2 = itemYear2EB[ids.heavenCombination];
        const cY3 = itemYear3EB[ids.heavenCombination];
        const cY4 = itemYear4EB[ids.heavenCombination];
        if(cY===cM && cY===cD && cM===cD && cYID!==cMID && cYID!==cDID && cMID!==cDID) {
            itemYearEBColor = configData.config[ids.heavenCombination][cY];
            itemMonthEBColor = configData.config[ids.heavenCombination][cM];
            itemDayEBColor = configData.config[ids.heavenCombination][cD];
            heavenCombinationElement = configData.config[ids.heavenCombination][cY];
            isHeavenCombination = true;
        } else if(cM===cD && cM===cH && cD===cH && cMID!==cDID && cMID!==cHID && cDID!==cHID) {
            itemMonthEBColor = configData.config[ids.heavenCombination][cM];
            itemDayEBColor = configData.config[ids.heavenCombination][cD];
            itemHourEBColor = configData.config[ids.heavenCombination][cH];
            heavenCombinationElement = configData.config[ids.heavenCombination][cM];
            isHeavenCombination = true;
        } else {
            if(cY===cM && cY===cY1 && cYID!==cMID && cYID!==cY1ID && cMID!==cY1ID) {
                itemYearEBColor = configData.config[ids.heavenCombination][cY];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear1ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cY];
                isHeavenCombination = true;
            } else if(cY===cM && cY===cY2&& cYID!==cMID && cYID!==cY2ID && cMID!==cY2ID) {
                itemYearEBColor = configData.config[ids.heavenCombination][cY];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear2ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cY];
                isHeavenCombination = true;
            } else if(cY===cM && cY===cY3 && cYID!==cMID && cYID!==cY3ID && cMID!==cY3ID) {
                itemYearEBColor = configData.config[ids.heavenCombination][cY];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear3ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cY];
                isHeavenCombination = true;
            } else if(cY===cM && cY===cY4 && cYID!==cMID && cYID!==cY4ID && cMID!==cY4ID) {
                itemYearEBColor = configData.config[ids.heavenCombination][cY];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear4ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cY];
                isHeavenCombination = true;
            }
            if(cM===cD && cM===cY1 && cMID!==cDID && cMID!==cY1ID && cDID!==cY1ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear1ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cM];
                isHeavenCombination = true;
            } else if(cM===cD && cM===cY2 && cMID!==cDID && cMID!==cY2ID && cDID!==cY2ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear2ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cM];
                isHeavenCombination = true;
            } else if(cM===cD && cM===cY3 && cMID!==cDID && cMID!==cY3ID && cDID!==cY3ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear3ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cM];
                isHeavenCombination = true;
            } else if(cM===cD && cM===cY4 && cMID!==cDID && cMID!==cY4ID && cDID!==cY4ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear4ItemColor = configData.config[ids.heavenCombination][cY];
                heavenCombinationElement = configData.config[ids.heavenCombination][cM];
                isHeavenCombination = true;
            }
            if(cD===cH && cD===cY1 && cDID!==cHID && cDID!==cY1ID && cHID!==cY1ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemHourEBColor = configData.config[ids.heavenCombination][cH];
                ebYear1ItemColor = configData.config[ids.heavenCombination][cY1];
                heavenCombinationElement = configData.config[ids.heavenCombination][cD];
                isHeavenCombination = true;
            } else if(cD===cH && cD===cY2 && cDID!==cHID && cDID!==cY2ID && cHID!==cY2ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemHourEBColor = configData.config[ids.heavenCombination][cH];
                ebYear2ItemColor = configData.config[ids.heavenCombination][cY2];
                heavenCombinationElement = configData.config[ids.heavenCombination][cD];
                isHeavenCombination = true;
            } else if(cD===cH && cD===cY3 && cDID!==cHID && cDID!==cY3ID && cHID!==cY3ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemHourEBColor = configData.config[ids.heavenCombination][cH];
                ebYear3ItemColor = configData.config[ids.heavenCombination][cY3];
                heavenCombinationElement = configData.config[ids.heavenCombination][cD];
                isHeavenCombination = true;
            } else if(cD===cH && cD===cY4 && cDID!==cHID && cDID!==cY4ID && cHID!==cY4ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemHourEBColor = configData.config[ids.heavenCombination][cH];
                ebYear4ItemColor = configData.config[ids.heavenCombination][cY4];
                heavenCombinationElement = configData.config[ids.heavenCombination][cD];
                isHeavenCombination = true;
            }
        }
    }

    //direct combination
    let isDirectCombination = false;
    let directCombinationElement = "";
    if(calcData[ids.directCombination]==="1") {
        const cY = itemYearEB[ids.directCombination];
        const cM = itemMonthEB[ids.directCombination];
        const cD = itemDayEB[ids.directCombination];
        const cH = itemHourEB[ids.directCombination];

        const cY1 = itemYear1EB[ids.directCombination];
        const cY2 = itemYear2EB[ids.directCombination];
        const cY3 = itemYear3EB[ids.directCombination];
        const cY4 = itemYear4EB[ids.directCombination];
        if(cY===cM && cY===cD && cM===cD && cYID!==cMID && cYID!==cDID && cMID!==cDID) {
            itemYearEBColor = configData.config[ids.directCombination][cY];
            itemMonthEBColor = configData.config[ids.directCombination][cM];
            itemDayEBColor = configData.config[ids.directCombination][cD];
            directCombinationElement = configData.config[ids.directCombination][cY];
            isDirectCombination = true;
        } else if(cM===cD && cM===cH && cD===cH && cMID!==cDID && cMID!==cHID && cDID!==cHID) {
            itemMonthEBColor = configData.config[ids.directCombination][cM];
            itemDayEBColor = configData.config[ids.directCombination][cD];
            itemHourEBColor = configData.config[ids.directCombination][cH];
            directCombinationElement = configData.config[ids.directCombination][cM];
            isDirectCombination = true;
        } else {
            if(cY===cM && cY===cY1 && cYID!==cMID && cYID!==cY1ID && cMID!==cY1ID) {
                itemYearEBColor = configData.config[ids.directCombination][cY];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear1ItemColor = configData.config[ids.directCombination][cY1];
                directCombinationElement = configData.config[ids.directCombination][cY];
                isDirectCombination = true;
            } else if(cY===cM && cY===cY2&& cYID!==cMID && cYID!==cY2ID && cMID!==cY2ID) {
                itemYearEBColor = configData.config[ids.directCombination][cY];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear2ItemColor = configData.config[ids.directCombination][cY2];
                directCombinationElement = configData.config[ids.directCombination][cY];
                isDirectCombination = true;
            } else if(cY===cM && cY===cY3 && cYID!==cMID && cYID!==cY3ID && cMID!==cY3ID) {
                itemYearEBColor = configData.config[ids.directCombination][cY];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear3ItemColor = configData.config[ids.directCombination][cY3];
                directCombinationElement = configData.config[ids.directCombination][cY];
                isDirectCombination = true;
            } else if(cY===cM && cY===cY4 && cYID!==cMID && cYID!==cY4ID && cMID!==cY4ID) {
                itemYearEBColor = configData.config[ids.directCombination][cY];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear4ItemColor = configData.config[ids.directCombination][cY4];
                directCombinationElement = configData.config[ids.directCombination][cY];
                isDirectCombination = true;
            }
            if(cM===cD && cM===cY1 && cMID!==cDID && cMID!==cY1ID && cDID!==cY1ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear1ItemColor = configData.config[ids.directCombination][cY1];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cM===cD && cM===cY2 && cMID!==cDID && cMID!==cY2ID && cDID!==cY2ID) {
                itemDayEBColor = configData.config[ids.heavenCombination][cD];
                itemMonthEBColor = configData.config[ids.heavenCombination][cM];
                ebYear2ItemColor = configData.config[ids.heavenCombination][cY2];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cM===cD && cM===cY3 && cMID!==cDID && cMID!==cY3ID && cDID!==cY3ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear3ItemColor = configData.config[ids.directCombination][cY3];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cM===cD && cM===cY4 && cMID!==cDID && cMID!==cY4ID && cDID!==cY4ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemMonthEBColor = configData.config[ids.directCombination][cM];
                ebYear4ItemColor = configData.config[ids.directCombination][cY4];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            }
            if(cD===cH && cD===cY1 && cDID!==cHID && cDID!==cY1ID && cHID!==cY1ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemHourEBColor = configData.config[ids.directCombination][cH];
                ebYear1ItemColor = configData.config[ids.directCombination][cY1];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cD===cH && cD===cY2 && cDID!==cHID && cDID!==cY2ID && cHID!==cY2ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemHourEBColor = configData.config[ids.directCombination][cH];
                ebYear2ItemColor = configData.config[ids.directCombination][cY2];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cD===cH && cD===cY3 && cDID!==cHID && cDID!==cY3ID && cHID!==cY3ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemHourEBColor = configData.config[ids.directCombination][cH];
                ebYear3ItemColor = configData.config[ids.directCombination][cY3];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            } else if(cD===cH && cD===cY4 && cDID!==cHID && cDID!==cY4ID && cHID!==cY4ID) {
                itemDayEBColor = configData.config[ids.directCombination][cD];
                itemHourEBColor = configData.config[ids.directCombination][cH];
                ebYear4ItemColor = configData.config[ids.directCombination][cY4];
                directCombinationElement = configData.config[ids.directCombination][cD];
                isDirectCombination = true;
            }
        }
    }
    //harmonic combination
    //hs
    //let itemYear1EB = getItem(ebArr, ebYear1Item);
    //let itemYear2EB = getItem(ebArr, ebYear2Item);
    //let itemYear3EB = getItem(ebArr, ebYear3Item);
    //let itemYear4EB = getItem(ebArr, ebYear4Item);
    let itemYearHS = getItem(hsArr, itemYearHSId);
    let itemMonthHS = getItem(hsArr, itemMonthHSId);
    let itemDayHS = getItem(hsArr, itemDayHSId);
    let itemHourHS = getItem(hsArr, itemHourHSId);

    let isHarmonicEBCombination = false;
    let harmonicEBCombinationElement = "";
    let isHarmonicHSCombination = false;
    let harmonicHSCombinationElement = "";
    if(calcData[ids.harmonicCombination]==="1") {
        //ids of friend
        const cY = itemYearEB[ids.harmonicCombinationItem];
        const cM = itemMonthEB[ids.harmonicCombinationItem];
        const cD = itemDayEB[ids.harmonicCombinationItem];
        const cH = itemHourEB[ids.harmonicCombinationItem];

        const cYColor = itemYearEB[ids.harmonicCombination];
        const cMColor = itemMonthEB[ids.harmonicCombination];
        const cDColor = itemDayEB[ids.harmonicCombination];
        const cHColor = itemHourEB[ids.harmonicCombination];

        const cY1 = itemYear1EB[ids.harmonicCombination];
        const cY2 = itemYear2EB[ids.harmonicCombination];
        const cY3 = itemYear3EB[ids.harmonicCombination];
        const cY4 = itemYear4EB[ids.harmonicCombination];
        //main BaZi
        if(cY===cMID && cM===cYID) {
            itemYearEBColor = configData.config[ids.harmonicCombination][cYColor];
            itemMonthEBColor = configData.config[ids.harmonicCombination][cMColor];
            harmonicEBCombinationElement = configData.config[ids.harmonicCombination][cYColor];
            isHarmonicEBCombination = true;
        }
        if(cM===cDID && cMID===cD) {
            harmonicEBCombinationElement = configData.config[ids.harmonicCombination][cMColor];
            itemMonthEBColor = configData.config[ids.harmonicCombination][cM];
            itemDayEBColor = configData.config[ids.directCombination][cD];
            isHarmonicEBCombination = true;
        }
        if(cD===cHID && cH===cDID) {
            harmonicEBCombinationElement = configData.config[ids.harmonicCombination][cDColor];
            itemDayEBColor = configData.config[ids.harmonicCombination][cDColor];
            itemHourEBColor = configData.config[ids.harmonicCombination][cHColor];
            isHarmonicEBCombination = true;
        }

        { //year pillars

        }

        if(isHarmonicEBCombination) {
            const yearItemHS = itemYearHS[ids.element];
            //const yearItemMotherHS = itemYearHS[ids.motherElement];
            const monthItemHS = itemMonthHS[ids.element];
            //const monthItemMotherHS = itemMonthHS[ids.motherElement];
            const dayItemHS = itemDayHS[ids.element];
            //const dayItemMotherHS = itemDayHS[ids.motherElement];
            const hourItemHS = itemHourHS[ids.element];
            //const hourItemMotherHS = itemHourHS[ids.motherElement];

            const yearItemEB = getItem(hsArr, itemYearEB[ids.energyId]);
            //const yearItemMotherEB = itemYearEB[ids.motherElement];
            const monthItemEB = getItem(hsArr, itemMonthEB[ids.energyId]);
            const dayItemEB = getItem(hsArr, itemDayEB[ids.energyId]);
            const hourItemEB = getItem(hsArr, itemHourEB[ids.energyId]);

            if(harmonicEBCombinationElement===yearItemHS===yearItemEB ||
                harmonicEBCombinationElement===monthItemHS===monthItemEB ||
                harmonicEBCombinationElement===dayItemHS===dayItemEB ||
                harmonicEBCombinationElement===hourItemHS===hourItemEB ||
                monthItemEB === harmonicEBCombinationElement) {

            } else {
                isHarmonicEBCombination = false;
            }
        }
    }
    /////////////////////////////////tai yuan///////////////////////////////////////
    let taiyuanItems = calculateTaiYuan(hsMonthItemPos, ebMonthItemPos, configData);
    ///////////////////////////////////minggong/////////////////////////////////////
    let mingGongItems = calculateMingGong(hsYearItemPos, ebMonthItemPos, ebHourItemPos, _calcData, configData);
    ///////////////////////////////////fengkong/////////////////////////////////////
    let isFengKong = calcData[ids.fengKong] && calcData[ids.fengKong]==="1";
    let fengKongItems = calculateFengKong(remainder, remainder2, configData);
    ////////////////////////////////////fuYin////////////////////////////////////////
    let isFuYin = calcData[ids.fuYin] && calcData[ids.fuYin]==="1";
    let fuYinYearMonthItem = calculateFuYin(itemYearHSId, itemYearEBId, itemMonthHSId, itemMonthEBId);
    let fuYinYearDayItem = calculateFuYin(itemYearHSId, itemYearEBId, itemDayHSId, itemDayEBId);
    let fuYinYearHourItem = calculateFuYin(itemYearHSId, itemYearEBId, itemHourHSId, itemHourEBId);
    let fuYinYearYear1Item = calculateFuYin(itemYearHSId, itemYearEBId, hsYear1Item, ebYear1Item);
    let fuYinYearYear2Item = calculateFuYin(itemYearHSId, itemYearEBId, hsYear2Item, ebYear2Item);
    let fuYinYearYear3Item = calculateFuYin(itemYearHSId, itemYearEBId, hsYear3Item, ebYear3Item);
    let fuYinYearYear4Item = calculateFuYin(itemYearHSId, itemYearEBId, hsYear4Item, ebYear4Item);
    let fuYinYearLucky1Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear1Item, ebluckyPillarsYear1Item);
    let fuYinYearLucky2Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear2Item, ebluckyPillarsYear2Item);
    let fuYinYearLucky3Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear3Item, ebluckyPillarsYear3Item);
    let fuYinYearLucky4Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear4Item, ebluckyPillarsYear4Item);
    let fuYinYearLucky5Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear5Item, ebluckyPillarsYear5Item);
    let fuYinYearLucky6Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear6Item, ebluckyPillarsYear6Item);
    let fuYinYearLucky7Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear7Item, ebluckyPillarsYear7Item);
    let fuYinYearLucky8Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear8Item, ebluckyPillarsYear8Item);
    let fuYinYearLucky9Item = calculateFuYin(itemYearHSId, itemYearEBId, hsluckyPillarsYear9Item, ebluckyPillarsYear9Item);
    let fuYinYearTaiYuanItem = calculateFuYin(itemYearHSId, itemYearEBId, taiyuanItems[0], taiyuanItems[1]);
    let fuYinYearMingGongItem = calculateFuYin(itemYearHSId, itemYearEBId, mingGongItems[0], mingGongItems[1]);

    let fuYinMonthDayItem = calculateFuYin(itemMonthHSId, itemMonthEBId, itemDayHSId, itemDayEBId);
    let fuYinMonthHourItem = calculateFuYin(itemMonthHSId, itemMonthEBId, itemHourHSId, itemHourEBId);
    let fuYinMonthYear1Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsYear1Item, ebYear1Item);
    let fuYinMonthYear2Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsYear2Item, ebYear2Item);
    let fuYinMonthYear3Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsYear3Item, ebYear3Item);
    let fuYinMonthYear4Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsYear4Item, ebYear4Item);
    let fuYinMonthLucky1Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear1Item, ebluckyPillarsYear1Item);
    let fuYinMonthLucky2Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear2Item, ebluckyPillarsYear2Item);
    let fuYinMonthLucky3Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear3Item, ebluckyPillarsYear3Item);
    let fuYinMonthLucky4Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear4Item, ebluckyPillarsYear4Item);
    let fuYinMonthLucky5Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear5Item, ebluckyPillarsYear5Item);
    let fuYinMonthLucky6Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear6Item, ebluckyPillarsYear6Item);
    let fuYinMonthLucky7Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear7Item, ebluckyPillarsYear7Item);
    let fuYinMonthLucky8Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear8Item, ebluckyPillarsYear8Item);
    let fuYinMonthLucky9Item = calculateFuYin(itemMonthHSId, itemMonthEBId, hsluckyPillarsYear9Item, ebluckyPillarsYear9Item);
    let fuYinMonthTaiYuanItem = calculateFuYin(itemMonthHSId, itemMonthEBId, taiyuanItems[0], taiyuanItems[1]);
    let fuYinMonthMingGongItem = calculateFuYin(itemMonthHSId, itemMonthEBId, mingGongItems[0], mingGongItems[1]);

    let fuYinDayHourItem = calculateFuYin(itemDayHSId, itemDayEBId, itemHourHSId, itemHourEBId);
    let fuYinDayYear1Item = calculateFuYin(itemDayHSId, itemDayEBId, hsYear1Item, ebYear1Item);
    let fuYinDayYear2Item = calculateFuYin(itemDayHSId, itemDayEBId, hsYear2Item, ebYear2Item);
    let fuYinDayYear3Item = calculateFuYin(itemDayHSId, itemDayEBId, hsYear3Item, ebYear3Item);
    let fuYinDayYear4Item = calculateFuYin(itemDayHSId, itemDayEBId, hsYear4Item, ebYear4Item);
    let fuYinDayLucky1Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear1Item, ebluckyPillarsYear1Item);
    let fuYinDayLucky2Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear2Item, ebluckyPillarsYear2Item);
    let fuYinDayLucky3Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear3Item, ebluckyPillarsYear3Item);
    let fuYinDayLucky4Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear4Item, ebluckyPillarsYear4Item);
    let fuYinDayLucky5Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear5Item, ebluckyPillarsYear5Item);
    let fuYinDayLucky6Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear6Item, ebluckyPillarsYear6Item);
    let fuYinDayLucky7Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear7Item, ebluckyPillarsYear7Item);
    let fuYinDayLucky8Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear8Item, ebluckyPillarsYear8Item);
    let fuYinDayLucky9Item = calculateFuYin(itemDayHSId, itemDayEBId, hsluckyPillarsYear9Item, ebluckyPillarsYear9Item);
    let fuYinDayTaiYuanItem = calculateFuYin(itemDayHSId, itemDayEBId, taiyuanItems[0], taiyuanItems[1]);
    let fuYinDayMingGongItem = calculateFuYin(itemDayHSId, itemDayEBId, mingGongItems[0], mingGongItems[1]);

    let fuYinHourYear1Item = calculateFuYin(itemHourHSId, itemHourEBId, hsYear1Item, ebYear1Item);
    let fuYinHourYear2Item = calculateFuYin(itemHourHSId, itemHourEBId, hsYear2Item, ebYear2Item);
    let fuYinHourYear3Item = calculateFuYin(itemHourHSId, itemHourEBId, hsYear3Item, ebYear3Item);
    let fuYinHourYear4Item = calculateFuYin(itemHourHSId, itemHourEBId, hsYear4Item, ebYear4Item);

    let fuYinHourLucky1Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear1Item, ebluckyPillarsYear1Item);
    let fuYinHourLucky2Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear2Item, ebluckyPillarsYear2Item);
    let fuYinHourLucky3Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear3Item, ebluckyPillarsYear3Item);
    let fuYinHourLucky4Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear4Item, ebluckyPillarsYear4Item);
    let fuYinHourLucky5Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear5Item, ebluckyPillarsYear5Item);
    let fuYinHourLucky6Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear6Item, ebluckyPillarsYear6Item);
    let fuYinHourLucky7Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear7Item, ebluckyPillarsYear7Item);
    let fuYinHourLucky8Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear8Item, ebluckyPillarsYear8Item);
    let fuYinHourLucky9Item = calculateFuYin(itemHourHSId, itemHourEBId, hsluckyPillarsYear9Item, ebluckyPillarsYear9Item);

    let fuYinHourTaiYuanItem = calculateFuYin(itemHourHSId, itemHourEBId, taiyuanItems[0], taiyuanItems[1]);
    let fuYinHourMingGongItem = calculateFuYin(itemHourHSId, itemHourEBId, mingGongItems[0], mingGongItems[1]);
    /////////////////////////////////TianYiGuiRen////////////////////////////////////////////
    let isTianYiGuiRen = calcData[ids.tianYiGuiRen] && calcData[ids.tianYiGuiRen]==="1";
    let tianYinGuiRen = calculateTianYiGuiRen(itemYearHSId, itemDayHSId, configData);

    let tianYiGuiRenYearItem = findTianYiGuiRen(tianYinGuiRen, itemYearEBId);
    let tianYiGuiRenMonthItem = findTianYiGuiRen(tianYinGuiRen, itemMonthEBId);
    let tianYiGuiRenDayItem = findTianYiGuiRen(tianYinGuiRen, itemDayEBId);
    let tianYiGuiRenHourItem = findTianYiGuiRen(tianYinGuiRen, itemHourEBId);

    let tianYiGuiRenTaiYuanItem = findTianYiGuiRen(tianYinGuiRen, taiyuanItems[1]);
    let tianYiGuiRenMingGongItem = findTianYiGuiRen(tianYinGuiRen, mingGongItems[1]);

    let tianYiGuiRenYear1Item = findTianYiGuiRen(tianYinGuiRen, ebYear1Item);
    let tianYiGuiRenYear2Item = findTianYiGuiRen(tianYinGuiRen, ebYear2Item);
    let tianYiGuiRenYear3Item = findTianYiGuiRen(tianYinGuiRen, ebYear3Item);
    let tianYiGuiRenYear4Item = findTianYiGuiRen(tianYinGuiRen, ebYear4Item);

    let tianYiGuiRenLucky1Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear1Item);
    let tianYiGuiRenLucky2Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear2Item);
    let tianYiGuiRenLucky3Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear3Item);
    let tianYiGuiRenLucky4Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear4Item);
    let tianYiGuiRenLucky5Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear5Item);
    let tianYiGuiRenLucky6Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear6Item);
    let tianYiGuiRenLucky7Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear7Item);
    let tianYiGuiRenLucky8Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear8Item);
    let tianYiGuiRenLucky9Item = findTianYiGuiRen(tianYinGuiRen, ebluckyPillarsYear9Item);
    ///////////////////////////////////TaoHua////////////////////////////////////////////
    let isTaoHua = calcData[ids.taoHua] && calcData[ids.taoHua]==="1";
    let taoHua = calculateTaoHua(itemYearEBId, itemDayEBId, configData);

    let taoHuaYearItem = findTaoHua(taoHua, itemYearEBId); //inner
    let taoHuaMonthItem = findTaoHua(taoHua, itemMonthEBId);//inner
    let taoHuaDayItem = findTaoHua(taoHua, itemDayEBId); //outer
    let taoHuaHourItem = findTaoHua(taoHua, itemHourEBId); //outer

    let taoHuaTaiYuanItem = findTaoHua(taoHua, taiyuanItems[1]);
    let taoHuaMingGongItem = findTaoHua(taoHua, mingGongItems[1]);

    let taoHuaYear1Item = findTaoHua(taoHua, ebYear1Item);
    let taoHuaYear2Item = findTaoHua(taoHua, ebYear2Item);
    let taoHuaYear3Item = findTaoHua(taoHua, ebYear3Item);
    let taoHuaYear4Item = findTaoHua(taoHua, ebYear4Item);

    let taoHuaLucky1Item = findTaoHua(taoHua, ebluckyPillarsYear1Item);
    let taoHuaLucky2Item = findTaoHua(taoHua, ebluckyPillarsYear2Item);
    let taoHuaLucky3Item = findTaoHua(taoHua, ebluckyPillarsYear3Item);
    let taoHuaLucky4Item = findTaoHua(taoHua, ebluckyPillarsYear4Item);
    let taoHuaLucky5Item = findTaoHua(taoHua, ebluckyPillarsYear5Item);
    let taoHuaLucky6Item = findTaoHua(taoHua, ebluckyPillarsYear6Item);
    let taoHuaLucky7Item = findTaoHua(taoHua, ebluckyPillarsYear7Item);
    let taoHuaLucky8Item = findTaoHua(taoHua, ebluckyPillarsYear8Item);
    let taoHuaLucky9Item = findTaoHua(taoHua, ebluckyPillarsYear9Item);
    ///////////////////////////////////YiMa////////////////////////////////////////////
    let isYiMa = calcData[ids.taoHua] && calcData[ids.taoHua]==="1";
    let yiMa = calculateYiMa(itemYearEBId, itemDayEBId, configData);

    let yiMaYearItem = findYiMa(yiMa, itemYearEBId); //inner
    let yiMaMonthItem = findYiMa(yiMa, itemMonthEBId);//inner
    let yiMaDayItem = findYiMa(yiMa, itemDayEBId); //outer
    let yiMaHourItem = findYiMa(yiMa, itemHourEBId); //outer

    let yiMaTaiYuanItem = findYiMa(yiMa, taiyuanItems[1]);
    let yiMaMingGongItem = findYiMa(yiMa, mingGongItems[1]);

    let yiMaYear1Item = findYiMa(yiMa, ebYear1Item);
    let yiMaYear2Item = findYiMa(yiMa, ebYear2Item);
    let yiMaYear3Item = findYiMa(yiMa, ebYear3Item);
    let yiMaYear4Item = findYiMa(yiMa, ebYear4Item);

    let yiMaLucky1Item = findYiMa(yiMa, ebluckyPillarsYear1Item);
    let yiMaLucky2Item = findYiMa(yiMa, ebluckyPillarsYear2Item);
    let yiMaLucky3Item = findYiMa(yiMa, ebluckyPillarsYear3Item);
    let yiMaLucky4Item = findYiMa(yiMa, ebluckyPillarsYear4Item);
    let yiMaLucky5Item = findYiMa(yiMa, ebluckyPillarsYear5Item);
    let yiMaLucky6Item = findYiMa(yiMa, ebluckyPillarsYear6Item);
    let yiMaLucky7Item = findYiMa(yiMa, ebluckyPillarsYear7Item);
    let yiMaLucky8Item = findYiMa(yiMa, ebluckyPillarsYear8Item);
    let yiMaLucky9Item = findYiMa(yiMa, ebluckyPillarsYear9Item);
    ///////////////////////////////////TianDe////////////////////////////////////////////
    let isTianDe = calcData[ids.tianDe] && calcData[ids.tianDe]==="1";
    let tianDe = calculateTianDe(itemMonthEBId, configData);

    let tianDeYearItem = findTianDe(tianDe, itemYearEBId, itemYearHSId);
    let tianDeMonthItem = findTianDe(tianDe, itemMonthEBId, itemMonthHSId);
    let tianDeDayItem = findTianDe(tianDe, itemDayEBId, itemDayHSId);
    let tianDeHourItem = findTianDe(tianDe, itemHourEBId, itemHourHSId);

    let tianDeYuanItem = findTianDe(tianDe, taiyuanItems[1], taiyuanItems[0]);
    let tianDeMingGongItem = findTianDe(tianDe, mingGongItems[1], mingGongItems[0]);

    let tianDeYear1Item = findTianDe(tianDe, ebYear1Item, hsYear1Item);
    let tianDeYear2Item = findTianDe(tianDe, ebYear2Item, hsYear2Item);
    let tianDeYear3Item = findTianDe(tianDe, ebYear3Item, hsYear3Item);
    let tianDeYear4Item = findTianDe(tianDe, ebYear4Item, hsYear4Item);

    let tianDeLucky1Item = findTianDe(tianDe, ebluckyPillarsYear1Item, hsluckyPillarsYear1Item);
    let tianDeLucky2Item = findTianDe(tianDe, ebluckyPillarsYear2Item, hsluckyPillarsYear2Item);
    let tianDeLucky3Item = findTianDe(tianDe, ebluckyPillarsYear3Item, hsluckyPillarsYear3Item);
    let tianDeLucky4Item = findTianDe(tianDe, ebluckyPillarsYear4Item, hsluckyPillarsYear4Item);
    let tianDeLucky5Item = findTianDe(tianDe, ebluckyPillarsYear5Item, hsluckyPillarsYear5Item);
    let tianDeLucky6Item = findTianDe(tianDe, ebluckyPillarsYear6Item, hsluckyPillarsYear6Item);
    let tianDeLucky7Item = findTianDe(tianDe, ebluckyPillarsYear7Item, hsluckyPillarsYear7Item);
    let tianDeLucky8Item = findTianDe(tianDe, ebluckyPillarsYear8Item, hsluckyPillarsYear8Item);
    let tianDeLucky9Item = findTianDe(tianDe, ebluckyPillarsYear9Item, hsluckyPillarsYear9Item);
    ///////////////////////////////////TianYi////////////////////////////////////////////
    let isTianYi = calcData[ids.tianYi] && calcData[ids.tianYi]==="1";
    let tianYi = calculateTianYi(itemMonthEBId, configData);

    let tianYiYearItem = findTianYi(tianYi, itemYearEBId);
    let tianYiMonthItem = findTianYi(tianYi, itemMonthEBId);
    let tianYiDayItem = findTianYi(tianYi, itemDayEBId);
    let tianYiHourItem = findTianYi(tianYi, itemHourEBId);

    let tianYiYuanItem = findTianYi(tianYi, taiyuanItems[1]);
    let tianYiMingGongItem = findTianYi(tianYi, mingGongItems[1]);

    let tianYiYear1Item = findTianYi(tianYi, ebYear1Item);
    let tianYiYear2Item = findTianYi(tianYi, ebYear2Item);
    let tianYiYear3Item = findTianYi(tianYi, ebYear3Item);
    let tianYiYear4Item = findTianYi(tianYi, ebYear4Item);

    let tianYiLucky1Item = findTianYi(tianYi, ebluckyPillarsYear1Item);
    let tianYiLucky2Item = findTianYi(tianYi, ebluckyPillarsYear2Item);
    let tianYiLucky3Item = findTianYi(tianYi, ebluckyPillarsYear3Item);
    let tianYiLucky4Item = findTianYi(tianYi, ebluckyPillarsYear4Item);
    let tianYiLucky5Item = findTianYi(tianYi, ebluckyPillarsYear5Item);
    let tianYiLucky6Item = findTianYi(tianYi, ebluckyPillarsYear6Item);
    let tianYiLucky7Item = findTianYi(tianYi, ebluckyPillarsYear7Item);
    let tianYiLucky8Item = findTianYi(tianYi, ebluckyPillarsYear8Item);
    let tianYiLucky9Item = findTianYi(tianYi, ebluckyPillarsYear9Item);
    ///////////////////////////////////WenChang////////////////////////////////////////////
    let isWenChang = calcData[ids.taoHua] && calcData[ids.taoHua]==="1";
    let wenChang = calculateWenChang(itemYearHSId, itemDayHSId, configData);

    let wenChangYearItem = findWenChang(wenChang, itemYearEBId);
    let wenChangMonthItem = findWenChang(wenChang, itemMonthEBId);
    let wenChangDayItem = findWenChang(wenChang, itemDayEBId);
    let wenChangHourItem = findWenChang(wenChang, itemHourEBId);

    let wenChangYuanItem = findWenChang(wenChang, taiyuanItems[1]);
    let wenChangMingGongItem = findWenChang(wenChang, mingGongItems[1]);

    let wenChangYear1Item = findWenChang(wenChang, ebYear1Item);
    let wenChangYear2Item = findWenChang(wenChang, ebYear2Item);
    let wenChangYear3Item = findWenChang(wenChang, ebYear3Item);
    let wenChangYear4Item = findWenChang(wenChang, ebYear4Item);

    let wenChangLucky1Item = findWenChang(wenChang, ebluckyPillarsYear1Item);
    let wenChangLucky2Item = findWenChang(wenChang, ebluckyPillarsYear2Item);
    let wenChangLucky3Item = findWenChang(wenChang, ebluckyPillarsYear3Item);
    let wenChangLucky4Item = findWenChang(wenChang, ebluckyPillarsYear4Item);
    let wenChangLucky5Item = findWenChang(wenChang, ebluckyPillarsYear5Item);
    let wenChangLucky6Item = findWenChang(wenChang, ebluckyPillarsYear6Item);
    let wenChangLucky7Item = findWenChang(wenChang, ebluckyPillarsYear7Item);
    let wenChangLucky8Item = findWenChang(wenChang, ebluckyPillarsYear8Item);
    let wenChangLucky9Item = findWenChang(wenChang, ebluckyPillarsYear9Item);
    ///////////////////////////////////WenChang////////////////////////////////////////////
    let isTaiJi = calcData[ids.taiJi] && calcData[ids.taiJi]==="1";
    let taiJi = calculateTaiJi(itemYearHSId, itemDayHSId, configData);

    let taiJiYearItem = findTaiJi(taiJi, itemYearEBId);
    let taiJiMonthItem = findTaiJi(taiJi, itemMonthEBId);
    let taiJiDayItem = findTaiJi(taiJi, itemDayEBId);
    let taiJiHourItem = findTaiJi(taiJi, itemHourEBId);

    let taiJiYuanItem = findWenChang(taiJi, taiyuanItems[1]);
    let taiJigMingGongItem = findWenChang(taiJi, mingGongItems[1]);

    let taiJiYear1Item = findTaiJi(taiJi, ebYear1Item);
    let taiJiYear2Item = findTaiJi(taiJi, ebYear2Item);
    let taiJiYear3Item = findTaiJi(taiJi, ebYear3Item);
    let taiJiYear4Item = findTaiJi(taiJi, ebYear4Item);

    let taiJiLucky1Item = findTaiJi(taiJi, ebluckyPillarsYear1Item);
    let taiJiLucky2Item = findTaiJi(taiJi, ebluckyPillarsYear2Item);
    let taiJiLucky3Item = findTaiJi(taiJi, ebluckyPillarsYear3Item);
    let taiJiLucky4Item = findTaiJi(taiJi, ebluckyPillarsYear4Item);
    let taiJiLucky5Item = findTaiJi(taiJi, ebluckyPillarsYear5Item);
    let taiJiLucky6Item = findTaiJi(taiJi, ebluckyPillarsYear6Item);
    let taiJiLucky7Item = findTaiJi(taiJi, ebluckyPillarsYear7Item);
    let taiJiLucky8Item = findTaiJi(taiJi, ebluckyPillarsYear8Item);
    let taiJiLucky9Item = findTaiJi(taiJi, ebluckyPillarsYear9Item);

    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////Yi Jing/////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    //eb
    // let itemYearEB = getItem(ebArr, itemYearEBId);
    // let itemMonthEB = getItem(ebArr, itemMonthEBId);
    // let itemDayEB = getItem(ebArr, itemDayEBId);
    // let itemHourEB = getItem(ebArr, itemHourEBId);
    // //hs
    // let itemYearHS = getItem(hsArr, itemYearHSId);
    // let itemMonthHS = getItem(hsArr, itemMonthHSId);
    // let itemDayHS = getItem(hsArr, itemDayHSId);
    // let itemHourHS = getItem(hsArr, itemHourHSId);

    let heavenTrigramRes = calculateHeavenTrigram(_calcData, configData, itemYearHS, itemYearEB, itemMonthHS, itemMonthEB, itemDayHS, itemDayEB, itemHourHS, itemHourEB);
    let heavenTrigram = "";
    let heaven = 0;
    let heavenLimit = configData.config[ids.heaven][ids.limit];
    if(heavenTrigramRes.length > 2) {
        heaven = heavenTrigramRes[0];
        heavenTrigram = heavenTrigramRes[1];
        heavenLimit = heavenTrigramRes[2];
    }
    let earthTrigramRes = calculateEarthTrigram(_calcData, configData, itemYearHS, itemYearEB, itemMonthHS, itemMonthEB, itemDayHS, itemDayEB, itemHourHS, itemHourEB);
    let earthTrigram = "";
    let earth = 0;
    let earthLimit = configData.config[ids.earth][ids.limit];
    if(earthTrigramRes.length > 2) {
        earth = earthTrigramRes[0];
        earthTrigram = earthTrigramRes[1];
        earthLimit = earthTrigramRes[2];
    }
    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////results/////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    let resultsYear = {};
    let resultsMonth = {};
    let resultsDay = {};
    let resultsHour = {};

    let resultsTaiYuan = {};
    let resultsMingGong = {};

    let resultsAnnualYears1 = {};
    let resultsAnnualYears2 = {};
    let resultsAnnualYears3 = {};
    let resultsAnnualYears4 = {};

    let resultsLuckyPillarsYears = {};
    let resultsLuckyPillarsYears1 = {};
    let resultsLuckyPillarsYears2 = {};
    let resultsLuckyPillarsYears3 = {};
    let resultsLuckyPillarsYears4 = {};
    let resultsLuckyPillarsYears5 = {};
    let resultsLuckyPillarsYears6 = {};
    let resultsLuckyPillarsYears7 = {};
    let resultsLuckyPillarsYears8 = {};
    let resultsLuckyPillarsYears9 = {};

    //year
    resultsYear[ids.eb] = itemYearEBId;
    resultsYear[ids.hs] = itemYearHSId;
    resultsYear[ids.title] = strings.year;
    resultsYear[ids.color] = itemYearEBColor;
    resultsYear[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(itemYearEBId) || fengKongItems[1].match(itemYearEBId));
    resultsYear[ids.fuYin] = isFuYin && (fuYinYearMonthItem || fuYinYearDayItem || fuYinYearHourItem || fuYinYearTaiYuanItem || fuYinYearMingGongItem ||
        fuYinYearLucky1Item || fuYinYearLucky2Item || fuYinYearLucky3Item || fuYinYearLucky4Item || fuYinYearLucky5Item || fuYinYearLucky6Item || fuYinYearLucky7Item ||
        fuYinYearLucky8Item || fuYinYearLucky9Item);
    resultsYear[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenYearItem);
    resultsYear[ids.taoHua] = isTaoHua && (taoHuaYearItem);
    resultsYear[ids.yiMa] = isYiMa && (yiMaYearItem);
    let resultsYearTianDe = {};
    resultsYearTianDe[ids.eb] = tianDeYearItem[0];
    resultsYearTianDe[ids.hs] = tianDeYearItem[1];
    resultsYear[ids.tianDe] = isTianDe && (resultsYearTianDe);
    resultsYear[ids.tianYi] = isTianYi && (tianYiYearItem);
    resultsYear[ids.wenChang] = isWenChang && (wenChangYearItem);
    resultsYear[ids.taiJi] = isTaiJi && (taiJiYearItem);

    //month
    resultsMonth[ids.eb] = itemMonthEBId;
    resultsMonth[ids.hs] = itemMonthHSId;
    resultsMonth[ids.title] = strings.month;
    resultsMonth[ids.color] = itemMonthEBColor;
    resultsMonth[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(itemMonthEBId) || fengKongItems[1].match(itemMonthEBId));
    resultsMonth[ids.fuYin] = isFuYin && (fuYinYearMonthItem || fuYinMonthDayItem || fuYinMonthHourItem || fuYinMonthTaiYuanItem || fuYinMonthMingGongItem ||
        fuYinMonthLucky1Item || fuYinMonthLucky2Item || fuYinMonthLucky3Item || fuYinMonthLucky4Item || fuYinMonthLucky5Item || fuYinMonthLucky6Item ||
        fuYinMonthLucky7Item || fuYinMonthLucky8Item || fuYinMonthLucky9Item);
    resultsMonth[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenMonthItem);
    resultsMonth[ids.taoHua] = isTaoHua && (taoHuaMonthItem);
    resultsMonth[ids.yiMa] = isYiMa && (yiMaMonthItem);
    let resultsMonthTianDe = {};
    resultsMonthTianDe[ids.eb] = tianDeMonthItem[0];
    resultsMonthTianDe[ids.hs] = tianDeMonthItem[1];
    resultsMonth[ids.tianDe] = isTianDe && (resultsMonthTianDe);
    resultsMonth[ids.tianYi] = isTianYi && (tianYiMonthItem);
    resultsMonth[ids.wenChang] = isWenChang && (wenChangMonthItem);
    resultsMonth[ids.taiJi] = isTaiJi && (taiJiMonthItem);

    //day
    resultsDay[ids.eb] = itemDayEBId;
    resultsDay[ids.hs] = itemDayHSId;
    resultsDay[ids.title] = strings.day;
    resultsDay[ids.color] = itemDayEBColor;
    resultsDay[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(itemDayEBId) || fengKongItems[1].match(itemDayEBId));
    resultsDay[ids.fuYin] = isFuYin && (fuYinYearDayItem || fuYinMonthDayItem || fuYinDayHourItem || fuYinDayTaiYuanItem || fuYinDayMingGongItem||
        fuYinDayLucky1Item || fuYinDayLucky2Item || fuYinDayLucky3Item || fuYinDayLucky4Item || fuYinDayLucky5Item || fuYinDayLucky6Item ||
        fuYinDayLucky7Item || fuYinDayLucky8Item || fuYinDayLucky9Item);
    resultsDay[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenDayItem);
    resultsDay[ids.taoHua] = isTaoHua && (taoHuaDayItem);
    resultsDay[ids.yiMa] = isYiMa && (yiMaDayItem);
    let resultsDayTianDe = {};
    resultsDayTianDe[ids.eb] = tianDeDayItem[0];
    resultsDayTianDe[ids.hs] = tianDeDayItem[1];
    resultsDay[ids.tianDe] = isTianDe && (resultsDayTianDe);
    resultsDay[ids.tianYi] = isTianYi && (tianYiDayItem);
    resultsDay[ids.wenChang] = isWenChang && (wenChangDayItem);
    resultsDay[ids.taiJi] = isTaiJi && (taiJiDayItem);

    //hour
    resultsHour[ids.eb] = itemHourEBId;
    resultsHour[ids.hs] = itemHourHSId;
    resultsHour[ids.title] = strings.hour;
    resultsHour[ids.color] = itemHourEBColor;
    resultsHour[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(itemHourEBId) || fengKongItems[1].match(itemHourEBId));
    resultsHour[ids.fuYin] = isFuYin && (fuYinYearHourItem || fuYinMonthHourItem || fuYinDayHourItem || fuYinHourTaiYuanItem || fuYinHourMingGongItem ||
        fuYinHourLucky1Item || fuYinHourLucky2Item || fuYinHourLucky3Item || fuYinHourLucky4Item || fuYinHourLucky5Item || fuYinHourLucky6Item || fuYinHourLucky7Item ||
        fuYinHourLucky8Item || fuYinHourLucky9Item);
    resultsHour[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenHourItem);
    resultsHour[ids.taoHua] = isTaoHua && (taoHuaHourItem);
    resultsHour[ids.yiMa] = isYiMa && (yiMaHourItem);
    let resultsHourTianDe = {};
    resultsHourTianDe[ids.eb] = tianDeHourItem[0];
    resultsHourTianDe[ids.hs] = tianDeHourItem[1];
    resultsHour[ids.tianDe] = isTianDe && (resultsHourTianDe);
    resultsHour[ids.tianYi] = isTianYi && (tianYiHourItem);
    resultsHour[ids.wenChang] = isWenChang && (wenChangHourItem);
    resultsHour[ids.taiJi] = isTaiJi && (taiJiHourItem);

    //annual
    resultsAnnualYears1[ids.eb] = ebYear1Item;
    resultsAnnualYears1[ids.hs] = hsYear1Item;
    resultsAnnualYears1[ids.title] = hsYear1ItemTitle;
    resultsAnnualYears1[ids.color] = ebYear1ItemColor;
    resultsAnnualYears1[ids.fuYin] = isFuYin && (fuYinYearYear1Item || fuYinMonthYear1Item || fuYinDayYear1Item || fuYinHourYear1Item);
    resultsAnnualYears1[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenYear1Item);
    resultsAnnualYears1[ids.taoHua] = isTaoHua && (taoHuaYear1Item);
    resultsAnnualYears1[ids.yiMa] = isYiMa && (yiMaYear1Item);
    let resultsAnnualYear1TianDe = {};
    resultsAnnualYear1TianDe[ids.eb] = tianDeYear1Item[0];
    resultsAnnualYear1TianDe[ids.hs] = tianDeYear1Item[1];
    resultsAnnualYears1[ids.tianDe] = isTianDe && (resultsAnnualYear1TianDe);
    resultsAnnualYears1[ids.tianYi] = isTianYi && (tianYiYear1Item);
    resultsAnnualYears1[ids.wenChang] = isWenChang && (wenChangYear1Item);
    resultsAnnualYears1[ids.taiJi] = isTaiJi && (taiJiYear1Item);

    resultsAnnualYears2[ids.eb] = ebYear2Item;
    resultsAnnualYears2[ids.hs] = hsYear2Item;
    resultsAnnualYears2[ids.title] = hsYear2ItemTitle;
    resultsAnnualYears2[ids.color] = ebYear2ItemColor;
    resultsAnnualYears2[ids.fuYin] = isFuYin && (fuYinYearYear2Item || fuYinMonthYear2Item || fuYinDayYear2Item || fuYinHourYear2Item);
    resultsAnnualYears2[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenYear2Item);
    resultsAnnualYears2[ids.taoHua] = isTaoHua && (taoHuaYear2Item);
    resultsAnnualYears2[ids.yiMa] = isYiMa && (yiMaYear2Item);
    let resultsAnnualYear2TianDe = {};
    resultsAnnualYear2TianDe[ids.eb] = tianDeYear2Item[0];
    resultsAnnualYear2TianDe[ids.hs] = tianDeYear2Item[1];
    resultsAnnualYears2[ids.tianDe] = isTianDe && (resultsAnnualYear2TianDe);
    resultsAnnualYears2[ids.tianYi] = isTianYi && (tianYiYear2Item);
    resultsAnnualYears2[ids.wenChang] = isWenChang && (wenChangYear2Item);
    resultsAnnualYears2[ids.taiJi] = isTaiJi && (taiJiYear2Item);

    resultsAnnualYears3[ids.eb] = ebYear3Item;
    resultsAnnualYears3[ids.hs] = hsYear3Item;
    resultsAnnualYears3[ids.title] = hsYear3ItemTitle;
    resultsAnnualYears3[ids.color] = ebYear3ItemColor;
    resultsAnnualYears3[ids.fuYin] = isFuYin && (fuYinYearYear3Item || fuYinMonthYear3Item || fuYinDayYear3Item || fuYinHourYear3Item);
    resultsAnnualYears3[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenYear3Item);
    resultsAnnualYears3[ids.taoHua] = isTaoHua && (taoHuaYear3Item);
    resultsAnnualYears3[ids.yiMa] = isYiMa && (yiMaYear3Item);
    let resultsAnnualYear3TianDe = {};
    resultsAnnualYear3TianDe[ids.eb] = tianDeYear3Item[0];
    resultsAnnualYear3TianDe[ids.hs] = tianDeYear3Item[1];
    resultsAnnualYears3[ids.tianDe] = isTianDe && (resultsAnnualYear3TianDe);
    resultsAnnualYears3[ids.tianYi] = isTianYi && (tianYiYear3Item);
    resultsAnnualYears3[ids.wenChang] = isWenChang && (wenChangYear3Item);
    resultsAnnualYears3[ids.taiJi] = isTaiJi && (taiJiYear3Item);

    resultsAnnualYears4[ids.eb] = ebYear4Item;
    resultsAnnualYears4[ids.hs] = hsYear4Item;
    resultsAnnualYears4[ids.title] = hsYear4ItemTitle;
    resultsAnnualYears4[ids.color] = ebYear4ItemColor;
    resultsAnnualYears4[ids.fuYin] = isFuYin && (fuYinYearYear4Item || fuYinMonthYear4Item || fuYinDayYear4Item || fuYinHourYear4Item);
    resultsAnnualYears4[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenYear4Item);
    resultsAnnualYears4[ids.taoHua] = isTaoHua && (taoHuaYear4Item);
    resultsAnnualYears4[ids.yiMa] = isYiMa && (yiMaYear4Item);
    let resultsAnnualYear4TianDe = {};
    resultsAnnualYear4TianDe[ids.eb] = tianDeYear4Item[0];
    resultsAnnualYear4TianDe[ids.hs] = tianDeYear4Item[1];
    resultsAnnualYears4[ids.tianDe] = isTianDe && (resultsAnnualYear4TianDe);
    resultsAnnualYears4[ids.tianYi] = isTianYi && (tianYiYear4Item);
    resultsAnnualYears4[ids.wenChang] = isWenChang && (wenChangYear4Item);
    resultsAnnualYears4[ids.taiJi] = isTaiJi && (taiJiYear4Item);

    //lucky
    resultsLuckyPillarsYears[ids.title] = luckyPillarsStartItem;
    resultsLuckyPillarsYears1[ids.eb] = ebluckyPillarsYear1Item;
    resultsLuckyPillarsYears1[ids.hs] = hsluckyPillarsYear1Item;
    resultsLuckyPillarsYears1[ids.title] = hsluckyPillarsYear1ItemTitle;
    resultsLuckyPillarsYears1[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear1Item) || fengKongItems[1].match(ebluckyPillarsYear1Item));
    resultsLuckyPillarsYears1[ids.fuYin] = isFuYin && (fuYinYearLucky1Item || fuYinMonthLucky1Item || fuYinDayLucky1Item || fuYinHourLucky1Item);
    resultsLuckyPillarsYears1[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky1Item);
    resultsLuckyPillarsYears1[ids.taoHua] = isTaoHua && (taoHuaLucky1Item);
    resultsLuckyPillarsYears1[ids.yiMa] = isYiMa && (yiMaLucky1Item);
    let resultsLuckyYear1TianDe = {};
    resultsLuckyYear1TianDe[ids.eb] = tianDeLucky1Item[0];
    resultsLuckyYear1TianDe[ids.hs] = tianDeLucky1Item[1];
    resultsLuckyPillarsYears1[ids.tianDe] = isTianDe && (resultsLuckyYear1TianDe);
    resultsLuckyPillarsYears1[ids.tianYi] = isTianYi && (tianYiLucky1Item);
    resultsLuckyPillarsYears1[ids.wenChang] = isWenChang && (wenChangLucky1Item);
    resultsLuckyPillarsYears1[ids.taiJi] = isTaiJi && (taiJiLucky1Item);

    resultsLuckyPillarsYears2[ids.eb] = ebluckyPillarsYear2Item;
    resultsLuckyPillarsYears2[ids.hs] = hsluckyPillarsYear2Item;
    resultsLuckyPillarsYears2[ids.title] = hsluckyPillarsYear2ItemTitle;
    resultsLuckyPillarsYears2[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear2Item) || fengKongItems[1].match(ebluckyPillarsYear2Item));
    resultsLuckyPillarsYears2[ids.fuYin] = isFuYin && (fuYinYearLucky2Item || fuYinMonthLucky2Item || fuYinDayLucky2Item || fuYinHourLucky2Item);
    resultsLuckyPillarsYears2[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky2Item);
    resultsLuckyPillarsYears2[ids.taoHua] = isTaoHua && (taoHuaLucky2Item);
    resultsLuckyPillarsYears2[ids.yiMa] = isYiMa && (yiMaLucky2Item);
    let resultsLuckyYear2TianDe = {};
    resultsLuckyYear2TianDe[ids.eb] = tianDeLucky2Item[0];
    resultsLuckyYear2TianDe[ids.hs] = tianDeLucky2Item[1];
    resultsLuckyPillarsYears2[ids.tianDe] = isTianDe && (resultsLuckyYear2TianDe);
    resultsLuckyPillarsYears2[ids.tianYi] = isTianYi && (tianYiLucky2Item);
    resultsLuckyPillarsYears2[ids.wenChang] = isWenChang && (wenChangLucky2Item);
    resultsLuckyPillarsYears2[ids.taiJi] = isTaiJi && (taiJiLucky2Item);

    resultsLuckyPillarsYears3[ids.eb] = ebluckyPillarsYear3Item;
    resultsLuckyPillarsYears3[ids.hs] = hsluckyPillarsYear3Item;
    resultsLuckyPillarsYears3[ids.title] = hsluckyPillarsYear3ItemTitle;
    resultsLuckyPillarsYears3[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear3Item) || fengKongItems[1].match(ebluckyPillarsYear3Item));
    resultsLuckyPillarsYears3[ids.fuYin] = isFuYin && (fuYinYearLucky3Item || fuYinMonthLucky3Item || fuYinDayLucky3Item || fuYinHourLucky3Item);
    resultsLuckyPillarsYears3[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky3Item);
    resultsLuckyPillarsYears3[ids.taoHua] = isTaoHua && (taoHuaLucky3Item);
    resultsLuckyPillarsYears3[ids.yiMa] = isYiMa && (yiMaLucky3Item);
    let resultsLuckyYear3TianDe = {};
    resultsLuckyYear3TianDe[ids.eb] = tianDeLucky3Item[0];
    resultsLuckyYear3TianDe[ids.hs] = tianDeLucky3Item[1];
    resultsLuckyPillarsYears3[ids.tianDe] = isTianDe && (resultsLuckyYear3TianDe);
    resultsLuckyPillarsYears3[ids.tianYi] = isTianYi && (tianYiLucky3Item);
    resultsLuckyPillarsYears3[ids.wenChang] = isWenChang && (wenChangLucky3Item);
    resultsLuckyPillarsYears3[ids.taiJi] = isTaiJi && (taiJiLucky3Item);

    resultsLuckyPillarsYears4[ids.eb] = ebluckyPillarsYear4Item;
    resultsLuckyPillarsYears4[ids.hs] = hsluckyPillarsYear4Item;
    resultsLuckyPillarsYears4[ids.title] = hsluckyPillarsYear4ItemTitle;
    resultsLuckyPillarsYears4[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear4Item) || fengKongItems[1].match(ebluckyPillarsYear4Item));
    resultsLuckyPillarsYears4[ids.fuYin] = isFuYin && (fuYinYearLucky4Item || fuYinMonthLucky4Item || fuYinDayLucky4Item || fuYinHourLucky4Item);
    resultsLuckyPillarsYears4[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky4Item);
    resultsLuckyPillarsYears4[ids.taoHua] = isTaoHua && (taoHuaLucky4Item);
    resultsLuckyPillarsYears4[ids.yiMa] = isYiMa && (yiMaLucky4Item);
    let resultsLuckyYear4TianDe = {};
    resultsLuckyYear4TianDe[ids.eb] = tianDeLucky4Item[0];
    resultsLuckyYear4TianDe[ids.hs] = tianDeLucky4Item[1];
    resultsLuckyPillarsYears4[ids.tianDe] = isTianDe && (resultsLuckyYear4TianDe);
    resultsLuckyPillarsYears4[ids.tianYi] = isTianYi && (tianYiLucky4Item);
    resultsLuckyPillarsYears4[ids.wenChang] = isWenChang && (wenChangLucky4Item);
    resultsLuckyPillarsYears4[ids.taiJi] = isTaiJi && (taiJiLucky4Item);

    resultsLuckyPillarsYears5[ids.eb] = ebluckyPillarsYear5Item;
    resultsLuckyPillarsYears5[ids.hs] = hsluckyPillarsYear5Item;
    resultsLuckyPillarsYears5[ids.title] = hsluckyPillarsYear5ItemTitle;
    resultsLuckyPillarsYears5[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear5Item) || fengKongItems[1].match(ebluckyPillarsYear5Item));
    resultsLuckyPillarsYears5[ids.fuYin] = isFuYin && (fuYinYearLucky5Item || fuYinMonthLucky5Item || fuYinDayLucky5Item || fuYinHourLucky5Item);
    resultsLuckyPillarsYears5[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky5Item);
    resultsLuckyPillarsYears5[ids.taoHua] = isTaoHua && (taoHuaLucky5Item);
    resultsLuckyPillarsYears5[ids.yiMa] = isYiMa && (yiMaLucky5Item);
    let resultsLuckyYear5TianDe = {};
    resultsLuckyYear5TianDe[ids.eb] = tianDeLucky5Item[0];
    resultsLuckyYear5TianDe[ids.hs] = tianDeLucky5Item[1];
    resultsLuckyPillarsYears5[ids.tianDe] = isTianDe && (resultsLuckyYear5TianDe);
    resultsLuckyPillarsYears5[ids.tianYi] = isTianYi && (tianYiLucky5Item);
    resultsLuckyPillarsYears5[ids.wenChang] = isWenChang && (wenChangLucky5Item);
    resultsLuckyPillarsYears5[ids.taiJi] = isTaiJi && (taiJiLucky5Item);

    resultsLuckyPillarsYears6[ids.eb] = ebluckyPillarsYear6Item;
    resultsLuckyPillarsYears6[ids.hs] = hsluckyPillarsYear6Item;
    resultsLuckyPillarsYears6[ids.title] = hsluckyPillarsYear6ItemTitle;
    resultsLuckyPillarsYears6[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear6Item) || fengKongItems[1].match(ebluckyPillarsYear6Item));
    resultsLuckyPillarsYears6[ids.fuYin] = isFuYin && (fuYinYearLucky6Item || fuYinMonthLucky6Item || fuYinDayLucky6Item || fuYinHourLucky6Item);
    resultsLuckyPillarsYears6[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky6Item);
    resultsLuckyPillarsYears6[ids.taoHua] = isTaoHua && (taoHuaLucky6Item);
    resultsLuckyPillarsYears6[ids.yiMa] = isYiMa && (yiMaLucky6Item);
    let resultsLuckyYear6TianDe = {};
    resultsLuckyYear6TianDe[ids.eb] = tianDeLucky6Item[0];
    resultsLuckyYear6TianDe[ids.hs] = tianDeLucky6Item[1];
    resultsLuckyPillarsYears6[ids.tianDe] = isTianDe && (resultsLuckyYear6TianDe);
    resultsLuckyPillarsYears6[ids.tianYi] = isTianYi && (tianYiLucky6Item);
    resultsLuckyPillarsYears6[ids.wenChang] = isWenChang && (wenChangLucky6Item);
    resultsLuckyPillarsYears6[ids.taiJi] = isTaiJi && (taiJiLucky6Item);

    resultsLuckyPillarsYears7[ids.eb] = ebluckyPillarsYear7Item;
    resultsLuckyPillarsYears7[ids.hs] = hsluckyPillarsYear7Item;
    resultsLuckyPillarsYears7[ids.title] = hsluckyPillarsYear7ItemTitle;
    resultsLuckyPillarsYears7[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear7Item) || fengKongItems[1].match(ebluckyPillarsYear7Item));
    resultsLuckyPillarsYears7[ids.fuYin] = isFuYin && (fuYinYearLucky7Item || fuYinMonthLucky7Item || fuYinDayLucky7Item || fuYinHourLucky7Item);
    resultsLuckyPillarsYears7[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky7Item);
    resultsLuckyPillarsYears7[ids.taoHua] = isTaoHua && (taoHuaLucky7Item);
    resultsLuckyPillarsYears7[ids.yiMa] = isYiMa && (yiMaLucky7Item);
    let resultsLuckyYear7TianDe = {};
    resultsLuckyYear7TianDe[ids.eb] = tianDeLucky7Item[0];
    resultsLuckyYear7TianDe[ids.hs] = tianDeLucky7Item[1];
    resultsLuckyPillarsYears7[ids.tianDe] = isTianDe && (resultsLuckyYear7TianDe);
    resultsLuckyPillarsYears7[ids.tianYi] = isTianYi && (tianYiLucky7Item);
    resultsLuckyPillarsYears7[ids.wenChang] = isWenChang && (wenChangLucky7Item);
    resultsLuckyPillarsYears7[ids.taiJi] = isTaiJi && (taiJiLucky7Item);

    resultsLuckyPillarsYears8[ids.eb] = ebluckyPillarsYear8Item;
    resultsLuckyPillarsYears8[ids.hs] = hsluckyPillarsYear8Item;
    resultsLuckyPillarsYears8[ids.title] = hsluckyPillarsYear8ItemTitle;
    resultsLuckyPillarsYears8[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear8Item) || fengKongItems[1].match(ebluckyPillarsYear8Item));
    resultsLuckyPillarsYears8[ids.fuYin] = isFuYin && (fuYinYearLucky8Item || fuYinMonthLucky8Item || fuYinDayLucky8Item || fuYinHourLucky8Item);
    resultsLuckyPillarsYears8[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky8Item);
    resultsLuckyPillarsYears8[ids.taoHua] = isTaoHua && (taoHuaLucky8Item);
    resultsLuckyPillarsYears8[ids.yiMa] = isYiMa && (yiMaLucky8Item);
    let resultsLuckyYear8TianDe = {};
    resultsLuckyYear8TianDe[ids.eb] = tianDeLucky8Item[0];
    resultsLuckyYear8TianDe[ids.hs] = tianDeLucky8Item[1];
    resultsLuckyPillarsYears8[ids.tianDe] = isTianDe && (resultsLuckyYear8TianDe);
    resultsLuckyPillarsYears8[ids.tianYi] = isTianYi && (tianYiLucky8Item);
    resultsLuckyPillarsYears8[ids.wenChang] = isWenChang && (wenChangLucky8Item);
    resultsLuckyPillarsYears8[ids.taiJi] = isTaiJi && (taiJiLucky8Item);

    resultsLuckyPillarsYears9[ids.eb] = ebluckyPillarsYear9Item;
    resultsLuckyPillarsYears9[ids.hs] = hsluckyPillarsYear9Item;
    resultsLuckyPillarsYears9[ids.title] = hsluckyPillarsYear9ItemTitle;
    resultsLuckyPillarsYears9[ids.fengKong] = isFengKong &&
        (fengKongItems[0].match(ebluckyPillarsYear9Item) || fengKongItems[1].match(ebluckyPillarsYear9Item));
    resultsLuckyPillarsYears9[ids.fuYin] = isFuYin && (fuYinYearLucky9Item || fuYinMonthLucky9Item || fuYinDayLucky9Item || fuYinHourLucky9Item);
    resultsLuckyPillarsYears9[ids.tianYiGuiRen] = isTianYiGuiRen && (tianYiGuiRenLucky9Item);
    resultsLuckyPillarsYears9[ids.taoHua] = isTaoHua && (taoHuaLucky9Item);
    resultsLuckyPillarsYears9[ids.yiMa] = isYiMa && (yiMaLucky9Item);
    let resultsLuckyYear9TianDe = {};
    resultsLuckyYear9TianDe[ids.eb] = tianDeLucky9Item[0];
    resultsLuckyYear9TianDe[ids.hs] = tianDeLucky9Item[1];
    resultsLuckyPillarsYears9[ids.tianDe] = isTianDe && (resultsLuckyYear9TianDe);
    resultsLuckyPillarsYears9[ids.tianYi] = isTianYi && (tianYiLucky9Item);
    resultsLuckyPillarsYears9[ids.wenChang] = isWenChang && (wenChangLucky9Item);
    resultsLuckyPillarsYears9[ids.taiJi] = isTaiJi && (taiJiLucky9Item);

    //taiyuan
    resultsTaiYuan[ids.eb] = taiyuanItems[1];
    resultsTaiYuan[ids.hs] = taiyuanItems[0];
    resultsTaiYuan[ids.title] = strings.taiYuan;
    resultsTaiYuan[ids.fengKong] = calcData[ids.fengKong] && calcData[ids.fengKong]==="1" &&
        (fengKongItems[0].match(taiyuanItems[1]) || fengKongItems[1].match(taiyuanItems[1]));
    resultsTaiYuan[ids.fuYin] = isFuYin && (fuYinYearTaiYuanItem || fuYinMonthTaiYuanItem || fuYinDayTaiYuanItem || fuYinHourTaiYuanItem);
    resultsTaiYuan[ids.tianYiGuiRen] = isTianYiGuiRen && tianYiGuiRenTaiYuanItem;
    resultsTaiYuan[ids.taoHua] = isTaoHua && taoHuaTaiYuanItem;
    resultsTaiYuan[ids.yiMa] = isYiMa && yiMaTaiYuanItem;
    let resultsTaiYuanTianDe = {};
    resultsTaiYuanTianDe[ids.eb] = tianDeYuanItem[0];
    resultsTaiYuanTianDe[ids.hs] = tianDeYuanItem[1];
    resultsTaiYuan[ids.tianDe] = isTianDe && (resultsTaiYuanTianDe);
    resultsTaiYuan[ids.tianYi] = isTianYi && (tianYiYuanItem);
    resultsTaiYuan[ids.wenChang] = isWenChang && (wenChangYuanItem);
    resultsTaiYuan[ids.taiJi] = isTaiJi && (taiJiYuanItem);

    //minggong
    resultsMingGong[ids.eb] = mingGongItems[1];
    resultsMingGong[ids.hs] = mingGongItems[0];
    resultsMingGong[ids.title] = strings.mingGong;
    resultsMingGong[ids.fengKong] = calcData[ids.fengKong] && calcData[ids.fengKong]==="1" &&
        (fengKongItems[0].match(mingGongItems[1]) || fengKongItems[1].match(taiyuanItems[1]));
    resultsMingGong[ids.fuYin] = isFuYin && (fuYinYearMingGongItem || fuYinMonthMingGongItem || fuYinDayMingGongItem || fuYinHourMingGongItem);
    resultsMingGong[ids.tianYiGuiRen] = isTianYiGuiRen && tianYiGuiRenMingGongItem;
    resultsMingGong[ids.taoHua] = isTaoHua && taoHuaMingGongItem;
    resultsMingGong[ids.yiMa] = isYiMa && yiMaMingGongItem;
    let resultsDayMingGong = {};
    resultsDayMingGong[ids.eb] = tianDeMingGongItem[0];
    resultsDayMingGong[ids.hs] = tianDeMingGongItem[1];
    resultsMingGong[ids.tianDe] = isTianDe && (resultsDayMingGong);
    resultsMingGong[ids.tianYi] = isTianYi && (tianYiMingGongItem);
    resultsMingGong[ids.wenChang] = isWenChang && (wenChangMingGongItem);
    resultsMingGong[ids.taiJi] = isTaiJi && (taiJigMingGongItem);

    results[ids.year] = resultsYear;
    results[ids.month] = resultsMonth;
    results[ids.day] = resultsDay;
    results[ids.hour] = resultsHour;

    results[ids.annualYear1] = resultsAnnualYears1;
    results[ids.annualYear2] = resultsAnnualYears2;
    results[ids.annualYear3] = resultsAnnualYears3;
    results[ids.annualYear4] = resultsAnnualYears4;
    results[ids.luckyPillar1] = resultsLuckyPillarsYears1;
    results[ids.luckyPillar2] = resultsLuckyPillarsYears2;
    results[ids.luckyPillar3] = resultsLuckyPillarsYears3;
    results[ids.luckyPillar4] = resultsLuckyPillarsYears4;
    results[ids.luckyPillar5] = resultsLuckyPillarsYears5;
    results[ids.luckyPillar6] = resultsLuckyPillarsYears6;
    results[ids.luckyPillar7] = resultsLuckyPillarsYears7;
    results[ids.luckyPillar8] = resultsLuckyPillarsYears8;
    results[ids.luckyPillar9] = resultsLuckyPillarsYears9;
    results[ids.activeLuckyPillar] = activeLuckyPillar;
    results[ids.luckyPillar] = resultsLuckyPillarsYears;
    results[ids.taiYuan] = resultsTaiYuan;
    results[ids.mingGong] = resultsMingGong;

    results[ids.directCombination] = isDirectCombination;
    results[ids.directCombinationElement] = directCombinationElement;

    results[ids.heavenCombination] = isHeavenCombination;
    results[ids.directCombinationElement] = heavenCombinationElement;

    results[ids.harmonicEBCombination] = isHarmonicEBCombination;
    results[ids.harmonicEBCombinationElement] = harmonicEBCombinationElement;

    results[ids.harmonicHSCombination] = isHarmonicHSCombination;
    results[ids.harmonicHSCombinationElement] = harmonicHSCombinationElement;

    results[ids.heavenTrigram] = heavenTrigram;
    results[ids.heaven] = heaven;
    results[ids.heavenLimit] = heavenLimit;
    results[ids.earthTrigram] = earthTrigram;
    results[ids.earth] = earth;
    results[ids.earthLimit] = earthLimit;
    results[ids.isYang] = itemYearHS[ids.isYang];

    return results;
}