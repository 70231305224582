import React, {Component} from 'react';
import {ids, strings} from "../Configuration/Config";
import {CardGroup} from 'react-bootstrap'
import PillarItem from './PillarItem'
import {Form} from 'react-bootstrap'

class Step6 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChecked(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.checked===true ? "1" : "0";

        this.updateData();
    }

    render() {
        if(!this.props.isAdvanced) return null;
        let generated = this.props.data[ids.generated];
        let checked = !generated ? false : this.props.data[ids.taiYuanMingGong]==="1";

        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={5}>{strings.taiYuanMingGong}</Form.Label>
                    <Form.Check
                        className={"item-switcher"}
                        type="switch"
                        id={ids.taiYuanMingGong}
                        label=""
                        disabled={!generated}
                        checked={checked}
                        onChange={this.handleChecked}
                    />
                </Form.Row>
                {checked ?
                    <CardGroup className={"two-pillar"}>
                        <PillarItem
                            updateData={this.updateData}
                            data={this.props.data}
                            keyId={ids.taiYuan}
                            tooltip={strings.taiYuanDesc}
                        />
                        <PillarItem
                            updateData={this.updateData}
                            data={this.props.data}
                            keyId={ids.mingGong}
                            tooltip={strings.mingGongDesc}
                        />
                    </CardGroup> : <div/>
                }
            </div>
        )
    }
}

export default Step6