import React, {Component} from 'react';
import FloatingMenu from './FloatingMenu/FloatingBtn'
import {Link} from "react-router-dom";
import HerbInfo from "./HerbInfo";
import scrollToComponent from 'react-scroll-to-component';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

export const fytoIds = {
    id: "id",
    name: "name",
    orig: "orig",
    leung: "leung",
    number: "number",
    group: "group",
    effects: "effets",
    effect: "effect",
    formulas: "formulas",
    formula: "formula",
    taste: "taste",
    tropism: "tropism",
    nature: "nature",
    herbs: "Herbs",
    explanation: "explanation",
    description: "description",
    modification: "Modification",
    indication: "Indication",
    impact: "Impact",
    usage: "Usage",
    combination: "Combination",
    note: "Note",
    dosage: "Dosage",
    herbInfo: "herbInfo",
    contraindication: "Contraindication",
    herbInfoSep: "-",
    separator: ","
}

class ProductInfo extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            item: [],
            history: []
        };
        this.onChange = this.onChange.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onShowHome = this.onShowHome.bind(this);
        this.descriptionFormatter = this.descriptionFormatter.bind(this);
        this.usageFormatter = this.usageFormatter.bind(this);
        this.dosageFormatter = this.dosageFormatter.bind(this);
        this.explanationFormatter = this.explanationFormatter.bind(this);
        this.herbLinkFormatter = this.herbLinkFormatter.bind(this);
        this.linkFormatter = this.linkFormatter.bind(this);
        this.impactFormatter = this.impactFormatter.bind(this);
        this.switchtoDifferenProduct = this.switchtoDifferenProduct.bind(this);
        this.onSwitchtoDifferenProduct = this.onSwitchtoDifferenProduct.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.getHerbsData = this.getHerbsData.bind(this);
    }

    componentDidMount() {
        this.scrollToTop();
    }

    scrollToTop() {
        scrollToComponent(this.top, {offset: 0, align: 'middle', duration: 500, ease: 'inCirc'});
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemId !== prevProps.itemId) {
            this.scrollToTop();
        }
    }

    onChange(e) {
        if (!this.props.search) {
            alert("Funkce pro hledání není definovaná!!!");
            return;
        }
        this.props.search(e.target.value);
    }

    onBack() {
        if (this.state.history.length > 0) {
            let key = this.state.history.pop();
            return this.switchtoDifferenProduct(key);
        }

        if (!this.props.onShowHome) {
            alert("Funkce pro zobrazení výchozí stránky  není definovaná!!!");
            return;
        }

        this.props.onShowHome();
    }

    onShowHome() {
        if (!this.props.onShowHome) {
            alert("Funkce pro zobrazení výchozí stránky  není definovaná!!!");
            return;
        }

        this.props.onShowHome();
    }

    onSwitchtoDifferenProduct(e) {
        let key = e.target.getAttribute("data-key").trim();
        this.switchtoDifferenProduct(key);
        this.state.history.push(this.props.itemId);
    }

    switchtoDifferenProduct(key) {
        if (!this.props.onSwitchProductInfoForm) {
            alert("Funkce pro zobrazení výchozí stránky není definovaná!!!");
            return;
        }

        this.props.onSwitchProductInfoForm(key);
        this.scrollToTop();
    }

    herbLinkFormatter(herbName, herbId) {
        return (
            <Link to={"/fyto/" + fytoIds.herbInfo + fytoIds.herbInfoSep + herbId} className="button-link" key={herbId} data-key={herbId}>{herbName}</Link>
        );
    }

    linkFormatter(item, usage) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <Link to={"/fyto/" + _items[1]} className="button-link" key={i}
                          data-key={_items[1]}>{_items[0]}</Link>
                );
            }

            return token + maybeSpace;
        });

        if (usage) {
            return (
                <li className="inlineRow">{contents}</li>
            );
        }
        return (
            <div className="inlineRow">{contents}</div>
        );
    }

    usageFormatter(cell, row) {
        let tokens = cell.split("*");
        if (tokens.length > 1) {
            let contents = tokens.map((token, i) => {
                if (i === 0) {
                    return (<div key={i}></div>);
                }

                let subtokens = token.split("%");
                if (subtokens.length > 1) {
                    let subcontents = subtokens.map((subtoken, i) => {
                        if (i === 0) {
                            return (<div key={i}></div>);
                        }

                        return (
                            <div key={i}>{this.linkFormatter(subtoken)}</div>);
                    });

                    return (
                        <li className="inlineRow usageRow">{subtokens[0]}
                            <ul className="ul-list usageSubRow">{subcontents}</ul>
                        </li>
                    );
                } else {
                    return (
                        <div key={i}>{this.linkFormatter(token, true)}</div>);
                }
            });

            return (
                <li className="inlineRow usageRow">{tokens[0]}
                    <ul className="ul-list usageSubRow">{contents}</ul>
                </li>
            );
        }

        return <div className="inlineRow usageRow">{this.linkFormatter(cell, true)}</div>;
    }

    descriptionFormatter(cell, row) {
        let tokens = cell.split("*");
        if (tokens.length > 1) {
            let contents = tokens.map((token, i) => {
                if (i === 0) {
                    return (<div key={i}></div>);
                }

                let subtokens = token.split("%");
                if (subtokens.length > 1) {
                    let subcontents = subtokens.map((subtoken, i) => {
                        if (i === 0) {
                            return (<div key={i}></div>);
                        }

                        return (
                            <li key={i}>{this.linkFormatter(subtoken)}</li>);
                    });

                    return (
                        <div className="inlineRow">{subtokens[0]}
                            <ul className="ul-list">{subcontents}</ul>
                        </div>
                    );
                } else {
                    return (
                        <li key={i}>{this.linkFormatter(token)}</li>);
                }
            });

            return (
                <div className="inlineRow">{this.linkFormatter(tokens[0])}
                    <ul className="ul-list">{contents}</ul>
                </div>
            );
        }

        // if(cell.match('@') !== null) {
        //     let newCell = "";
        //     let tokens2 = cell.split('@');
        //     tokens2.forEach((token, i) => {
        //         let hasSpace = i !== (tokens2.length - 1);
        //         let maybeSpace = hasSpace ? ' ' : '';
        //         newCell +=  "<i key=" + i + ">" + token + maybeSpace + "</i>";
        //     });
        //
        //     cell = newCell;
        // }
        // //return token + maybeSpace;
        return this.linkFormatter(cell);
        // return (
        //     <div className="inlineRow">{cell}</div>
        // );
    }

    dosageFormatter(cell, row) {
        return this.explanationFormatter(cell, row);
    }

    impactFormatter(cell, row) {
        return (
            <li className="inlineRow">{cell}</li>
        );
    }

    explanationFormatter(cell, row) {
        const tokens = cell.split(' ');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';
            if (token.match('#') !== null) {
                if (token.match('~')) {
                    let tokens = token.split('#');
                    return tokens.map((token, i) => {
                        let hasSpace = i !== (tokens.length - 1);
                        let maybeSpace = hasSpace ? ' ' : '';

                        if (token.match('~')) {
                            let _items = token.split('~');
                            return (
                                <Link to={"/fyto/" + _items[1]} className="button-link" key={i}
                                      data-key={_items[1]}>{_items[0]}</Link>
                            );
                        } else {
                            return ' ';
                        }
                    });
                }

                let _items = token.split('#');
                return (
                    <b key={i}>{_items[1] + _items[2] + maybeSpace}</b>
                );
            }
            if (token.match('@') !== null) {
                let _items = token.split('@');
                return (
                    <i key={i}>{_items[1] + maybeSpace}</i>
                );
            }
            return token + maybeSpace;
        });

        return (
            <div className="inlineRow">{contents}</div>
        );
    }

    expandComponent(row) {
        let products = [];
        products.push(row);
        // products.push(
        //         "number": _item.number,
        //         "group": _item.group
        //     };
        // });
        return (
            <BootstrapTable data={products}
                            striped
                            hover
                            pagination={false}
                            tableStyle={{border: '#000 1px solid'}}>
                <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                <TableHeaderColumn thStyle={{fontSize: '14px'}}
                                   width='80'
                                   dataField='number'>Číslo</TableHeaderColumn>
                <TableHeaderColumn thStyle={{fontSize: '14px'}}
                                   width='100'
                                   dataSort={false}
                                   dataField='name'>Jméno</TableHeaderColumn>
                <TableHeaderColumn thStyle={{fontSize: '14px'}}
                                   dataField='group'>Skupina</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    expandColumnComponent({isExpandableRow, isExpanded}) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? '-' : '+');
        } else {
            content = ' ';
        }
        return (
            <div> {content} </div>
        );
    }

    isExpandableRow(row) {
        return true;
    }

    getHerbsData(data, id) {
        if (data.length < 1) return undefined;

        let herb = data.Herbs.filter(item => {
            return item.id === id;
        });

        return herb.length > 0 ? herb[0] : undefined;
    }

    render() {
        const item = this.props.items.filter((_item, i) => {
            return (_item.id === this.props.itemId)
        });
        if (!item || item.length === 0) {
            return (
                <h2 className="productHeading">Žádná data</h2>
            )
        }

        let products = [];
        if(item[0][fytoIds.herbs]) {
            products = item[0][fytoIds.herbs].map(_item => {
                let herb = this.getHerbsData(this.props.herbs, _item.id);
                if (herb === undefined)
                    herb = _item;
                return {
                    [fytoIds.id]: herb.id,
                    [fytoIds.orig]: this.herbLinkFormatter(herb.orig, herb.id),
                    [fytoIds.name]: herb.name,
                    [fytoIds.number]: herb.number,
                    [fytoIds.group]: herb.group
                };
            });
        }

        let explanations = [];
        if (item[0][fytoIds.explanation]) {
            explanations.push({[fytoIds.id]: "0", [fytoIds.explanation]: item[0].explanation});
        }

        let impacts = [];
        if(item[0][fytoIds.impact]) {
            impacts = item[0][fytoIds.impact].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.impact]: _item.description};
            });
        }

        let indications = [];
        if (item[0][fytoIds.indication]) {
            indications = item[0][fytoIds.indication].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.indication]: _item.description};
            });
        }
        let usages = [];
        if (item[0][fytoIds.usage]) {
            usages = item[0][fytoIds.usage].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.usage]: _item.description};
            });
        }

        let combinations = [];
        if (item[0][fytoIds.combination]) {
            combinations = item[0][fytoIds.combination].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.combination]: _item.description};
            });
        }

        let notes = [];
        if (item[0][fytoIds.note]) {
            notes = item[0][fytoIds.note].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.note]: _item.description};
            });
        }

        let dosages = [];
        if (item[0][fytoIds.dosage]) {
            dosages = item[0][fytoIds.dosage].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.dosage]: _item.description};
            });
        }

        let modifications = [];
        if (item[0][fytoIds.modification]) {
            modifications = item[0][fytoIds.modification].map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.modification]: _item.description};
            });
        }

        const orig = item[0].orig.length > 0 ? item[0].orig : "";
        let additional = orig.length > 0 ? " (" + orig + ")" : "";
        if (item[0].ext) {
            const ext = item[0].ext.length > 0 ? item[0].ext : "";

            if (ext.length > 0) {
                additional = orig.length > 0 ? " (" + ext + " / " + orig + ")" : " (" + ext + ")";
            }
        }
        return (
            <div className="productInfo">
                <section ref={(section) => {
                    this.top = section;
                }}></section>
                <h2 className="productHeading">{item[0][fytoIds.name] + " " + additional}</h2>
                {/*<h2 className="productHeadingOrig">{(item[0].orig.length > 0 ? " (" + item[0].orig + ")" : "")}</h2>*/}
                <h4 className="designationHeading">{item[0][fytoIds.leung]}</h4>
                <h5 className="descriptionHeading">{item[0][fytoIds.description]}</h5>
                {explanations.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={explanations}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                               thStyle={{fontSize: '18px'}}
                                               dataFormat={this.explanationFormatter}
                                               dataField={fytoIds.explanation}></TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}

                <BootstrapTable data={products}
                                trClassName='inlineRow'
                                striped
                                hover
                                pagination={false}
                                tableStyle={{border: '#000 2.5px solid'}}

                >
                    <TableHeaderColumn isKey
                                       dataField={fytoIds.id}
                                       hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                       width='250'
                                       dataSort={true}
                                       dataFormat={this.herbLinkFormatter}
                                       dataField={fytoIds.orig}>Orig</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                       width='50'
                                       dataField={fytoIds.number}
                                       dataSort={true}
                    >#</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                       width='500'
                                       dataField={fytoIds.group}
                    >Skupina (dle Farmakologie)</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                       dataSort={false}
                                       dataField={fytoIds.name}
                    >Jméno</TableHeaderColumn>
                </BootstrapTable>
                {impacts.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={impacts}
                                        trClassName='inlineRow'
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                               thStyle={{fontSize: '18px'}}
                                               dataFormat={this.impactFormatter}
                                               dataField={fytoIds.impact}>účinky:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {usages.length > 0 ? (
                    <div className="marginTop">

                        <BootstrapTable data={usages}
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                               thStyle={{fontSize: '18px'}}
                                               dataFormat={this.usageFormatter}
                                               dataField={fytoIds.usage}>použití na:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {indications.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={indications}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField={fytoIds.id} hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                               thStyle={{fontSize: '18px'}}
                                               dataFormat={this.usageFormatter}
                                               dataField={fytoIds.indication}>indikace:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {combinations.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={combinations}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                               thStyle={{fontSize: '18px'}}
                                               dataFormat={this.usageFormatter}
                                               dataField={fytoIds.combination}>kombinace:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {modifications.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={modifications}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField={fytoIds.id} hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                               tdStyle={{fontSize: '14px', whiteSpace: 'normal'}}
                                               dataFormat={this.descriptionFormatter}
                                               dataField={fytoIds.modification}>modifikace:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {notes.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={notes}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField={fytoIds.id} hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                               tdStyle={{fontWeight: '800', fontSize: '14px', whiteSpace: 'normal'}}
                                               dataFormat={this.descriptionFormatter}
                                               dataField={fytoIds.note}>upozornění:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {dosages.length > 0 ? (
                    <div className="marginTop">
                        <BootstrapTable data={dosages}
                                        striped
                                        hover
                                        pagination={false}
                                        tableStyle={{border: '#000 2.5px solid'}}
                        >
                            <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{fontSize: '18px'}}
                                               tdStyle={{fontSize: '14px', whiteSpace: 'normal'}}
                                               dataFormat={this.dosageFormatter}
                                               dataField={
                                                   fytoIds.dosage}>dávkování:</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                ) : (
                    <div/>
                )}
                {/*{this.state.history.length > 0 ? (*/}
                <FloatingMenu
                    key="i0"
                    title="domů"
                    icon="fa fa-home"
                    class="floating-menu"/>
                {/*) : (*/}
                {/*<div />*/}
                {/*)}*/}
                {/*<FloatingMenu*/}
                {/*    id="i1"*/}
                {/*    title="zpět"*/}
                {/*    icon="fa fa-arrow-left"*/}
                {/*    class="floating-menu"*/}
                {/*    showBack={this.onBack}/>*/}
            </div>
        );
    }
}

export default ProductInfo;
