export const keys = {
    name: {value: 'name', label: 'jméno'},
    description: {value: 'description', label: 'popis'},
    header: {value: 'header', label: ''},
    pos: {value: 'pos', label: ''},
    id: {value: 'id', label: ''},
    orig: {value: 'orig', label: ''},
    points: {value: 'points', label: ''},
    short: {value: 'short', label: ''},
    img: {value: '.png', label: ''},
    imgName: {value: 'img', label: ''},
    imgPath: {value: './assets/images/bach/', label: ''},
    number: {value: 'number', label: ''},
    categories: {value: 'categories', label: 'Skupina:'},
    categoryId: {value: 'categoryId', label: ''},
    mission: {value: 'mission', label: 'Téma:'},
    time: {value: 'time', label: 'čas'},
    color: {value: 'color', label: 'barva', tooltip: 'barva dle prvku'},
    hide: {value: 'hide', label: ''},
    search: {value: 'search', label: 'Hledat'},
    searchText: {value: 'searchText', label: ''},
    checkSearchText: {value: 'checkSearchText', label: ''},
    current: {value: 'current', label: ''},
    user: {value: process.env.REACT_APP_EMAIL, label: ''},
    passwd: {value: 'passwd', label: ''},
    localization: {value: 'localization', label: 'Lokalizace:'},
    indication: {value: 'indication', label: 'Indikace:'},
    combination: {value: 'combination', label: 'Kombinace:'},
    function: {value: 'function', label: 'Symptomy:'},
    category: {value: 'category', label: 'Kategorie:'},
    items: {value: 'items', label: ''},
    note: {value: 'note', label: 'Poznámky:'},
    cards: {value: 'cards', label: 'zobrazit karty', tooltip:'zobrazit jako karty'},
    cookieaccept: {value: 'aku-accepts-cookies', label: ''},
    bamaijiaohuixue: {value: 'bamaijiaohuixue', label: 'bamaijiaohuixue', tooltip:'osm propojovacích bodů mimořádných drah'},
    sunsimiaoguixue: {value: 'sunsimiaoguixue', label: 'sunsimiao guixue', tooltip:'13 Sun Simiaových bodů démonů'},
    qihaixue: {value: 'qihaixue', label: 'qihaixue', tooltip:'body Moře čchi'},
    suihaixue: {value: 'suihaixue', label: 'suihaixue', tooltip:'body Moře dřeně'},
    madanyangxue: {value: 'madanyangxue', label: 'madanyangxue', tooltip:'body Ma Danyang shier xue'},
    windowxue: {value: 'windowxue', label: 'window of heaven', tooltip:'body Okna do nebes'},
    xiahexue: {value: 'xiahexue', label: 'xiahexue', tooltip:'dolní body spojení'},
    extraxue: {value: 'extraxue', label: 'extraxue', tooltip:'extra body'},
    jinglabels: {value: 'jinglabels', label: 'nadpisy drah', tooltip:'zobrazit nadpisy drah'},
    leung: {value: 'leung', label: 'Leungova tabulka', tooltip:'Leungova tabulka boddů v rámci bagang'},
    bagangResult: {value: 'bagangResult', label: 'Leungova tabulka', tooltip:'Leungova tabulka boddů v rámci bagang'},
    organsResult: {value: 'organsResult'},
    bodyPartsResult: {value: 'bodyPartsResult'},

};