import eb from './eb.json';
import hs from './hs.json';
import months from './months.json';
import towns from './towns.json';
import hours from './hours.json';
import date from './date.json';
import config from './config.json';
import scChart from './sexagenarycyclechart.json';
import jieqi from './jieqi.json';
import trg from './trigram.json';
import hextrg from './hexagrams.json';

export const data = {
    eb: eb,
    hs: hs,
    date: date,
    months: months,
    hours: hours,
    towns: towns,
    config: config,
    scChart: scChart,
    jieqi: jieqi,
    trg: trg,
    hextrg: hextrg
};

export const ids = {
    scChart: "scChart",
    jieqi: "jieqi",
    bigMonth: "bigMonth",
    lValue: "lValue",
    add: "add",
    removeEvt: "remove",
    maxItems: "maxItems",
    key: "keyId",
    en: "en",
    activeId: "activeId",
    itemIds: "ids",
    itemId: "id",
    sData: "sDate",
    mData: "mDate",
    color: "color",
    hhs: "hhs",
    hhs1: "hhs1",
    hhs2: "hhs2",
    hhs3: "hhs3",
    hhsPower: "hhsPower",
    generated: "generated",
    insideUpdate: "insideUpdate",
    startYear1: "startYear1",
    ebYears: "ebYears",
    ebMonth: "ebMonth",
    hsEmpty: "hsEmpty",
    hsEmptyId: "f8b149fd",
    ebEmpty: "ebEmpty",
    ebEmptyId: "8ecac882",
    isYang: "isYang",
    time: "time",
    startMonthHS: "startMonthHS",
    eb: "eb",
    pos: "pos",
    annualPillars: "annualPillars",
    annualYear1: "annualYear1",
    annualYear2: "annualYear2",
    annualYear3: "annualYear3",
    annualYear4: "annualYear4",
    luckyPillars: "luckyPillars",
    luckyPillar: "luckyPillar",
    luckyPillar1: "luckyPillar1",
    luckyPillar2: "luckyPillar2",
    luckyPillar3: "luckyPillar3",
    luckyPillar4: "luckyPillar4",
    luckyPillar5: "luckyPillar5",
    luckyPillar6: "luckyPillar6",
    luckyPillar7: "luckyPillar7",
    luckyPillar8: "luckyPillar8",
    luckyPillar9: "luckyPillar9",
    activeLuckyPillar: "activeLuckyPillar",
    heavenCombination: "heavenCombination",
    heavenCombinationElelement: "heavenCombinationElement",
    directCombination: "directCombination",
    directCombinationElement: "directCombinationElement",
    harmonicCombination: "harmonicCombination",
    harmonicCombinationItem: "harmonicCombinationItem",
    harmonicEBCombination: "harmonicEBCombination",
    harmonicHSCombination: "harmonicHSCombination",
    harmonicEBCombinationElement: "harmonicEBCombinationElement",
    harmonicHSCombinationElement: "harmonicHSCombinationElement",
    sixCollision: "sixCollision",
    fiveFactors: "fiveFactors",
    taiYuanMingGong: "taiYuanMingGong",
    mingGong: "mingGong",
    taiYuan: "taiYuan",
    fengKong: "fengKong",
    fuYin: "fuYin",
    tianYiGuiRen: "tianYiGuiRen",
    taoHua: "taoHua",
    yiMa: "yiMa",
    tianDe: "tianDe",
    tianYi: "tianYi",
    wenChang: "wenChang",
    taiJi: "taiJi",
    weakDM: "card-item-active-weak",
    strongDM: "card-item-active-strong",
    wood: "wood",
    fire: "fire",
    earth: "earth",
    metal: "metal",
    water: "water",
    hs: "hs",
    energy: "energyId",
    element: "elementId",
    motherElement: "motherElementId",
    gender: "genderId",
    healthProblem: "healthProblemId",
    print: "printId",
    printPreview: "printPreviewId",
    next: "nextId",
    previous: "previousId",
    back: "backId",
    measure: "measureId",
    name: 'userNameId',
    options: 'OptionsId',
    nameId: 'name',
    origId: 'orig',
    age: 'ageId',
    day: 'dayId',
    days: 'days',
    month: 'monthId',
    monthView: 'monthView',
    weekView: 'weekView',
    dayView: 'dayView',
    months: 'months',
    monthEnergy: 'monthEnergyId',
    year: 'yearId',
    birthYearTongShu: 'birthYearTongShuId',
    birthMonthTongShu: 'birthMonthTongShu',
    killersYear1: 'killersYear1Id',
    killersYear2: 'killersYear2Id',
    killersYear3: 'killersYear3Id',
    killersMonth1: 'killersMonth1Id',
    killersMonth2: 'killersMonth2Id',
    killersMonth3: 'killersMonth3Id',
    killers: 'killersId',
    town: 'townId',
    towns: 'towns',
    empty: 'emptyId',
    annualYear: 'annualYearId',
    hour: 'hourId',
    min: 'min',
    max: 'max',
    minute: 'minuteId',
    unknown: 'unknownId',
    chineseCalendar: 'chineseCalendarId',
    title: 'titleId',
    description: 'description',
    establish: 'establishId',
    remove: 'removeId',
    full: 'fullId',
    balance: 'balanceId',
    destruction: 'destructionId',
    stable: "stableId",
    initiate: "initiateId",
    danger: "dangerId",
    success: "successId",
    receive: "receiveId",
    open: "openId",
    close: "closeId",
    yearCollision: 'yearCollision',
    monthCollision: 'monthCollision',
    dayCollision: 'dayCollision',
    results: "resultsId",
    monthId: "months",
    cure: "cureId",
    channelKey: "channelKeyId",
    imageKey: "imageId",
    imgEBPath: "./assets/images/bazi/eb/",
    imgPath: "./assets/images/bazi/",
    imgHSPath: "./assets/images/bazi/hs/",
    imgTrigramPath: "./assets/images/bazi/trigrams/",
    printArea: 'printAreaId',
    printTableArea: 'printTableAreaId',
    separator: ",",
    timeSeparator: ":",
    itemstoCheck: "itemsToCheckId",
    December: 1,
    January: 2,
    February: 3,
    width: "width",
    length: "length",
    timediff: "timediff",
    yiJing: "yiJing",
    tongShu: "tongShu",
    yijingNum: "yijingNum",
    trigramNum: "num",
    trigrams: "trg",
    hextrigrams: "hextrg",
    trigramsDefault: "default",
    trigramsMirror: "mirror",
    trigramsSubst: "subst",
    trigramsSpecial: "special",
    earthTrigram: "earthTrigram",
    heaven: "heaven",
    limit: "limit",
    heavenLimit: "heavenLimit",
    earthLimit: "earthLimit",
    center: "middle",
    heavenTrigram: "heavenTrigram",
    trigramDesc: "trigramDesc",
    hexagramDesc: "hexagramDesc",
    topImage: "topImage",
    bottomImage: "bottomImage"
};

export const imagesKey = {
    fileExt: ".png",
    dmWood: "dm_wood",
    dmFire: "dm_fire",
    dmEarth: "dm_earth",
    dmMetal: "dm_metal",
    dmWater: "dm_water",
    pagguangRight: "pagguangRight",
    danLeft: "danLeft",
    danRight: "danRight",
    weiLeft: "weiLeft",
    weiRight: "weiRight",
    xiaochangLeft: "xiaochangLeft",
    xiaochangRight: "xiaochangRight",
    sanjiaoLeft: "sanjiaoLeft",
    sanjiaoRight: "sanjiaoRight",
    dachanLeft: "dachanLeft",
    dachanRight: "dachanRight",
    feiLeft: "feiLeft",
    feiRight: "feiRight",
    xinbaoLeft: "xinbaoLeft",
    xinbaoRight: "xinbaoRight",
    xinLeft: "xinLeft",
    xinRight: "xinRight",
    piLeft: "piLeft",
    piRight: "piRight",
    ganLeft: "ganLeft",
    ganRight: "ganRight",
    shenLeft: "shenLeft",
    shenRight: "shenRight",
};

export const strings = {
    print: "Tisk",
    download: "Stáhnout",
    date: "Datum narození",
    time: "Hodina narození",
    town: "Město narození",
    day: "Den",
    month: "Měsíc",
    week: "Týden",
    year: "Rok",
    hour: "Hodina",
    minute: "Minuta",
    unknown: "Neznámá",
    chineseCalendar: "Lunární rok",
    age: "Věk",
    printPreview: "Náhled",
    next: "Další",
    previous: "Předchozí",
    back: "Zpět",
    title: "Osobní čínský horoskop BaZi",
    name: 'Jméno:',
    options: 'Možnosti:',
    measure: "Měření",
    empty: "",
    id: "id",
    weakStrongDM: "silný / slabý DM",
    annualPillars: "Annual Pillars",
    taiYuanMingGong: "TaiYuan & MingGong (početí a osud)",
    taiYuan: "TaiYuan",
    mingGong: "MingGong",
    fengKong: "FengKong",
    fuYin: "FuYin",
    tianYiGuiRen: "TianYiGuiRen",
    taoHua: "TaoHua",
    yiMa: "YiMa",
    tianDe: "TianDe",
    tianYi: "TianYi",
    wenChang: "WenChang",
    taiJi: "TaiJi",
    luckyPillars: "DaYun (Luck Pillars)",
    check: "Kontrola",
    postmeasure: "Přeměření",
    cure: "Ošetření",
    measureLeft: "levá",
    measureRight: "pravá",
    measureRatio: "poměr",
    results: "Výsledky",
    gender: "Pohlaví:",
    man: "Muž",
    woman: "Žena",
    other: "Jiné",
    unnamed: "Nepojmenované",
    labelFactorName: "Faktor",
    labelFactorValueJang: "poměr Jangu",
    labelFactorValueJin: "poměr Jinu",
    labelFactorValue: "Hodnota",
    errorReadingData: "chyba při načítání",
    fiveFateFactors: "5 faktorů osudu",
    dm: "Osobnostní faktor (DM)",
    tf: "Tvůrčí faktor (TF)",
    fb: "Faktor bohatství (FB)",
    fk: "Faktor kontroly (FK)",
    fp: "Faktor podpory (FP)",
    yiJing: "Yi Jing (osobní hexagram)",
    tongShu: "Tong Shu Almanach",
    heaven: "Nebe",
    earth: "Země",
    monday: "Pondělí",
    tuesday: "Úterý",
    wednesday: "Středa",
    thursday: "Čtvrtek",
    friday: "Pátek",
    saturday: "Sobota",
    sunday: "Neděle",
    previousMonth: "předchozí měsíc",
    nextMonth: "následující měsíc",
    monthView: "měsíční náhled",
    weekView: "týdenní náhled",
    dayView: "denní náhled",
    heavenCombination: "Nebeské trojice",
    directCombination: "Přímé kombinace",
    harmonicCombination: "Manželské kombinace",
    sixCollision: "Kolize",
    dyc: "den roční kolize",
    dmc: "den měsíční kolize",
    dpc: "den osobní kolize",
    killersYear1: "den loupeže (r)",
    killersYear2: "den kalamity, nemoci (r)",
    killersYear3: "den překážek (r)",
    killersMonth1: "den loupeže (m)",
    killersMonth2: "den kalamity, nemoci (m)",
    killersMonth3: "den překážek (m)",
    establish: "založení",
    remove: "odstranění",
    full: "hojnost",
    balance: "vyrovnání",
    stable: "stabilita",
    initiate: "zahájení",
    destruction: "zničení",
    danger: "nebezpečí",
    success: "úspěch",
    receive: "obdržení",
    open: "otevření",
    close: "zavření",
    luckyPilarsStartDesc1: "věk Lucky Pillar začíná v %1 roku, %2 měsících, %3 dnech v %4",
    luckyPilarsStartDesc2: "věk Lucky Pillar začíná v %1 letech, %2 měsících, %3 dnech v %4",
    mingGongDesc: "MingGong, palác vaší karmy, nabízí vhled do obecné trajektorie života člověka. Můžete si to představit jako široce ovlivňující karmickou energii nebo osud.",
    taiYuanDesc: "TaiYuan neboli počátek početí, představuje převládající energii ovlivňující okamžik početí. Také předává informace o tom, jak energie předků a genetika ovlivňují BaZi.",
    fengKongDesc: "FengKong označuje duševní prázdnotou nebo pokročilý stav duchovního odloučení v životě člověka. FengKong také znamená kvalitu prázdnoty nebo nedostatku, zejména pokud jde o BaZi. Například prázdnota v 'Money Elementu' může ukazovat na celoživotní vzorec finanční nestability, zatímco FengKong v pozici partnera může znamenat pozdní nalezení životního partnera (nebo dokonce vůbec žádného životního partnera).",
    fuYinDesc: "FuYin znamená „ukrytý pláč“. To samo o sobě naznačuje, že se nejedná úplně o pozitivní vliv. Jde vlastně o jisté znásobení nebo zesílení určitého vlivu, které ono znamení v našem životě představuje.. Přítomnost FuYin je silnou identifikací významných životních výzev. Dvojice kmenů / větví FuYin může provádět pokusy v oblasti symbolicky související s přidruženými prvky a / nebo pozicí v BaZi. Je důležité si uvědomit, že FuYin není zaručeným ukazatelem zkázy a temnoty. Jelikož je podstatou kalendáře opakování cyklů, každý z nás bude během svého života prožívat různé stupně FuYin. Na sílu a vliv této energie má vliv souhrn samotného BaZi a také autokultivační praxe jednotlivce.",
    tianYiGuiRenDesc: "TianYiGuiRen, což doslovně znamená „štěstí, ušlechtilá osoba“. Je to nejnadějnější indikátor, jaký člověk může mít v astrologické tabulce. Přítomnost TianYi naznačuje dobrý život téměř ve všech aspektech a také působí jako označení toho, že daná osoba bude mít relativně snadný posun, transformaci nebo pohyb v náročných situacích, které život nevyhnutelně přináší. TianYi může také zastávat čas nebo osobu, která s největší pravděpodobností v případě potřeby poskytne externí podporu. Schopnost TianYi prosadit svůj vliv na BaZi se dramaticky snižuje, pokud je také v pozici FengKong.",
    taoHuaDesc: "TaoHua odhaluje vzorce lásky a romantiky. Může poskytnout vhled do základních strategií romantického vztahu jednotlivce a může také silně ovlivnit roční cyklus a DaYun. Je třeba si uvědomit, že TaoHua je „vnitřní“ nebo „vnější“ odrůdy. Vnitřní TaoHua představuje vlastnosti vnitřní krásy a skutečného talentu. Mít v BaZi vnitřní TaoHua naznačuje, že člověk má větší pravděpodobnost, že bude věrný ve vztazích a že bude zapojen do stabilních milostných vztahů po celý svůj život. TaoHua je považována za „vnitřní“, je-li umístěna v poloze větve 'Rok' nebo 'Měsíc'. Vnější Taohua představuje vlastnosti vnější krásy a obvykle souvisí se silným sociálním magnetismem. Mít vnější TaoHua naznačuje, že člověk má větší pravděpodobnost, že bude mít po celý život mnoho milenců, může mít vážné problémy s nevěrou a je často zapojen do řady nestabilních milostných realit. Vnější Taohua se nachází v pozici denní nebo hodinové větve.",
    yiMaDesc: "YiMa (zázračný dům), jeho přítomnost v BaZi naznačuje, že určitá míra nestability ovlivní BaZi. Mohlo by se to projevit jako období těžkého cestování, velkého přemístění nebo jiných obecných otřesů.",
    tianDeDesc: "TianDe (nebeská ctnost) pomáhá uvolnit nebo pokárat energii smůly, čímž zvyšuje pravděpodobnost, že osoba zažije obecně klidný a plynulý život. Také to naznačuje, že daná osoba má velkorysé srdce a autentickou vůli pomáhat druhým. Zatímco TianDe lze najít kdekoli v BaZi, má největší vliv, pokud je nalezen v „hlavním pilíři“, TaiYuan nebo MingGong.",
    tianYiDesc: "TianYi (medicína), jeho přítomnost v grafu naznačuje, že osoba bude pravděpodobně požehnána dobrým zdravím. Rovněž se navrhuje, že jedinec má vrozený talent praktikovat jakýkoli druh medicíny nebo tradičního léčitelského umění. Pokud je TianYi slabý, mohlo by to znamenat, že by člověk mohl mít zdravotní problémy po celý svůj život.",
    wenChangDesc: "WenChang (vědomosti) je známkou silné tvůrčí energie, která se projevuje například ve vysoké míře umění, opravitelné eleganci nebo dlouhém životě úspěšného stipendia.",
    taijiDesc: "TaiJi (mystika), představuje silné spojení s intuicí a duchovností, stejně jako kvalitu schopnosti rezonovat s mystickými kulturami z celého světa a porozumět jim. Je to také obecně příznivá energie ovlivňující náš život.",
};

export const results = {
    zao: {
        id: "resultsZaoId",
        name: "Průměrné sucho",
        error: ""
    },
    huo: {
        id: "resultsHuoId",
        name: "Průměrné teplo",
        error: "Teplo má být menší než celkový průměr a má být nejmenší!"
    },
    feng: {
        id: "resultsFengId",
        name: "Průměrný vítr",
        error: ""
    },
    shi: {
        id: "resultsShiId",
        name: "Průměrné vlhko",
        error: ""
    },
    han: {
        id: "resultsHanId",
        name: "Průměrný chlad",
        error: "Chlad má být větší než celkový průměr a má být největší!"
    },
    hanhuoratio: {
        id: "resultRatioHuosHanId",
        name: "Poměr chladu / tepla",
        error1: "Poměr chladu / tepla je větší 2.2, je potřeba moxovat!",
        error2: "Poměr chladu / tepla je menší než 1.2, je potřeba prohřívat obě větve drah, PC, TE, KI a SI, které mají dlouhé časy!"
    },
    resultsRatio: {
        id: "resultsRatio",
        name: "Celkový průměr",
        error: ""
    }
};