import React, {Component} from 'react'
import Select from 'react-select'
import ReactChipInput from "react-chip-input";
import {keys} from "./Constants"
import {acu_data} from "./data/Data"
import AcuCards from "./Cards/Cards";
import {reactLocalStorage} from 'reactjs-localstorage';

import './Client.css';
import 'rodal/lib/rodal.css';

class Acu extends Component {
    constructor(props) {
        super(props);

        this.onLoadConfiguration = this.onLoadConfiguration.bind(this);
        this.onShowChannel = this.onShowChannel.bind(this);
        this.getChannels = this.getChannels.bind(this);
        this.getXuesOptions = this.getXuesOptions.bind(this);
        this.channelChange = this.channelChange.bind(this);
        this.xueChange = this.xueChange.bind(this);
        this.isAnyXueOption = this.isAnyXueOption.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);

        this.saveData = this.saveData.bind(this);
        this.channels = React.createRef();

        this.state = {
            configuration: [],
            currentChannel: "",
            filters: {}
        }
    }

    saveData() {
        //save data
        let _filters = this.state.filters;
        reactLocalStorage.set(keys.yuanxue.value, _filters[keys.yuanxue.value]);
        reactLocalStorage.set(keys.xiexue.value, _filters[keys.xiexue.value]);
        reactLocalStorage.set(keys.xixue.value, _filters[keys.xixue.value]);
        reactLocalStorage.set(keys.buxue.value, _filters[keys.buxue.value]);
        reactLocalStorage.set(keys.benxue.value, _filters[keys.benxue.value]);
        reactLocalStorage.set(keys.luoxue.value, _filters[keys.luoxue.value]);
        reactLocalStorage.set(keys.wushuxue.value, _filters[keys.wushuxue.value]);
        reactLocalStorage.set(keys.beishuxue.value, _filters[keys.beishuxue.value]);
        reactLocalStorage.set(keys.bahuixue.value, _filters[keys.bahuixue.value]);
        reactLocalStorage.set(keys.xiahexue.value, _filters[keys.xiahexue.value]);
        reactLocalStorage.set(keys.bamaijiaohuixue.value, _filters[keys.bamaijiaohuixue.value]);
        reactLocalStorage.set(keys.jiaohuixue.value, _filters[keys.jiaohuixue.value]);
        reactLocalStorage.set(keys.windowxue.value, _filters[keys.windowxue.value]);
        reactLocalStorage.set(keys.madanyangxue.value, _filters[keys.madanyangxue.value]);
        reactLocalStorage.set(keys.sunsimiaoguixue.value, _filters[keys.sunsimiaoguixue.value]);
        reactLocalStorage.set(keys.extraxue.value, _filters[keys.extraxue.value]);
        reactLocalStorage.set(keys.mu_xue.value, _filters[keys.mu_xue.value]);

        // reactLocalStorage.set(keys.jingxue.value, _filters[keys.jingxue.value]);
        // reactLocalStorage.set(keys.yingxue.value, _filters[keys.yingxue.value]);
        // reactLocalStorage.set(keys.shuxue.value, _filters[keys.shuxue.value]);
        // reactLocalStorage.set(keys.jingxue2.value, _filters[keys.jingxue2.value]);
        // reactLocalStorage.set(keys.hexue.value, _filters[keys.hexue.value]);
        reactLocalStorage.set(keys.allxue.value, _filters[keys.allxue.value]);

        reactLocalStorage.set(keys.current.value, this.state.currentChannel);
        //reactLocalStorage.set(keys.cards.value, _filters[keys.cards.value]);
        reactLocalStorage.set(keys.jinglabels.value, _filters[keys.jinglabels.value]);
        reactLocalStorage.set(keys.search.value, _filters[keys.search.value]);
        reactLocalStorage.set(keys.searchText.value, _filters[keys.searchText.value]);
    }

    componentDidMount() {
        this.onLoadConfiguration(acu_data.configuration);
    }

    onLoadConfiguration(data) {
        let wushuxue = reactLocalStorage.get(keys.wushuxue.value);
        let beishuxue = reactLocalStorage.get(keys.beishuxue.value);
        let mu_xue = reactLocalStorage.get(keys.mu_xue.value);
        let yuanxue = reactLocalStorage.get(keys.yuanxue.value);
        let buxue = reactLocalStorage.get(keys.buxue.value);
        let benxue = reactLocalStorage.get(keys.benxue.value);
        let xiexue = reactLocalStorage.get(keys.xiexue.value);
        let xixue = reactLocalStorage.get(keys.xixue.value);
        let luoxue = reactLocalStorage.get(keys.luoxue.value);
        let bahuixue = reactLocalStorage.get(keys.bahuixue.value);
        let bamaijiaohuixue = reactLocalStorage.get(keys.bamaijiaohuixue.value);
        let jiaohuixue = reactLocalStorage.get(keys.jiaohuixue.value);
        let xiahexue = reactLocalStorage.get(keys.xiahexue.value);
        let extraxue = reactLocalStorage.get(keys.extraxue.value);
        let windowxue = reactLocalStorage.get(keys.windowxue.value);
        let madanyangxue = reactLocalStorage.get(keys.madanyangxue.value);
        let sunsimiaoxue = reactLocalStorage.get(keys.sunsimiaoguixue.value);

        // let jingxue = reactLocalStorage.get(keys.jingxue.value);
        // let yingxue = reactLocalStorage.get(keys.yingxue.value);
        // let shuxue = reactLocalStorage.get(keys.shuxue.value);
        // let jing2xue = reactLocalStorage.get(keys.jingxue2.value);
        // let hexue = reactLocalStorage.get(keys.hexue.value);
        let allxue = reactLocalStorage.get(keys.allxue.value);

        //let isCards = reactLocalStorage.get(keys.cards.value);
        let isLabels = reactLocalStorage.get(keys.jinglabels.value);
        let isSearching = reactLocalStorage.get(keys.search.value);
        let searchText = reactLocalStorage.get(keys.searchText.value);

        let currentChannel = reactLocalStorage.get(keys.current.value);
        if (currentChannel === undefined) currentChannel = "";

        let _filters = this.state.filters;
        _filters[keys.wushuxue.value] = wushuxue === undefined ? true : (wushuxue === "true");
        _filters[keys.beishuxue.value] = wushuxue === undefined ? false : (beishuxue === "true");
        _filters[keys.mu_xue.value] = mu_xue === undefined ? false : (mu_xue === "true");
        _filters[keys.color.value] = true;

        _filters[keys.yuanxue.value] = yuanxue === undefined ? false : (yuanxue === "true");
        _filters[keys.xixue.value] = xixue === undefined ? false : (xixue === "true");
        _filters[keys.luoxue.value] = luoxue === undefined ? false : (luoxue === "true");
        _filters[keys.benxue.value] = benxue === undefined ? false : (benxue === "true");
        _filters[keys.buxue.value] = buxue === undefined ? false : (buxue === "true");
        _filters[keys.xiexue.value] = xiexue === undefined ? false : (xiexue === "true");
        _filters[keys.bahuixue.value] = bahuixue === undefined ? false : (bahuixue === "true");
        _filters[keys.xiahexue.value] = xiahexue === undefined ? false : (xiahexue === "true");
        _filters[keys.bamaijiaohuixue.value] = bamaijiaohuixue === undefined ? false : (bamaijiaohuixue === "true");
        _filters[keys.jiaohuixue.value] = jiaohuixue === undefined ? false : (jiaohuixue === "true");
        _filters[keys.extraxue.value] = extraxue === undefined ? false : (extraxue === "true");
        _filters[keys.windowxue.value] = windowxue === undefined ? false : (windowxue === "true");
        _filters[keys.madanyangxue.value] = madanyangxue === undefined ? false : (madanyangxue === "true");
        _filters[keys.sunsimiaoguixue.value] = sunsimiaoxue === undefined ? false : (sunsimiaoxue === "true");

        // _filters[keys.jingxue.value] = jingxue === undefined ? false : (jingxue === "true");
        // _filters[keys.yingxue.value] = yingxue === undefined ? false : (yingxue === "true");
        // _filters[keys.shuxue.value] = shuxue === undefined ? false : (shuxue === "true");
        // _filters[keys.jingxue2.value] = jing2xue === undefined ? false : (jing2xue === "true");
        // _filters[keys.hexue.value] = hexue === undefined ? false : (hexue === "true");
        _filters[keys.search.value] = isSearching === undefined ? false : (isSearching === "true");
        _filters[keys.searchText.value] = searchText === undefined ? "" : (searchText);
        _filters[keys.cards.value] = true;//isCards===undefined ? true : (isCards==="true");

        let _allxue = allxue === undefined ? true : (allxue === "true");
        if (!_allxue && !this.isAnyXueOption())
            _allxue = true;
        _filters[keys.allxue.value] = _allxue;
        _filters[keys.jinglabels.value] = isLabels === undefined ? true : (isLabels === "true" || _filters[keys.allxue.value]);

        let that = this;
        let tempData = this.state.configuration;
        that.setState({
            configuration: tempData.concat(data),
            filters: _filters,
            currentChannel: currentChannel
        }, () => {
            that.onShowChannel(this.state.currentChannel);
        });
    };

    getChannels() {
        if (this.state.configuration.length === 0) return [];
        let channels = this.state.configuration[0]["channels"];
        return channels.map(item => {
            let id = item["channel"];
            let name = item["name"];
            return {value: id, label: name};
        });
    }

    getXuesOptions(filters) {
        let options = [];
        // if (filters[keys.yuanxue.value] || filters[keys.xixue.value] || filters[keys.luoxue.value] ||
        //     filters[keys.jinglabels.value] || filters[keys.wushuxue.value] || filters[keys.allxue.value])
        options.push({value: keys.jinglabels.value, label: keys.jinglabels.label});

        // if ((filters[keys.beishuxue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.beishuxue.value, label: keys.beishuxue.label});

        // if ((filters[keys.mu_xue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.mu_xue.value, label: keys.mu_xue.label});

        options.push({value: keys.wushuxue.value, label: keys.wushuxue.label});
        if ((filters[keys.yuanxue.value] || filters[keys.xixue.value] || filters[keys.luoxue.value] || filters[keys.wushuxue.value] ||
                filters[keys.allxue.value]) && !filters[keys.extraxue.value] && !filters[keys.windowxue.value] &&
            !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
            !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] &&
            !filters[keys.mu_xue.value] && !filters[keys.extraxue.value] && filters[keys.benxue.value] && filters[keys.buxue.value] &&
            filters[keys.xiexue.value]) {

            if (filters[keys.wushuxue.value]) {
                // options.push({value: keys.jingxue.value, label: keys.jingxue.label});
                // options.push({value: keys.yingxue.value, label: keys.yingxue.label});
                // options.push({value: keys.shuxue.value, label: keys.shuxue.label});
                // options.push({value: keys.jingxue2.value, label: keys.jingxue2.label});
                // options.push({value: keys.hexue.value, label: keys.hexue.label});
                options.push({value: keys.benxue.value, label: keys.benxue.label});
                options.push({value: keys.buxue.value, label: keys.buxue.label});
                options.push({value: keys.xiexue.value, label: keys.xiexue.label});
            }
        }

        // if ((filters[keys.yuanxue.value] || filters[keys.xixue.value] || filters[keys.luoxue.value] || filters[keys.wushuxue.value] ||
        //         filters[keys.xixue.value] || filters[keys.allxue.value]) && !filters[keys.mu_xue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] && !filters[keys.windowxue.value])
        options.push({value: keys.yuanxue.value, label: keys.yuanxue.label});

        // if ((filters[keys.yuanxue.value] || filters[keys.xixue.value] || filters[keys.luoxue.value] || filters[keys.wushuxue.value] ||
        //         filters[keys.allxue.value]) && !filters[keys.mu_xue.value] && !filters[keys.extraxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value])
        options.push({value: keys.luoxue.value, label: keys.luoxue.label});


        // if ((filters[keys.yuanxue.value] || filters[keys.xixue.value] || filters[keys.luoxue.value] || filters[keys.wushuxue.value] ||
        //         filters[keys.allxue.value]) && !filters[keys.mu_xue.value] && !filters[keys.jiaohuixue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bahuixue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value])
        options.push({value: keys.xixue.value, label: keys.xixue.label});

        // if ((filters[keys.xiahexue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.xiahexue.value, label: keys.xiahexue.label});

        // if ((filters[keys.bahuixue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.bahuixue.value, label: keys.bahuixue.label});

        // if ((filters[keys.bamaijiaohuixue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.bahuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.bamaijiaohuixue.value, label: keys.bamaijiaohuixue.label});

        // if ((filters[keys.jiaohuixue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.xiahexue.value] && !filters[keys.bamaijiaohuixue.value] &&
        //     !filters[keys.bahuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.madanyangxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.jiaohuixue.value, label: keys.jiaohuixue.label});

        // if ((filters[keys.sunsimiaoguixue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.bahuixue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.jiaohuixue.value] && !filters[keys.madanyangxue.value] &&
        //     !filters[keys.jinglabels.value] && !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.sunsimiaoguixue.value, label: keys.sunsimiaoguixue.label});

        // if ((filters[keys.madanyangxue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.extraxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.jinglabels.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.madanyangxue.value, label: keys.madanyangxue.label});

        // if ((filters[keys.extraxue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.madanyangxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.jinglabels.value] && !filters[keys.windowxue.value] &&
        //     !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value])
        options.push({value: keys.extraxue.value, label: keys.extraxue.label});

        // if ((filters[keys.windowxue.value] || filters[keys.allxue.value]) && !filters[keys.wushuxue.value] && !filters[keys.madanyangxue.value] &&
        //     !filters[keys.mu_xue.value] && !filters[keys.beishuxue.value] && !filters[keys.bahuixue.value] && !filters[keys.jiaohuixue.value] &&
        //     !filters[keys.bamaijiaohuixue.value] && !filters[keys.sunsimiaoguixue.value] && !filters[keys.jinglabels.value] &&
        //     !filters[keys.yuanxue.value] && !filters[keys.xiexue.value] && !filters[keys.luoxue.value] && !filters[keys.extraxue.value])
        options.push({value: keys.windowxue.value, label: keys.windowxue.label});
        return options;
    }

    onShowChannel(item) {
        let that = this;
        if (item === "") {
            let channels = this.state.configuration[0]["channels"];
            for (let _item in channels) {
                if (channels[_item]["channel"] === "") continue;
                item += channels[_item]["channel"] + ",";
            }
        }
        that.setState({
            currentChannel: item,
            //filters: _filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    onSearch() {
        let filters = this.state.filters;
        filters[keys.search.value] = !filters[keys.search.value];
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    addChip(value) {
        let filters = this.state.filters;
        let searchText = filters[keys.searchText.value];
        searchText = searchText.length > 0 ? searchText + "," + value : value;
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    };

    removeChip(index) {
        let filters = this.state.filters;
        let chips = filters[keys.searchText.value].split(",");
        chips.splice(index, 1);
        let searchText = "";
        chips.forEach(item => (searchText = searchText.length > 0 ? searchText + "," + item : item));
        filters[keys.searchText.value] = searchText;

        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    onSearchTextChange(e) {
        let filters = this.state.filters;
        filters[keys.searchText.value] = e.target.value;
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    channelChange(e) {
        let value = ""; //all channells
        if (e !== null) {
            for (let id in e) {
                value += e[id]["value"] + ",";
            }
        }
        this.onShowChannel(value);
    }

    xueChange(e) {
        //let value = ""; //all channells
        let filters = this.state.filters;
        filters[keys.xiahexue.value] = false;
        filters[keys.wushuxue.value] = false;
        filters[keys.beishuxue.value] = false;
        filters[keys.mu_xue.value] = false;
        //filters[keys.color.value] = true;

        filters[keys.yuanxue.value] = false;
        filters[keys.xixue.value] = false;
        filters[keys.luoxue.value] = false;
        filters[keys.benxue.value] = false;
        filters[keys.buxue.value] = false;
        filters[keys.xiexue.value] = false;
        filters[keys.bahuixue.value] = false;
        filters[keys.xiahexue.value] = false;
        filters[keys.bamaijiaohuixue.value] = false;
        filters[keys.jiaohuixue.value] = false;
        filters[keys.sunsimiaoguixue.value] = false;
        filters[keys.madanyangxue.value] = false;
        filters[keys.extraxue.value] = false;
        filters[keys.windowxue.value] = false;

        // filters[keys.jingxue.value] = false;
        // filters[keys.yingxue.value] = false;
        // filters[keys.shuxue.value] = false;
        // filters[keys.jingxue2.value] = false;
        // filters[keys.hexue.value] = false;
        //filters[keys.cards.value] = true;;
        filters[keys.jinglabels.value] = false;

        if (e !== null) {
            for (let id in e) {
                filters[e[id]["value"]] = true;
            }
        }

        filters[keys.allxue.value] = e === null || e.length === 0 || !this.isAnyXueOption();
        let that = this;
        this.setState({
            filters: filters
        }, () => {
            //if (that.channels.current.resetItemSummary) that.channels.current.resetItemSummary();
            that.saveData();
        });
    }

    isAnyXueOption() {
        //let value = ""; //all channells
        let filters = this.state.filters;
        return (filters[keys.xiahexue.value] ||
            filters[keys.wushuxue.value] ||
            filters[keys.beishuxue.value] ||
            filters[keys.mu_xue.value] ||
            //filters[keys.color.value] = true;

            filters[keys.yuanxue.value] ||
            filters[keys.xixue.value] ||
            filters[keys.luoxue.value] ||
            filters[keys.benxue.value] ||
            filters[keys.buxue.value] ||
            filters[keys.xiexue.value] ||
            filters[keys.bahuixue.value] ||
            filters[keys.xiahexue.value] ||
            filters[keys.bamaijiaohuixue.value] ||
            filters[keys.jiaohuixue.value] ||
            filters[keys.sunsimiaoguixue.value] ||
            filters[keys.madanyangxue.value] ||
            filters[keys.extraxue.value] ||
            filters[keys.windowxue.value]); //||

        // filters[keys.jingxue.value] ||
        // filters[keys.yingxue.value] ||
        // filters[keys.shuxue.value] ||
        // filters[keys.jingxue2.value] ||
        // filters[keys.hexue.value]);
    }

    render() {
        let _filters = this.state.filters;
        const channelsOptions = this.getChannels();
        if (channelsOptions.length === 0)
            return (<div/>);

        const xuesOptions = this.getXuesOptions(_filters);
        if (xuesOptions.length === 0)
            return (<div/>);

        let channelsDefaultvalue = null;
        let current = this.state.currentChannel.slice(0, -1);

        if (current !== "") {
            channelsDefaultvalue = channelsOptions.filter(option => {
                return (current.indexOf(option.value) !== -1);
            });
        }

        let xuesDefaultvalue = null;
        //if (this.state.currentChannel !== "" && !(this.state.currentChannel.length > 0 && (this.state.currentChannel.indexOf(',') > 0))) {
        xuesDefaultvalue = xuesOptions.filter(option => {
            return (_filters[option.value]);
        });
        //}

        let searchText = _filters[keys.searchText.value];
        let chips = searchText.length > 0 ? searchText.split(",") : [];

        let isSearching = _filters[keys.search.value];
        let searchClass = !isSearching ? "acu-sk-search fa fa-search" : "acu-sk-search-down fa fa-search";
        return (
            <div id="tcme">
                <div className="acu-select-area">
                    <div className="acu-basic-single ">
                        <Select
                            className="acu-basic-single-top1"
                            classNamePrefix="select"
                            name="channels-selector"
                            isMulti={true}
                            defaultValue={channelsDefaultvalue}
                            isSearchable={false}
                            options={channelsOptions}
                            onChange={this.channelChange}
                            isDisabled={false}
                        />
                    </div>
                    <div className="acu-basic-single ">
                        {isSearching ?
                            (<ReactChipInput
                                classes="acu-basic-single-top2"
                                chips={chips}
                                placeholder="co budeme hledat..."
                                onSubmit={value => this.addChip(value)}
                                onRemove={index => this.removeChip(index)}
                            />)


                            //         <input className="basic-single-top2 basic-input pt-input" name="search" type="search" ref={(input) => {
                            //     this.searchInput = input
                            // }} placeholder={"Hledat"} defaultValue={searchText.length > 0 ? searchText : "Hledat"} onChange={this.onSearchTextChange}/>)
                            :
                            (<Select
                                className="acu-basic-single-top2"
                                classNamePrefix="select"
                                name="xue-selector"
                                isMulti={true}
                                defaultValue={xuesDefaultvalue}
                                isSearchable={false}
                                options={xuesOptions}
                                onChange={this.xueChange}
                            />)}
                        <button className={searchClass} onClick={this.onSearch}></button>
                    </div>
                </div>
                <AcuCards
                    ref={this.channels}
                    currentChannel={this.state.currentChannel}
                    filters={this.state.filters}
                    showInfoForm={this.showInfoForm}
                />}

            </div>
        )
    }
}

export default Acu