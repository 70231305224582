import React, {Component} from 'react';
import {Col, Form} from 'react-bootstrap'

import {Row} from "react-bootstrap/esm/index";
import {ids, channels, strings} from "../Configuration/Config";

class ChannleItem extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.calcRatios = this.calcRatios.bind(this);
        this.getValidationClass = this.getValidationClass.bind(this);
        this.getMeasureValidationClass = this.getMeasureValidationClass.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleChange(evt) {
        if(evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.value;

        this.calcRatios(this.props.data, true);
    }

    getValidationClass(value) {
        if(value===undefined) return "";

        return value > 2.5 ? "is-invalid" : "is-valid";
    }

    getMeasureValidationClass(cureId, controlId) {
        if(cureId===undefined) return "";

        return cureId === controlId ? "is-invalid form-control" : "";
    }

    getErrorMessage(cureId, controlId) {
        if(cureId===undefined) return "";

        if(cureId !== controlId) return "";

        return channels[this.props.channelKey].error2.replace("%d", this.props.data[channels[this.props.channelKey].measureRatio]);
    }

    calcRatios(data, bupdate) {
        if(!data) return;

        let value1 = 0;
        let value2 = 0;
        let valueId = "";
        if(data[channels[this.props.channelKey].measureLeft]!==undefined && data[channels[this.props.channelKey].measureRight]!==undefined) {
            if(data[channels[this.props.channelKey].measureLeft].indexOf(ids.separator) > 0) {
                let values = data[channels[this.props.channelKey].measureLeft].split(ids.separator);
                value1 = Number(values[0]*60) + Number(values[1]);

                data[channels[this.props.channelKey].measureLeft] = value1.toString();
            } else {
                value1 = Number(data[channels[this.props.channelKey].measureLeft]);
            }

            if(data[channels[this.props.channelKey].measureRight].indexOf(ids.separator) > 0) {
                let values = data[channels[this.props.channelKey].measureRight].split(ids.separator);
                value1 = Number(values[0]*60) + Number(values[1]);

                data[channels[this.props.channelKey].measureRight] = value2.toString();
            } else {
                value2 = Number(data[channels[this.props.channelKey].measureRight]);
            }

            valueId = channels[this.props.channelKey].measureRatio;
        }

        if(valueId === "" || value1 ===0 || value2 ===0) return;
        let value = value2 / value1;
        if(value1 > value2)
            value = value1 / value2;

        // if(!this.props.calcRatios) {
        //     alert("Funkce není definovaná!!!");
        //     return;
        // }

        //this.props.calcRatios(valueId, Math.round(value * 100) / 100);
        this.props.data[valueId] = (Math.round(value * 100) / 100).toString();
        if(bupdate) this.updateData();
        //this.forceUpdate();
    }

    render() {
        // The markup for the Step 1 UI
        this.calcRatios(this.props.data, false);
        return (
            <div>
                <Form.Row>
                    <Form.Label column sm={3}>{channels[this.props.channelKey].name}</Form.Label>
                    <Form.Group as={Row} controlId={channels[this.props.channelKey].id} className={"col-sm-9"}>
                        <Form.Group as={Col} controlId={channels[this.props.channelKey].measureLeft}>
                            <Form.Control placeholder={strings.measureLeft}
                                          className={this.getMeasureValidationClass(this.props.data[ids.cure],channels[this.props.channelKey].measureLeft)}
                                          onChange={this.handleChange}
                                          defaultValue={this.props.data[channels[this.props.channelKey].measureLeft]}/>
                            <Form.Control.Feedback type="invalid" tooltip>{this.getErrorMessage(this.props.data[ids.cure],channels[this.props.channelKey].measureLeft)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId={channels[this.props.channelKey].measureRight}>
                            <Form.Control placeholder={strings.measureRight}
                                          className={this.getMeasureValidationClass(this.props.data[ids.cure],channels[this.props.channelKey].measureRight)}
                                          onChange={this.handleChange}
                                          defaultValue={this.props.data[channels[this.props.channelKey].measureRight]}/>
                            <Form.Control.Feedback type="invalid" tooltip>{this.getErrorMessage(this.props.data[ids.cure],channels[this.props.channelKey].measureRight)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId={channels[this.props.channelKey].measureRatio}>
                            <Form.Control readOnly
                                          className={this.getValidationClass(this.props.data[channels[this.props.channelKey].measureRatio])}
                                          placeholder={strings.measureRatio}
                                          onChange={this.handleChange}
                                          defaultValue={this.props.data[channels[this.props.channelKey].measureRatio]}/>
                            <Form.Control.Feedback type="invalid" tooltip>{channels[this.props.channelKey].error}</Form.Control.Feedback>
                        </Form.Group>

                    </Form.Group>
                </Form.Row>
            </div>
        )
    }
}

export default ChannleItem