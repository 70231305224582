import React, {Component} from 'react'
import {keys} from "../Constants"
import {acu_data} from "../data/Data"
import {reactLocalStorage} from 'reactjs-localstorage';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Select from 'react-select'
import PointsTable from './PointsTable/PointsTable'
import MethodTable from './PointsTable/MethodTable'
import Cards from "../Cards/Cards";
import './LeungTable.css';
import '../Client.css';
import '../Cards/Cards.css';

class LeungTable extends Component {
    constructor(props) {
        super(props);

        this.saveData = this.saveData.bind(this);
        this.getBagangPoints = this.getBagangPoints.bind(this);
        this.getBagangXues = this.getBagangXues.bind(this);
        this.getBagangMethods = this.getBagangMethods.bind(this);
        this.getOrgans = this.getOrgans.bind(this);
        this.getBodyParts = this.getBodyParts.bind(this);
        this.bodyPartsChange = this.bodyPartsChange.bind(this);
        this.organsChange = this.organsChange.bind(this);

        this.onYinYangValueChanged = this.onYinYangValueChanged.bind(this);
        this.onShiXuValueChanged = this.onShiXuValueChanged.bind(this);
        this.onHanReValueChanged = this.onHanReValueChanged.bind(this);
        this.onBiaoLiValueChanged = this.onBiaoLiValueChanged.bind(this);

        this.state = {
            ready: false,
            table: [],
            filters: [],
            channels: [],
            configuration: [],
            currentOrgans: "",
            currentBodyParts: "",

            yinyangValue: 0,
            shixuValue: 0,
            hanreValue: 0,
            biaoliValue: 0
        }
    }

    saveData() {
        let bagangvalue = this.state.yinyangValue + "," + this.state.shixuValue + "," + this.state.hanreValue + "," + this.state.biaoliValue;
        reactLocalStorage.set(keys.bagangResult.value, bagangvalue);
        reactLocalStorage.set(keys.organsResult.value, this.state.currentOrgans);
        reactLocalStorage.set(keys.bodyPartsResult1.value, this.state.currentBodyParts);
    }

    componentDidMount() {
        let bagangValue = reactLocalStorage.get(keys.bagangResult.value, "0,0,0,0");
        let organsValue = reactLocalStorage.get(keys.organsResult.value, "");
        let bodyPartsValue = reactLocalStorage.get(keys.bodyPartsResult1.value, "");

        let bagangValues = bagangValue.split(",");

        let tempData = this.state.channels;
        tempData = tempData.concat(acu_data.lu);
        tempData = tempData.concat(acu_data.li);
        tempData = tempData.concat(acu_data.st);
        tempData = tempData.concat(acu_data.sp);
        tempData = tempData.concat(acu_data.ht);
        tempData = tempData.concat(acu_data.si);
        tempData = tempData.concat(acu_data.bl);
        tempData = tempData.concat(acu_data.ki);
        tempData = tempData.concat(acu_data.pc);
        tempData = tempData.concat(acu_data.te);
        tempData = tempData.concat(acu_data.gb);
        tempData = tempData.concat(acu_data.lr);
        tempData = tempData.concat(acu_data.cv);
        tempData = tempData.concat(acu_data.gv);
        tempData = tempData.concat(acu_data.extra);

        let leungTable = acu_data.leung;

        let configuration = acu_data.configuration;

        this.setState({
            channels: tempData,
            table: leungTable,
            configuration: configuration,
            currentOrgans: organsValue,
            currentBodyParts: bodyPartsValue,
            yinyangValue: Number(bagangValues[0]),
            shixuValue: Number(bagangValues[1]),
            hanreValue: Number(bagangValues[2]),
            biaoliValue: Number(bagangValues[3]),

            ready: true
        });
    }

    onYinYangValueChanged(val) {
        let that = this;
        this.setState({
            yinyangValue: val
        }, () => {
            that.saveData();
        });
    }

    onShiXuValueChanged(val) {
        let that = this;
        this.setState({
            shixuValue: val
        }, () => {
            that.saveData();
        });
    }

    onHanReValueChanged(val) {
        let that = this;
        this.setState({
            hanreValue: val
        }, () => {
            that.saveData();
        });
    }

    onBiaoLiValueChanged(val) {
        let that = this;
        this.setState({
            biaoliValue: val
        }, () => {
            that.saveData();
        });
    }

    organsChange(e) {
        let current = "";
        if (e !== null) {
            for (let id in e) {
                if (current.length > 0)
                    current += ",";
                current += e[id]["value"];
            }
        }

        let that = this;
        this.setState({
            currentOrgans: current
        }, () => {
            that.saveData();
        });
    }

    getOrgans() {
        let organs = this.state.table[keys.organsTable.value];
        return organs.map(item => {
            let name = item[keys.name.value];
            return {value: name, label: name};
        });
    }

    bodyPartsChange(e) {
        let current = "";
        if (e !== null) {
            for (let id in e) {
                if (current.length > 0)
                    current += ",";
                current += e[id]["value"];
            }
        }

        let that = this;
        this.setState({
            currentBodyParts: current
        }, () => {
            that.saveData();
        });
    }

    getBodyParts() {
        let bodyParts = this.state.table[keys.bodyPartsTable.value];
        return bodyParts.map(item => {
            let name = item[keys.name.value];
            let id = item[keys.id.value];
            return {value: id, label: name};
        });
    }

    getBagangXues(organ, bodyPart) {
        if (organ === "" && bodyPart === "")
            return "";

        let table = this.state.table;

        //organ
        let bagangPoints = "";
        if (organ.length !== 0) {
            let organItems = organ.split(",");
            organItems.forEach(_organ => {
                let nearPoints = table[_organ][keys.nearPoints.value].split(",");
                let centerPoints = table[_organ][keys.centerPoints.value].split(",");
                let distancePoints = table[_organ][keys.distancePoints.value].split(",");

                this.state.channels.forEach(channel => {
                    let allxue = channel[keys.allxue.value].split(",");
                    //near points
                    nearPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //center points
                    centerPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //distance points
                    distancePoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value];///;.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                });
            });
        }

        //body parts
        if (bodyPart.length !== 0) {
            let bodyItems = bodyPart.split(",");
            bodyItems.forEach(_body => {
                let nearPoints = table[_body][keys.nearPoints.value].split(",");
                let centerPoints = table[_body][keys.centerPoints.value].split(",");
                let distancePoints = table[_body][keys.distancePoints.value].split(",");

                this.state.channels.forEach(channel => {
                    let allxue = channel[keys.allxue.value].split(",");
                    //near points
                    nearPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value]//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //center points
                    centerPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //distance points
                    distancePoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (bagangPoints.length > 0)
                                    bagangPoints += ", ";
                                bagangPoints += foundItem[0][keys.id.value];//;.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                });
            });
        }


        return bagangPoints;
    }

    getBagangPoints(organ, bodyPart) {
        if (organ === "" && bodyPart === "")
            return <div/>;

        let table = this.state.table;

        //organ
        let pointsTable = [];
        let organsTable = [];
        let i = 0;
        if (organ.length !== 0) {
            let organItems = organ.split(",");
            organItems.forEach(_organ => {
                let nearPoints = table[_organ][keys.nearPoints.value].split(",");
                let centerPoints = table[_organ][keys.centerPoints.value].split(",");
                let distancePoints = table[_organ][keys.distancePoints.value].split(",");

                //near points
                let _nearPoints = "";
                //center points
                let _centerPoints = "";
                //distance points
                let _distancePoints = "";

                this.state.channels.forEach(channel => {
                    let allxue = channel[keys.allxue.value].split(",");
                    //near points
                    nearPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_nearPoints.length > 0)
                                    _nearPoints += ", ";
                                _nearPoints += foundItem[0][keys.name.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //center points
                    centerPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_centerPoints.length > 0)
                                    _centerPoints += ", ";
                                _centerPoints += foundItem[0][keys.name.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //distance points
                    distancePoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_distancePoints.length > 0)
                                    _distancePoints += ", ";
                                _distancePoints += foundItem[0][keys.name.value];///;.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                });
                organsTable.push(
                    {
                        name: keys[_organ].label.toUpperCase(),
                        nearPoints: _nearPoints,
                        distancePoints: _distancePoints,
                        centerPoints: _centerPoints
                    }
                );

            });
        }

        //body parts
        if (bodyPart.length !== 0) {
            let bodyItems = bodyPart.split(",");
            bodyItems.forEach(_body => {
                let nearPoints = table[_body][keys.nearPoints.value].split(",");
                let centerPoints = table[_body][keys.centerPoints.value].split(",");
                let distancePoints = table[_body][keys.distancePoints.value].split(",");

                //near points
                let _nearPoints = "";
                //center points
                let _centerPoints = "";
                //distance points
                let _distancePoints = "";
                this.state.channels.forEach(channel => {
                    let allxue = channel[keys.allxue.value].split(",");
                    //near points
                    nearPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_nearPoints.length > 0)
                                    _nearPoints += ", ";
                                _nearPoints += foundItem[0][keys.name.value]//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //center points
                    centerPoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_centerPoints.length > 0)
                                    _centerPoints += ", ";
                                _centerPoints += foundItem[0][keys.name.value];//.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                    //distance points
                    distancePoints.forEach(id => {
                        allxue.forEach(xueId => {
                            if (xueId.trim() === id) {
                                let points = channel[keys.xues.value];
                                let foundItem = points.filter(_point => {
                                    return (_point[keys.id.value] === id)
                                });
                                if (_distancePoints.length > 0)
                                    _distancePoints += ", ";
                                _distancePoints += foundItem[0][keys.name.value];//;.push(foundItem[0][keys.name.value]);
                            }
                        });
                    });
                });
                organsTable.push(
                    {
                        name: keys[_body].label.toUpperCase(),
                        nearPoints: _nearPoints,
                        distancePoints: _distancePoints,
                        centerPoints: _centerPoints
                    }
                );

            });
        }

        pointsTable.push(
            <PointsTable
                key={i++}
                table={organsTable}
            />
        );

        return pointsTable;
    }

    getBagangMethods(yinyangValue, shixuValue, hanreValue, biaoliValue, organ, bodyPart) {
        if (yinyangValue === 0 || shixuValue === 0 || hanreValue === 0 || biaoliValue === 0 ||
            (organ === "" && bodyPart === ""))
            return null;

        let biaoli = biaoliValue === 2 ? keys.biao.value : keys.li.value;
        let shixu = shixuValue === 2 ? keys.shi.value : keys.xu.value;
        let hanre = hanreValue === 2 ? keys.re.value : keys.han.value;

        let table = this.state.table;

        //organ
        let pointsTable = [];
        let i = 0;
        //methods
        let currentTable = table[biaoli][shixu][hanre];
        let points = currentTable[keys.points.value].split(",");

        let methodsTable = [];
        points.forEach(point => {
            let methods = currentTable[point].split(",");
            let isMoxa = false;
            let isBU = false;
            let isPBPX = false;
            let isXie = false;
            methods.forEach(method => {
                if (method === keys.moxa.value)
                    isMoxa = true;
                else if (method === keys.bu.value)
                    isBU = true;
                else if (method === keys.pbpx.value)
                    isPBPX = true;
                else if (method === keys.xie.value)
                    isXie = true;
            });

            methodsTable.push(
                {name: keys[point].label.toUpperCase(), moxa: isMoxa, bu: isBU, pbpx: isPBPX, xie: isXie}
            );
        });

        pointsTable.push(
            <MethodTable
                key={i++}
                table={methodsTable}
            />
        );
        return pointsTable;
    }

    render() {
        if (!this.state.ready) return <div/>;

        let heading = "Leungova tabulka bodů v rámci bagang";
//        let heading2 = "Použití";

        //organs
        const organsOptions = this.getOrgans();
        if (organsOptions.length === 0)
            return (<div/>);

        let organsDefaultvalue = null;
        let currentOrg = this.state.currentOrgans;

        if (currentOrg !== "") {
            organsDefaultvalue = organsOptions.filter(option => {
                return (currentOrg.indexOf(option.value) !== -1);
            });
        }

        //body parts
        const bodyPartsOptions = this.getBodyParts();
        if (bodyPartsOptions.length === 0)
            return (<div/>);

        let bodyPartsDefaultvalue = null;
        let currentBP = this.state.currentBodyParts;

        if (currentBP !== "") {
            bodyPartsDefaultvalue = bodyPartsOptions.filter(option => {
                return (currentBP.indexOf(option.value) !== -1);
            });
        }

        let currentChannel = "";
        let channels = this.state.configuration["channels"];
        for (let _item in channels) {
            if (channels[_item]["channel"] === "") continue;
            currentChannel += channels[_item]["channel"] + ",";
        }

        const yinyangValue = this.state.yinyangValue;
        const shixuValue = this.state.shixuValue;
        const hanreValue = this.state.hanreValue;
        const biaoliValue = this.state.biaoliValue;
        let bagangPoints = this.getBagangPoints(currentOrg, currentBP);
        let bagangxue = this.getBagangXues(currentOrg, currentBP);
        let filters = this.state.filters;
        filters[keys.bagangxue.value] = bagangxue;
        let bagangMethods = this.getBagangMethods(yinyangValue, shixuValue, hanreValue, biaoliValue, currentOrg, currentBP);
        return (
            <div id="tcme">
                <div className="channel-heading channel-heading-top normal-color">{heading}</div>
                <div className="leung-basic-single">
                    <Select
                        className="acu-basic-single-top-o"
                        classNamePrefix="select"
                        name="organ-selector"
                        isMulti={true}
                        defaultValue={organsDefaultvalue}
                        isSearchable={false}
                        options={organsOptions}
                        onChange={this.organsChange}
                    />
                </div>
                <div className="leung-basic-single">
                    <Select
                        className="acu-basic-single-top-p"
                        classNamePrefix="select"
                        name="bodyPart-selector"
                        isMulti={true}
                        defaultValue={bodyPartsDefaultvalue}
                        isSearchable={false}
                        options={bodyPartsOptions}
                        onChange={this.bodyPartsChange}
                    />
                </div>
                <div className="leung-basic-single centered-div">
                    <ToggleButtonGroup type="radio" name="options" value={yinyangValue}
                                       onChange={this.onYinYangValueChanged} className="leung-table-inlineRow mb-2">
                        <ToggleButton
                            variant="dark"
                            value={1}
                            size="lg"
                        >
                            YIN
                        </ToggleButton>
                        <ToggleButton
                            variant="dark"
                            value={2}
                            size="lg"
                        >
                            YANG
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup type="radio" name="options" value={shixuValue}
                                       onChange={this.onShiXuValueChanged} className="leung-table-inlineRow mb-2">
                        <ToggleButton
                            variant="dark"
                            value={1}
                            size="lg"
                        >
                            XU
                        </ToggleButton>
                        <ToggleButton
                            variant="dark"
                            value={2}
                            size="lg"
                        >
                            SHI
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup type="radio" name="options" value={hanreValue}
                                       onChange={this.onHanReValueChanged} className="leung-table-inlineRow mb-2">
                        <ToggleButton
                            variant="dark"
                            value={1}
                            size="lg"
                        >
                            HAN
                        </ToggleButton>
                        <ToggleButton
                            variant="dark"
                            value={2}
                            size="lg"
                        >
                            RE
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup type="radio" name="options" value={biaoliValue}
                                       onChange={this.onBiaoLiValueChanged} className="leung-table-inlineRow mb-2">
                        <ToggleButton
                            variant="dark"
                            value={1}
                            size="lg"
                        >
                            LI
                        </ToggleButton>
                        <ToggleButton
                            variant="dark"
                            value={2}
                            size="lg"
                        >
                            BIAO
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <div className="leung-basic-single">
                    {bagangPoints}
                </div>
                {bagangMethods ?
                    <div>
                        {/*<div className="channel-heading channel-heading-top normal-color">{heading2}</div>*/}
                        <div className="leung-basic-single">
                            {bagangMethods}
                        </div>
                    </div>
                    : <div/>}
                <Cards
                    currentChannel={currentChannel}
                    filters={filters}
                    showInfoForm={this.showInfoForm}
                />
            </div>
        )
    }
}

export default LeungTable