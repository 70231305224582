import React, {Component} from 'react'
//import CompareCard from "./CompareCard";

import './CompareCards.css';
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {keys} from "../../Acu/Constants";
import {ids} from "../../Utils/Constants";
import {Link} from "react-router-dom";

class CompareCards extends Component {
    constructor(props) {
        super(props);

        this.getItems = this.getItems.bind(this);
        this.getSameItems = this.getSameItems.bind(this);
        this.nameFormater = this.nameFormater.bind(this);
        this.imageFormatter = this.imageFormatter.bind(this);
        this.usageFormatter = this.usageFormatter.bind(this);
        this.herbsFormatter = this.herbsFormatter.bind(this);
        this.getHerbsData = this.getHerbsData.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);

        this.state = {
            items: this.props.items
        };

        this.sameItems = [];
    }

    getHerbsData(data, id) {
        if(data.length < 1 ) return undefined;

        let herb = data.Herbs.filter(item => {
            return item.id === id;
        });

        return herb.length > 0 ? herb[0] : undefined;
    }

    getItems(items, products) {
        if(items===undefined || items.length === 0)
            return [];

        let compareItems = [];
        //let i = 0;
        items.forEach(item => {
            products.forEach(product => {
                if(product.id===item) {
                    let usages = product.Impact.map(item => {
                        return item.description;
                    });
                    let herbs = product.Herbs.map(item => {
                        return item.id;
                    });
                    compareItems.push(
                            {   "id": product.id,
                              //  "imgName": product.img,
                                "name": product.name,
                                "usage": usages,
                                "herbs": herbs
                            }
                    )

                    return;
                }
            });
        });
        return compareItems;
    }

    getSameItems(items, products) {
        if(items===undefined || items.length === 0)
            return false;

        const firstItem = items[0];
        let firtsHerbs = [];
        for (let productKey in products) {
            const product =  products[productKey];
            if(product.id===firstItem) {
                firtsHerbs = product.Herbs;
                break;
            }
        }

       this.sameItems = [];
        for (let firstHerbKey in firtsHerbs) {
            let firstHerb = firtsHerbs[firstHerbKey];
            let found = false;
            for (let i = 1; i < items.length; i++) {
                const item = items[i];
                for (let id in products) {
                    const product = products[id];
                    if (product.id === item) {
                        for (let herbKey in product.Herbs) {
                            let herb = product.Herbs[herbKey];
                            if (herb.id === firstHerb.id) {
                                found = true;
                                break;
                            }
                            found = false;
                        }
                    }
                }
                if(!found)
                    break;
            }

            if(found)
                this.sameItems.push(firstHerb);
        }

        return true;
    }

    nameFormater(cell, row, enumObject, index) {
        return <Link to={"/fyto/" + row.id} className="btn-default button-detail-link" data-key={row.id} >{cell}</Link>
    }

    imageFormatter(cell, row, enumObject, index) {
        const imgName = ids.imgFytoPath + row[keys.imgname.value].toLowerCase();
        return <img src={imgName} className="img-fluid img-thumbnail" alt=""/>;
    }

    usageFormatter(cell, row, enumObject, index) {
        let i = 0;
        const usages = row[keys.usage.value].map(item => {
            return <li className="fyto-card-summary-item" key={i++}>{item}</li>;
        });
        return (<ul className="fyto-card-summary-list">
            {usages}
        </ul>)
    }

    herbsFormatter(cell, row, enumObject, index) {
        let i = 0;

        const herbs = row[keys.herbs.value].map(item => {
            let herb = this.getHerbsData(this.props.herbs, item);
            let setBold = false;
            for (let key in this.sameItems) {
                const sameItem =  this.sameItems[key];
                if(herb.id===sameItem.id) {
                    setBold = true;
                    break;
                }
            }
            if(setBold)
                return <li className="fyto-card-summary-item fyto-card-item-bolt" key={i++}>{herb.orig + " - " + herb.group}</li>;
            return <li className="fyto-card-summary-item" key={i++}>{herb.orig + " - " + herb.group}</li>;
        });
        return (<ul className="fyto-card-summary-list">
            {herbs}
        </ul>)
    }

    onRowSelect(row, isSelected, e) {
        if (!isSelected ) {
            if(e.target.localName==="a" && e.target.innerText===row.name)
                return;
            let items = this.state.items;
            const index = items.indexOf(row.id);
            if (index > -1) {
                items.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.setState({
                items: items
            })
            return true;
        }
    }

    render() {
        const items = this.getItems(this.state.items, this.props.products);
        if(items.length === 0) return <div></div>

        ///get same items
        this.getSameItems(this.state.items, this.props.products);
        let empty = "";
        const options = {
            // page: 2,  // which page you want to show as default
            // sizePerPageList: [ {
            //     text: '5', value: 5
            // }, {
            //     text: '10', value: 10
            // }, {
            //     text: 'All', value: products.length
            // } ], // you can change the dropdown list for size per page
            sizePerPage: 15,  // which size per page you want to locate as default
            //    pageStartIndex: 0, // where to start counting the pages
            //    paginationSize: 3,  // the pagination bar size.
            // prePage: 'Prev', // Previous page button text
            // nextPage: 'Next', // Next page button text
            // firstPage: 'First', // First page button text
            // lastPage: 'Last', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            hideSizePerPage: true //> You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };

        const selectedRows = [];
        for(let key in items) {
            selectedRows.push(items[key].id);
        }

        const selectRow = {
            mode: 'checkbox',
            selected: selectedRows,
            clickToSelect: true,
            onSelect: this.onRowSelect
        };

        return (
            <div>
                <BootstrapTable data={items}
                                striped
                                hover
                                selectRow={ selectRow }
                                pagination={ false }
                                options={ options }
                                tableHeaderClass='leung-table'
                                tableBodyClass='leung-table'
                                trClassName='leung-table'
                                tableStyle={ { backgroundColor: 'transparent', width: '100%', marginBottom: '0px',  marginLeft: 'auto', marginRight: 'auto', marginTop: '5px'} }
                >
                    <TableHeaderColumn isKey
                                       dataField='id'
                                       width='1%'
                                       hidden>ID</TableHeaderColumn>
                    {/*<TableHeaderColumn thStyle={ {fontSize: '18px'}}*/}
                    {/*                   className='leung-table'*/}
                    {/*                   width='10%'*/}
                    {/*                   dataSort={ false }*/}
                    {/*                   dataField={keys.imgname.value}*/}
                    {/*                   dataFormat={ this.imageFormatter }*/}
                    {/*>{empty}</TableHeaderColumn>*/}
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='12%'
                                       dataSort={ false }
                                       dataField={keys.name.value}
                                       dataFormat={this.nameFormater}
                    >{keys.name.label}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='42%'
                                       dataField={keys.usage.value}
                                       dataFormat={this.usageFormatter}
                    >{keys.usage.label}</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px', textAlign: 'center'}}
                                       className='leung-table'
                                       width='42%'
                                       dataField={keys.herbs.value}
                                       dataFormat={this.herbsFormatter}
                    >{keys.herbs.label}</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default CompareCards
