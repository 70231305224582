import React, {Component} from 'react'
import {fytoIds} from './ProductInfo'
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

import './Products.css';
import {Link} from "react-router-dom";
class HerbInfo extends Component {
    constructor(props, context) {
        super(props, context);

        this.linkFormatter = this.linkFormatter.bind(this);
        this.getHerbsData = this.getHerbsData.bind(this);
    }

    getHerbsData(data, id) {
        if (data.length < 1) return undefined;

        let herb = data.Herbs.filter(item => {
            return item.id === id;
        });

        return herb.length > 0 ? herb[0] : undefined;
    }

    linkFormatter(item) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    <Link to={"/fyto/" + _items[1]} className="button-link" key={i}
                          data-key={_items[1]}>{_items[0]}</Link>
                );
            }

            return token + maybeSpace;
        });

        return (
            <div className="inlineRow">{contents}</div>
        );
    }

    render() {
        const items = this.props.itemId.split(fytoIds.herbInfoSep);
        if(items.length < 1)
            return <div />

        let herbInfo = this.getHerbsData(this.props.herbs, items[1]);

        let basicInfo = [];
        basicInfo.push({[fytoIds.id]: "0",[fytoIds.nature]: herbInfo.nature,[fytoIds.taste]: herbInfo.taste,[fytoIds.tropism]: herbInfo.tropism});

        let effetcs = [];
        if (herbInfo.effects) {
            effetcs = herbInfo.effects.map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.effect]: _item.effect};
            });
        }

        let contraindications = [];
        contraindications.push({[fytoIds.id]: "0",[fytoIds.contraindication]: herbInfo.contraindication});

        let formulas = [];
        if (herbInfo.formulas) {
            formulas = herbInfo.formulas.map(_item => {
                return {[fytoIds.id]: "0", [fytoIds.formula]: _item.formula};
            });
        }
        return (
            <div className="productInfo">
                <h2 className="productHeading">{herbInfo.orig.toUpperCase()}</h2>
                <div className="herbInfo-upper-2">#{herbInfo.number}</div>
                <h4 className="designationHeading">{herbInfo.name}</h4>
                <h3 className="designationHeading">{herbInfo.group}</h3>
                <div className="marginTop">
                    <BootstrapTable data={basicInfo}
                                    trClassName='inlineRow'
                                    striped
                                    hover
                                    pagination={false}
                                    tableStyle={{border: '#000 2.5px solid'}}
                    >
                        <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                                           // dataFormat={this.impactFormatter}
                                           dataField={fytoIds.nature}>povaha:</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                                           // dataFormat={this.impactFormatter}
                                           dataField={fytoIds.taste}>chuť:</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                                            // dataFormat={this.impactFormatter}
                                           dataField={fytoIds.tropism}>tropismus:</TableHeaderColumn>
                    </BootstrapTable>
                </div>

                <div className="marginTop">
                    <BootstrapTable data={effetcs}
                                    trClassName='inlineRow'
                                    striped
                                    hover
                                    pagination={false}
                                    tableStyle={{border: '#000 2.5px solid'}}
                    >
                        <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                            // dataFormat={this.impactFormatter}
                                           dataField={fytoIds.effect}>účinky:</TableHeaderColumn>
                    </BootstrapTable>
                </div>

                <div className="marginTop">
                    <BootstrapTable data={contraindications}
                                    trClassName='inlineRow'
                                    striped
                                    hover
                                    pagination={false}
                                    tableStyle={{border: '#000 2.5px solid'}}
                    >
                        <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                            // dataFormat={this.impactFormatter}
                                           dataField={fytoIds.contraindication}>kontraindikace:</TableHeaderColumn>
                    </BootstrapTable>
                </div>

                <div className="marginTop">
                    <BootstrapTable data={formulas}
                                    trClassName='inlineRow'
                                    striped
                                    hover
                                    pagination={false}
                                    tableStyle={{border: '#000 2.5px solid'}}
                    >
                        <TableHeaderColumn isKey dataField='id' hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{whiteSpace: 'normal'}}
                                           thStyle={{fontSize: '18px'}}
                                           dataFormat={this.linkFormatter}
                                           dataField={fytoIds.formula}>směsi:</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        )
    }
}

export default HerbInfo;