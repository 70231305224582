import React, {Component} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {fyto_data} from '../../Fyto/data/Data';
//styles
import './HerbsTable.css'
import {Link} from "react-router-dom";
import {fytoIds} from "../Products/ProductInfo";

class HerbsTable extends Component {
    constructor(props) {
        super(props);

        this.onLoadTable = this.onLoadTable.bind(this);
        this.herbLinkFormatter = this.herbLinkFormatter.bind(this);
        this.state = {
            table: []
        };
    }
    componentWillMount() {
        // fetch('/fyto_data/herbs.json')
        //     .then(res => res.json())
        this.onLoadTable(fyto_data.herbs);
    }

    onLoadTable(data) {
        //sort
        data.Herbs.sort((a,b) => Number(a.number) - (Number(b.number)));
        this.setState({
            table: data
        });
    }

    herbLinkFormatter(herbName, herbId) {
        return (
            <Link to={"/fyto/" + fytoIds.herbInfo + fytoIds.herbInfoSep + herbId} className="button-link" key={herbId} data-key={herbId}>{herbName}</Link>
        );
    }

    render() {
        if(this.state.table.length === 0)
            return <div/>;

        const items = this.state.table.Herbs.map(herb => {
            return {
                [fytoIds.id]: herb.id,
                [fytoIds.orig]: this.herbLinkFormatter(herb.orig, herb.id),
                [fytoIds.number]: herb.number,
                [fytoIds.group]: herb.group,
                [fytoIds.name]: herb.name
            };
        });

        const options = {
            // page: 2,  // which page you want to show as default
            // sizePerPageList: [ {
            //     text: '5', value: 5
            // }, {
            //     text: '10', value: 10
            // }, {
            //     text: 'All', value: products.length
            // } ], // you can change the dropdown list for size per page
            sizePerPage: 20,  // which size per page you want to locate as default
        //    pageStartIndex: 0, // where to start counting the pages
        //    paginationSize: 3,  // the pagination bar size.
            // prePage: 'Prev', // Previous page button text
            // nextPage: 'Next', // Next page button text
            // firstPage: 'First', // First page button text
            // lastPage: 'Last', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            hideSizePerPage: true //> You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };

        return (
            <div>
                <BootstrapTable data={items}
                                striped
                                hover
                                pagination={ true }
                                options={ options }
                                tableStyle={ {border: '#000 2.5px solid' , width: '98%', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px'} }
                >
                    <TableHeaderColumn isKey
                                       dataField={fytoIds.id}
                                       hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='170'
                                       dataFormat={this.herbLinkFormatter}
                                       dataSort={ true }
                                       dataField={fytoIds.orig}
                    >Orig název</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='200'
                                       dataField={fytoIds.number}
                    >Číslo (dle Farmak.)</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       dataField={fytoIds.group}
                    >Skupina (dle Farmakologie)</TableHeaderColumn>
                    <TableHeaderColumn thStyle={ {fontSize: '18px'}}
                                       width='400'
                                       dataSort={ false }
                                       dataField={fytoIds.name}
                    >Jméno</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }


}

export default HerbsTable;