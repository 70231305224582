import React, {Component} from 'react'
import Card from "./Card";

import './Cards.css';
import './ProductCards.css';

class FytoCards extends Component {
    constructor(props) {
        super(props);

        this.getAllCards = this.getAllCards.bind(this);
    }

    getAllCards(products) {
        if(products===undefined || products.length === 0)
            return <div />;

        let cards = [];
        let i = 0;
        products.forEach(product => (
            cards.push(
                <Card
                    key={i++}
                    id={product.id}
                    product={product}
                    compareItems={this.props.compareItems}
                    scrollToProduct={this.props.scrollToProduct}
                    compareProducts={this.props.compareProducts}
                />
            )
        ));
        return cards;
    }

    render() {
        let cards = this.getAllCards(this.props.items);

        return (
            <div className="card-container">
                {cards}
            </div>
        )
    }
}

export default FytoCards
