import React, {Component} from 'react';
import {ids, strings, data} from "../Configuration/Config";
import {Form} from 'react-bootstrap'
import {calculateDayMasters, calculateTongShuAlmanach} from "../Master/UtilityCalc";

//tong shu
class Step8 extends Component {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handlePrevMonth = this.handlePrevMonth.bind(this);
        this.handleNextMonth = this.handleNextMonth.bind(this);
        this.handleView = this.handleView.bind(this);
        this.generateMonthView = this.generateMonthView.bind(this);
        this.generateWeekView = this.generateWeekView.bind(this);
        this.generateDayView = this.generateDayView.bind(this);
        this.generateView = this.generateView.bind(this);

        this.getYear = this.getYear.bind(this);
        this.getMonth = this.getMonth.bind(this);

        const actualDate = new Date();
        let actualMonth = actualDate.getMonth(); //< 0 - 11 >
        let actualYear = actualDate.getFullYear();
        this.state = {
            month: actualMonth,
            year: actualYear,
            view: ids.monthView
        };
    }

    updateData() {
        if (!this.props.updateData) {
            alert("Funkce pro občerstvení data není definovaná!!!");
            return;
        }

        this.props.updateData();
    }

    handleView(evt) {
        let newView = evt.target.getAttribute("data-key");

        this.setState({
            view: newView
        });
    }

    handleChecked(evt) {
        if (evt === null) return;
        let id = evt.target.id;
        this.props.data[id] = evt.target.checked === true ? "1" : "0";

        this.updateData();
    }

    handlePrevMonth(evt) {
        let _month = this.state.month - 1;
        let _year = this.state.year;
        if (_month < 0) {
            _year = _year - 1;
            _month = _month + 12;
        }

        this.setState({
            year: _year,
            month: _month
        });
    }

    handleNextMonth(evt) {
        let _month = this.state.month + 1;
        let _year = this.state.year;
        if (_month > 11) {
            _year = _year + 1;
            _month = _month - 12;
        }

        this.setState({
            year: _year,
            month: _month
        });
    }

    getYear() {
        return this.state.year;
    }

    getMonth() {
        let _month = this.state.month;
        let _year = this.state.year;

        let actualDate = new Date();
        actualDate.setMonth(_month);
        actualDate.setFullYear(_year);
        let locale = "cs-cz",
            month = actualDate.toLocaleString(locale, {month: "long"});
        return month;
    }

    generateView() {
        let _view = this.state.view;
        if (_view === ids.weekView)
            return this.generateWeekView();
        else if (_view === ids.dayView)
            return this.generateDayView();

        return this.generateMonthView();
    }

    generateDayView() {
        let _month = this.state.month;
        let _year = this.state.year;

        const daysInWeek = 7;
        let firstDayOfMonth = (new Date(_year, _month)).getDay();
        if (firstDayOfMonth === 0)
            firstDayOfMonth = 7;
        let daysInMonth = 32 - new Date(_year, _month, 32).getDate();

        let weeksInMonth = Math.ceil((firstDayOfMonth + daysInMonth) / daysInWeek);
    }

    generateWeekView() {
        let _month = this.state.month;
        let _year = this.state.year;

        const daysInWeek = 7;
        let firstDayOfMonth = (new Date(_year, _month)).getDay();
        if (firstDayOfMonth === 0)
            firstDayOfMonth = 7;
        let daysInMonth = 32 - new Date(_year, _month, 32).getDate();

        let weeksInMonth = Math.ceil((firstDayOfMonth + daysInMonth) / daysInWeek);
        let weekView = [];
        weekView.push(
            <table>
                <tbody>
                <tr>
                    <th>Company</th>
                    <th>Contact</th>
                    <th>Country</th>
                </tr>
                <tr>
                    <td>Alfreds Futterkiste</td>
                    <td>Maria Anders</td>
                    <td>Germany</td>
                </tr>
                <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                    <td>Mexico</td>
                </tr>
                </tbody>
            </table>
        );

        return weekView;
    }

    generateMonthView() {
        let _month = this.state.month;
        let _year = this.state.year;

        //today
        let currDate = new Date();
        let currDay = currDate.getDate();
        let currMonth = currDate.getMonth();
        let currYear = currDate.getFullYear();

        const daysInWeek = 7;
        let firstDayOfMonth = (new Date(_year, _month)).getDay();
        if (firstDayOfMonth === 0)
            firstDayOfMonth = 7;
        let daysInMonth = 32 - new Date(_year, _month, 32).getDate();

        let weeksInMonth = Math.ceil((firstDayOfMonth + daysInMonth) / daysInWeek);

        let calcData = {};
        calcData[ids.year] = _year;
        calcData[ids.month] = _month;
        const results = this.props.data[ids.results];
        calcData[ids.birthYearTongShu] = results[ids.year][ids.eb];
        calcData[ids.birthMonthTongShu] = results[ids.month][ids.eb];
        let dayMasters = calculateTongShuAlmanach(calcData, data);

        let blanksArr = [];
        for (let i = 1; i < firstDayOfMonth; i++) {
            let key = "blanka" + i.toString();
            blanksArr.push(<td key={key} className="calendar__day__cell-blank"></td>);
        }

        let daysInMonthArr = [];
        for (let d = 1; d <= daysInMonth; d++) {
            // let currentDay = d == this.currentDay() ? "today" : "";
            let key = "days" + d.toString();
            const dm = dayMasters[d - 1];
            daysInMonthArr.push(
                <td key={key} className="calendar__day__cell">
                    <div className="calendar__day-wrapper">
                        <div data-actual={currMonth === _month && currYear === _year && currDay === d}
                             className="calendar__day__cell-day">
                            <span>{d}</span>
                        </div>
                        <div className="calendar__day__cell-stack">
                            <div className="calendar__day__cell-day-master" data-color="orange">{dm[ids.description]}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.yearCollision] ? "" : " hide")} data-color="black">{strings.dyc}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.monthCollision] ? "" : " hide")} data-color="blue">{strings.dmc}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.dayCollision] ? "" : " hide")} data-color="red">{strings.dpc}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersYear1] ? "" : " hide")} data-color="green">{strings.killersYear1}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersYear2] ? "" : " hide")} data-color="green">{strings.killersYear2}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersYear3] ? "" : " hide")} data-color="green">{strings.killersYear3}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersMonth1] ? "" : " hide")} data-color="darkgreen">{strings.killersYear1}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersMonth2] ? "" : " hide")} data-color="darkgreen">{strings.killersYear2}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.killersMonth3] ? "" : " hide")} data-color="darkgreen">{strings.killersYear3}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.establish] ? "" : " hide")} data-color="darkcyan">{strings.establish}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.remove] ? "" : " hide")} data-color="darkcyan">{strings.remove}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.full] ? "" : " hide")} data-color="darkcyan">{strings.full}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.stable] ? "" : " hide")} data-color="darkcyan">{strings.stable}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.initiate] ? "" : " hide")} data-color="darkcyan">{strings.initiate}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.destruction] ? "" : " hide")} data-color="darkcyan">{strings.destruction}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.balance] ? "" : " hide")} data-color="darkcyan">{strings.balance}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.danger] ? "" : " hide")} data-color="darkcyan">{strings.danger}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.success] ? "" : " hide")} data-color="darkcyan">{strings.success}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.receive] ? "" : " hide")} data-color="darkcyan">{strings.receive}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.open] ? "" : " hide")} data-color="darkcyan">{strings.open}</div>
                            <div className={"calendar__day__cell-day-master" + (dm[ids.close] ? "" : " hide")} data-color="darkcyan">{strings.close}</div>
                        </div>
                    </div>
                </td>
                //       <td key={d} className={`calendar-day ${currentDay}`}>
                // <span
                //     onClick={e => {
                //         this.onDayClick(e, d);
                //     }}
                // >
                //   {d}
                // </span>
                //       </td>
            );
        }

        let totalSlots = [...blanksArr, ...daysInMonthArr];

        if (totalSlots.length < weeksInMonth * daysInWeek) {//add missing empty cells
            for (let i = totalSlots.length; i < weeksInMonth * daysInWeek; i++) {
                let key = "blankb" + i.toString();
                totalSlots.push(<td key={key} className="calendar__day__cell-blank"></td>);
            }
        }
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % daysInWeek !== 0) {
                cells.push(row);
            } else {
                rows.push(cells);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                // let insertRow = cells.slice();
                rows.push(cells);
            }
        });

        let row = 0;
        let daysArray = rows.map((d, i) => {
            row++;
            let key = "row" + row.toString();
            return <tr key={key}>{d}</tr>;
        });
        return (<table className="calendar">
            <thead>
            <tr>
                <th className="calendar__day__header">{strings.monday}</th>
                <th className="calendar__day__header">{strings.tuesday}</th>
                <th className="calendar__day__header">{strings.wednesday}</th>
                <th className="calendar__day__header">{strings.thursday}</th>
                <th className="calendar__day__header">{strings.friday}</th>
                <th className="calendar__day__header">{strings.saturday}</th>
                <th className="calendar__day__header">{strings.sunday}</th>
            </tr>
            </thead>
            <tbody>
            {daysArray}
            </tbody>
        </table>);
    }

    render() {
        if (!this.props.isAdvanced) return null;
        let generated = this.props.data[ids.generated];
        let checked = !generated ? false : this.props.data[ids.tongShu] === "1";

        const calendar = this.generateView();
        // The markup for the Step 1 UI
        return (
            <div className={"upper-border"}>
                <Form.Row>
                    <Form.Label column="lg" lg={5}>{strings.tongShu}</Form.Label>
                    <Form.Check
                        className={"item-switcher"}
                        type="switch"
                        id={ids.tongShu}
                        label=""
                        disabled={!generated}
                        checked={checked}
                        onChange={this.handleChecked}
                    />
                </Form.Row>
                {checked ?
                    <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard" /*style={{height: "500px"}}*/>
                        <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                            <div className="fc-toolbar-chunk">
                                <div className="fc-button-group">
                                    <button onClick={this.handlePrevMonth} type="button" title={strings.previousMonth}
                                            aria-pressed="false"
                                            className="fc-prev-button fc-button fc-button-primary"><span
                                        className="fc-icon fc-icon-chevron-left"></span></button>
                                    <button onClick={this.handleNextMonth} type="button" title={strings.nextMonth}
                                            aria-pressed="false"
                                            className="fc-next-button fc-button fc-button-primary"><span
                                        className="fc-icon fc-icon-chevron-right"></span></button>
                                </div>
                            </div>
                            <div className="fc-toolbar-chunk"><h2 className="fc-toolbar-title"
                                                                  id="fc-dom-2">{this.getMonth()}</h2></div>
                            <div className="fc-toolbar-chunk"><h2 className="fc-toolbar-title"
                                                                  id="fc-dom-2">{this.getYear()}</h2></div>
                            <div className="fc-toolbar-chunk">
                                <div className="fc-button-group">
                                    <button type="button" data-key={ids.monthView} title={strings.monthView}
                                            aria-pressed="true"
                                            onClick={(event) => {
                                                this.handleView(event)
                                            }}
                                            className="fc-dayGridMonth-button fc-button fc-button-primary fc-button-active">{strings.month}
                                    </button>
                                    <button type="button" data-key={ids.weekView} title={strings.weekView}
                                            aria-pressed="false"
                                            onClick={(event) => {
                                                this.handleView(event)
                                            }}
                                            className="fc-timeGridWeek-button fc-button fc-button-primary">{strings.week}
                                    </button>
                                    <button type="button" data-key={ids.dayView} title={strings.dayView}
                                            aria-pressed="false"
                                            onClick={(event) => {
                                                this.handleView(event)
                                            }}
                                            className="fc-timeGridDay-button fc-button fc-button-primary">{strings.day}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {calendar}
                    </div> : <div/>
                }
            </div>
        )
    }
}

export default Step8