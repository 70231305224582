import React, {Component} from 'react';
import {keys} from "../../Acu/Constants"
import './Info.css';

class InfoForm extends Component {
    constructor(props) {
        super(props);

        this.closeMe = this.closeMe.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.infoFormatter = this.infoFormatter.bind(this);
    }

    componentDidMount() {
        let that = this;
        setTimeout(function() { that.closeMe() }, 5000);
    }

    closeMe() {
        this.props.onClose();
    }

    getInfo(data, key) {
        if (!data || data.toString().length === 0 || key.length === 0) {
            return;
        }
        let item = data[key];
        let i = 0;
        const descriptions = item[keys.description.value].map(item => {
            return <li className="info-item" key={i++}>{this.infoFormatter(item[keys.description.value])}</li>
        });

        let headInfo = item[keys.name.value].toUpperCase() + " (" + item[keys.pronuncination.value] + ") - " + item[keys.translation.value];

        return (
            <button href="#" title="zavřít" className="info-link" onClick={this.closeMe}>
                <h1 className="info-heading">{headInfo}</h1>
                <ul className="info">
                    {descriptions}
                </ul>
            </button>
        );
    }

    infoFormatter(data) {
        const tokens = data.split(' ');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';
            if (token.match('#') !== null) {
                let _items = token.split('#');
                return (
                    <b key={i}>{_items[1] + _items[2] + maybeSpace}</b>
                );
            }
            if(token.match('@') !== null) {
                let _items = token.split('@');
                return (
                    <i key={i}>{_items[1] + maybeSpace}</i>
                );
            }
            return token + maybeSpace;
        });

        return (
            <div className="inlineRow">{contents}</div>
        );
    }

     render() {
        let extraData = this.props.extraData;
        let extraDataKey = this.props.extraDataKey;

        let info = this.getInfo(extraData, extraDataKey);
        return (
            <div>
                {info}
            </div>
        );
    }


}

export default InfoForm;