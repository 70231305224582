import React, {Component} from 'react'
import ReactCardFlip from 'react-card-flip';
import {syndromIds, syndromStrings} from "../Products/Constants";
import "./Cards.css"

const enums = {
  fyto: {value: "fyto"},
  acu: {value: "acu"},
  mushrooms: {value: "mushrooms"}
};

class GeneralCard extends Component {
    constructor(props) {
        super(props);

        this.linkFormatter = this.linkFormatter.bind(this);
        this.handleOnFlip = this.handleOnFlip.bind(this);

        this.getFrontItemSummary = this.getFrontItemSummary.bind(this);
        this.getBackItemSummary = this.getBackItemSummary.bind(this);
        this.showFytoCard = this.showFytoCard.bind(this);
        this.showAcuCard = this.showAcuCard.bind(this);
        this.showMushroomCard = this.showMushroomCard.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
        this.state = {
            isFlipped: false
        };
    }

    handleOnFlip(event) {
        event.preventDefault();
        this.setState(prevState => ({isFlipped: !prevState.isFlipped}));
    }

    showFytoCard(e) {
        e.stopPropagation();
        if (!this.props.showFytoCard) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        this.props.showFytoCard(e);
    }

    showAcuCard(e) {
        e.stopPropagation();
        if (!this.props.showAcuCard) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        this.props.showAcuCard(e);
    }

    showMushroomCard(e) {
        e.stopPropagation();
        if (!this.props.showMushroomCard) {
            alert("Funkce pro zobrazení informací o vybraném stránce není definovaná!!!");
            return;
        }

        this.props.showMushroomCard(e);
    }

    getFrontItemSummary(data) {
        if (data === undefined)
            return <div className="summary-heading">žádné info</div>;

        let i = 0;
        const etiology = data[syndromIds.etiology].map(item => {
            return <li className="syndroms-card-summary-item" key={i++}>{item.description}</li>;
        });

        const keyprincips = data[syndromIds.keyprincip].map(item => {
            return <li className="syndroms-card-summary-item" key={i++}>{item.description}</li>;
        });

        let fytoArr = data[syndromIds.fyto].split(syndromIds.separator);
        let fytos = [];
        let fytoAll = "";
        fytoArr.forEach(fyto => (
            fytos.push(this.linkFormatter(fyto, enums.fyto.value))
        ));

        let acuArr = data[syndromIds.acu].split(syndromIds.separator);
        let acus = [];
        let acuAll = "";
        acuArr.forEach(acu => (
            acus.push(this.linkFormatter(acu, enums.acu.value))
        ));

        let mushroomsArr = data[syndromIds.mushrooms].split(syndromIds.separator);
        let mushrooms = [];
        //let mushroomsAll = "";
        mushroomsArr.forEach(mushroom => (
            mushrooms.push(this.linkFormatter(mushroom, enums.mushrooms.value))
        ));

        return (
            <div>
                <div
                    className="syndroms-card-summary-heading-items card-center">{data.number + " " + data.syndrom.toUpperCase()}</div>
                <div className="card-summary-text card-center">{"(" + data.name + ")"}</div>
                <div className="card-margin"></div>
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.etiology}</div>
                <ul className="card-summary-list">
                    {etiology}
                </ul>
                <div className="card-margin"></div>
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.keysymptom}</div>
                <ul className="card-summary-list">
                    <li className="syndroms-card-summary-item" key={i++}>{data.keysymptom}</li>
                </ul>
                <div className="card-margin"></div>
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.keyprincip}</div>
                <ul className="card-summary-list">
                    {keyprincips}
                </ul>
                {!data[syndromIds.addtoback] ?
                    <div>
                        <div className="card-margin"></div>
                        <button
                            className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space normal-color syndroms-button-link"
                            data-key={acuAll} onClick={(event) => {
                            this.showAcuCard(event)
                        }}>{syndromStrings.acu}</button>
                        <div className="card-summary-list">
                            {acus}
                        </div>
                        <div className="card-margin"></div>
                        <button
                            className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space normal-color syndroms-button-link"
                            data-key={fytoAll}
                            onClick={(event) => {
                            this.showFytoCard(event)
                        }}>{syndromStrings.fyto}</button>
                        <div className="card-summary-list">
                            {fytos}
                        </div>
                        <div className="card-summary-list">
                            {mushrooms}
                        </div>
                    </div> :
                    <div/>}
            </div>
        );
    }

    getBackItemSummary(data) {
        if (data === undefined)
            return <div className="summary-heading">žádné info</div>;

        let i = 0;
        const symptoms = data[syndromIds.symptom].map(item => {
            return <li className="syndroms-card-summary-item" key={i++}>{item.description}</li>;
        });

        let notes = [];
        if (data.note) {
            notes = data.note.map(_item => {
                return <li className="syndroms-card-summary-item" key={i++}>{_item.description}</li>;
            });
        }

        let fytoArr = data[syndromIds.fyto].split(syndromIds.separator);
        let fytos = [];
        let fytoAll = "";
        fytoArr.forEach(fyto => (
            fytos.push(this.linkFormatter(fyto, enums.fyto.value))
        ));

        let acuArr = data[syndromIds.acu].split(syndromIds.separator);
        let acus = [];
        let acuAll = "";
        acuArr.forEach(acu => (
            acus.push(this.linkFormatter(acu, enums.acu.value))
        ));

        let mushroomsArr = data[syndromIds.mushrooms].split(syndromIds.separator);
        let mushrooms = [];
        //let mushroomsAll = "";
        mushroomsArr.forEach(mushroom => (
            mushrooms.push(this.linkFormatter(mushroom, enums.mushrooms.value))
        ));

        return (
            <div>
                {/*<div className="card-margin"></div>*/}
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.symptoms}</div>
                <ul className="card-summary-list">
                    {symptoms}
                </ul>
                <div className="card-margin"></div>
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.pulse}</div>
                <ul className="card-summary-list">
                    <li className="syndroms-card-summary-item" key={i++}>{data.pulse}</li>
                </ul>
                <div className="card-margin"></div>
                <div
                    className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.tongue}</div>
                <ul className="card-summary-list">
                    <li className="syndroms-card-summary-item" key={i++}>{data.tongue}</li>
                </ul>
                {notes.length > 0 ?
                    (<div>
                        <div className="card-margin"></div>
                        <div
                            className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space">{syndromStrings.note}</div>
                        <ul className="card-summary-list">
                            {notes}
                        </ul>
                    </div>) : <div/>}
                {data[syndromIds.addtoback] ?
                    <div>
                        <div className="card-margin"></div>
                        <button
                            className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space normal-color syndroms-button-link"
                            data-key={acuAll} onClick={(event) => {
                            this.showAcuCard(event)
                        }}>{syndromStrings.acu}</button>
                        <div className="card-summary-list">
                            {acus}
                        </div>
                        <div className="card-margin"></div>
                        <button
                            className="syndroms-card-summary-heading-items syndroms-card-summary-heading-space normal-color syndroms-button-link"
                            data-key={fytoAll} onClick={(event) => {
                            this.showFytoCard(event)
                        }}>{syndromStrings.fyto}</button>
                        <div className="card-summary-list">
                            {fytos}
                        </div>
                        <div className="card-summary-list">
                            {mushrooms}
                        </div>
                    </div> :
                    <div/>}
            </div>);
    }

    linkFormatter(item, key) {
        let tokens = item.split('#');
        let contents = tokens.map((token, i) => {
            let hasSpace = i !== (tokens.length - 1);
            let maybeSpace = hasSpace ? ' ' : '';

            if (token.match('~')) {
                let _items = token.split('~');
                return (
                    this.renderSwitch(_items[0], _items[1], i, key)
                );
            } else {
                return <i className="syndroms-button-rest">{token + maybeSpace}</i>;// + maybeSpace;
            }
        });

        return contents;
    };

    renderSwitch(label, param, id, key) {
        switch(key) {
            case enums.fyto.value:
                return (<button className="normal-color syndroms-button-link" key={id} data-key={param}
                                         onClick={(event) => {
                                             this.showFytoCard(event)
                                         }}>{label}</button>);
             case enums.acu.value:
                 return (<button className="normal-color syndroms-button-link" key={id} data-key={param}
                                 onClick={(event) => {
                                     this.showAcuCard(event)
                                 }}>{label}</button>);
            case enums.mushrooms.value:
                return (<button className="normal-color syndroms-button-link" key={id} data-key={param}
                                onClick={(event) => {
                                    this.showMushroomCard(event)
                                }}>{label}</button>);
            default:
                return 'foo';
        }
    }

    render() {
        //let _currentPage = this.props.currentPage;
        let data = this.props.syndrom;
        let itemFrontSummary = this.getFrontItemSummary(data);
        let itemBackSummary = this.getBackItemSummary(data);

//        let classes = this.getItemClasses(data, currentItemId, point, this.props.filters);
        const frontClass = "syndroms-frontSide normal-color ";
        const backClass = "syndroms-backSide normal-color ";

        const mainClass = "syndroms-my-card";// + (this.isVisible(data, currentItemId, this.props.filters)===true ? "" : " card-hide");
        return (
            <div className={mainClass} id={this.props.id}>
                <ReactCardFlip
                    isFlipped={this.state.isFlipped}
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                >
                    <div className={frontClass} onClick={this.handleOnFlip}>
                        {itemFrontSummary}
                    </div>

                    <div className={backClass} onClick={this.handleOnFlip}>
                        {itemBackSummary}
                    </div>
                </ReactCardFlip>
            </div>
        )
    }
}

export default GeneralCard
