import React, { useContext } from "react";
import { AuthContext } from "../../Authentication";

import Bazi from './Bazi'

const BaziRoute = ({ ...rest }) => {
    const {currentUser} = useContext(AuthContext);
    return (
           !currentUser ? (
                    <Bazi isAdvanced={true}/>
                ) : (
                    <BaziNew/>
                )

    );
};

function BaziNew() {
    // 👇️ redirect to external URL
    window.location.replace('https://www.jkprojects.cz/subdom/bazinew');

    return null;
}

export default BaziRoute