import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AuthProvider} from './Authentication'
import PrivateRoute from './PrivateRoute'
import Home from './components/Home/Home'
import Signup from './components/Signup/Signup'
import Login from './components/Login/Login'
import ResetPswd from './components/ResetPswd/ResetPswd'
import NotFound from './components/NotFound/NotFound'
import Fyto from './components/Fyto/Fyto'
import Syndroms from './components/Syndroms/Syndroms'
import Acu from './components/Acu/Acu'
import EarAcu from './components/EarAcu/EarAcu'
import Matrix from './components/Matrix/Matrix'
import LeungTable from './components/Acu/LeungTable/LeungTable'
import TaoTable from './components/Acu/TaoTable/TaoTable'
import BachEssence from './components/Bach/BachEssence'
import Akabane from './components/Akabane/Akabane'
import BaziRoute from './components/Bazi/BaziRoute'
import Barcode from './components/Barcode/Barcode'

import './styles/foundation.min.css';
import './App.css';

class App extends Component {
    render() {
        return (
            <AuthProvider>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <PrivateRoute path="/fyto" component={Fyto}/>
                        <PrivateRoute path="/syndroms" component={Syndroms}/>
                        <PrivateRoute path="/acu" component={Acu}/>
                        <PrivateRoute path="/earacu" component={EarAcu}/>
                        <PrivateRoute path="/matrix" component={Matrix}/>
                        <PrivateRoute path="/leung" component={LeungTable}/>
                        <PrivateRoute path="/tao" component={TaoTable}/>
                        <PrivateRoute path="/bachovky" component={BachEssence}/>
                        <PrivateRoute path="/akabane" component={Akabane}/>
                        <PrivateRoute path="/barcode" component={Barcode}/>
                        {/*<PrivateRoute path="/baziadv" component={BaziAdv}/>*/}
                        <Route path="/bazi" component={BaziRoute}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/signup" component={Signup}/>
                        <Route path="/reset" component={ResetPswd}/>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </Router>
            </AuthProvider>
        );
    }
}

export default App;
