import React, {Component} from 'react'
import Products from "./Products/Products";

import '../../styles/custom.css';

class Fyto extends Component {
    render() {
        let pathArr = this.props.location["pathname"].split('/');
        let productId = "";
        if(pathArr.length === 3)
            productId = pathArr[2];
        return (
            <div id="tcme">
                <Products productId={productId}/>
            </div>
        )
    }
}

export default Fyto;