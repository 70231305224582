import React, {Component} from 'react';


class ResultsActionBar extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = { };
        // this.onChange = this.onChange.bind(this);
        this.onShowMeaningTable = this.onShowMeaningTable.bind(this);
        this.onShowHerbsTable = this.onShowHerbsTable.bind(this);
        this.onSearchAnd = this.onSearchAnd.bind(this);
        this.onCompareProducts = this.onCompareProducts.bind(this);
        //this.onFilterSellableItems = this.onFilterSellableItems.bind(this);
        // this.onSwitchToSyndroms = this.onSwitchToSyndroms.bind(this);
    }

    // onChange(e) {
    //     if(!this.props.search) {
    //         alert("Funkce pro hledání není definovaná!!!");
    //         return;
    //     }
    //     this.props.search(e.target.value);
    // }

    onShowMeaningTable(e) {
        if(!this.props.onShowMeangsTable) {
            alert("Funkce pro zobrazení informace o písmených kódů není definovaná!!!");
            return;
        }
        this.props.onShowMeangsTable();
    }

    onShowHerbsTable(e) {
        if(!this.props.onShowHerbsTable) {
            alert("Funkce pro zobrazení informace o použitých bylinách není definovaná!!!");
            return;
        }
        this.props.onShowHerbsTable();
    }

    onSearchAnd(e) {
        if(!this.props.onShowHerbsTable) {
            alert("Funkce pro hledání produktu není definovaná!!!");
            return;
        }
        this.props.onSearchAnd();
    }

    onCompareProducts(e) {
        if(!this.props.onCompareProducts) {
            alert("Funkce pro porovnání produktu není definovaná!!!");
            return;
        }
        this.props.onCompareProducts();
    }

    // onFilterSellableItems(e) {
    //     if(!this.props.onFilterSellableItems) {
    //         alert("Funkce pro filtrování prodejných produktů není definovaná!!!");
    //         return;
    //     }
    //     this.props.onFilterSellableItems(!this.props.isBuyable);
    // }

    // onSwitchToSyndroms(e) {
    //     if(!this.props.onSwitchToSyndroms) {
    //         alert("Funkce pro filtrování dle syndromů není definovaná!!!");
    //         return;
    //     }
    //     this.props.onSwitchToSyndroms(!this.props.isSyndroms);
    // }

    render() {
        const classBtn1Filter = this.props.isMeaningTable ? "sk-hits-stats__table button-filter btn-down fa fa-list" : "sk-hits-stats__table button-filter btn-default fa fa-list";
        const classBtn2Filter = this.props.isHerbsTable ? "sk-hits-stats__table button-filter btn-down fa fa-database" : "sk-hits-stats__table button-filter btn-default fa fa-database";
         const classBtn3Filter = this.props.isSearchAnd ? "sk-hits-stats__table button-filter btn-down fa fa-search-plus" : "sk-hits-stats__table button-filter btn-default fa fa-search-plus";
         const classBtn4Filter = this.props.isCompareProductsTable ? "sk-hits-stats__table button-filter btn-down fa fa-balance-scale" : "sk-hits-stats__table button-filter btn-default fa fa-balance-scale";
        // const classBtn4Filter = this.props.isSyndroms ? "sk-hits-stats__table button-filter btn-down fa fa-tasks" : "sk-hits-stats__table button-filter btn-default fa fa-tasks";
        return (
            <div className="select-area-result">
            <div className="sk-action-bar">
                <div className="sk-action-bar-row">
                    <div className="sk-hits-stats" data-qa="hits-stats">
                        <div className="sk-hits-stats__info" data-qa="info">Nalezeno {this.props.items ? this.props.items.length : 0} výsledků</div>
                        <button title="význam písmených kódů" className={classBtn1Filter} onClick={this.onShowMeaningTable} />
                        <button title="seznam použitých bylinek" className={classBtn2Filter} onClick={this.onShowHerbsTable} />
                        <button title="hledat produkty zároveň" className={classBtn3Filter} onClick={this.onSearchAnd} />
                        <button title="porovnat produkty" className={classBtn4Filter} onClick={this.onCompareProducts} />
                        {/*<button title="filtrovat produkty, které nelze koupit" className={classBtn3Filter} onClick={this.onFilterSellableItems} />*/}
                        {/*<button title="filtrovat dle syndromů" className={classBtn4Filter} onClick={this.onSwitchToSyndroms} />*/}
                    </div>
                </div>
            </div>
                <div className="sk-action-bar-line"/>
            </div>
        );
    }
}

export default ResultsActionBar;
